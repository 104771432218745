import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CreditCardValidate,
  DonationPurchase,
  RequestError,
} from '@open-tender/types'
import { useDonationsForm } from '@open-tender/utils'
import {
  purchaseDonation,
  resetDonation,
  selectBrand,
  selectCustomer,
  selectCustomerCreditCardsForPayment,
  selectDonation,
  selectRecaptcha,
  setAlert,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  ButtonSubmit,
  CreditCard,
  FormError,
  FormFieldset,
  FormInputsView,
  FormLegend,
  FormRecaptcha,
  FormSubmit,
  Input,
  Recaptcha,
  Select,
} from 'components'
import styled from '@emotion/styled'

const handleAmountError = (error: string) => {
  if (!error) return null
  return error.includes('money') ? 'Amount must be a positive number' : error
}

const DonationFormSuccessLink = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.layout.margin} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0 0;
  }
`

const DonationForm = () => {
  const dispatch = useAppDispatch()
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [newCard, setNewCard] = useState<CreditCardValidate | null>(null)
  const { use_enterprise_recaptcha } = useAppSelector(selectBrand) || {}
  const { donations: includeRecaptcha } = useAppSelector(selectRecaptcha) || {}
  const { profile: customer } = useAppSelector(selectCustomer) || {}
  const creditCards = useAppSelector(selectCustomerCreditCardsForPayment)
  const { success, loading, error, donation } = useAppSelector(selectDonation)
  const isSubmitDisabled =
    includeRecaptcha && !use_enterprise_recaptcha && !recaptchaToken

  const purchase = useCallback(
    (data: DonationPurchase, callback?: () => void) =>
      dispatch(purchaseDonation({ data, callback })),
    [dispatch]
  )
  const reset = useCallback(() => dispatch(resetDonation()), [dispatch])
  const showAlert = useCallback(
    (obj: { type: string }) => dispatch(setAlert(obj)),
    [dispatch]
  )
  const { card, cardType, errors: cardErrors } = newCard || {}

  const {
    inputRef,
    submitRef,
    recaptchaRef,
    amount,
    handleAmount,
    email,
    handleEmail,
    errors,
    errMsg,
    newCardErrors,
    submitting,
    isNewCard,
    creditCard,
    creditCardOptions,
    handleCreditCard,
    handleSubmit,
    handleReset,
  } = useDonationsForm(
    purchase,
    reset,
    showAlert,
    loading,
    error as RequestError, // TODO: remove @open-tender/cloud
    success,
    customer || undefined,
    creditCards,
    includeRecaptcha,
    card,
    cardType
  )
  const allCardErrors = { ...cardErrors, ...newCardErrors }

  useEffect(() => {
    if (donation) window.scrollTo(0, 0)
  }, [donation])

  return donation ? (
    <>
      <FormFieldset>
        <FormLegend
          as="div"
          title="Success! Please check your email for your receipt."
          subtitle={`Thanks for your contribution of $${donation.amount}. We really
            appreciate it.`}
          style={{ textAlign: 'center' }}
        />
        <FormSubmit>
          <Button onClick={handleReset}>Make Another Contribution</Button>
        </FormSubmit>
      </FormFieldset>
      <DonationFormSuccessLink>
        {customer ? (
          <Link to="/account">Head back to your account page</Link>
        ) : (
          <Link to="/account">
            Head back to the home page to start an order
          </Link>
        )}
      </DonationFormSuccessLink>
    </>
  ) : (
    <>
      <form id="donation-form" onSubmit={handleSubmit} noValidate>
        <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
        <FormFieldset>
          <FormLegend
            as="div"
            title="Enter an amount and an email address"
            subtitle="We'll send a receipt to the email address you enter below."
          />
          <FormInputsView>
            <Input
              ref={inputRef}
              label="Contribution Amount"
              name="amount"
              type="text"
              pattern="[0-9]*"
              value={amount || ''}
              onChange={(evt) => handleAmount(evt.target.value)}
              error={handleAmountError(errors.amount)}
              required={true}
            />
            <Input
              label="Your Email"
              name="email"
              type="email"
              value={email || ''}
              onChange={(evt) => handleEmail(evt.target.value)}
              error={errors.email}
              required={true}
              disabled={customer ? true : false}
            />
          </FormInputsView>
        </FormFieldset>
        <FormFieldset>
          {!isNewCard && creditCards.length > 0 ? (
            <>
              <FormLegend
                as="div"
                title="Add your payment information"
                subtitle="Choose an existing credit card or add new one from your
                  account page."
              />
              <FormInputsView>
                <Select
                  type="select"
                  label="Choose Card"
                  name="credit_card"
                  value={creditCard?.customer_card_id}
                  onChange={(evt) => handleCreditCard(evt.target.value)}
                  error={errors.credit_card}
                  required={true}
                  options={creditCardOptions.map((option) => ({
                    ...option,
                    value: option.value as string,
                    name: option.name,
                    disabled: false,
                  }))}
                />
              </FormInputsView>
            </>
          ) : (
            <>
              <FormLegend
                as="div"
                title="Add your payment information"
                subtitle="Please enter your payment info below."
              />
              <CreditCard
                setCard={setNewCard}
                formErrors={allCardErrors}
                showDefault={false}
              />
            </>
          )}

          {includeRecaptcha && (
            <FormRecaptcha>
              <Recaptcha
                onChange={setRecaptchaToken}
                recaptchaRef={recaptchaRef}
              />
            </FormRecaptcha>
          )}
          <FormSubmit style={{ margin: '3rem 0 0' }}>
            <ButtonSubmit
              disabled={isSubmitDisabled}
              submitRef={submitRef}
              submitting={submitting}
            >
              {submitting ? 'Submitting...' : 'Submit Contribution'}
            </ButtonSubmit>
          </FormSubmit>
        </FormFieldset>
      </form>
    </>
  )
}

export default DonationForm
