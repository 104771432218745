import { useTheme } from '@emotion/react'

interface HamburgerProps {
  size: number
  strokeWidth?: number
}

const Hamburger = ({ size, strokeWidth, ...rest }: HamburgerProps & any) => {
  const { icons } = useTheme()

  return (
    <svg
      width={size}
      viewBox="0 0 25 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth || icons.strokeWidth}
      strokeLinecap="square"
      overflow="visible"
      {...rest}
    >
      <path
        d="M3.36499 5H21.365"
        strokeWidth={strokeWidth || icons.strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.36499 12H21.365"
        strokeWidth={strokeWidth || icons.strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.36499 19H21.365"
        strokeWidth={strokeWidth || icons.strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Hamburger
