import { ReactNode } from 'react'
import { useTheme } from '@emotion/react'
import { isBrowser } from 'react-device-detect'
import { useImage } from '@open-tender/utils'
import { selectBrand, useAppSelector } from '@open-tender/cloud'
import { ImageSpinner } from 'components'
import {
  MenuItemBackgroundImage,
  MenuItemImageLoading,
  MenuItemImagePlaceholder,
  MenuItemImageView,
} from './MenuItemImage.styled'

const MenuItemImage = ({
  imageUrl,
  alt,
  children,
  className,
}: {
  imageUrl: string
  alt: string
  className?: string
  children?: ReactNode | ReactNode[]
}) => {
  const { openGraphImage } = useAppSelector(selectBrand) || {}
  const { hasLoaded, hasError } = useImage(imageUrl)
  const isLoading = !hasLoaded && !hasError
  const theme = useTheme()
  const { asImg } = theme.menuItems.image
  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl}` } : undefined
  const showPlaceholder = !imageUrl && openGraphImage ? true : false

  return (
    <MenuItemImageView className={className}>
      {bgStyle && isLoading && (
        <MenuItemImageLoading>
          <ImageSpinner size={isBrowser ? 24 : 16} />
        </MenuItemImageLoading>
      )}
      {hasLoaded ? (
        <>
          {asImg ? (
            <img src={imageUrl} alt={alt} />
          ) : (
            <MenuItemBackgroundImage style={bgStyle}>
              &nbsp;
            </MenuItemBackgroundImage>
          )}
        </>
      ) : showPlaceholder ? (
        <MenuItemImagePlaceholder>
          <img src={openGraphImage || undefined} alt={alt} />
        </MenuItemImagePlaceholder>
      ) : null}
      {children}
    </MenuItemImageView>
  )
}

export default MenuItemImage
