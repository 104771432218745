import { Discounts } from '@open-tender/types'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { Reward, ScrollableSection } from 'components'
import AccountSection from './AccountSection'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { useMemo } from 'react'

const AccountDealsSection = styled(AccountSection)`
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

const AccountDeals = ({ deals }: { deals: Discounts }) => {
  const account = useAppSelector(selectContentSection('account'))
  const { title } = account?.deals || {}

  const items = useMemo(() => {
    return isMobile ? deals : deals.slice(0, 3)
  }, [deals])

  return (
    <AccountDealsSection>
      <ScrollableSection
        title={title}
        to={deals.length > 2 ? '/deals' : undefined}
        items={items}
        renderItem={({ item }) => <Reward item={item} withoutImage />}
        keyName="discount_id"
      />
    </AccountDealsSection>
  )
}

export default AccountDeals
