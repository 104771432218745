import styled from '@emotion/styled'
// import ScrollableSectionHeader from './ScrollableSectionHeader'
import Loading from './Loading'
import AccountSectionHeader from './pages/Account/AccountSectionHeader'

const ScrollableSectionView = styled.div``

const ScrollableSectionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: auto;
  margin: -1.5rem 0;
  padding-left: ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: -1.5rem -${(props) => props.theme.layout.paddingMobile};
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollableSectionItem = styled.div`
  flex: 0 0 33.33%;
  padding: 1.5rem ${(props) => props.theme.layout.padding} 1.5rem 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex: 0 0 18rem;
    padding-right: 0;
    margin-right: ${(props) => props.theme.layout.paddingMobile};
  }

  &:first-of-type {
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin-left: ${(props) => props.theme.layout.paddingMobile};
    }
  }
`

const ScrollableSectionLoading = styled.div`
  padding: 2rem ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.layout.paddingMobile};
  }
`

const ScrollableSection = <T extends {}>({
  title,
  to,
  items,
  renderItem,
  keyName,
  isLoading,
  loadingText,
}: {
  title?: string
  to?: string
  items: T[]
  renderItem: (item: any) => JSX.Element
  keyName: string
  isLoading?: boolean
  loadingText?: string
}) => {
  const hasItems = items && items.length > 0 ? true : false
  return (
    <ScrollableSectionView>
      <AccountSectionHeader title={title} to={to} />
      {hasItems ? (
        <ScrollableSectionContainer>
          {items.map((item, index) => (
            <ScrollableSectionItem
              key={`${item[keyName as keyof T] as string}-${index}`}
            >
              {renderItem({ item })}
            </ScrollableSectionItem>
          ))}
        </ScrollableSectionContainer>
      ) : isLoading ? (
        <ScrollableSectionLoading>
          <Loading text={loadingText ?? ''} style={{ textAlign: 'left' }} />
        </ScrollableSectionLoading>
      ) : null}
    </ScrollableSectionView>
  )
}

export default ScrollableSection
