import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const makeStyles = () => css`
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: sans-serif;
    min-height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul,
  legend {
    margin: 0;
    padding: 0;
  }
`

export const LoadingContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.4rem;
    margin: 1em 0;
  }
`

export const FatalErrorView = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #fff;
  background-color: #640018;
  line-height: 1.5;
  font-size: 1.6rem;
  text-align: center;

  > div {
    width: 100%;
    max-width: 72rem;
    padding: 2rem;
  }

  h1 {
    line-height: 1;
  }

  p {
    font-size: 1.4rem;
    margin: 1em 0;

    strong {
      font-size: 1.8rem;
      font-weight: 600;
    }

    a {
      color: #fff;
      transition: all 0.15s ease;
      &:hover,
      &:active,
      &:focus {
        color: #000;
      }
    }
  }

  pre {
    overflow-x: scroll;
  }
`
