import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  selectContent,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonLink,
  FormWrapper,
  PageContainer,
  PageTitle,
  SignUpGuestForm,
} from 'components'

const defaultText = {
  title: 'Sign up or checkout as a guest',
  subtitle: 'To checkout as a guest, you can skip the password field.',
  showGuest: undefined,
}

const CheckoutSignUpEmail = styled.div`
  margin: 2rem 0;

  p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const CheckoutSignUpDefault = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { checkoutFlow } = useAppSelector(selectContent) || {}
  const { title, subtitle, showGuest } =
    checkoutFlow?.sections?.signUp || defaultText

  const changeEmail = () => {
    dispatch(updateForm({ customer: {} }))
    navigate('/checkout/guest')
  }

  return (
    <PageContainer style={{ maxWidth: '76.8rem' }}>
      <PageTitle title={title} subtitle={subtitle}>
        <CheckoutSignUpEmail>
          <p>
            <ButtonLink onClick={changeEmail}>
              Go back & enter a different email address
            </ButtonLink>
          </p>
        </CheckoutSignUpEmail>
      </PageTitle>
      <FormWrapper>
        <SignUpGuestForm showGuest={showGuest} />
      </FormWrapper>
    </PageContainer>
  )
}

export default CheckoutSignUpDefault
