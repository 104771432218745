import { useNavigate } from 'react-router-dom'
import {
  openModal,
  selectOrder,
  selectGroupOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button } from 'components'
import { Users } from 'components/icons'

const GroupOrder = ({ showIcon = true }: { showIcon?: boolean }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { cartGuest } = useAppSelector(selectGroupOrder)
  const { revenueCenter } = useAppSelector(selectOrder)
  const hasGroupOrdering = !!(
    revenueCenter?.group_ordering && revenueCenter.revenue_center_type === 'OLO'
  )

  const review = () => {
    const reviewOrders = () => navigate(`/review`)
    dispatch(openModal({ type: 'groupOrder', args: { reviewOrders } }))
  }

  if (!hasGroupOrdering || cartGuest) return null

  return (
    <Button
      icon={showIcon ? <Users size={18} /> : undefined}
      onClick={review}
      color="header"
      size="header"
    >
      Group Order
    </Button>
  )
}

export default GroupOrder
