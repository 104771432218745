import { isMobile } from 'react-device-detect'
import HeaderLogoSmall from './HeaderLogoSmall'
import HeaderLogoWithName from './HeaderLogoWithName'
import { FC } from 'react'

type HeaderLogoDeviceProps = {
  isSmall?: boolean
  isLarge?: boolean
}

const HeaderLogoDevice: FC<HeaderLogoDeviceProps> = ({
  isSmall = false,
  isLarge = false,
}) => {
  return isMobile ? <HeaderLogoSmall /> : <HeaderLogoWithName />
}

export default HeaderLogoDevice
