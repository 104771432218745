import styled from '@emotion/styled'
import { MenuCategory } from '@open-tender/types'
import MenuSquare from './MenuSquare'

const MenuSquaresView = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.categories.desktop.containerMaxWidth};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: ${(props) => props.theme.categories.mobile.containerMaxWidth};
  }
`

const MenuSquaresContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-content: ${(props) => props.theme.categories.desktop.justifyContent};
  margin: -${(props) => props.theme.categories.desktop.gapHalf};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: ${(props) => props.theme.categories.mobile.justifyContent};
    margin: -${(props) => props.theme.categories.mobile.gapHalf};
  }
`

const MenuSquares = ({
  sections,
  categories,
}: {
  sections: Partial<MenuCategory>[]
  categories: Partial<MenuCategory>[]
}) => {
  if (!categories || !categories.length) return null

  return (
    <MenuSquaresView>
      <MenuSquaresContainer>
        {sections.map((section) => (
          <MenuSquare
            key={section.name}
            category={section}
            section={section.name}
          />
        ))}
        {categories.map((category) => (
          <MenuSquare key={category.name} category={category} />
        ))}
      </MenuSquaresContainer>
    </MenuSquaresView>
  )
}

export default MenuSquares
