import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { DisplayItem } from '@open-tender/types'
import { makeSimpleCart } from '@open-tender/utils'
import {
  useAppDispatch,
  addCustomerFavorite,
  removeCustomerFavorite,
} from '@open-tender/cloud'
import { Heart } from '../icons'

const FavoriteView = styled.button<{
  filled?: boolean
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-align: center;
  width: ${(props) => props.theme.favorite.size};
  height: ${(props) => props.theme.favorite.size};
  color: ${(props) => props.theme.colors.primary};

  svg {
    fill: ${(props) => (props.filled ? props.theme.colors.primary : null)};
  }

  &:hover {
    color: ${(props) => props.theme.links.primary.color};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      color: ${(props) => props.theme.colors.primary};
    }

    svg {
      fill: ${(props) =>
        props.filled ? props.theme.links.primary.color : null};
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        fill: ${(props) => (props.filled ? props.theme.colors.primary : null)};
      }
    }
  }
`

const FavoriteIcon = styled.span`
  display: block;
  line-height: 0;
  width: ${(props) => props.theme.favorite.iconSize};
  height: ${(props) => props.theme.favorite.iconSize};
`

const Favorite = ({
  item,
  favoriteId,
}: {
  item: DisplayItem
  favoriteId: number | null
}) => {
  const dispatch = useAppDispatch()
  const [filled, setFilled] = useState<boolean>(false)

  const handleAdd = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setFilled(true)
    const cart = makeSimpleCart([item])
    dispatch(addCustomerFavorite({ item: cart[0] }))
  }

  const handleRemove = (evt: React.MouseEvent<HTMLButtonElement>) => {
    if (!favoriteId) return
    evt.preventDefault()
    setFilled(false)
    dispatch(removeCustomerFavorite({ favoriteId }))
  }

  useEffect(() => {
    setFilled(favoriteId ? true : false)
  }, [favoriteId])

  return (
    <FavoriteView
      onClick={favoriteId ? handleRemove : handleAdd}
      aria-label={favoriteId ? 'Remove favorite' : 'Add favorite'}
      filled={filled}
    >
      <FavoriteIcon>
        <Heart />
      </FavoriteIcon>
    </FavoriteView>
  )
}

export default Favorite
