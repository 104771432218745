import styled from '@emotion/styled'

// as of 2020-07-12, this is only used in <GoogleMapsAutocomplete /> and
// and <CheckoutPromoCodes /> and not exported for use outside of this library

const ButtonClearView = styled.button`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  border: 0.1rem solid ${(props) => props.theme.links.primary.color};
  color: ${(props) => props.theme.links.primary.color};
  opacity: 1;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0.2rem;
    left: 0.6rem;
    content: ' ';
    height: 0.9rem;
    width: 0.1rem;
    background-color: ${(props) => props.theme.links.primary.color};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const ButtonClear = ({
  onClick,
  ariaLabel,
  disabled,
  style,
}: {
  onClick: () => void
  ariaLabel: string
  disabled?: boolean
  style?: React.CSSProperties
}) => {
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!disabled) onClick()
  }

  return (
    <ButtonClearView
      type="button"
      aria-label={ariaLabel}
      onClick={(evt) => handleClick(evt)}
      disabled={disabled}
      style={style}
    />
  )
}

export default ButtonClear
