import { Fragment, useEffect } from 'react'
import { TPLSTerms } from '@open-tender/types'
import {
  fetchTerms,
  selectBrand,
  selectTerms,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import Body from 'components/Body'
import styled from '@emotion/styled'

const termsNames: Record<keyof TPLSTerms, string> = {
  loyalty_terms: "Loyalty Program's Terms of Use",
  privacy_policy: 'Privacy Policy',
  terms_of_use: 'Terms & Conditions',
}

const makeLinks = (urls: TPLSTerms | null) => {
  if (!urls) return null
  const links = Object.entries(termsNames).map(([key, name]) => {
    const href = urls[key as keyof TPLSTerms]
    return { name, href }
  })
  return links.filter((i) => i.href)
}

const ThirdPartyLoyaltyTermsView = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.paddingMobile};
  }
`

const ThirdPartyLoyaltyTerms = ({
  action = 'registering',
  includeMarketing = true,
}: {
  action?: string
  includeMarketing?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { terms_url, privacy_url } = useAppSelector(selectBrand) || {}
  const { terms, loading, error } = useAppSelector(selectTerms)

  useEffect(() => {
    dispatch(fetchTerms())
  }, [dispatch])

  const links = makeLinks(terms)

  if (!links && !error) return null

  return (
    <ThirdPartyLoyaltyTermsView>
      {loading === 'pending' ? (
        <Body as="p" size="small">
          Retrieving terms & conditions...
        </Body>
      ) : (
        <Body as="p" size="small">
          By {action}, I hereby agree to the{' '}
          {links ? (
            links.map(({ href, name }, index) => (
              <Fragment key={href}>
                <a href={href} rel="noopener noreferrer" target="_blank">
                  {name}
                </a>
                {index < links.length ? ', ' : ''}
              </Fragment>
            ))
          ) : (
            <>
              {terms_url && (
                <>
                  <a href={terms_url} rel="noopener noreferrer" target="_blank">
                    Terms & Conditions
                  </a>{' '}
                </>
              )}
              {terms_url && privacy_url ? ' and ' : ''}
              {privacy_url && (
                <>
                  <a
                    href={privacy_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </>
              )}
            </>
          )}
          {includeMarketing
            ? ` and to receive marketing communications via the selected marketing
          channels.`
            : '.'}
        </Body>
      )}
    </ThirdPartyLoyaltyTermsView>
  )
}

export default ThirdPartyLoyaltyTerms
