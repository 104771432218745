import { Checkmark } from 'components'
import { PlusCircle, XCircle } from 'components/icons'
import {
  CheckoutButtonApply,
  CheckoutButtonButton,
  CheckoutButtonButtonIcon,
  CheckoutButtonCheckmark,
  CheckoutButtonIcon,
  CheckoutButtonInfo,
  CheckoutButtonRemove,
  CheckoutButtonSubtitle,
  CheckoutButtonText,
  CheckoutButtonTitle,
  CheckoutButtonView,
} from './CheckoutButton.styled'
import { isMobile } from 'react-device-detect'

interface CheckoutButtonProps {
  icon?: JSX.Element
  title?: string
  subtitle?: JSX.Element | string | null
  finePrint?: string
  onPress?: (evt: any) => void
  isApplied?: boolean
  disabled?: boolean
  applyText?: string
}

const CheckoutButton = ({
  icon,
  title,
  subtitle,
  finePrint,
  onPress,
  isApplied,
  disabled,
  applyText = 'Apply',
}: CheckoutButtonProps) => {
  const iconSize = isMobile ? 16 : 18

  return (
    <CheckoutButtonView
      onClick={onPress}
      disabled={disabled}
      isApplied={isApplied}
    >
      {isApplied ? (
        <CheckoutButtonCheckmark>
          <Checkmark />
        </CheckoutButtonCheckmark>
      ) : null}
      <CheckoutButtonInfo>
        {icon && (
          <CheckoutButtonIcon isApplied={isApplied}>{icon}</CheckoutButtonIcon>
        )}
        <CheckoutButtonText>
          <CheckoutButtonTitle isApplied={isApplied}>
            {title}
          </CheckoutButtonTitle>
          {subtitle && (
            <CheckoutButtonSubtitle isApplied={isApplied}>
              {subtitle}
            </CheckoutButtonSubtitle>
          )}
          {finePrint && (
            <CheckoutButtonSubtitle isApplied={isApplied}>
              {finePrint}
            </CheckoutButtonSubtitle>
          )}
        </CheckoutButtonText>
      </CheckoutButtonInfo>
      <CheckoutButtonButton disabled={disabled}>
        {isApplied ? (
          <>
            {disabled ? (
              <CheckoutButtonApply>Applied</CheckoutButtonApply>
            ) : (
              <>
                <CheckoutButtonButtonIcon isApplied={isApplied}>
                  <XCircle size={iconSize} />
                </CheckoutButtonButtonIcon>
                <CheckoutButtonRemove>Remove</CheckoutButtonRemove>
              </>
            )}
          </>
        ) : (
          <>
            <CheckoutButtonButtonIcon>
              <PlusCircle size={iconSize} />
            </CheckoutButtonButtonIcon>
            <CheckoutButtonApply>{applyText}</CheckoutButtonApply>
          </>
        )}
      </CheckoutButtonButton>
    </CheckoutButtonView>
  )
}

export default CheckoutButton
