import styled from '@emotion/styled'
import { Preface } from 'components'
import { AlertCircle } from '../icons'

const MenuItemOptionOverlay = styled.div<{ overlay: string; size: string }>`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.overlay[props.overlay]};
  span {
    line-height: 1;
    color: ${(props) => props.theme.colors.light};
    font-size: ${(props) => props.theme.fonts.sizes[props.size]};
  }
`

const MenuItemOptionOverlayAlert = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  color: ${(props) => props.theme.colors.light};
`

export const MenuItemOptionSoldOut = ({ size = 'xSmall' }) => (
  <MenuItemOptionOverlay overlay="dark" size={size}>
    <div>
      <Preface>Sold Out</Preface>
    </div>
  </MenuItemOptionOverlay>
)

export const MenuItemOptionAlert = ({ size = 'xSmall' }) => (
  <MenuItemOptionOverlay overlay="alert" size={size}>
    <MenuItemOptionOverlayAlert>
      <AlertCircle />
    </MenuItemOptionOverlayAlert>
  </MenuItemOptionOverlay>
)
