import { isoToDate } from '@open-tender/utils'
import styled from '@emotion/styled'
import {
  Cart,
  CartErrors as CartErrorsType,
  CartWithErrors,
  MenuVars,
} from '@open-tender/types'
import { Button } from '.'

export const InvalidView = styled.div`
  margin: 3rem 0 0;
`

export const InvalidItemsView = styled.div`
  margin: 0 0 3rem;

  > p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  ul {
    list-style: disc inside;
    margin: 1.5rem 0 0;

    li {
      margin: 1rem 0;

      span:first-of-type {
        position: relative;
        left: -0.5rem;
        font-weight: ${(props) => props.theme.boldWeight};
        color: ${(props) => props.theme.colors.primary};
      }

      span + span {
        font-size: ${(props) => props.theme.fonts.sizes.small};
      }
    }
  }
`

const InvalidButtons = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  button {
    display: block;
    width: 100%;

    & + button {
      margin-top: 1rem;
    }
  }
`

const InvalidItems = ({
  missingItems,
  invalidItems,
}: {
  missingItems: Cart
  invalidItems: CartWithErrors
}) => {
  const unavailable = [...missingItems, ...invalidItems]
  return unavailable.length ? (
    <InvalidItemsView>
      <p>The following items will need to be removed from your cart:</p>
      <ul>
        {missingItems.map((item, index) => {
          return (
            <li key={`${item.id}-${index}`}>
              <span>{item.name}</span>
            </li>
          )
        })}
        {invalidItems.map((item, index) => {
          const missingOptions =
            item.missingOptions && item.missingOptions.length
              ? item.missingOptions.map((option) => option.name).join(', ')
              : null
          return (
            <li key={`${item.id}-${index}`}>
              <span>{item.name}</span>
              {missingOptions ? (
                <span> (unavailable modifiers: {missingOptions})</span>
              ) : null}
            </li>
          )
        })}
      </ul>
    </InvalidItemsView>
  ) : null
}

const isCartRevertable = (previous: MenuVars, current: MenuVars) => {
  if (!previous) return null
  const now = new Date()
  const requestedDate =
    current.requestedAt === 'asap' ? now : isoToDate(current.requestedAt)
  if (requestedDate < now) return null
  if (
    previous.revenueCenterId === current.revenueCenterId &&
    previous.serviceType === current.serviceType &&
    previous.requestedAt === current.requestedAt
  ) {
    return null
  }
  return { newMenuVars: previous }
}

const CartErrors = ({
  errors,
  revert,
  proceed,
  previousMenuVars,
  menuVars,
}: {
  errors: CartErrorsType
  revert: (menuVars: MenuVars) => void
  proceed: () => void
  previousMenuVars: MenuVars
  menuVars: MenuVars
}) => {
  const isRevertable = isCartRevertable(previousMenuVars, menuVars)
  const { missingItems, invalidItems } = errors

  const handleRevert = () => {
    const { newMenuVars } = isRevertable || {}
    if (newMenuVars) revert(newMenuVars)
  }

  return (
    <InvalidView>
      <InvalidItems missingItems={missingItems} invalidItems={invalidItems} />
      <p>
        {isRevertable
          ? 'Please either remove these items or switch back to your previous menu.'
          : 'Please click the button below to remove these items and proceed with your order.'}
      </p>
      <InvalidButtons>
        {isRevertable && (
          <Button onClick={handleRevert}>Back to Previous Menu</Button>
        )}
        <Button onClick={proceed}>Remove Items</Button>
      </InvalidButtons>
    </InvalidView>
  )
}

export default CartErrors
