import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { selectBrand, selectContent, useAppSelector } from '@open-tender/cloud'
import {
  Content,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
  HeaderDefault,
} from 'components'

const NotFound = () => {
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { notFound: config } = useAppSelector(selectContent) || {}

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageTitle {...config} />
            <PageContent>
              <Link to="/">{config?.back}</Link>
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default NotFound
