import { AnnouncementsState } from '@open-tender/cloud'
import AccountSection from './AccountSection'
import News from 'components/News'
import styled from '@emotion/styled'
import AccountSectionHeader from './AccountSectionHeader'

const AccountNewsView = styled(AccountSection)`
  // background-color: ${(props) => props.theme.bgColors.tertiary};
  padding: ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
  }
`

const AccountNewsHeader = styled(AccountSectionHeader)`
  padding-left: 0;
  padding-right: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const AccountNews = ({
  announcements,
}: {
  announcements: Partial<AnnouncementsState>
}) => {
  return (
    <AccountNewsView>
      <AccountNewsHeader title="What's Trending" />
      <News announcements={announcements} />
    </AccountNewsView>
  )
}

export default AccountNews
