import styled from '@emotion/styled'
import MenuItemPrice from 'components/MenuItemPrice'
import MenuItemImage from 'components/MenuItemImage'
import Body from 'components/Body'
import Heading from 'components/Heading'
import { ThemeJustifyContent } from '@open-tender/types'

export const MenuItemButtonView = styled.button<{
  disabled?: boolean
}>`
  label: MenuItemButtonView;
  flex-grow: 1;
  display: block;
  text-align: left;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: ${(props) => props.theme.menuItems.box.transition};

  &:disabled {
    opacity: 1;
  }
`

export const MenuItemButtonContainer = styled.div<{
  flexDirection: string
  alignItems: string
}>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
`
export const MenuItemButtonImage = styled.div<{ isVertical: boolean }>`
  display: flex;
  overflow: hidden;
  align-self: flex-start;
  aspect-ratio: ${(props) =>
    props.theme.menuItems.image.asImg
      ? 'auto'
      : props.theme.menuItems.image.aspectRatio};
  flex-grow: 0;
  flex-shrink: 0;
  width: ${(props) =>
    props.isVertical ? '100%' : props.theme.menuItems.image.minWidth};
  padding: ${(props) => props.theme.menuItems.image.padding};
  border-style: solid;
  border-width: ${(props) => props.theme.menuItems.image.border};
  border-radius: ${(props) => props.theme.menuItems.image.borderRadius};
  margin: ${(props) => props.theme.menuItems.image.margin};
  border-color: ${(props) => props.theme.menuItems.image.borderColor};
  box-shadow: ${(props) => props.theme.menuItems.image.boxShadow};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: ${(props) =>
      props.isVertical ? '100%' : props.theme.menuItems.image.minWidthMobile};
    padding: ${(props) => props.theme.menuItems.image.paddingMobile};
    border-width: ${(props) => props.theme.menuItems.image.borderMobile};
    border-radius: ${(props) => props.theme.menuItems.image.borderRadiusMobile};
    margin: ${(props) => props.theme.menuItems.image.marginMobile};
  }
`

export const MenuItemImageStyled = styled(MenuItemImage)`
  width: 100%;
  height: 100%;
`

export const MenuItemPriceStyled = styled(MenuItemPrice)`
  position: absolute;
  right: 0px;
  top: 0px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 2px;
  }
`

export const MenuItemButtonContent = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: ${(props) => props.theme.menuItems.content.padding};
  margin-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 0px;
    padding: ${(props) => props.theme.menuItems.content.paddingMobile};
  }
`

export const MenuItemButtonInfoView = styled.div``

export const MenuItemButtonTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

export const MenuItemButtonName = styled(Heading)`
  display: block;
  flex: 1 1 auto;
  font-weight: bold;
  text-align: center;
  transition: ${(props) => props.theme.menuItems.box.transition};
  color: ${(props) => props.theme.menuItems.title.color};
  font-size: ${(props) => props.theme.menuItems.title.fontSize};
  padding: ${(props) => props.theme.menuItems.title.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
    font-size: ${(props) => props.theme.menuItems.title.fontSizeMobile};
    padding: ${(props) => props.theme.menuItems.title.paddingMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.title.colorHover};
  }
`

export const MenuItemButtonDescription = styled(Body)`
  display: block;
  transition: ${(props) => props.theme.menuItems.box.transition};
  color: ${(props) => props.theme.menuItems.text.color};
  font-size: ${(props) => props.theme.menuItems.text.fontSize};
  padding: ${(props) => props.theme.menuItems.text.padding};
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
    font-size: ${(props) => props.theme.menuItems.text.fontSizeMobile};
    padding: ${(props) => props.theme.menuItems.text.paddingMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.text.colorHover};
  }
`

export const MenuItemButtonTagsAllergens = styled.div<{
  justifyContent?: ThemeJustifyContent
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.theme.menuItems.tagsAllergens.flexDirection};
  justify-content: ${(props) =>
    props.justifyContent ?? props.theme.menuItems.tagsAllergens.justifyContent};
  align-items: center;
  flex-wrap: wrap;
  padding: ${(props) => props.theme.menuItems.tagsAllergens.padding};
  gap: ${(props) => props.theme.menuItems.tagsAllergens.gap};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: flex-start;
    gap: ${(props) => props.theme.menuItems.tagsAllergens.gapMobile};
  }

  & > span {
    display: block;
  }
`
