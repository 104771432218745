import { useEffect, useCallback } from 'react'
import {
  closeModal,
  selectCustomerAddresses,
  updateCustomerAddress,
  resetCustomerAddressesError,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { AddressForm, ModalContent, ModalView } from 'components'
import { Address as AddressType } from '@open-tender/types'

const Address = ({
  windowRef,
  address,
}: {
  windowRef: React.RefObject<HTMLDivElement>
  address: AddressType
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectCustomerAddresses)
  const update = useCallback(
    (id: number, data: AddressType, callback: () => void) =>
      dispatch(updateCustomerAddress({ addressId: id, data, callback })),
    [dispatch]
  )
  const callback = useCallback(() => dispatch(closeModal()), [dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetCustomerAddressesError())
    }
  }, [dispatch])

  useEffect(() => {
    if (error && windowRef.current) windowRef.current.scrollTop = 0
  }, [error, windowRef])

  return (
    <ModalView>
      <ModalContent title="Update this address">
        <AddressForm
          address={address}
          loading={loading}
          error={error}
          update={update}
          callback={callback}
        />
      </ModalContent>
    </ModalView>
  )
}

export default Address
