import {
  selectDisplaySettings,
  setCurrentItem,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Header, HeaderLogoWithName } from 'components'
import { Allergens, Back, NavMenu } from 'components/buttons'
import { isMobile } from 'react-device-detect'

const ItemHeader = () => {
  const dispatch = useAppDispatch()
  const { allergens: displayAllergens } =
    useAppSelector(selectDisplaySettings) || {}
  const showAllergens = displayAllergens && !isMobile ? true : false

  const cancel = () => {
    dispatch(setCurrentItem(null))
  }

  return (
    <Header
      style={{ boxShadow: 'none' }}
      title={<HeaderLogoWithName />}
      left={<Back onClick={cancel} text={isMobile ? null : 'Back to Menu'} />}
      right={
        <>
          {showAllergens && <Allergens />}
          <NavMenu />
        </>
      }
    />
  )
}

export default ItemHeader
