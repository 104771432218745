import { ReactElement, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { Discounts } from '@open-tender/types'
import { hasEntities } from '@open-tender/utils'
import {
  closeModal,
  fetchAnnouncementPage,
  fetchDeals,
  selectCustomer,
  selectAnnouncementsPage,
  selectBrand,
  selectContentSection,
  selectDeals,
  selectHasAnnouncementsPage,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  BackgroundImage,
  Content,
  Header,
  HeaderLogo,
  Main,
  Welcome,
  Video,
} from 'components'
import {
  AccountContent,
  AccountDeals,
  AccountHero,
  AccountLoyalty,
  AccountMobile,
  AccountView,
  AccountWrapper,
} from '../Account'
import GuestButtons from './GuestButtons'
import AccountNews from '../Account/AccountNews'
import { Cart, NavMenu, User } from 'components/buttons'
const backgroundImage = require('../../../assets/background-small.jpg')

const Guest = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector(selectCustomer)
  const {
    title: siteTitle,
    has_deals,
    has_loyalty,
    has_thanx,
    pilot_slug,
  } = useAppSelector(selectBrand) || {}
  const {
    title,
    subtitle,
    background,
    mobile,
    content,
    videoUrlMobile,
    displayLogoMobile,
    showGuest,
    displayed: displayedDesktop,
    displayedMobile,
  } = useAppSelector(selectContentSection('guest')) || {}
  const displayed = isMobile ? displayedMobile : displayedDesktop
  const showLogo = isMobile ? displayLogoMobile : false

  const hasContent = displayed?.includes('CONTENT') && content && content.length
  const contentSection = hasContent
    ? (key: string) => <AccountContent key={key} content={content} />
    : null

  const deals = useAppSelector(selectDeals)
  const hasDeals = has_deals && displayed?.includes('DEALS')
  const displayDeals = hasDeals && hasEntities<Discounts>(deals)
  const dealsSection = displayDeals
    ? (key: string) => <AccountDeals key={key} deals={deals.entities} />
    : null

  const hasLoyalty = has_loyalty || has_thanx
  const displayLoyalty = displayed?.includes('LOYALTY') && hasLoyalty
  const loyaltySection = displayLoyalty
    ? (key: string) => <AccountLoyalty key={key} />
    : null

  const hasHero = isMobile ? mobile || videoUrlMobile : background
  const displayHero = displayed?.includes('HERO') && hasHero
  const heroSection = displayHero
    ? (key: string) => (
        <AccountHero key={key}>
          {videoUrlMobile ? (
            <Video src={videoUrlMobile} poster={mobile} />
          ) : (
            <BackgroundImage imageUrl={mobile} />
          )}
        </AccountHero>
      )
    : null

  const announcements = useAppSelector(selectAnnouncementsPage('GUEST'))
  const hasAnnouncements = useAppSelector(selectHasAnnouncementsPage('GUEST'))
  const displayAnnouncements =
    displayed?.includes('ANNOUNCEMENTS') && hasAnnouncements
  const announcementsSection = displayAnnouncements
    ? (key: string) => (
        <AccountNews announcements={announcements as any} key={key} />
      )
    : null

  const sections: Record<string, ((key: string) => ReactElement) | null> = {
    CONTENT: contentSection,
    LOYALTY: loyaltySection,
    DEALS: dealsSection,
    HERO: heroSection,
    ANNOUNCEMENTS: announcementsSection,
  }
  const displayedSections = displayed
    ? displayed
        .map((i) => {
          const func = sections[i]
          return func ? func(i) : null
        })
        .filter(Boolean)
    : null

  const buttons = useRef<any>(null)

  useEffect(() => {
    dispatch(closeModal())
  }, [dispatch])

  useEffect(() => {
    if (auth) {
      navigate('/account')
    } else if (!showGuest) {
      navigate('/order-type')
    } else if (pilot_slug) {
      navigate(`/locations/${pilot_slug}`)
    }
  }, [auth, showGuest, pilot_slug, navigate])

  useEffect(() => {
    dispatch(fetchAnnouncementPage('GUEST'))
  }, [dispatch])

  useEffect(() => {
    if (hasDeals) dispatch(fetchDeals())
  }, [hasDeals, dispatch])

  if (auth || !showGuest) return null

  return (
    <>
      <Helmet>
        <title>Welcome | {siteTitle}</title>
      </Helmet>
      {/* <Background
        imageUrl={background}
        videoUrl={videoUrl}
        announcements={displayAnnouncements ? announcements : undefined}
        style={{ top: theme.layout.navHeight }}
      /> */}
      {/* <HeaderGuest maxWidth="100%" /> */}
      <Header
        maxWidth="100%"
        borderColor={isMobile ? 'transparent' : undefined}
        title={showLogo ? <HeaderLogo /> : undefined}
        left={isMobile ? <User /> : <HeaderLogo />}
        right={
          <>
            {!isMobile && <User />}
            <Cart />
            <NavMenu />
          </>
        }
      />
      <Content maxWidth="100%">
        <Main bgImage={backgroundImage}>
          <AccountWrapper>
            <AccountView>
              <Welcome
                title={title}
                subtitle={!isMobile ? subtitle : undefined}
              />
              <GuestButtons ref={buttons} />
              <AccountMobile>{displayedSections}</AccountMobile>
            </AccountView>
          </AccountWrapper>
        </Main>
      </Content>
    </>
  )
}

export default Guest
