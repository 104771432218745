import {
  GoogleMapsIcons,
  RevenueCenter,
  RevenueCenters,
} from '@open-tender/types'
import { GoogleMapsMarker } from 'components/maps'

const RevenueCenterMarkers = ({
  maps,
  map,
  icons,
  missingAddress,
  activeMarker,
  setActive,
  revenueCenters,
}: {
  maps?: typeof google.maps | null
  map?: google.maps.Map | null
  icons?: GoogleMapsIcons
  missingAddress: boolean
  activeMarker: number | null
  setActive: (revenueCenter: RevenueCenter) => void
  revenueCenters: RevenueCenters
}) => {
  const markers = revenueCenters.map((i) => {
    const isActive = i.revenue_center_id === activeMarker
    const icon = isActive ? icons?.locationSelected : icons?.location
    return (
      <GoogleMapsMarker
        key={i.revenue_center_id}
        maps={maps}
        map={map}
        title={i.name}
        position={{
          lat: i.address.lat,
          lng: i.address.lng,
        }}
        icon={icon?.url}
        size={icon?.size}
        anchor={icon?.anchor}
        events={missingAddress ? {} : { onClick: () => setActive(i) }}
      />
    )
  })

  return <div>{markers}</div>
}

export default RevenueCenterMarkers
