import { useNavigate } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import styled from '@emotion/styled'
import {
  resetOrder,
  selectOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button } from 'components'

const OrderNowView = styled.div`
  margin: 0 1rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
    margin: 0;
  }

  button {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 0.7rem 1.2rem;
    }
  }
`

const OrderNow = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { revenueCenter, serviceType, cart } = useAppSelector(selectOrder)
  const isCurrentOrder =
    revenueCenter && serviceType && cart?.length ? true : false

  const startNewOrder = () => {
    dispatch(resetOrder())
    navigate(`/order-type`)
  }

  return isCurrentOrder ? null : (
    <OrderNowView>
      <Button size="small" onClick={startNewOrder}>
        Order{isMobileOnly ? '' : ' Now'}
      </Button>
    </OrderNowView>
  )
}

export default OrderNow
