import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import {
  addMessage,
  fetchQuote,
  selectBrand,
  // selectCustomer,
  selectQuote,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Loading,
  Main,
  Order,
  PageContainer,
} from 'components'

const QuoteCentered = styled.div`
  display: flex;
  justify-content: center;
  max-width: 54rem;
  margin: 4rem auto;
  text-align: center;
`

const Quote = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { uuid: orderUuid } = useParams()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  // const { auth } = useAppSelector(selectCustomer)
  const { order, loading, error } = useAppSelector(selectQuote)
  const isLoading = loading === 'pending'
  const errMsg = error
    ? "We couldn't find this quote. Please double check your order ID and give it another try."
    : null

  const title = `Quote #${orderUuid}`

  // useEffect(() => {
  //   if (!auth) navigate('/guest')
  // }, [auth, navigate])

  useEffect(() => {
    if (error?.code === 'FORBIDDEN') {
      dispatch(
        addMessage('Quote requires authenticated customer. Please login.')
      )
      navigate('/guest')
    }
  }, [error?.code, dispatch, navigate])

  useEffect(() => {
    if (orderUuid) dispatch(fetchQuote(orderUuid))
  }, [dispatch, orderUuid])

  return (
    <>
      <Helmet>
        <title>
          {title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault path="/orders" />
        <Main>
          <PageContainer>
            {error ? (
              <QuoteCentered>
                <p>{errMsg}</p>
              </QuoteCentered>
            ) : order ? (
              <Order order={order} isQuote={true} />
            ) : isLoading ? (
              <QuoteCentered>
                <Loading text="Retrieving your quote..." />
              </QuoteCentered>
            ) : null}
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Quote
