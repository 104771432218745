import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  fetchCustomerAddresses,
  selectBrand,
  selectContent,
  selectCustomer,
  selectCustomerAddresses,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderUser,
  Loading,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
} from 'components'
import Addresses from './Addresses'

const AccountAddresses = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { addresses: config } = useAppSelector(selectContent) || {}
  const { auth } = useAppSelector(selectCustomer)
  const { entities, loading } = useAppSelector(selectCustomerAddresses)
  const isLoading = loading === 'pending'
  const limit = 25

  useEffect(() => {
    if (!auth) return navigate('/account')
  }, [auth, navigate])

  useEffect(() => {
    dispatch(fetchCustomerAddresses(limit))
  }, [dispatch])

  return auth ? (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderUser />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config} />
            {entities.length ? (
              <Addresses addresses={entities} isLoading={isLoading} />
            ) : (
              <PageContent>
                {isLoading ? (
                  <Loading text="Retrieving your addresses..." />
                ) : (
                  <p>
                    Looks like you haven't added any addresses yet. Please place
                    an order to add one.
                  </p>
                )}
              </PageContent>
            )}
          </PageContainer>
        </Main>
      </Content>
    </>
  ) : null
}

export default AccountAddresses
