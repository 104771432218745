import styled from '@emotion/styled'
import { ThemeFontSize } from '@open-tender/types'
import { ColorName } from 'utils/types'

const Title = styled.span<{
  size?: ThemeFontSize
  color?: ColorName | string
}>`
  ${({ theme, size, color }) => `
    font-family: "coniferous", sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    color: ${color ? theme.colors[color] : theme.fonts.headline.color};
    ${size ? `font-size: ${theme.fonts.sizes[size]};` : 'big'}
  `}
`

export default Title
