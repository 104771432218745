import { HtmlContent } from '../..'
import AccountSection from './AccountSection'

const AccountContent = ({ content }: { content?: string }) => {
  return (
    <AccountSection>
      <HtmlContent content={content} />
    </AccountSection>
  )
}

export default AccountContent
