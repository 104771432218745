/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'

const useGoogleMapsPlace = (
  maps: typeof google.maps | null,
  map: google.maps.Map | null,
  placeId: string | null
) => {
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  )
  const placeService = maps && map ? new maps.places.PlacesService(map) : null
  const ok = maps ? maps.places.PlacesServiceStatus.OK : null

  const callback = useCallback(
    (
      place: google.maps.places.PlaceResult | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status === ok) {
        setPlace(place)
      }
    },
    [ok]
  )

  useEffect(() => {
    if (placeId && placeService) {
      const request = {
        placeId: placeId,
        fields: [
          'place_id',
          'address_component',
          'formatted_address',
          'geometry',
        ],
      }
      placeService.getDetails(request, callback)
    }
  }, [placeId])

  return place
}

export default useGoogleMapsPlace
