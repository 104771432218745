import React from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import {
  resetOrder,
  selectOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLarge } from '../..'
import { ArrowRight } from '../../icons'
import AccountScan from './AccountScan'

export const AccountButtonsView = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 1rem 0 4rem;
  gap: 2rem;
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 1rem;
    margin: 1.5rem 0 2.5rem;
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const AccountButtons = React.forwardRef(
  (props, ref: React.LegacyRef<HTMLDivElement> | undefined) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const currentOrder = useAppSelector(selectOrder)
    const { revenueCenter, serviceType, cart } = currentOrder
    const isCurrentOrder =
      revenueCenter && serviceType && (cart?.length ?? 0) > 0

    const continueCurrent = () => {
      navigate(revenueCenter ? `/menu/${revenueCenter.slug}` : '/order-type')
    }

    const startNewOrder = () => {
      dispatch(resetOrder())
      navigate(`/order-type`)
    }

    return (
      <AccountButtonsView ref={ref}>
        <AccountScan />
        <ButtonLarge
          onClick={isCurrentOrder ? continueCurrent : startNewOrder}
          text={isCurrentOrder ? 'Continue Order' : 'Order Now'}
          color="primary"
        >
          <ArrowRight
            size={22}
            strokeWidth={2}
            style={{ position: 'relative', top: 3 }}
          />
        </ButtonLarge>
      </AccountButtonsView>
    )
  }
)

export default AccountButtons
