import { CardIconProps } from './types'

const Amex = ({ size }: CardIconProps) => {
  return (
    <svg
      width={size}
      version="1.1"
      // enable-background="new 0 0 780 500"
      viewBox="0 0 780 500"
    >
      <path
        d="m199.87 124.06l-26.311 63.322-28.644-63.323h-35.722v88.379l-37.224-88.378h-32.517l-39.193 93.344h23.77l8.496-20.82h45.57l8.41 20.82h44.515v-73.171l31.819 73.171h19.218l31.735-73.106 0.083 73.106h22.435v-93.344h-36.44zm-129.68 53.068h-29.875l14.896-37.213 14.979 37.211v2e-3z"
        fill="currentColor"
      />
      <polygon
        points="325.74 217.41 252.62 217.41 252.62 124.06 325.74 124.06 325.74 143.5 274.51 143.5 274.51 160.33 324.51 160.33 324.51 179.46 274.51 179.46 274.51 198.1 325.74 198.1 325.74 217.41"
        fill="currentColor"
      />
      <path
        d="m428.82 149.2c0-10.412-4.152-16.5-11.076-20.669-7.076-4.17-15.316-4.471-26.502-4.471h-50.34v93.344h21.884v-33.952h23.495c7.88 0 12.755 0.713 15.952 3.996 3.982 4.491 3.455 12.569 3.455 18.186l0.084 11.771h22.075v-18.338c0-8.336-0.534-12.484-3.624-17.126-1.947-2.72-6.037-6.002-10.827-7.861 5.679-2.31 15.424-9.998 15.424-24.88zm-28.622 13.109c-3.007 1.861-6.564 1.923-10.826 1.923h-26.588v-20.734h26.95c3.813 0 7.795 0.176 10.378 1.685 2.84 1.36 4.597 4.253 4.597 8.251 0 4.08-1.672 7.363-4.511 8.875z"
        fill="currentColor"
      />
      <rect
        x="441.52"
        y="124.06"
        width="22.334"
        height="93.344"
        fill="currentColor"
      />
      <path
        d="m700.52 124.07v65.009l-38.474-65.009h-33.323v88.29l-37.056-88.29h-32.794l-30.847 73.407h-9.83c-5.761 0-11.884-1.121-15.253-4.813-4.068-4.711-6.02-11.922-6.02-21.922 0-9.785 2.562-17.213 6.293-21.144 4.344-4.318 8.854-5.53 16.842-5.53h20.743v-20h-21.189c-15.083 0-26.163 3.436-33.238 10.885-9.407 10-11.798 22.656-11.798 36.499 0 16.975 4 27.71 11.692 35.637 7.629 7.925 21.098 10.323 31.737 10.323h25.61l8.263-20.82h45.465l8.518 20.82h44.573v-70.059l41.485 70.059h31.016l1e-3 1e-3v-93.342h-22.416zm-110.64 53.064h-30.209l15.062-37.213 15.147 37.213z"
        fill="currentColor"
      />
      <path
        d="m387.16 284.61h-69.936l-27.839 30.603-26.928-30.603h-88.027v93.366h86.693l28.007-30.909 26.951 30.908h42.54v-31.315h27.31c19.132 0 38.113-5.355 38.113-31.292-1e-3 -25.857-19.516-30.758-36.884-30.758zm-137.12 73.909h-53.811v-18.577h48.049v-19.05h-48.049v-16.974h54.872l23.939 27.208-25 27.393zm86.78 10.966l-33.603-38.032 33.603-36.823v74.855zm50.082-41.789h-28.285v-23.776h28.539c7.902 0 13.386 3.284 13.386 11.448 1e-3 8.075-5.23 12.328-13.64 12.328z"
        fill="currentColor"
      />
      <polygon
        points="534.57 284.61 607.62 284.61 607.62 303.92 556.37 303.92 556.37 320.89 606.37 320.89 606.37 339.94 556.37 339.94 556.37 358.52 607.62 358.6 607.62 377.98 534.57 377.98"
        fill="currentColor"
      />
      <path
        d="m506.92 334.59c5.761-2.331 15.511-9.936 15.513-24.838 0-10.652-4.344-16.479-11.252-20.734-7.183-3.906-15.253-4.404-26.334-4.404h-50.53v93.365h21.993v-34.1h23.389c7.985 0 12.861 0.799 16.059 4.144 4.067 4.342 3.537 12.658 3.537 18.276v11.681h21.973v-18.509c-0.088-8.229-0.535-12.484-3.625-17.043-1.87-2.719-5.852-6.006-10.723-7.838zm-13.218-11.619c-2.928 1.771-6.549 1.923-10.809 1.923h-26.588v-20.971h26.951c3.896 0 7.796 0.085 10.445 1.688 2.836 1.512 4.532 4.403 4.532 8.399 1e-3 3.995-1.695 7.212-4.531 8.961z"
        fill="currentColor"
      />
      <path
        d="m691.2 328.73c4.262 4.496 6.547 10.173 6.547 19.782 0 20.086-12.312 29.461-34.383 29.461h-42.629v-20.021h42.457c4.152 0 7.096-0.559 8.939-2.311 1.506-1.445 2.585-3.543 2.585-6.09 0-2.721-1.167-4.88-2.67-6.174-1.674-1.426-3.982-2.072-7.794-2.072-20.468-0.713-46.101 0.646-46.101-28.9 0-13.542 8.347-27.796 31.292-27.796h43.877v19.872h-40.148c-3.98 0-6.568 0.151-8.77 1.686-2.396 1.513-3.286 3.757-3.286 6.718 0 3.522 2.035 5.92 4.789 6.954 2.309 0.818 4.79 1.059 8.519 1.059l11.78 0.324c11.884 0.295 20.039 2.391 24.996 7.508z"
        fill="currentColor"
      />
      <path
        d="m729.75 307.08c2.228-1.54 4.874-1.692 8.857-1.692h39.889v-261.75c0-23.553-18.586-42.638-41.512-42.638h-695.48c-22.917 0-41.512 19.089-41.512 42.638v124.31l26.505-62.085h57.077l7.351 15.422v-15.421h66.925l14.641 33.726 14.256-33.726h212.48c9.657 0 18.345 1.84 24.718 7.624v-7.624h58.159v7.624c10.018-5.611 22.417-7.625 36.524-7.625h84.278l7.819 15.422v-15.422h62.392l8.515 15.422v-15.421h60.804v130.58h-61.438l-11.611-19.753v19.753h-76.672l-8.327-20.795h-18.877l-8.515 20.795h-39.787c-15.612 0-27.478-3.767-35.106-7.947v7.947h-94.573v-29.631c0-4.182-0.723-4.417-3.201-4.504h-3.537l0.084 34.136h-182.86v-16.137l-6.568 16.202h-38.196l-6.568-15.965v15.898h-73.577l-8.41-20.795h-18.877l-8.432 20.795h-37.395v1e-3h-3e-3v223.92c0 23.553 18.585 42.637 41.512 42.637h695.48c22.917 0 41.512-19.089 41.512-42.638v-93.235l-1e-3 1e-3v24.343c-8.77 4.331-20.294 6.022-32.095 6.022h-56.712v-8.361c-6.569 5.39-18.436 8.361-29.787 8.361h-178.79v-30.198c0-3.703-0.357-3.854-3.979-3.854h-2.84v34.055h-58.854v-35.264c-9.852 4.354-21.019 4.744-30.486 4.594h-7.016v30.738l-71.355-0.065-17.542-20.313-18.624 20.313h-115.76v-130.67h117.98l16.928 20 18.071-20h78.981c9.13 0 24.11 0.978 30.846 7.648v-7.648h70.57c6.632 0 21 1.368 29.515 7.648v-7.648h106.99v7.648c5.319-5.219 16.564-7.648 26.146-7.648h59.913v7.648c6.288-4.652 15.168-7.648 27.391-7.648h40.507v18.633h-43.596c-22.968 0-31.403 14.297-31.403 27.88 0 29.635 25.64 28.271 46.189 28.986 3.812 0 6.122 0.648 7.711 2.079 1.609 1.298 2.674 3.463 2.674 6.192 0.017 2.319-0.924 4.538-2.588 6.107-1.76 1.758-4.681 2.317-8.857 2.317h-42.096v20.082h42.269c14.023 0 24.383-4.03 29.699-11.979v-1e-3h1e-3v-35.092c-0.61-0.803-1.145-1.604-2.03-2.318-4.872-5.131-12.861-7.235-24.831-7.537l-11.862-0.324c-3.646 0-6.126-0.242-8.435-1.062-2.836-1.039-4.785-3.443-4.785-6.975 0-2.971 0.888-5.221 3.197-6.737z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Amex
