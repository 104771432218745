import { ReactElement } from 'react'
import { isBrowser } from 'react-device-detect'
import { Button, ButtonIcon } from 'components'

const ButtonBoth = ({
  text,
  label,
  icon,
  onClick,
  color = 'header',
  size = 'header',
  useButton = false,
  style,
}: {
  text?: string
  label?: string
  icon?: ReactElement
  onClick: () => void
  color?: string
  size?: string
  style?: React.CSSProperties
  useButton?: boolean
}) => {
  return isBrowser || useButton ? (
    <Button
      onClick={onClick}
      label={label}
      icon={icon}
      color={color}
      size={size}
      style={style}
    >
      {text}
    </Button>
  ) : (
    <ButtonIcon label={label || text} onClick={onClick}>
      {icon}
    </ButtonIcon>
  )
}

export default ButtonBoth
