import styled from '@emotion/styled'
import { Body } from 'components'

export const LoyaltyHowItWorksView = styled.div`
  width: 90%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.layout.paddingMobile};
  // margin-top: 24px;
  border-radius: 11px;
  box-shadow: ${(props) => `11px 11px 0px ${props.theme.colors.primary}`};
`

export const LoyaltyHowItWorksDescription = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

export const LoyaltyHowItWorksDescriptionLine = styled(Body)`
  font-weight: 800;
  text-align: center;
  padding: 0 ${(props) => props.theme.layout.paddingMobile};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.main};
  }
`
