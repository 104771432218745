import { useEffect } from 'react'
import { serviceTypeNamesMap, useFirstTimes } from '@open-tender/utils'
import {
  RevenueCenter,
  DateString,
  OrderType,
  ServiceType,
  RequestedAt,
  FirstTime,
} from '@open-tender/types'
import { ButtonLink, Button, Heading, SelectOnly } from 'components'
import {
  RequestedAtDateTimeAsap,
  RequestedAtDateTimeNevermind,
  RequestedAtDateTimeSelect,
  RequestedAtDateTimeSelects,
  RequestedAtDateTimeTitle,
  RequestedAtDateTimeView,
} from './RequestedAt.styled'

const RequestedAtDateTime = ({
  firstTime,
  revenueCenter,
  serviceType,
  orderType,
  requestedAt,
  chooseTime,
  cancel,
  isLocation,
  isReorder,
  isGroupOrder,
  isPilot,
}: {
  firstTime: FirstTime
  revenueCenter: RevenueCenter
  serviceType: ServiceType
  orderType: OrderType
  requestedAt?: RequestedAt
  chooseTime: (requestedAt: RequestedAt) => void
  cancel: () => void
  isLocation?: boolean
  isReorder?: boolean
  isGroupOrder?: boolean
  isPilot?: boolean
}) => {
  const orderTypeName = orderType === 'CATERING' ? 'Catering ' : ''
  const serviceTypeName = serviceTypeNamesMap[serviceType]
  const { name } = revenueCenter
  const {
    date,
    setDate,
    time,
    setTime,
    dateChange,
    setDateChange,
    dateOptions,
    timeOptions,
    firstMinutes,
    requestedTime,
    orderMsg,
  } = useFirstTimes(
    firstTime,
    revenueCenter,
    serviceType,
    orderType,
    requestedAt,
    isGroupOrder
  )
  const isClosed = timeOptions.find((i) => i.name === 'Closed') ? true : false

  const changeDate = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setDate(evt.target.value as DateString)
    setDateChange(true)
  }

  const changeTime = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value
    const timeValue = value === 'asap' ? value : parseInt(value)
    setTime(timeValue)
  }

  useEffect(() => {
    if (dateChange) {
      if (firstMinutes) setTime(firstMinutes)
      setDateChange(false)
    }
  }, [dateChange, firstMinutes, setTime, setDateChange])

  return (
    <RequestedAtDateTimeView>
      <RequestedAtDateTimeTitle>
        <Heading>
          {orderTypeName}
          {serviceTypeName}
          {orderTypeName ? <br /> : null} from {name}
        </Heading>
      </RequestedAtDateTimeTitle>
      <p>Please choose a date & time below.</p>
      <RequestedAtDateTimeSelects>
        <RequestedAtDateTimeSelect>
          <SelectOnly
            label="Order Date"
            name="order-date"
            value={date || ''}
            onChange={changeDate}
            options={dateOptions}
          />
        </RequestedAtDateTimeSelect>
        <RequestedAtDateTimeSelect>
          <SelectOnly
            label="Order Time"
            name="order-time"
            value={time || ''}
            onChange={changeTime}
            options={timeOptions}
          />
        </RequestedAtDateTimeSelect>
      </RequestedAtDateTimeSelects>
      <RequestedAtDateTimeAsap>
        <Button
          onClick={() => chooseTime(requestedTime)}
          color="primary"
          disabled={isClosed}
        >
          {orderMsg}
        </Button>
      </RequestedAtDateTimeAsap>
      {!isPilot && (
        <RequestedAtDateTimeNevermind>
          <ButtonLink onClick={cancel}>
            {isLocation
              ? "Nevermind, let's choose a different location"
              : isReorder
              ? "Nevermind, I don't want to order this again"
              : 'Nevermind, keep current time'}
          </ButtonLink>
        </RequestedAtDateTimeNevermind>
      )}
    </RequestedAtDateTimeView>
  )
}

export default RequestedAtDateTime
