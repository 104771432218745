import { useMemo } from 'react'
import {
  LoyaltyHowItWorksDescription,
  LoyaltyHowItWorksDescriptionLine,
  LoyaltyHowItWorksView,
} from './LoyaltyHowItWorks.styled'
import { Heading } from 'components'
import {
  selectCustomerLoyaltyProgram,
  useAppSelector,
} from '@open-tender/cloud'

const LoyaltyHowItWorks = ({ className }: { className?: string }) => {
  const { program } = useAppSelector(selectCustomerLoyaltyProgram)

  const lines = useMemo(
    () => program?.description.split('\n').filter((l) => l.length) ?? [],
    [program]
  )

  return (
    <LoyaltyHowItWorksView className={className}>
      <Heading color="alert">How it works</Heading>
      <LoyaltyHowItWorksDescription>
        {lines.map((line) => (
          <LoyaltyHowItWorksDescriptionLine key={line} color="primary">
            {line}
          </LoyaltyHowItWorksDescriptionLine>
        ))}
      </LoyaltyHowItWorksDescription>
    </LoyaltyHowItWorksView>
  )
}

export default LoyaltyHowItWorks
