import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeServiceTypeName } from '@open-tender/utils'
import {
  openModal,
  selectOrder,
  selectOutpostName,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonBoth } from '.'

const ServiceType = ({
  useButton = false,
  style,
}: {
  useButton?: boolean
  style?: CSSProperties
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { orderType, serviceType, isOutpost, prepType } =
    useAppSelector(selectOrder)
  const outpostName: string | undefined = useAppSelector(selectOutpostName)
  const isCatering = orderType === 'CATERING'
  const serviceTypeName = serviceType
    ? makeServiceTypeName(serviceType, isCatering, isOutpost, outpostName ?? '')
    : ''
  const name = prepType === 'TAKE_OUT' ? 'Take Out' : serviceTypeName

  if (!serviceType) return null

  const handleServiceType = () => {
    dispatch(openModal({ type: 'orderType' }))
  }

  const handlePrepType = () => {
    dispatch(openModal({ type: 'prepType' }))
  }

  const handleCatering = () => {
    navigate(`/catering-address`)
  }

  const change =
    prepType !== null
      ? handlePrepType
      : isCatering
      ? handleCatering
      : handleServiceType

  return (
    <ButtonBoth
      label="Change Service Type"
      text={name}
      onClick={change}
      style={style}
      useButton={useButton}
    />
  )
}

export default ServiceType
