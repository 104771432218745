import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { selectTags, useAppSelector } from '@open-tender/cloud'
import { isEmpty } from '@open-tender/utils'
import Body from 'components/Body'
import Preface from 'components/Preface'

const MenuItemTagsAllergensAltView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.9rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0.2rem 0 0;
  }

  & > span {
    display: block;
  }
`

const MenuItemTagsAllergensAltAllergen = styled(Body)`
  // color: ${(props) => props.theme.colors.allergens};
  color: ${(props) => props.theme.menuItems.allergens.color};
  font-size: ${(props) => props.theme.menuItems.allergens.fontSize};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.allergens.fontSizeMobile};
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.menuItems.allergens.colorHover};
  }
`

const MenuItemTagsAllergensAltTags = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  // gap: ${(props) => props.theme.menuItems.tags.gap};
  // @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  //   gap: ${(props) => props.theme.menuItems.tags.gapMobile};
  // }
`

const MenuItemTagsAllergensAltTag = styled(Preface)`
  transition: ${(props) => props.theme.links.transition};
  // color: ${(props) => props.theme.colors.tags};
  color: ${(props) => props.theme.menuItems.tags.color};
  font-size: ${(props) => props.theme.menuItems.tags.fontSize};
  margin-left: ${(props) => props.theme.menuItems.tags.gap};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.tags.fontSizeMobile};
    margin-left: ${(props) => props.theme.menuItems.tags.gapMobile};
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.menuItems.tags.colorHover};
  }
`

const MenuItemTagsAllergensAltTagImage = styled.span`
  display: block;
  width: ${(props) => props.theme.menuItems.tags.width};
  margin-left: ${(props) => props.theme.menuItems.tags.gap};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: ${(props) => props.theme.menuItems.tags.widthMobile};
    margin-left: ${(props) => props.theme.menuItems.tags.gapMobile};
  }
`

const MenuItemTagsAllergensAlt = ({
  tags,
  allergens,
}: {
  tags: string[]
  allergens: string[]
}) => {
  const hasTagsAllergens = tags.length || allergens.length ? true : false
  const theme = useTheme()
  const { displayType } = theme.menuItems.tags
  const allergensStr = allergens.length
    ? `Contains: ${allergens.join(', ')}`
    : ' '
  const { lookup } = useAppSelector(selectTags)
  const hasLookup = lookup && !isEmpty(lookup)
  const showTagImages = hasLookup && displayType === 'IMAGE'

  if (!hasTagsAllergens) return null

  return (
    <MenuItemTagsAllergensAltView>
      <MenuItemTagsAllergensAltAllergen>
        {allergensStr}
      </MenuItemTagsAllergensAltAllergen>
      <MenuItemTagsAllergensAltTags>
        {showTagImages
          ? tags.map((tag) => (
              <MenuItemTagsAllergensAltTagImage key={tag}>
                <img src={lookup[tag].imageUrl} alt={lookup[tag].name} />
              </MenuItemTagsAllergensAltTagImage>
            ))
          : tags.map((tag) => (
              <MenuItemTagsAllergensAltTag key={tag}>
                {tag}
              </MenuItemTagsAllergensAltTag>
            ))}
      </MenuItemTagsAllergensAltTags>
    </MenuItemTagsAllergensAltView>
  )
}

export default MenuItemTagsAllergensAlt
