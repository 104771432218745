import styled from '@emotion/styled'
import Body from 'components/Body'

export const MenuItemAllergensView = styled.div`
  display: flex;
  justify-content: inherit;
  align-items: center;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.menuItems.allergens.gap};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: ${(props) => props.theme.menuItems.allergens.gapMobile};
  }
`

export const MenuItemAllergen = styled.div`
  display: flex;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

export const MenuItemAllergenText = styled(Body)`
  transition: ${(props) => props.theme.menuItems.box.transition};
  letter-spacing: ${(props) => props.theme.menuItems.allergens.letterSpacing};
  text-transform: ${(props) => props.theme.menuItems.allergens.textTransform};
  color: ${(props) => props.theme.menuItems.allergens.color};
  font-size: ${(props) => props.theme.menuItems.allergens.fontSize};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.allergens.fontSizeMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.allergens.colorHover};
  }
`

export const MenuItemAllergenImage = styled.span`
  display: block;
  width: ${(props) => props.theme.menuItems.allergens.width};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: ${(props) => props.theme.menuItems.allergens.widthMobile};
  }
`
