import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  selectOrder,
  resetOrder,
  resetCheckout,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button } from 'components'

const CancelEditView = styled.div`
  button {
    color: ${(props) => props.theme.colors.error};
  }
`

const CancelEdit = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { orderId } = useAppSelector(selectOrder)

  const cancel = () => {
    dispatch(resetOrder())
    dispatch(resetCheckout())
    navigate(`/account`)
  }

  if (!orderId) return null

  return (
    <CancelEditView>
      <Button onClick={cancel} size="header" color="header">
        Cancel Edit
      </Button>
    </CancelEditView>
  )
}

export default CancelEdit
