import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { formatQuantity } from '@open-tender/utils'
import Heading from 'components/Heading'
import { ThemeFontSize } from '@open-tender/types'

const MenuItemPriceCalsView = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const MenuItemPrice = styled(Heading)<{
  priceColor?: string | null
  size: ThemeFontSize
}>`
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
  ${(props) => (props.priceColor ? `color: ${props.priceColor};` : '')};
`

const MenuItemPriceSep = styled.span<{ textColor?: string | null }>`
  display: block;
  width: 8px;
  height: 1px;
  margin: 0 3px;
  background-color: ${(props) => props.theme.fonts.body.color};
  ${(props) =>
    props.textColor ? `background-color: ${props.textColor};` : ''};
`

const MenuItemCals = styled(Heading)<{
  caloriesColor?: string | null
  size: string
}>`
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
  ${(props) => (props.caloriesColor ? `color: ${props.caloriesColor};` : '')};
`

const MenuItemPriceCals = ({
  price,
  cals,
  size = 'small',
  isModifier = false,
  children,
  style,
}: {
  price?: string | null
  cals?: number | null
  children?: React.ReactNode
  style?: React.CSSProperties
  size?: ThemeFontSize
  isModifier?: boolean
}) => {
  const { menuItem, modifier } = useTheme().cards
  if (!price && !cals && !children) return null
  const display = isModifier ? modifier : menuItem
  const colors = display.overrideFontColors
    ? display
    : { textColor: null, priceColor: null, caloriesColor: null }
  const { textColor, priceColor, caloriesColor } = colors

  return (
    <MenuItemPriceCalsView style={style}>
      {price ? (
        <MenuItemPrice size={size} priceColor={priceColor}>
          {price}
        </MenuItemPrice>
      ) : null}
      {price && cals ? <MenuItemPriceSep textColor={textColor} /> : null}
      {cals ? (
        <MenuItemCals size={size} caloriesColor={caloriesColor}>
          {formatQuantity(`${cals}`)} Cal
        </MenuItemCals>
      ) : null}
      {children}
    </MenuItemPriceCalsView>
  )
}

export default MenuItemPriceCals
