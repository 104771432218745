import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { selectBrand, useAppSelector } from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Main,
  OrderFulfillmentForm,
  PageContainer,
  PageContent,
} from 'components'

const Fulfillment = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    title: siteTitle,
    fulfillment,
    has_ext_curbside,
  } = useAppSelector(selectBrand) || {}
  const orderId = id ? parseInt(id) : null

  useEffect(() => {
    if (!fulfillment || !orderId || has_ext_curbside) {
      return navigate('/account')
    }
  }, [fulfillment, orderId, has_ext_curbside, navigate])

  if (!fulfillment || !orderId || has_ext_curbside) return null

  return (
    <>
      <Helmet>
        <title>Curbside Pickup | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageContent>
              <OrderFulfillmentForm orderId={orderId} settings={fulfillment} />
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Fulfillment
