import styled from '@emotion/styled'
import { Headline } from 'components'
import { Link } from 'react-router-dom'
import Title from './Title'

const LoyaltyStatusView = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoyaltyStatusLabel = styled(Title)`
  margin-left: 12px;
  font-weight: bold;
  text-transform: capitalize;
  white-space: pre-wrap;
`

const LinkTo = styled(Link)`
  align-self: center;
  text-decoration: none;
`

const Count = styled(Headline)`
  line-height: 1;
`

interface LoyaltyStatusProps {
  count?: number | string
  name?: string
  to?: string
  className?: string
}

const LoyaltyStatus = ({ count, name, to, className }: LoyaltyStatusProps) => {
  const render = () => (
    <LoyaltyStatusView className={className}>
      <Count size="h1" color="alert">
        {count}
      </Count>
      {name && (
        <LoyaltyStatusLabel size="big" as="div">
          {name.split(' ').join('\n')}
        </LoyaltyStatusLabel>
      )}
    </LoyaltyStatusView>
  )

  if (to) {
    return <LinkTo to={to}>{render()}</LinkTo>
  }

  return render()
}

export default LoyaltyStatus
