import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  fetchCustomerHistory,
  selectBrand,
  selectContent,
  selectCustomer,
  selectCustomerHistory,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Loading,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
} from 'components'
import { HistoryEvents } from './History.styled'
import HistoryEvent from './HistoryEvent'

const History = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector(selectCustomer)
  const { title: siteTitle, tpls } = useAppSelector(selectBrand) || {}
  const { accountHistory: config } = useAppSelector(selectContent) || {}
  const { entities: history, loading } = useAppSelector(selectCustomerHistory)
  const hasHistory = tpls === 'PUNCHH'
  const isLoading = loading === 'pending'

  useEffect(() => {
    if (!hasHistory || !auth) return navigate('/')
  }, [hasHistory, auth, navigate])

  useEffect(() => {
    dispatch(fetchCustomerHistory())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageTitle {...config} />
            <PageContent style={{ maxWidth: '99rem' }}>
              {hasHistory ? (
                <HistoryEvents>
                  {history.map((historyEvent) => (
                    <HistoryEvent
                      key={historyEvent.date}
                      event={historyEvent}
                    />
                  ))}
                </HistoryEvents>
              ) : isLoading ? (
                <Loading text="Retrieving your loyalty history..." />
              ) : (
                <p>
                  Looks like you haven't yet established any loyalty history.
                </p>
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default History
