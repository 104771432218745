import { useCallback, useEffect } from 'react'
import { CartItemOption, CartLevels } from '@open-tender/types'
import MenuItemPizzaAmount from '../MenuItemPizza/MenuItemPizzaAmount'
import styled from '@emotion/styled'

export const MenuItemOptionAmountView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const MenuItemOptionAmount = ({
  option,
  levels,
  incrementDisabled,
  toggleOption,
  setSpacer,
}: {
  option: CartItemOption
  levels: CartLevels
  incrementDisabled: boolean
  toggleOption: (levels: CartLevels) => void
  setSpacer: (bool: boolean) => void
}) => {
  const isDisabled = incrementDisabled && option.quantity === 0
  const otherGroup = option.groups[0]
  const otherOptions = otherGroup?.options
  const otherGroupId = otherGroup?.id
  const selectedOther = otherGroup?.options?.find((i) => i.quantity === 1)
  const selectedOtherId = selectedOther ? selectedOther.id : null

  const handleOther = useCallback(
    (optionId: number) => {
      if (!otherGroupId || !optionId) return
      const updatedLevels: CartLevels = [...levels, [otherGroupId, optionId]]
      toggleOption(updatedLevels)
    },
    [levels, otherGroupId, toggleOption]
  )

  useEffect(() => {
    setSpacer(option.quantity > 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option.quantity])

  return otherOptions && !isDisabled ? (
    <MenuItemPizzaAmount
      quantity={option.quantity}
      options={otherOptions}
      changeOption={handleOther}
      selectedId={selectedOtherId}
    />
  ) : null
}

export default MenuItemOptionAmount
