import { PrepType as PrepTypeType } from '@open-tender/types'
import {
  closeModal,
  selectOrder,
  setPrepType,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, ModalContent, ModalView } from 'components'

const PrepType = () => {
  const dispatch = useAppDispatch()
  const { prepType } = useAppSelector(selectOrder)

  const changePrepType = (prepType: PrepTypeType) => {
    dispatch(setPrepType(prepType))
    dispatch(closeModal())
  }

  return (
    <ModalView>
      <ModalContent
        title="Change your order type"
        footer={
          <div>
            <Button
              onClick={() => changePrepType('EAT_HERE')}
              color={
                (prepType as string) === 'EAT_HERE' ? 'primary' : 'secondary'
              }
            >
              Eat Here
            </Button>
            <Button
              onClick={() => changePrepType('TAKE_OUT')}
              color={prepType === 'TAKE_OUT' ? 'primary' : 'secondary'}
            >
              Take Out
            </Button>
          </div>
        }
      >
        <div>
          <p>Please choose an order type below.</p>
        </div>
      </ModalContent>
    </ModalView>
  )
}

export default PrepType
