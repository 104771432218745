import { CartItemGroup, CartItemOption, CartLevels } from '@open-tender/types'
import { useModifier } from '@open-tender/utils'
import {
  selectDisplaySettings,
  selectSelectedAllergenNames,
  useAppSelector,
} from '@open-tender/cloud'
import {
  MenuItemOptionSimpleOptionButton,
  MenuItemOptionSimpleOptionName,
  MenuItemOptionSimpleOptionPrice,
} from './MenuItemOptionSimpleOption.styled'
import { isMobile } from 'react-device-detect'

const MenuItemOptionSimpleOption = ({
  group,
  option,
  levels = [],
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
}: {
  group: CartItemGroup
  option: CartItemOption
  levels: CartLevels
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  toggleOption: (levels: CartLevels) => void
  setOptionQuantity: (levels: CartLevels, quantity: number) => void
}) => {
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const {
    name,
    isSoldOut,
    price,
    isRadio,
    showGroups,
    toggle,
    increment,
    decrement,
    groupAtMax,
  } = useModifier(
    group,
    option,
    levels,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity,
    allergenAlerts,
    displaySettings
  )
  const disabled =
    isSoldOut || (group.max !== 1 && groupAtMax && option.quantity === 0)
  const classSelected = option.quantity > 0 ? 'selected' : ''
  const classHover = isMobile || disabled ? '' : ' show-hover'
  const className = classSelected + classHover
  const nameAdjusted = name.startsWith('Extra') ? 'Extra' : name

  const onClick = () => {
    if (isRadio || (group.max === 1 && option.quantity === 0)) {
      toggle()
    } else if ((group.max === 1 || option.max === 1) && option.quantity === 1) {
      decrement()
    } else {
      increment()
    }
  }

  return (
    <MenuItemOptionSimpleOptionButton
      onClick={onClick}
      disabled={disabled}
      showGroups={showGroups}
      className={className}
    >
      <MenuItemOptionSimpleOptionName size="xSmall">
        {nameAdjusted}
      </MenuItemOptionSimpleOptionName>
      {/* {isSoldOut && (
          <MenuItemOptionSimpleOptionSoldOut size="xSmall">
            Out of stock
          </MenuItemOptionSimpleOptionSoldOut>
        )} */}
      {price !== null && (
        <MenuItemOptionSimpleOptionPrice>
          {price}
        </MenuItemOptionSimpleOptionPrice>
      )}
    </MenuItemOptionSimpleOptionButton>
  )
}

export default MenuItemOptionSimpleOption
