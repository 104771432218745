import { toggleNavSite, useAppDispatch } from '@open-tender/cloud'
import { Hamburger } from '../icons'
import Icon from './Icon'

const NavSiteMenu = ({ color }: { color: string }) => {
  const dispatch = useAppDispatch()

  return (
    <Icon
      aria-label="Navigation Menu"
      color={color}
      margin="0 -1.5rem 0 0"
      onClick={() => dispatch(toggleNavSite())}
    >
      <Hamburger size={20} />
    </Icon>
  )
}

export default NavSiteMenu
