import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {
  ConfigContentField,
  RequestError,
  SimpleCart,
} from '@open-tender/types'
import {
  resetGroupOrder,
  reloadGuestOrder,
  resetOrder,
  selectContent,
  selectGroupOrder,
  selectMenuSlug,
  selectOrder,
  updateGroupOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  CartSummaryItem,
  Content,
  Header,
  Loading,
  Main,
  Message,
  PageTitle,
  PageContainer,
  PageContent,
} from 'components'
import { Back } from '../../buttons'
import { GroupOrderCartView, GroupOrderCartTitle } from './GroupOrderReview'

const makeSubtitle = (
  error: RequestError,
  groupCart: SimpleCart,
  firstName: string,
  config?: ConfigContentField & {
    background: string
  }
) => {
  if (!error) {
    return (
      config?.subtitle ||
      'Please review your order below and return to the menu if you need to make any updates.'
    )
  } else {
    if (groupCart.length) {
      return 'This group order is now closed for editing, but the items below have been added.'
    } else {
      return `We're sorry, but this group order is already closed. Please contact ${firstName} to see if they can reopen it.`
    }
  }
}

const GroupOrderReviewGuest = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { groupOrders: config } = useAppSelector(selectContent) || {}
  const menuSlug = useAppSelector(selectMenuSlug)
  const { cart } = useAppSelector(selectOrder)
  const {
    loading,
    error,
    cartOwner,
    cart: groupCart,
    cartGuest,
    closed,
  } = useAppSelector(selectGroupOrder)
  const { cartGuestId } = cartGuest || {}
  const firstName = cartOwner ? cartOwner.first_name : ''
  const isLoading = loading === 'pending'
  const subtitle = makeSubtitle(error, groupCart, firstName, config)
  const cartClosed = error?.status === 404

  useEffect(() => {
    error ? dispatch(reloadGuestOrder()) : dispatch(updateGroupOrder())
  }, [dispatch, error, cartGuestId])

  const startOver = () => {
    dispatch(resetGroupOrder())
    dispatch(resetOrder())
    navigate('/guest')
  }

  return (
    <>
      <Content>
        <Header
          left={
            closed || error ? undefined : (
              <Back label="Back to Menu" path={menuSlug} />
            )
          }
          right={undefined}
        />
        <Main>
          <PageContainer>
            <PageTitle
              title={cartClosed ? 'Group Order Closed' : config?.title}
              subtitle={subtitle}
            >
              {isLoading ? (
                <Loading text="Submitting your order to the group..." />
              ) : (
                <></>
              )}
            </PageTitle>
            {!isLoading && (
              <>
                <PageContent style={{ margin: '3rem auto' }}>
                  {!error ? (
                    <p>
                      <Link to={menuSlug}>
                        Click here to head back to the menu and make changes to
                        your order.
                      </Link>
                    </p>
                  ) : cart?.length ? (
                    <Message color="alert">
                      This group order was closed while you were making updates
                      on the menu page, but the items you previously submitted
                      are listed below. Please contact {firstName} if you need
                      to make any changes.
                    </Message>
                  ) : null}
                  <p>
                    Want to start a new order just for you? Use the button
                    below.
                  </p>
                  <p>
                    <Button onClick={startOver}>Start A New Order</Button>
                  </p>
                </PageContent>
                {(cart?.length ?? 0) > 0 && (
                  <GroupOrderCartView>
                    <GroupOrderCartTitle
                      as="p"
                      style={{ marginBottom: '2rem' }}
                    >
                      Items submitted to {firstName}'s group order
                    </GroupOrderCartTitle>
                    <ul>
                      {cart?.map((item, index) => {
                        return (
                          <li key={`${item.id}-${index}`}>
                            <CartSummaryItem item={item} pointsObj={null} />
                          </li>
                        )
                      })}
                    </ul>
                  </GroupOrderCartView>
                )}
              </>
            )}
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default GroupOrderReviewGuest
