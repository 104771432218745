import { useNavigate } from 'react-router-dom'
import {
  closeModal,
  selectGroupOrder,
  resetGroupOrder,
  removeCustomerGroupOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  GroupOrderLink,
  GroupOrderTime,
  ModalContent,
} from 'components'

const GroupOrderShare = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { token, cartId } = useAppSelector(selectGroupOrder)

  const proceed = () => {
    navigate('/review')
    dispatch(closeModal())
  }

  const backToMenu = () => {
    dispatch(closeModal())
  }

  const save = () => {
    dispatch(resetGroupOrder())
    dispatch(closeModal())
  }

  const cancel = () => {
    dispatch(removeCustomerGroupOrder({ cartId: cartId ?? 0 }))
    dispatch(closeModal())
  }

  return (
    <ModalContent
      title="Spread the love!"
      subtitle={
        <p>
          Share the link below with your friends so they can add their orders
        </p>
      }
      footer={
        <>
          <p>
            Change your mind? Save this order for later or cancel it altogether.
          </p>
          <div>
            <Button onClick={save} color="secondary" size="small">
              Save for Later
            </Button>
            <Button onClick={cancel} color="secondary" size="small">
              Delete Forever
            </Button>
          </div>
        </>
      }
    >
      <div>
        <GroupOrderLink token={token ?? ''} />
        <GroupOrderTime />
        <p>
          Once you've added your own items, proceed to the next page to review
          the orders that have been submitted by others.
        </p>
        <p>
          <Button onClick={proceed}>Review All Orders</Button>
          <Button onClick={backToMenu} color="secondary">
            Back To Menu
          </Button>
        </p>
      </div>
    </ModalContent>
  )
}

export default GroupOrderShare
