import styled from '@emotion/styled'
import MenuCard from './MenuCard'
import { MenuCategory } from '@open-tender/types'

const MenuCardsView = styled.div`
  max-width: ${(props) => props.theme.categories.desktop.containerMaxWidth};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: ${(props) => props.theme.categories.mobile.containerMaxWidth};
  }
`

const MenuCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.theme.categories.desktop.justifyContent};
  margin: -${(props) => props.theme.categories.desktop.gapHalf};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: ${(props) => props.theme.categories.mobile.justifyContent};
    margin: -${(props) => props.theme.categories.mobile.gapHalf};
  }
`

const makeNumbers = (n: number) => {
  let numbers = []
  for (let i = 2; i <= n; i++) {
    numbers.push(i)
  }
  return numbers
}

const makeEmpty = (perRow: number, count: number) => {
  const numbers: number[] = makeNumbers(perRow)
  return numbers.reduce((arr: number[], i, index) => {
    const leftOver = count % i
    return leftOver ? [...arr, index] : arr
  }, [])
}

const MenuCards = ({
  sections,
  categories,
}: {
  sections: Partial<MenuCategory>[]
  categories: Partial<MenuCategory>[]
}) => {
  if (!categories || !categories.length) return null
  const perRow = 5
  const combinedLength = sections.length + categories.length
  const empty = makeEmpty(perRow, combinedLength)

  return (
    <MenuCardsView>
      <MenuCardsContainer>
        {sections.map((section) => (
          <MenuCard
            key={section.name}
            category={section}
            section={section.name}
          />
        ))}
        {categories.map((category) => (
          <MenuCard key={category.name} category={category} />
        ))}
        {empty.map((idx) => (
          <MenuCard key={idx} category={{}} />
        ))}
      </MenuCardsContainer>
    </MenuCardsView>
  )
}

export default MenuCards
