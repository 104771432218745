import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import {
  checkout,
  openModal,
  selectContent,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink, DineInForm, FormWrapper, PageTitle } from 'components'

const defaultText = {
  title: 'Add Your Name or Sign In',
  subtitle:
    'Creating an account allows you to apply promo codes and to start earning rewards. It takes just a few seconds!',
}

const CheckoutDineInFormView = styled.div`
  & > div:first-of-type p {
    margin: 2rem 0 0;
  }
`

const CheckoutDineInForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { checkoutFlow } = useAppSelector(selectContent) || {}
  const { title, subtitle } = checkoutFlow?.sections?.name || defaultText
  const { auth } = useAppSelector(selectCustomer)

  const signUp = () => {
    dispatch(openModal({ type: 'signUp' }))
  }

  const signIn = () => {
    dispatch(openModal({ type: 'login' }))
  }

  useEffect(() => {
    if (auth) {
      dispatch(checkout())
      navigate('/checkout')
    }
  }, [auth, dispatch, navigate])

  return (
    <CheckoutDineInFormView>
      <PageTitle title={title}>
        <p>{subtitle}</p>
        <p>
          <ButtonLink onClick={signUp}>Create an account</ButtonLink> or{' '}
          <ButtonLink onClick={signIn}>sign into an existing one</ButtonLink>
        </p>
      </PageTitle>
      <FormWrapper>
        <DineInForm />
      </FormWrapper>
    </CheckoutDineInFormView>
  )
}

export default CheckoutDineInForm
