import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { isBrowser } from 'react-device-detect'
import {
  ConfigDisplaySetting,
  MenuCategory,
  MenuItems,
} from '@open-tender/types'
import {
  makeFavorites,
  makeFeatured,
  makeMenuItemLookup,
  makeRecents,
  makeUniqueDisplayItems,
} from '@open-tender/utils'
import {
  fetchDeals,
  selectBrand,
  selectContentSection,
  selectCustomer,
  selectCustomerFavorites,
  selectCustomerOrders,
  selectDisplaySettings,
  selectGroupOrder,
  selectMenu,
  selectMenuSection,
  setMenuSection,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Container, Loading } from 'components'
import MenuScrollable from './MenuScrollable'
import MenuBrowse from './MenuBrowse'
import { capitalize } from 'lodash'

const makeMenuSections = (
  displayedSections: Record<string, MenuItems>
): Partial<MenuCategory>[] => {
  return Object.entries(displayedSections).map(([section, items]) => {
    const { small_image_url, large_image_url, app_image_url } = items[0]
    return {
      name: capitalize(section),
      small_image_url,
      large_image_url,
      app_image_url,
      items,
    }
  })
}

const MenuTopView = styled.div`
  margin: ${(props) => props.theme.layout.margin} auto;
  max-width: ${(props) => props.theme.categories.desktop.containerMaxWidth};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} auto;
    max-width: ${(props) => props.theme.categories.mobile.containerMaxWidth};
  }
`

const MenuTop = () => {
  const dispatch = useAppDispatch()
  const { has_deals } = useAppSelector(selectBrand) || {}
  const menuSection = useAppSelector(selectMenuSection)
  const { categories, revenueCenters, soldOut } = useAppSelector(selectMenu)
  const menuContent = useAppSelector(selectContentSection('menu'))
  const { displayed: displayedDesktop = [], displayedMobile = [] } =
    menuContent || {}
  const displaySettings = useAppSelector(selectDisplaySettings) || {}
  const displayed = isBrowser ? displayedDesktop : displayedMobile
  const { menuType: menuTypeDesktop, menuTypeMobile } =
    displaySettings as ConfigDisplaySetting
  const menuType = isBrowser ? menuTypeDesktop : menuTypeMobile
  const isScrollable = menuType === 'SCROLLABLE'
  const itemLookup = useMemo(() => makeMenuItemLookup(categories), [categories])

  // handle deals
  const { cartGuest } = useAppSelector(selectGroupOrder)
  const { profile } = useAppSelector(selectCustomer)
  const { customer_id = null } = profile || {}
  // const { entities } = useAppSelector(selectDeals)
  // const deals = useMemo(() => {
  //   const current = has_deals && entities.length ? entities : []
  //   return makeValidDeals(current, orderType, serviceType, revenueCenterId)
  // }, [has_deals, entities, orderType, serviceType, revenueCenterId])

  // handle featured
  const featured = makeFeatured(categories)

  // handle favorites
  const favs = useAppSelector(selectCustomerFavorites)
  const favorites = useMemo(
    () =>
      favs.entities ? makeFavorites(favs.entities, itemLookup, soldOut) : [],
    [favs.entities, itemLookup, soldOut]
  )
  const hasFavorites = favorites && favorites.length > 0 ? true : false
  const loadingFavs = favs.loading === 'pending' && !hasFavorites ? true : false

  // handle recents
  const orders = useAppSelector(selectCustomerOrders)
  // TODO: figure out how orders.entities is null at times
  const displayItems = useMemo(
    () => makeUniqueDisplayItems(orders.entities || []),
    [orders.entities]
  )
  const recents = useMemo(
    () => makeRecents(displayItems, itemLookup, soldOut),
    [displayItems, itemLookup, soldOut]
  )
  const hasRecents = recents && recents.length > 0 ? true : false
  const loadingRecents =
    orders.loading === 'pending' && !hasRecents ? true : false

  // tie it all together
  const sections: Record<string, MenuItems> = {
    FEATURED: featured,
    RECENTS: recents,
    FAVORITES: favorites,
  }
  const displayedSections = displayed.reduce((obj, i) => {
    const itemCount = sections[i]?.length ?? 0
    return itemCount > 0 ? { ...obj, [i]: sections[i] } : obj
  }, {} as Record<string, MenuItems>)
  const displayedKeys = Object.keys(displayedSections)
  const firstSection = displayedKeys[0] || null
  const currentSection = menuSection ? displayedSections[menuSection] || [] : []
  const hasSection = currentSection.length > 0
  const isLoading = loadingRecents || loadingFavs
  const hasItems =
    displayedKeys.length > 0 || categories.length > 0 || revenueCenters?.length
  const showLoading = isLoading && !hasItems
  const menuSections = makeMenuSections(displayedSections)

  useEffect(() => {
    if (!hasSection) dispatch(setMenuSection(firstSection))
  }, [hasSection, firstSection, dispatch])

  useEffect(() => {
    if (has_deals && !isLoading && !cartGuest) {
      dispatch(fetchDeals())
    }
  }, [has_deals, customer_id, isLoading, dispatch, cartGuest])

  if (!isLoading && !hasItems) return null

  return showLoading ? (
    <MenuTopView>
      <Container>
        <Loading text="Loading your favorites..." />
      </Container>
    </MenuTopView>
  ) : isScrollable ? (
    <MenuScrollable menuSections={menuSections} />
  ) : (
    <MenuBrowse menuSections={menuSections} />
  )
}

export default MenuTop
