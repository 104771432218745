import { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  closeModal,
  selectOrder,
  selectCustomerPointsProgram,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  Heading,
  ModalContent,
  ModalView,
  PointsBalance,
} from 'components'

const PointsView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0;
  text-align: center;
`

const PointsHeader = styled.div`
  & > p {
    margin: 0;
  }

  p:first-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.h3};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    margin: 0;
  }

  p + p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    margin: 1rem 0 0;
  }
`

const PointsContent = styled.div`
  margin: 1.5rem 0 3rem;

  & > div {
    margin: 1rem auto 2.5rem;
  }

  & > div + p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
  }
`

const Points = () => {
  const dispatch = useAppDispatch()
  const order = useAppSelector(selectOrder)
  const program = useAppSelector(selectCustomerPointsProgram(order.orderType))
  const { name, description, perk, spend_name } = program || {}
  const points = perk?.credit ? parseInt(perk.credit) : 0

  useEffect(() => {
    if (!program) dispatch(closeModal())
  }, [program, dispatch])

  if (!spend_name) return null

  return (
    <ModalView style={{ maxWidth: '36rem' }}>
      <ModalContent>
        <PointsView>
          <PointsHeader>
            <Heading as="p">{name}</Heading>
            {description ? <p>{description}</p> : <></>}
          </PointsHeader>
          <PointsContent>
            <div>
              {spend_name && (
                <PointsBalance name={spend_name} points={points} />
              )}
            </div>
            <p>{spend_name} can be applied to eligible items at checkout.</p>
          </PointsContent>
          <div>
            <Button onClick={() => dispatch(closeModal())}>Close</Button>
          </div>
        </PointsView>
      </ModalContent>
    </ModalView>
  )
}

export default Points
