import {} from '@open-tender/cloud'
import styled from '@emotion/styled'
import { CartItem, CartItemGroup, CartItemGroups } from '@open-tender/types'
import { displayPrice, rehydrateOrderItem } from '@open-tender/utils'
import {
  addItemToCart,
  selectCustomerFavorites,
  selectMenuItems,
  showNotification,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, Card } from 'components'
import { Favorite } from 'components/buttons'

const OrderCardItemImageView = styled.div`
  div {
    width: 100%;
    padding: 33.33333%;
    margin: 0 0 1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  p {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

interface OrderCardItemImageProps {
  bgStyle?: React.CSSProperties
  names: string
}

const OrderCardItemImage = ({ bgStyle, names }: OrderCardItemImageProps) => (
  <OrderCardItemImageView>
    {bgStyle && <div style={bgStyle} />}
    <p>{names}</p>
  </OrderCardItemImageView>
)

const OrderCardItem = ({ item }: { item: CartItem }) => {
  const dispatch = useAppDispatch()
  const { lookup } = useAppSelector(selectCustomerFavorites)
  const { entities: menuItems } = useAppSelector(selectMenuItems)
  const { name, groups, quantity, totalPrice, imageUrl, signature } = item
  const price = totalPrice
    ? `$${displayPrice(`${totalPrice / quantity}`)}`
    : null
  const favoriteId = signature ? lookup[signature] : null
  const optionNames = groups
    .reduce<any>((arr: CartItemGroups, group: Partial<CartItemGroup>) => {
      const names = group?.options?.map((o) => o.name)
      return [...arr, ...(names ?? [])]
    }, [])
    .join(', ')
  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl}` } : null

  const addToCart = (item: CartItem) => {
    const menuItem = menuItems.find((i) => i.id === item.id)
    if (!menuItem) {
      dispatch(showNotification('Item not currently available'))
    } else {
      const orderItem = rehydrateOrderItem(menuItem, item)
      dispatch(addItemToCart(orderItem))
      dispatch(showNotification('Item added to cart!'))
    }
  }

  return (
    <Card
      preface={price ?? ''}
      title={name}
      content={
        <OrderCardItemImage
          bgStyle={bgStyle ?? undefined}
          names={optionNames}
        />
      }
      footer={
        <>
          <Button onClick={() => addToCart(item)} size="small">
            Add To Order
          </Button>
          <Favorite item={item as any} favoriteId={favoriteId ?? 0} />
        </>
      }
    />
  )
}

export default OrderCardItem
