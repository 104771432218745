import { Order, Orders } from '@open-tender/types'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { OrderCard, OrderCardSimple, ScrollableSection } from 'components'
import AccountSection from './AccountSection'

const OrderCardItem = ({ item }: { item: Order }) => <OrderCard order={item} />

const OrderCardSimpleItem = ({ item }: { item: Order }) => (
  <OrderCardSimple order={item} />
)

const AccountOrders = ({ orders }: { orders: Orders }) => {
  const account = useAppSelector(selectContentSection('account'))
  const { title } = account?.recentOrders || {}
  const useSimple = true
  const filtered = orders
    .map((i) => ({ ...i, key: i.order_id }))
    .filter((i) => i.order_type !== 'MERCH')
  const displayed = filtered.slice(0, 3)

  return (
    <AccountSection>
      <ScrollableSection
        title={title}
        to={displayed.length > 1 ? '/orders' : undefined}
        items={displayed}
        renderItem={useSimple ? OrderCardSimpleItem : OrderCardItem}
        keyName="order_id"
      />
    </AccountSection>
  )
}

export default AccountOrders
