import { ISOString, TPLSReward } from '@open-tender/types'
import {
  TPLSRewardContent,
  TPLSRewardDescription,
  TPLSRewardImage,
  TPLSRewardTitle,
  TPLSRewardView,
} from './ThirdPartyLoyalty.styled'
import { isoToDateStr } from '@open-tender/utils'
import { selectTimezone, useAppSelector } from '@open-tender/cloud'

const ThirdPartyLoyaltyReward = ({ reward }: { reward: TPLSReward }) => {
  const tz = useAppSelector(selectTimezone)
  const { name, description, expires_at, image } = reward
  const bgStyle = image ? { backgroundImage: `url(${image}` } : undefined
  const expiresAt = expires_at
    ? isoToDateStr(expires_at as ISOString, tz, 'MMM d, yyyy')
    : null

  return (
    <TPLSRewardView>
      {bgStyle && <TPLSRewardImage style={bgStyle} />}
      <TPLSRewardContent>
        <TPLSRewardTitle as="p" size="main">
          {name}
        </TPLSRewardTitle>
        {description && (
          <TPLSRewardDescription as="p" size="small">
            {description}
          </TPLSRewardDescription>
        )}
        {expiresAt && (
          <TPLSRewardDescription as="p" size="xSmall">
            Expires {expiresAt}
          </TPLSRewardDescription>
        )}
      </TPLSRewardContent>
    </TPLSRewardView>
  )
}

export default ThirdPartyLoyaltyReward
