import { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  fetchCustomerQRCode,
  openModal,
  selectBrand,
  selectCustomerQRCode,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Grid } from '../../icons'
import ButtonLarge from 'components/ButtonLarge'

const AccountScanIcon = styled.span`
  display: block;
  margin: 0 0.7rem 0 0;
  line-height: 0;
`

const AccountScan = () => {
  const dispatch = useAppDispatch()
  const { has_app = false } = useAppSelector(selectBrand) || {}
  const { qrcode, loading, error } = useAppSelector(selectCustomerQRCode)
  const hasQRCode = has_app && qrcode && !error && loading === 'idle'
  const src = qrcode
  const alt = 'Earn & Redeem In-store'
  const title = 'Scan In-Store'
  const alert = 'Only relevant for IN-STORE transactions'
  const isLoyalty = true
  // const footnote = "This is NOT required if you're paying with a credit card"
  const footnote = null

  useEffect(() => {
    if (has_app) dispatch(fetchCustomerQRCode())
  }, [dispatch, has_app])

  const showQrCode = () => {
    const args = { src, alt, title, alert, footnote, isLoyalty }
    dispatch(openModal({ type: 'qrCode', args }))
  }

  return hasQRCode ? (
    <ButtonLarge color="secondary" text="Scan In-store" onClick={showQrCode}>
      <AccountScanIcon>
        <Grid
          size={18}
          strokeWidth={2}
          style={{ position: 'relative', top: 1 }}
        />
      </AccountScanIcon>
    </ButtonLarge>
  ) : null
}

export default AccountScan
