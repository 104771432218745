import styled from '@emotion/styled'
import { LoyaltyProgram as LoyaltyProgramType } from '@open-tender/types'
import { LoyaltyPoints, LoyaltySpend, LoyaltyTiers, LoyaltyVisits } from '.'

const LoyaltyProgramView = styled.div`
  margin-bottom: ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: ${(props) => props.theme.layout.marginMobile};
  }
`

const LoyaltyProgram = ({
  program,
  isLoading = false,
}: {
  program: LoyaltyProgramType
  isLoading?: boolean
}) => {
  const { loyalty_type, perk, status, spend_name } = program || {}

  if (isLoading && !program) return null

  return (
    <LoyaltyProgramView>
      {perk && (
        <>
          {perk.loyalty_perk === 'BANKABLE_POINTS' ? (
            <LoyaltyPoints
              program={program}
              perk={perk}
              showThresholds={true}
            />
          ) : perk.loyalty_perk === 'DOLLARS_CREDIT' ? (
            <LoyaltySpend program={program} perk={perk} />
          ) : loyalty_type === 'ORDERS' ? (
            <LoyaltyVisits program={program} perk={perk} />
          ) : null}
        </>
      )}

      {status && spend_name && (
        <LoyaltyTiers
          loyaltyType={loyalty_type}
          status={status}
          tiers={status.tiers}
          spendName={spend_name}
        />
      )}
    </LoyaltyProgramView>
  )
}

export default LoyaltyProgram
