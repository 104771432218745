import styled from '@emotion/styled'

const ButtonIconView = styled.button<{ size: number; color?: string }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
  width: 5rem;
  height: 5rem;
  border: 0;
  margin: 0;
  color: ${(props) => props.color || props.theme.buttons.colors.header.color};
  transition: ${(props) => props.theme.links.transition};
  opacity: ${(props) => (props.disabled ? '0.5' : '1.0')};

  & > span {
    display: block;
    width: ${(props) => (props.size / 10.0).toFixed(1)}rem;
    height: ${(props) => (props.size / 10.0).toFixed(1)}rem;
  }

  &:hover {
    color: ${(props) => props.theme.buttons.colors.headerHover.color};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.buttons.colors.header.color};
    }
  }
`

const ButtonIcon = ({
  label,
  onClick,
  color,
  size = 20,
  disabled,
  children,
  style,
}: {
  label?: string
  onClick: () => void
  color?: string
  size?: number
  disabled?: boolean
  children?: JSX.Element | JSX.Element[]
  style?: React.CSSProperties
}) => {
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!disabled) onClick()
  }

  return (
    <ButtonIconView
      type="button"
      aria-label={label}
      onClick={(evt) => handleClick(evt)}
      disabled={disabled}
      color={color}
      size={size}
      style={style}
    >
      <span>{children}</span>
    </ButtonIconView>
  )
}

export default ButtonIcon
