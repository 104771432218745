import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { RevenueCenter, ServiceType } from '@open-tender/types'
import {
  closeModal,
  openModal,
  selectCustomer,
  setIsGroupOrder,
  setOrderServiceType,
  setRevenueCenter,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, ButtonLink, ModalContent, ModalView } from 'components'

const GroupOrderTypeView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;

  button {
    display: block;
    width: 47.5%;
  }
`

const GroupOrderTypeFooter = styled.div`
  justify-content: center !important;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

export interface GroupOrderTypeModalProps extends Record<string, unknown> {
  revenueCenter?: RevenueCenter
  serviceTypes?: ServiceType[]
}

const GroupOrderType = ({
  revenueCenter,
  serviceTypes,
}: GroupOrderTypeModalProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { auth } = useAppSelector(selectCustomer)
  const hasPickup =
    !serviceTypes || serviceTypes.includes('PICKUP') ? true : false
  const hasDelivery =
    !serviceTypes || serviceTypes.includes('DELIVERY') ? true : false

  const chooseOrderType = (serviceType: ServiceType) => {
    dispatch(setOrderServiceType({ orderType: 'OLO', serviceType }))
    dispatch(setIsGroupOrder(true))
    dispatch(closeModal())
    if (revenueCenter) {
      dispatch(setRevenueCenter(revenueCenter))
      navigate(`/menu/${revenueCenter.slug}`)
    } else {
      navigate(`/locations`)
    }
  }

  const cancel = () => {
    dispatch(closeModal())
  }

  const callback = () => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'groupOrderType', args: { serviceTypes } }))
    }, 300)
  }

  const login = (type: string) => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type, args: { callback } }))
    }, 300)
  }

  return (
    <ModalView>
      <ModalContent
        title={auth ? 'Choose group order type' : 'Account Required'}
        subtitle={
          auth ? (
            <p>What time of group order would you like to place?</p>
          ) : undefined
        }
        style={{ textAlign: 'center' }}
        footer={
          <GroupOrderTypeFooter>
            <ButtonLink onClick={cancel}>
              Nevermind, cancel this group order
            </ButtonLink>
          </GroupOrderTypeFooter>
        }
      >
        {auth ? (
          <GroupOrderTypeView>
            {hasPickup && (
              <Button onClick={() => chooseOrderType('PICKUP')}>
                Order Pickup
              </Button>
            )}
            {hasDelivery && (
              <Button onClick={() => chooseOrderType('DELIVERY')}>
                Order Delivery
              </Button>
            )}
          </GroupOrderTypeView>
        ) : (
          <>
            <div>
              <p>You must be logged into an accout to start a group order.</p>
              <p>
                <Button onClick={() => login('login')} color="primary">
                  Click here to login
                </Button>
              </p>
            </div>
            <div style={{ margin: '3rem 0 0' }}>
              <p>
                Don't have an account?{' '}
                <ButtonLink onClick={() => login('signUp')}>
                  Click here to create one.
                </ButtonLink>
              </p>
            </div>
          </>
        )}
      </ModalContent>
    </ModalView>
  )
}

export default GroupOrderType
