import { useLocation } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import styled from '@emotion/styled'
import { contains } from '@open-tender/utils'
import {
  selectOrder,
  selectCartQuantity,
  toggleSidebar,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ShoppingBag } from '../icons'
import Icon from './Icon'

interface CartIconProps {
  isFilled?: boolean | null
}

const CartIcon = styled.div<CartIconProps>`
  position: relative;
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 -1rem 0 0;
  }

  button {
    color: ${(props) =>
      props.isFilled
        ? props.theme.buttons.colors.header.color
        : props.theme.buttons.colors.header.color};

    &:hover,
    &:active,
    &:focus {
      color: ${(props) =>
        props.isFilled
          ? props.theme.buttons.colors.headerHover.color
          : props.theme.buttons.colors.headerHover.color};
    }
  }
`

const CartCount = styled.div`
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  right: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.7rem;
  height: 1.7rem;
  border-radius: 1.2rem;
  padding-bottom: 0.1rem;
  color: ${(props) => props.theme.counts.alerts.color};
  background-color: ${(props) => props.theme.counts.alerts.bgColor};
  font-size: 1rem;
`

const Cart = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const cartQuantity = useAppSelector(selectCartQuantity)
  const currentOrder = useAppSelector(selectOrder)
  const { revenueCenter, serviceType } = currentOrder
  const isCurrentOrder = revenueCenter && serviceType && cartQuantity > 0
  const hideCart = contains(pathname, ['/item/', 'review'])
  const showCart =
    (isCurrentOrder || contains(pathname, ['menu', 'checkout'])) && !hideCart

  return showCart ? (
    <CartIcon isFilled={isCurrentOrder}>
      {cartQuantity > 0 && <CartCount>{cartQuantity}</CartCount>}
      <Icon aria-label="Cart" onClick={() => dispatch(toggleSidebar())}>
        <ShoppingBag size={isMobileOnly ? 20 : 24} />
      </Icon>
    </CartIcon>
  ) : null
}

export default Cart
