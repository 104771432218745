const Logo = ({ width = undefined }) => {
  return (
    <svg
      width={width}
      viewBox="0 0 142 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.83286 7.74385C3.04587 6.36921 4.74395 5.66846 6.8734 5.66846C9.00284 5.66846 10.701 6.3692 11.9139 7.74385C13.1269 9.11867 13.747 10.8438 13.747 12.9732C13.747 15.0756 13.1269 16.8276 11.9139 18.2022C10.701 19.5771 9.00284 20.251 6.8734 20.251C4.74395 20.251 3.04587 19.5771 1.83286 18.2022C0.619933 16.8276 0 15.0756 0 12.9732C0 10.8438 0.619933 9.11867 1.83286 7.74385ZM10.0272 9.09164C9.1915 8.14819 8.14029 7.66312 6.8734 7.66312C5.60651 7.66312 4.5553 8.14819 3.69274 9.09164C2.85722 10.0351 2.42585 11.3288 2.42585 12.9732C2.42585 14.5904 2.85722 15.8843 3.69274 16.8276C4.5553 17.771 5.60651 18.2293 6.8734 18.2293C8.14029 18.2293 9.1915 17.771 10.0272 16.8276C10.8898 15.8843 11.3209 14.5904 11.3209 12.9732C11.3209 11.3288 10.8898 10.0351 10.0272 9.09164Z" />
      <path d="M27.4401 7.77087C28.5453 9.14552 29.0844 10.8975 29.0844 13.0001C29.0844 15.0756 28.5453 16.8276 27.4401 18.2022C26.335 19.5771 24.7716 20.251 22.723 20.251C20.5936 20.251 19.1112 19.2534 18.2216 17.2588H18.1677V25.3183H15.7418V5.99172H18.2755L18.2216 8.76803H18.3025C18.8146 6.74652 20.6745 5.66846 22.75 5.66846C24.7986 5.66846 26.362 6.3692 27.4401 7.77087ZM25.6072 16.6928C26.2811 15.6416 26.6046 14.3747 26.6046 12.8384C26.6046 11.4097 26.2541 10.1967 25.5263 9.22642C24.8255 8.22909 23.7743 7.74385 22.3726 7.74385C19.758 7.74385 18.2754 9.71149 18.1946 12.5957V12.7574C18.1946 14.294 18.5182 15.6148 19.165 16.666C19.8389 17.7172 20.9171 18.2293 22.4266 18.2293C23.8821 18.2293 24.9333 17.7172 25.6072 16.6928H25.6072Z" />
      <path d="M43.5598 13.674H32.5353C32.7778 16.7198 34.3143 18.2293 37.1445 18.2293C39.1123 18.2293 40.5408 17.3128 40.9451 15.5068H43.3171C42.6973 18.5527 40.3791 20.251 37.1445 20.251C32.7239 20.251 30.244 17.3399 30.1093 12.9192C30.1093 10.8438 30.7831 9.11867 32.1039 7.74385C33.4248 6.36921 35.0959 5.66846 37.0636 5.66846C42.1042 5.66846 43.7214 10.2776 43.5598 13.674V13.674ZM41.1069 11.6254C40.9722 9.3612 39.3548 7.66312 37.0636 7.66312C34.7995 7.66312 33.0474 9.25345 32.6161 11.6254H41.1069H41.1069Z" />
      <path d="M57.5765 10.7627V19.9273H55.1506V11.0054C55.1506 8.55252 53.9916 7.68997 51.943 7.68997C49.2205 7.68997 47.8189 9.06461 47.8189 12.5419V19.9273H45.393V5.99172H47.6842V9.01074H47.7381C48.385 6.77354 50.1101 5.66846 52.2665 5.66846C55.7975 5.66846 57.5765 7.6361 57.5765 10.7627Z" />
      <path d="M63.7228 8.98387H60.7308V5.9917H63.7228V8.98387ZM60.7308 16.9355H63.7228V19.9273H60.7308V16.9355Z" />
      <path d="M73.1032 7.98647H70.3269V17.0163C70.3269 17.6632 70.5155 17.8791 71.351 17.8791H72.9684V19.9274H70.246C68.5209 19.9274 67.9548 18.9571 67.9548 17.2589V7.98647H65.6636V5.9918H67.9548V2.67637L70.246 1.54443H70.3269V5.9918H73.1032V7.98647Z" />
      <path d="M87.6858 13.674H76.6613C76.9038 16.7198 78.4402 18.2293 81.2704 18.2293C83.2382 18.2293 84.6668 17.3128 85.0711 15.5068H87.4431C86.8232 18.5527 84.505 20.251 81.2704 20.251C76.8499 20.251 74.37 17.3399 74.2352 12.9192C74.2352 10.8438 74.9091 9.11867 76.2299 7.74385C77.5507 6.36921 79.2219 5.66846 81.1896 5.66846C86.2302 5.66846 87.8474 10.2776 87.6858 13.674V13.674ZM85.2329 11.6254C85.0981 9.3612 83.4808 7.66312 81.1896 7.66312C78.9255 7.66312 77.1733 9.25345 76.7421 11.6254H85.2329H85.2329Z" />
      <path d="M101.702 10.7627V19.9273H99.2766V11.0054C99.2766 8.55252 98.1176 7.68997 96.0689 7.68997C93.3465 7.68997 91.9449 9.06461 91.9449 12.5419V19.9273H89.519V5.99172H91.8101V9.01074H91.8641C92.511 6.77354 94.2361 5.66846 96.3925 5.66846C99.9235 5.66846 101.702 7.6361 101.702 10.7627Z" />
      <path d="M114.264 0.681641H116.69V19.9274H114.345V17.2588H114.291C113.402 19.2535 111.919 20.251 109.79 20.251C107.741 20.251 106.178 19.5771 105.073 18.2023C103.967 16.8276 103.428 15.0757 103.428 13.0001C103.428 10.8975 103.967 9.14554 105.046 7.77089C106.151 6.36922 107.714 5.66848 109.763 5.66848C111.838 5.66848 113.698 6.74654 114.21 8.76805H114.318L114.264 0.681641V0.681641ZM113.321 16.666C113.995 15.6148 114.318 14.294 114.318 12.7574V12.5689C114.237 9.68466 112.755 7.74387 110.14 7.74387C108.738 7.74387 107.687 8.22911 106.959 9.22644C106.259 10.1967 105.908 11.4098 105.908 12.8385C105.908 14.3747 106.232 15.6416 106.905 16.6928C107.579 17.7172 108.631 18.2293 110.086 18.2293C111.596 18.2293 112.674 17.7172 113.321 16.666V16.666Z" />
      <path d="M131.758 13.674H120.734C120.976 16.7198 122.513 18.2293 125.343 18.2293C127.311 18.2293 128.739 17.3128 129.143 15.5068H131.516C130.896 18.5527 128.577 20.251 125.343 20.251C120.922 20.251 118.442 17.3399 118.308 12.9192C118.308 10.8438 118.982 9.11867 120.302 7.74385C121.623 6.36921 123.294 5.66846 125.262 5.66846C130.303 5.66846 131.92 10.2776 131.758 13.674V13.674ZM129.305 11.6254C129.17 9.3612 127.553 7.66312 125.262 7.66312C122.998 7.66312 121.246 9.25345 120.814 11.6254H129.305Z" />
      <path d="M141.489 8.22928C138.12 7.69016 136.017 9.65797 136.017 13.728V19.9275H133.591V5.99191H135.883V9.46914H135.936C136.718 6.6658 138.875 5.50684 141.489 5.9382V8.22928Z" />
    </svg>
  )
}

export default Logo
