import styled from '@emotion/styled'
import { Star } from 'react-feather'
import { CartItemWithPoints, CheckoutPoints } from '@open-tender/types'
import { Points } from 'components'

const CartSummaryItemPointsView = styled.div`
  label: CartSummaryItemPoints;
  position: relative;
  display: inline-block;
  margin: 0.5rem 0 0;
`

const CartSummaryItemPointsCount = styled.div`
  label: CartSummaryItemPointsCount;
  position: absolute;
  top: -0.6rem;
  right: -0.9rem;
  min-width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.success};

  span {
    display: block;
    line-height: 0;
    font-family: ${(props) => props.theme.counts.alerts.family};
    font-weight: ${(props) => props.theme.counts.alerts.weight};
    -webkit-font-smoothing: ${(props) =>
      props.theme.counts.alerts.fontSmoothing};
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    color: ${(props) => props.theme.colors.light};
  }
`

const CartSummaryItemPointsApply = styled.button`
  label: CartSummaryItemPointsApply;
  position: relative;

  &:enabled:hover > span,
  &:enabled:active > span {
    transition: ${(props) => props.theme.links.transition};
    filter: brightness(85%);
  }
`

const CartSummaryItemPointsRemove = styled.button`
  label: CartSummaryItemPointsRemove;
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0.2rem 0 0;
  height: 2rem;
  padding: 0 0.5em 0.1rem;
  border-radius: 1em;
  border: 0.1rem solid ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  color: ${(props) => props.theme.colors.error};
`

const CartSummaryItemPoints = ({
  item,
  pointsObj,
}: {
  item: CartItemWithPoints
  pointsObj: CheckoutPoints | null
}) => {
  const points = item.points
  const applied = pointsObj?.applied.find((i) => i.index === item.index)
  const quantityApplied =
    points && applied ? Math.floor(applied.points / points.per) : null
  const remaining = pointsObj?.points.remaining || 0
  const disabled =
    points && applied
      ? applied.points === points.total || points.per > remaining
      : points
      ? points.per > remaining
      : false

  const apply = (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: CartItemWithPoints
  ) => {
    evt.preventDefault()
    if (!disabled && pointsObj) pointsObj.apply(item)
  }

  const remove = (
    evt: React.MouseEvent<HTMLButtonElement>,
    item: CartItemWithPoints
  ) => {
    evt.preventDefault()
    if (pointsObj) pointsObj.remove(item)
  }

  if (!points) return null

  return (
    <CartSummaryItemPointsView>
      <CartSummaryItemPointsApply
        disabled={disabled}
        onClick={(evt) => apply(evt, item)}
      >
        {quantityApplied && (
          <CartSummaryItemPointsCount>
            <span>{quantityApplied}</span>
          </CartSummaryItemPointsCount>
        )}
        <Points
          color="light"
          bgColor="dark"
          size="xSmall"
          points={points.per}
          icon={<Star width={11} height={11} strokeWidth={2} />}
        />
      </CartSummaryItemPointsApply>
      {quantityApplied && (
        <CartSummaryItemPointsRemove onClick={(evt) => remove(evt, item)}>
          remove
        </CartSummaryItemPointsRemove>
      )}
    </CartSummaryItemPointsView>
  )
}

export default CartSummaryItemPoints
