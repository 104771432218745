import styled from '@emotion/styled'

const Stack = styled.div<{
  direction?: 'row' | 'column'
  gap?: number | string
}>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? 'row'};
  align-items: ${(props) =>
    props.direction === 'column' ? 'flex-start' : 'center'};
  gap: ${(props) =>
    props.gap === undefined
      ? props.theme.layout.margin
      : typeof props.gap === 'number'
      ? `${props.gap}px`
      : props.gap};
`

export default Stack
