import { useNavigate } from 'react-router-dom'
import { makeReadableDateStrFromIso } from '@open-tender/utils'
import {
  addMessage,
  resetMessages,
  selectCartTotal,
  selectGroupOrder,
  selectMessages,
  selectTimezone,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button } from 'components'

const GroupGuest = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const tz = useAppSelector(selectTimezone)
  const { cartGuest, cutoffAt, spendingLimit } =
    useAppSelector(selectGroupOrder)
  const limit = spendingLimit ? parseFloat(spendingLimit) : null
  const cartTotal = useAppSelector(selectCartTotal)
  const messages = useAppSelector(selectMessages)
  const aboveLimit = limit && cartTotal > limit
  const cutoffTime =
    cutoffAt && tz ? makeReadableDateStrFromIso(cutoffAt, tz) : null
  const text = cutoffTime ? `Submit by ${cutoffTime}` : 'Submit'

  const onClick = () => {
    if (cartTotal === 0) {
      const msg = `Your cart is currently empty. Please add some items to your order.`
      const current = messages.find((i) => i.message === msg)
      if (!current) dispatch(addMessage(msg))
    } else if (aboveLimit) {
      const msg = `Above spending limit of $${spendingLimit}`
      const current = messages.find((i) => i.message === msg)
      if (!current) dispatch(addMessage(msg))
    } else {
      dispatch(resetMessages())
      navigate('/review')
    }
  }

  if (!cartGuest) return null

  return (
    <Button onClick={onClick} color="header" size="header">
      {text}
    </Button>
  )
}

export default GroupGuest
