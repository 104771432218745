import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { CartItem } from '@open-tender/types'
import { useBuilder, useMenuItem } from '@open-tender/utils'
import {
  openModal,
  selectCustomer,
  selectCustomerFavorites,
  selectDisplaySettings,
  selectGroupOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  BackgroundImage,
  Body,
  ButtonLink,
  Button,
  Headline,
  MenuItemFavorite,
  MenuItemTagsAllergens,
} from 'components'
import MenuItemPrice from '../MenuItemPrice'
import MenuItemInfo from '../MenuItemInfo'
import MenuItemFullAdd from './MenuItemFullAdd'

const MenuItemFullHeaderView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48rem;
  gap: ${(props) => props.theme.layout.margin};
  margin: 0 0 ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${(props) => props.theme.layout.marginMobile};
    margin: 0 0 ${(props) => props.theme.layout.marginMobile};
  }
`

const MenuItemFullInfo = styled.div`
  margin: 0 0 0 1.5rem;
  button {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`

const MenuItemFullHeaderImage = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
`

const MenuItemFullHeaderContent = styled.div`
  flex: 1;
`

const MenuItemFullHeaderName = styled(Headline)`
  margin-left: -0.2rem;
`

const MenuItemFullHeaderDesc = styled(Body)`
  margin: 1rem 0 0;
`

const MenuItemFullButtons = styled.div`
  margin: ${(props) => props.theme.layout.padding} 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    min-width: 11rem;
  }
  button:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
  button:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const MenuItemFullHeaderIncomplete = styled.div`
  margin: ${(props) => props.theme.layout.padding} 0;
  p {
    color: ${(props) => props.theme.colors.error};
    font-weight: ${(props) => props.theme.boldWeight};
  }
`

const MenuItemFullHeaderActions = styled.div`
  margin: ${(props) => props.theme.layout.padding} 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > button + div {
    margin: 0 0 0 ${(props) => props.theme.layout.padding};
  }
`

const MenuItemFullHeaderFavorite = styled.span`
  display: block;
  margin: 0 0 0 0.75rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  transition: ${(props) => props.theme.links.transition};
  text-decoration: ${(props) => props.theme.links.textDecoration};
  color: ${(props) => props.theme.links.primary.color};

  button:hover &,
  button:active & {
    color: ${(props) => props.theme.links.primary.hover};
  }
`

const MenuItemFullHeaderNotes = styled.div`
  button {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const MenuItemFullHeader = ({
  builder,
  addItem,
}: {
  builder: ReturnType<typeof useBuilder>
  addItem: (builtItem: CartItem) => void
}) => {
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector(selectCustomer)
  const { lookup } = useAppSelector(selectCustomerFavorites)
  const { cartId } = useAppSelector(selectGroupOrder)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const { increment, decrement, setMadeForNotes, toggleOption } = builder
  const item = useMenuItem(builder.item, auth, lookup, cartId, displaySettings)
  const {
    name,
    description,
    imageUrl,
    quantity,
    displayPrice,
    displayCals,
    totalPoints,
    sizeGroup,
    sizeSelected,
    isIncomplete,
    favoriteId,
    hasMadeFor,
    hasNotes,
    madeFor,
    notes,
  } = item

  const incompleteMessage = sizeSelected
    ? 'Certain groups require selections before you can add this item to your order.'
    : 'Please choose a size above before you can add this item to your order.'

  const notesTitle =
    hasMadeFor && hasNotes
      ? `${madeFor || notes ? 'Edit' : 'Add'} Name or Special Instructions`
      : hasMadeFor
      ? `${madeFor ? 'Edit' : 'Add'} Name`
      : hasNotes
      ? `${notes ? 'Edit' : 'Add'} Special Instructions`
      : null

  const hasActions = auth || notesTitle

  const addNotes = () => {
    const args = {
      title: notesTitle,
      hasMadeFor,
      hasNotes,
      madeFor,
      notes,
      setMadeForNotes,
    }
    dispatch(openModal({ type: 'itemNotes', args }))
  }

  const toggleSize = (optionId: number) => {
    if (sizeGroup) toggleOption([[sizeGroup.id, optionId]])
  }

  return (
    <MenuItemFullHeaderView>
      <MenuItemFullHeaderImage>
        <BackgroundImage imageUrl={imageUrl} />
      </MenuItemFullHeaderImage>
      <MenuItemFullHeaderContent>
        <MenuItemFullHeaderName as="h1" size="mega">
          {name}
        </MenuItemFullHeaderName>
        {description ? (
          <MenuItemFullHeaderDesc as="p" size="big">
            {description}
          </MenuItemFullHeaderDesc>
        ) : null}
        <MenuItemTagsAllergens
          {...item}
          size="small"
          style={{ marginTop: '1.5rem' }}
        />
        <MenuItemPrice
          price={displayPrice}
          cals={displayCals}
          points={totalPoints}
          size={isMobile ? 'small' : 'big'}
          sizePoints="main"
          sizePointsMobile="small"
          style={{ marginTop: '1.5rem' }}
        >
          <MenuItemFullInfo>
            <MenuItemInfo item={item} />
          </MenuItemFullInfo>
        </MenuItemPrice>
        {sizeGroup && (
          <MenuItemFullButtons>
            {sizeGroup.options.map((option) => (
              <Button
                onClick={() => toggleSize(option.id)}
                color={option.quantity > 0 ? 'primary' : 'secondary'}
              >
                {option.name}
              </Button>
            ))}
          </MenuItemFullButtons>
        )}
        <MenuItemFullAdd
          addToOrder={() => addItem(builder.item)}
          disabled={isIncomplete}
          quantity={quantity}
          increment={increment}
          decrement={decrement}
        />
        {isIncomplete && (
          <MenuItemFullHeaderIncomplete>
            <Body as="p" size="small">
              {incompleteMessage}
            </Body>
          </MenuItemFullHeaderIncomplete>
        )}
        {hasActions && (
          <MenuItemFullHeaderActions>
            {auth && (
              <MenuItemFavorite
                size={isMobile ? 18 : 24}
                favoriteId={favoriteId}
                builtItem={builder.item}
                disabled={isIncomplete}
              >
                <MenuItemFullHeaderFavorite>
                  {favoriteId ? 'Remove from' : 'Add to'} Favorites
                </MenuItemFullHeaderFavorite>
              </MenuItemFavorite>
            )}
            {notesTitle ? (
              <MenuItemFullHeaderNotes>
                <ButtonLink onClick={addNotes}>{notesTitle}</ButtonLink>
              </MenuItemFullHeaderNotes>
            ) : null}
          </MenuItemFullHeaderActions>
        )}
      </MenuItemFullHeaderContent>
    </MenuItemFullHeaderView>
  )
}

export default MenuItemFullHeader
