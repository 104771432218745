import { useNavigate } from 'react-router-dom'
import {
  openModal,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'

import { UserCircle } from 'components/icons'
import Icon from './Icon'
import { isMobile } from 'react-device-detect'

const User = ({ to }: { to?: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { auth } = useAppSelector(selectCustomer)

  const login = () => {
    dispatch(openModal({ type: 'login' }))
  }

  const goToSettings = () => navigate(to || '/account/settings')

  return (
    <Icon
      aria-label={auth ? 'Account Profile' : 'Log into your account'}
      margin={isMobile ? '0 0 0 -1.25rem' : '0'}
      // color={theme.colors.tertiary}
      onClick={auth ? goToSettings : login}
    >
      <UserCircle strokeWidth={1.5} size={24} isFilled={false} />
    </Icon>
  )
}

export default User
