import {
  closeModal,
  selectBrand,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { TPLSSignUpMessage } from './ThirdPartyLoyalty.styled'
import Body from 'components/Body'
import ButtonLink from 'components/ButtonLink'
import { useNavigate } from 'react-router-dom'
// import Heading from 'components/Heading'

const defaultMsg = `Welcome back! We just integrated our loyalty program with our new online
        ordering site, and we're going to need for you to sign up one more time
        and accept the new terms & conditions. Sorry for the hassle, but it will
        be worth it!`

const ThirdPartyLoyaltySignUpMessage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sign_up_message } = useAppSelector(selectBrand) || {}
  const signUpMsg = sign_up_message || defaultMsg

  const signUp = () => {
    dispatch(closeModal())
    navigate(`/signup`)
  }

  return (
    <TPLSSignUpMessage>
      <Body size="small" as="p">
        {signUpMsg}
      </Body>
      <Body size="small" as="p">
        <ButtonLink onClick={signUp}>
          Sign up for an account & accept the terms.
        </ButtonLink>
      </Body>
    </TPLSSignUpMessage>
  )
}

export default ThirdPartyLoyaltySignUpMessage
