import { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Input } from 'components'
import { closeModal, useAppDispatch } from '@open-tender/cloud'
import { ModalContent, ModalView } from 'components'

const ItemNotesView = styled.div``

const ItemNotesInput = styled.div`
  width: 100%;
  margin: 0 0 2rem;
  label {
    margin: 0;
  }
`

const ItemNotesFooter = styled.div`
  margin: 3rem 0 0;
  text-align: center;
`

export interface ItemNotesModalProps extends Record<string, unknown> {
  title: string
  hasMadeFor: boolean
  hasNotes: boolean
  madeFor: string
  notes: string
  setMadeForNotes: (madeFor: string, notes: string) => void
}

const ItemNotes = ({
  title,
  hasMadeFor,
  hasNotes,
  madeFor,
  notes,
  setMadeForNotes,
}: ItemNotesModalProps) => {
  const dispatch = useAppDispatch()
  const [madeForText, setMadeForText] = useState(madeFor)
  const [notesText, setNotesText] = useState(notes)

  const allDone = () => {
    setMadeForNotes(madeForText, notesText)
    dispatch(closeModal())
  }

  return (
    <ModalView>
      <ModalContent title={title}>
        <ItemNotesView>
          {hasMadeFor && (
            <ItemNotesInput>
              <Input
                label="Who is this order for?"
                name="made-for"
                type="text"
                value={madeForText || ''}
                onChange={(evt) => setMadeForText(evt.target.value)}
              />
            </ItemNotesInput>
          )}
          {hasNotes && (
            <ItemNotesInput>
              <Input
                label="Any special instructions?"
                name="notes"
                type="text"
                value={notesText || ''}
                onChange={(evt) => setNotesText(evt.target.value)}
              />
            </ItemNotesInput>
          )}
          <ItemNotesFooter>
            <Button onClick={allDone}>All Done</Button>
          </ItemNotesFooter>
        </ItemNotesView>
      </ModalContent>
    </ModalView>
  )
}

export default ItemNotes
