import styled from '@emotion/styled'

export const FormSubmit = styled.div`
  margin: 3rem 0 0;
  text-align: center;

  button + button {
    margin: 0 0 0 1rem;
  }
`

export default FormSubmit
