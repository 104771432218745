import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { selectBrand, selectSettings, useAppSelector } from '@open-tender/cloud'
import { Container, Logo } from 'components'
import packageJson from '../../package.json'

const FooterView = styled.footer`
  // margin-top: 32px;
  position: relative;
  z-index: 1;
  width: 100%;
  color: ${(props) => props.theme.colors.light};
  background-color: ${(props) => props.theme.bgColors.dark};
`

const FooterContainer = styled.div`
  height: 24rem;
  padding: 0 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: 30rem;
    flex-direction: column;
    justify-content: space-between;
  }
`

const FooterContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FooterNav = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem 0 1rem;
  }
`

const FooterLogo = styled.a`
  display: block;
  margin: 0 0 2rem;
  margin-top: ${(props) => props.theme.logoFooter.marginTop};
  width: ${(props) => props.theme.logoFooter.width};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 0 1rem;
    margin-top: ${(props) => props.theme.logoFooter.marginTopMobile};
    width: ${(props) => props.theme.logoFooter.widthMobile};
  }

  img {
    pointer-events: none;
  }
`

const FooterLinks = styled.ul`
  display: flex;
  font-size: ${(props) => props.theme.fonts.sizes.small};

  li {
    display: block;
    margin: 0 3rem 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0 2rem 0 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: ${(props) => props.theme.links.light.color};
    }

    a:hover,
    a:active,
    a:focus {
      color: ${(props) => props.theme.links.light.hover};
    }
  }
`

const FooterTerms = styled.nav`
  flex: 0 0 auto;
  display: block;
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0 2rem;
  }

  ul li {
    float: left;
    margin: 1.5rem 2rem 0 0;
    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: ${(props) => props.theme.links.light.color};
    }

    a:hover,
    a:active,
    a:focus {
      color: ${(props) => props.theme.links.light.hover};
    }
  }
`

const FooterOTLogo = styled.div`
  max-width: 14rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    text-align: left;
  }

  span {
    display: block;
    margin: 0 0 0.5rem;
  }
`

const FooterVersion = styled.div`
  position: absolute;
  z-index: 2;
  right: ${(props) => props.theme.layout.padding};
  bottom: 1rem;
  // opacity: 0.3;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.light};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    left: ${(props) => props.theme.layout.paddingMobile};
    bottom: 0.7rem;
  }
`

interface FooterProps {
  hasRouter?: boolean
}

const Footer = ({ hasRouter = true }: FooterProps) => {
  const {
    logoLight,
    url,
    has_gift_cards,
    has_donations,
    hide_logo,
    terms_url,
    privacy_url,
  } = useAppSelector(selectBrand) || {}
  const { orderTypes = [] } = useAppSelector(selectSettings) || {}
  const hasGiftCards = has_gift_cards && orderTypes.includes('GIFT_CARDS')
  const hasDonations = has_donations && orderTypes.includes('DONATIONS')

  return (
    <FooterView role="contentinfo">
      <Container>
        <FooterContainer>
          <FooterContent>
            <FooterNav>
              {logoLight && (
                <FooterLogo href={url || '/'} rel="noopener noreferrer">
                  <img src={logoLight} alt="logo" />
                </FooterLogo>
              )}
              {hasRouter && (
                <FooterLinks>
                  {url && (
                    <li>
                      <a href={url} rel="noopener noreferrer">
                        Back to Website
                      </a>
                    </li>
                  )}
                  {hasGiftCards && (
                    <li>
                      <Link to="/gift-cards">Gift Cards</Link>
                    </li>
                  )}
                  {hasDonations && (
                    <li>
                      <Link to="/donations">Donations</Link>
                    </li>
                  )}
                </FooterLinks>
              )}
            </FooterNav>
            <FooterTerms aria-label="Legal Policies Navigation">
              <ul>
                <li>
                  <a
                    href={`${terms_url || 'https://www.opentender.io/terms'}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      privacy_url || 'https://itsacheckmate.com/privacy-policy'
                    }`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy
                  </a>
                </li>
                {hasRouter && (
                  <>
                    <li>
                      <Link to="/accessibility">Accessibility</Link>
                    </li>
                    <li>
                      <Link to="/refunds">Refunds</Link>
                    </li>
                  </>
                )}
              </ul>
            </FooterTerms>
          </FooterContent>
          {!hide_logo && (
            <FooterOTLogo>
              <span>powered by</span>
              <Logo />
            </FooterOTLogo>
          )}
        </FooterContainer>
      </Container>
      <FooterVersion aria-hidden="true">v{packageJson.version}</FooterVersion>
    </FooterView>
  )
}

export default Footer
