import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  selectBrand,
  selectCurrentItem,
  selectDisplaySettings,
  selectMenuPath,
  setCurrentItem,
  selectMenuSlug,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  BackgroundImage,
  Content,
  Main,
  MenuItem,
  ScreenreaderTitle,
} from 'components'
import ItemHeader from './ItemHeader'

const ItemPageView = styled.div`
  label: ItemPageView;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

const ItemPageImage = styled.div`
  label: ItemPageImage;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  padding: ${(props) => props.theme.item.desktop.imagePaddingAll};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`

const ItemPageImagePlaceholder = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50%;
    width: auto;
  }
`

const ItemPageContent = styled.div`
  label: ItemPageContent;
  flex: 0 0 ${(props) => props.theme.item.desktop.maxWidth};
  width: ${(props) => props.theme.item.desktop.maxWidth};
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.bgColors.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    flex: 1 1 auto;
  }
`

const Item = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { imageBgSize } = theme.item.desktop
  const { title: siteTitle, openGraphImage } = useAppSelector(selectBrand) || {}
  const menuPath = useAppSelector(selectMenuPath)
  const menuSlug = useAppSelector(selectMenuSlug)
  const item = useAppSelector(selectCurrentItem)
  const { builderType = 'PAGE' } = useAppSelector(selectDisplaySettings) || {}
  const showFullPage = isMobile ? false : builderType === 'PAGE_FULL'
  const [imageUrl, setImageUrl] = useState(item ? item.imageUrl : null)
  const imageUrlWithFallback = (imageUrl || item?.imageUrl) ?? null
  const showPlaceholder =
    item && !imageUrlWithFallback && openGraphImage ? true : false
  const style = showFullPage
    ? {}
    : isMobile
    ? { height: '100%', paddingTop: 0 }
    : { height: '100%' }

  const cancel = () => {
    dispatch(setCurrentItem(null))
  }

  useEffect(() => {
    if (!item) navigate(menuPath || menuSlug)
  }, [item, navigate, menuSlug, menuPath])

  useEffect(() => {
    if (item?.imageUrl) {
      setImageUrl(item.imageUrl)
    }
  }, [item?.imageUrl, openGraphImage])

  if (!item) return null

  return (
    <>
      <Helmet>
        <title>
          Menu - {item.name} | {siteTitle}
        </title>
      </Helmet>
      <Content hasFooter={showFullPage}>
        {!isMobile && <ItemHeader />}
        <Main style={style}>
          <ScreenreaderTitle>{item.name}</ScreenreaderTitle>
          {showFullPage ? (
            <MenuItem
              cancel={cancel}
              showBack={isMobile}
              showClose={false}
              showImage={isMobile}
              showFullPage={true}
              setImageUrl={setImageUrl}
            />
          ) : (
            <ItemPageView>
              <ItemPageImage>
                {showPlaceholder ? (
                  <ItemPageImagePlaceholder>
                    <img src={openGraphImage || undefined} alt={item.name} />
                  </ItemPageImagePlaceholder>
                ) : (
                  <BackgroundImage
                    imageUrl={imageUrlWithFallback}
                    bgSize={imageBgSize}
                  />
                )}
              </ItemPageImage>
              <ItemPageContent>
                <MenuItem
                  cancel={cancel}
                  showBack={isMobile}
                  showClose={false}
                  showImage={isMobile}
                  imageUrlWithFallback={imageUrlWithFallback}
                  setImageUrl={setImageUrl}
                />
              </ItemPageContent>
            </ItemPageView>
          )}
        </Main>
      </Content>
    </>
  )
}

export default Item
