import styled from '@emotion/styled'
import { selectBrand, useAppSelector } from '@open-tender/cloud'
import { CSSProperties } from 'react'

const HeaderLogoWithNameLink = styled.a`
  display: block;
  line-height: 0;
  max-width: ${(props) => props.theme.logo.width};
  margin: ${(props) => props.theme.logo.marginTop} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: ${(props) => props.theme.logo.widthMobile};
    margin: ${(props) => props.theme.logo.marginTopMobile} 0 0;
  }

  img {
    pointer-events: none;
  }
`

const HeaderLogoWithName = ({
  style,
  className,
}: {
  style?: CSSProperties
  className?: string
}) => {
  const { url, logo } = useAppSelector(selectBrand) || {}

  return (
    <HeaderLogoWithNameLink
      href={url || '/'}
      rel="noopener noreferrer"
      style={style}
      className={className}
    >
      <img src={logo} alt="logo" />
    </HeaderLogoWithNameLink>
  )
}

export default HeaderLogoWithName
