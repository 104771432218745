import styled from '@emotion/styled'
import { ThemeBackgroundColors } from '@open-tender/types'

const MainView = styled.main<{
  bgColor: keyof ThemeBackgroundColors
}>`
  label: Main;
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-size: 100% auto;
  background-position: top;
  background-repeat: repeat-y;
  padding-top: ${(props) => props.theme.layout.navHeight};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-top: ${(props) => props.theme.layout.navHeightMobile};
  }
`

const MainBackground = styled.div`
  position: absolute;
  z-index: 0;
  inset: 0;
  background-size: 110% auto;
  background-position: top;
  background-repeat: repeat-y;
  opacity: 0.15;
  background-attachment: fixed;
  // background-size: 50% auto;
  // background-position: top;
  // background-repeat: repeat;
  // background-attachment: fixed;
  // background-color: ${(props) => props.theme.colors.primary};
`

const Main = ({
  bgColor = 'primary',
  style,
  children,
  bgImage,
}: {
  bgColor?: keyof ThemeBackgroundColors
  style?: React.CSSProperties
  bgImage?: React.CSSProperties
  children: React.ReactNode
}) => {
  return (
    <MainView role="main" id="main" bgColor={bgColor} style={style}>
      {bgImage && (
        <MainBackground style={{ backgroundImage: `url(${bgImage})` }} />
      )}
      {children}
    </MainView>
  )
}

export default Main
