import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  openModal,
  selectContent,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonLink,
  FormWrapper,
  PageContainer,
  PageTitle,
  SignInForm,
} from 'components'

const ForgetPasswordContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

const defaultText = {
  title: 'Sign into your account',
  subtitle:
    'Looks like you already have an account. Please enter your password below.',
}

const CheckoutSignInDefault = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { checkoutFlow } = useAppSelector(selectContent) || {}
  const config = checkoutFlow?.sections?.signIn || defaultText
  const changeEmail = () => navigate('/checkout/guest')
  const forgotPassword = () => dispatch(openModal({ type: 'forgotPassword' }))

  return (
    <PageContainer style={{ maxWidth: '76.8rem' }}>
      <PageTitle {...config}>
        <div style={{ margin: '1rem 0 2rem' }}>
          <p>
            <ButtonLink onClick={changeEmail}>
              Or go back & enter a different email address
            </ButtonLink>
          </p>
        </div>
      </PageTitle>
      <FormWrapper>
        <SignInForm />
        <ForgetPasswordContainer>
          <p>
            <ButtonLink onClick={forgotPassword}>
              Forget your password?
            </ButtonLink>
          </p>
        </ForgetPasswordContainer>
      </FormWrapper>
    </PageContainer>
  )
}

export default CheckoutSignInDefault
