import styled from '@emotion/styled'

const DeliveryLinkIcon = styled.span`
  display: inline-block;
  position: relative;
  top: 0.1rem;
  margin: 0 0 0 0.6rem;
  width: 1.2rem;
  height: 1.2rem;
`

const DeliveryLink = ({
  text,
  trackingUrl,
  newWindowIcon,
}: {
  text?: string
  trackingUrl?: string
  newWindowIcon?: JSX.Element
}) => (
  <a
    href={trackingUrl}
    rel="noopener noreferrer"
    target="_blank"
    title="Check delivery status"
  >
    {text}
    {newWindowIcon && <DeliveryLinkIcon>{newWindowIcon}</DeliveryLinkIcon>}
  </a>
)

export default DeliveryLink
