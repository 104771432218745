import styled from '@emotion/styled'
import { LoyaltyTier as LoyaltyTierType } from '@open-tender/types'
import Preface from 'components/Preface'

const LoyaltyTierView = styled.button<{ color: string }>`
  padding: 0.3rem 1.2rem;
  border-radius: 2rem;
  background-color: ${(props) => `#${props.color}`};
`

const LoyaltyTierLabel = styled(Preface)`
  line-height: 1;
  // margin: 0 0 0.3rem 0.6rem;
  // color: ${(props) => props.theme.bgColors.primary};
`

const LoyaltyTier = ({ tier }: { tier: LoyaltyTierType }) => {
  const { name, hex_code } = tier
  return (
    <LoyaltyTierView color={hex_code}>
      <LoyaltyTierLabel size="small">{name}</LoyaltyTierLabel>
    </LoyaltyTierView>
  )
}

export default LoyaltyTier
