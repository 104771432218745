import styled from '@emotion/styled'
import Body from 'components/Body'
import ButtonLink from 'components/ButtonLink'
import { useNavigate } from 'react-router-dom'

const CheckoutSignInOTPFooterView = styled.div`
  text-align: center;

  p {
    margin: 1em 0;
  }
`

const CheckoutSignInFooter = () => {
  const navigate = useNavigate()
  return (
    <CheckoutSignInOTPFooterView>
      <Body as="p" size="small">
        Don't have an account yet?
      </Body>
      <Body as="p" size="small">
        <ButtonLink onClick={() => navigate(`/signup`)}>
          Sign up here
        </ButtonLink>{' '}
        or{' '}
        <ButtonLink onClick={() => navigate(`/checkout/signup`)}>
          checkout as a guest
        </ButtonLink>
        .
      </Body>
    </CheckoutSignInOTPFooterView>
  )
}

export default CheckoutSignInFooter
