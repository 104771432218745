import styled from '@emotion/styled'
import { PageSectionHeader } from '.'

const PageSectionView = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }

  &:first-of-type {
    margin-top: 0;
  }
`

const PageSection = ({
  id = undefined,
  title,
  subtitle,
  style = undefined,
  children,
}: {
  id?: string
  title?: string
  subtitle?: string
  children?: JSX.Element | JSX.Element[]
  style?: React.CSSProperties
}) => {
  return (
    <PageSectionView id={id} style={style}>
      <PageSectionHeader title={title} subtitle={subtitle} />
      {children}
    </PageSectionView>
  )
}

export default PageSection
