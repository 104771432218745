import styled from '@emotion/styled'
import { Heading, SeeMoreLink } from 'components'
import { isMobile } from 'react-device-detect'

const ScrollableSectionHeaderView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: 0 0 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    margin: 0 0 0.7rem;
  }

  p {
    line-height: 1;
  }
`

const ScrollableSectionTitle = styled.div`
  text-align: left;
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.big};
  }
`

const ScrollableSectionTitleHeading = styled(Heading)``

const ScrollableSectionHeader = ({
  title,
  to,
  toText = 'View All',
  style,
  className,
}: {
  title?: string
  to?: string
  style?: React.CSSProperties
  toText?: string
  className?: string
}) => {
  return (
    <ScrollableSectionHeaderView style={style} className={className}>
      <ScrollableSectionTitle>
        <ScrollableSectionTitleHeading as="p" size={isMobile ? 'main' : 'big'}>
          {title}
        </ScrollableSectionTitleHeading>
      </ScrollableSectionTitle>
      {to && <SeeMoreLink text={toText} to={to} />}
    </ScrollableSectionHeaderView>
  )
}

export default ScrollableSectionHeader
