import { Discounts } from '@open-tender/types'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { Reward, ScrollableSection } from 'components'
import AccountSection from './AccountSection'

const AccountRewards = ({ rewards }: { rewards: Discounts }) => {
  const account = useAppSelector(selectContentSection('account'))
  const { title } = account?.rewards || {}

  return (
    <AccountSection>
      <ScrollableSection
        title={title}
        to={rewards.length > 2 ? '/rewards' : undefined}
        items={rewards}
        renderItem={Reward}
        keyName="discount_id"
      />
    </AccountSection>
  )
}

export default AccountRewards
