import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  fetchCustomerTpls,
  selectBrand,
  selectContent,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Loading,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
  PointsShopReward,
} from 'components'
import {
  PointsShopRewards,
  PointsShopRewardsContainer,
  PointsShopRewardsReward,
} from './PointsShop.styled'
import PointsShopBalance from './PointsShopBalance'
import usePointsShop from './usePointsShop'
import useTplsPointsShop from './useTplsPointsShop'

const PointsShop = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector(selectCustomer)
  const { tpls } = useAppSelector(selectBrand) || {}
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { pointsShop: config } = useAppSelector(selectContent) || {}

  useEffect(() => {
    if (!auth) return navigate('/')
  }, [auth, navigate])

  const hasTplPointsShop = tpls === 'COMO' || tpls === 'PUNCHH'
  const usePointsShopHook = hasTplPointsShop ? useTplsPointsShop : usePointsShop

  const {
    rewards,
    pointsBalance,
    hasPointsRewards,
    isLoading,
    updatingBalance,
  } = usePointsShopHook()

  useEffect(() => {
    dispatch(fetchCustomerTpls())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageTitle {...config}>
              <PointsShopBalance
                pointsBalance={pointsBalance}
                isLoading={updatingBalance}
              />
            </PageTitle>
            <PageContent style={{ maxWidth: '99rem' }}>
              {hasPointsRewards ? (
                <PointsShopRewards>
                  <PointsShopRewardsContainer>
                    {rewards.map((reward) => (
                      <PointsShopRewardsReward key={reward.id}>
                        <PointsShopReward
                          reward={reward}
                          pointsBalance={pointsBalance}
                        />
                      </PointsShopRewardsReward>
                    ))}
                  </PointsShopRewardsContainer>
                </PointsShopRewards>
              ) : isLoading ? (
                <Loading text="Retrieving today's offers..." />
              ) : (
                <p>
                  We're not featuring any offers today. Please check back soon!
                </p>
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default PointsShop
