import styled from '@emotion/styled'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  OrderType,
  RequestedAt as RequestedAtType,
  RevenueCenter,
  ServiceType,
} from '@open-tender/types'
import { capitalize, serviceTypeNamesMap } from '@open-tender/utils'
import {
  closeModal,
  fetchMenu,
  selectBrand,
  setAddress,
  setOrderServiceType,
  setRequestedAt,
  setRevenueCenter,
  toggleSidebar,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button } from 'components'
import { ModalClose, ModalContent } from '../..'
import ModalView from '../../Modal/ModalView'
import RequestedAtDateTime from './RequestedAtDateTime'
import RequestedAtTimes from './RequestedAtTimes'

const RequestedAtModalView = styled(ModalView)<{ width?: string }>`
  width: ${(props) => props.width};

  & > div {
    padding: 3.5rem 3.5rem 4rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 3rem ${(props) => props.theme.layout.paddingMobile};
    }
  }
`

export interface RequestedAtModalProps extends Record<string, unknown> {
  revenueCenter: RevenueCenter
  serviceType: ServiceType
  orderType: OrderType
  requestedAt?: RequestedAtType
  openSidebar?: boolean
  isReorder?: boolean
  isGroupOrder?: boolean
}

const RequestedAt = ({
  revenueCenter,
  orderType,
  serviceType,
  requestedAt,
  openSidebar = false,
  isReorder = false,
  isGroupOrder = false,
}: RequestedAtModalProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pilot_slug } = useAppSelector(selectBrand) || {}
  const { pathname } = useLocation()
  const isLocation = pathname.includes('/locations')
  if (!revenueCenter) return null

  const {
    revenue_center_id: revenueCenterId,
    revenue_center_type: rcType,
    is_outpost: isOutpost,
    slug,
    address,
    first_times,
    order_times,
  } = revenueCenter

  const menuSlug = `/menu/${slug}`
  const firstTime = first_times ? first_times[serviceType] : null
  const orderTimes = order_times ? order_times[serviceType] : null
  const serviceTypeName = serviceTypeNamesMap[serviceType]?.toLowerCase() || ''

  const chooseTime = (requestedAt: RequestedAtType) => {
    dispatch(setRequestedAt(requestedAt))
    if (isLocation) {
      dispatch(setRevenueCenter(revenueCenter))
      dispatch(
        setOrderServiceType({
          orderType: rcType as OrderType,
          serviceType: serviceType as ServiceType,
          isOutpost,
        })
      )
      if (isOutpost) dispatch(setAddress(address))
      dispatch(closeModal())
      navigate(menuSlug)
    } else {
      dispatch(
        fetchMenu({
          revenueCenterId,
          serviceType: serviceType as ServiceType,
          requestedAt,
        })
      )
      dispatch(closeModal())
    }
    if (openSidebar) dispatch(toggleSidebar())
  }

  const cancel = () => {
    dispatch(closeModal())
  }

  return (
    <RequestedAtModalView width={firstTime ? '44rem' : '56rem'}>
      {firstTime ? (
        <>
          <ModalClose />
          <RequestedAtDateTime
            firstTime={firstTime}
            revenueCenter={revenueCenter}
            serviceType={serviceType}
            orderType={orderType}
            requestedAt={requestedAt}
            chooseTime={chooseTime}
            cancel={cancel}
            isLocation={isLocation}
            isReorder={isReorder}
            isGroupOrder={isGroupOrder}
            isPilot={!!pilot_slug}
          />
        </>
      ) : orderTimes ? (
        <ModalContent
          title="Choose an order date & time"
          subtitle={
            <p>
              Please select from the available {serviceTypeName} times below
            </p>
          }
        >
          <RequestedAtTimes
            orderTimes={orderTimes}
            revenueCenter={revenueCenter}
            requestedAt={requestedAt}
            setRequestedAt={chooseTime}
          />
        </ModalContent>
      ) : (
        <ModalContent
          title={`${capitalize(serviceTypeName)} is not available`}
          subtitle={<p>Please choose a different location</p>}
        >
          <ModalClose />
          <Button onClick={() => dispatch(closeModal())}>Close</Button>
        </ModalContent>
      )}
    </RequestedAtModalView>
  )
}

export default RequestedAt
