import styled from '@emotion/styled'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const CollapsibleView = styled.div``

const Collapsible = ({
  show,
  style,
  children,
}: {
  show: boolean
  style?: React.CSSProperties
  children: JSX.Element | JSX.Element[]
}) => {
  return (
    <TransitionGroup component={null}>
      {show ? (
        <CSSTransition
          key="collapsible"
          classNames="reveal"
          timeout={{ enter: 250, exit: 250 }}
        >
          <CollapsibleView style={style}>{children}</CollapsibleView>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

export default Collapsible
