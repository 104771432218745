import { FC } from 'react'
import { Footer } from 'components'
import styled from '@emotion/styled'

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: column;
  }
`

const DesktopLandingPageLayoutRightView = styled.div`
  flex: 1;
  // border: 1px solid green;
  padding: 50px;
  padding-top: calc(7.2rem + 50px);
`

const DesktopLandingPageLayoutLeftView = styled.div`
  flex: 2;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
`

export const DesktopLandingPageLayoutBottomGap = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

type DesktopLandingPageLayoutProps = {
  children: React.ReactNode
  right?: React.ReactNode
}

const DesktopLandingPageLayout: FC<DesktopLandingPageLayoutProps> = ({
  children,
  right,
}) => {
  return (
    <PageWrapper>
      <PageContent>
        <DesktopLandingPageLayoutLeftView>
          {children}
        </DesktopLandingPageLayoutLeftView>
        <DesktopLandingPageLayoutRightView>
          {right}
        </DesktopLandingPageLayoutRightView>
      </PageContent>
      <Footer />
    </PageWrapper>
  )
}

export default DesktopLandingPageLayout
