import styled from '@emotion/styled'

const ItemsStackedView = styled.div`
  margin: -0.5rem;
  display: flex;
  flex-wrap: wrap;
`

const ItemsStackedItem = styled.div`
  width: 50%;
  padding: 0.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

const ItemsStacked = <T extends { key: string }>({
  items,
  renderItem,
  children,
}: {
  items: T[]
  renderItem: (item: any) => JSX.Element
  children: JSX.Element | JSX.Element[]
}) => {
  const hasItems = items ? items.length > 0 : false

  return hasItems ? (
    <ItemsStackedView>
      {items.map((item) => (
        <ItemsStackedItem key={item.key}>
          {renderItem({ item })}
        </ItemsStackedItem>
      ))}
    </ItemsStackedView>
  ) : (
    children
  )
}

export default ItemsStacked
