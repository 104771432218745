import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import GooglePayButton from '@google-pay/button-react'
import {
  CheckoutTender,
  ConfigBrand,
  Money,
  TenderType,
} from '@open-tender/types'
import {
  selectBrand,
  selectCheck,
  submitOrderPay,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { FormError } from 'components'
import { isSandbox } from 'utils/helpers'

const GooglePayView = styled.div`
  width: 100%;
  margin: 2rem 0 1rem;

  &:nth-of-type(2) {
    margin: -1rem 0 1rem;
  }

  > div {
    width: 100%;

    button {
      min-height: 5rem;
    }
  }
`

const makePaymentRequest = (
  brand: ConfigBrand | null,
  amount: string,
  paymentGateway: 'freedompay' | 'gatewayservices'
): google.payments.api.PaymentDataRequest | null => {
  const { title, googlePayMerchantId, gatewayId } = brand || {}
  if (!googlePayMerchantId || !gatewayId) return null
  return {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: paymentGateway,
            gatewayMerchantId: gatewayId,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: googlePayMerchantId,
      merchantName: title,
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: amount,
      currencyCode: 'USD',
      countryCode: 'US',
    },
  }
}

const CheckoutGooglePay = ({
  amount,
  error,
  addTender,
  removeTender,
}: {
  amount: Money
  error: string | null
  addTender: (data: CheckoutTender) => Promise<void>
  removeTender: (data: TenderType) => Promise<void>
}) => {
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const brand = useAppSelector(selectBrand)
  const { config } = useAppSelector(selectCheck) || {}
  const paymentGateway = config?.require_cardholder_name
    ? 'freedompay'
    : 'gatewayservices'

  const paymentRequest = makePaymentRequest(brand, amount, paymentGateway)
  const nonZero = parseFloat(amount) > 0

  useEffect(() => {
    if (error) setErrMsg(error)
  }, [error])

  const onLoadPaymentData = async (data: any) => {
    const tender: CheckoutTender = {
      tender_type: 'GOOGLE_PAY',
      amount: amount,
      token: data.paymentMethodData,
    }
    await addTender(tender)
    dispatch(submitOrderPay(true)).then((action) => {
      const { type } = action || {}
      const isFufilled = type && type === 'checkout/SubmitOrderPay/fulfilled'
      if (isFufilled) {
        return
      } else {
        removeTender('GOOGLE_PAY')
      }
    })
  }

  return nonZero && paymentRequest ? (
    <GooglePayView>
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      <GooglePayButton
        environment={isSandbox ? 'TEST' : 'PRODUCTION'}
        buttonSizeMode="fill"
        buttonType="plain"
        paymentRequest={paymentRequest}
        onLoadPaymentData={onLoadPaymentData}
      />
    </GooglePayView>
  ) : null
}

export default CheckoutGooglePay
