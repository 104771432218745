import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import { fetchCustomer } from '@open-tender/cloud'
import {
  checkout,
  selectBrand,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Content, Header, HeaderLogoWithName, Main } from 'components'
import { Back, BackToMenu, NavMenu } from 'components/buttons'
import CheckoutSignInDefault from './CheckoutSignInDefault'
import CheckoutSignInOTP from './CheckoutSignInOTP'

const CheckoutSignIn = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { title: siteTitle, tpls } = useAppSelector(selectBrand) || {}
  const { auth, profile } = useAppSelector(selectCustomer)
  const hasOTP = tpls === 'COMO'

  useEffect(() => {
    if (auth) {
      if (!profile) dispatch(fetchCustomer())
      dispatch(checkout())
      navigate('/checkout')
    }
  }, [auth, profile, dispatch, navigate])

  return (
    <>
      <Helmet>
        <title>Checkout Sign In | {siteTitle}</title>
      </Helmet>
      <Content>
        <Header
          title={<HeaderLogoWithName />}
          left={
            hasOTP ? (
              <BackToMenu />
            ) : (
              <Back label="Back to Email Entry" path="/checkout/guest" />
            )
          }
          right={<NavMenu />}
        />
        <Main>
          {hasOTP ? <CheckoutSignInOTP /> : <CheckoutSignInDefault />}
        </Main>
      </Content>
    </>
  )
}

export default CheckoutSignIn
