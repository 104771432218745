import { ChangeEvent, useRef } from 'react'
import styled from '@emotion/styled'
import { Minus, Plus, X } from './icons'

interface QuantityViewProps {
  bgColor?: string
}

const QuantityView = styled.div<QuantityViewProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 9.2rem;

  label {
    display: block;
  }

  button {
    transition: none;
    &:disabled {
      opacity: 0.25;
    }
  }
`

const QuantityAdd = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 0;
  border-radius: 1.2rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: ${(props) => props.theme.border.color};
  color: ${(props) => props.theme.colors.primary};
`

const QuantityRemove = styled(QuantityAdd)`
  border-color: ${(props) => props.theme.colors.error};
  color: ${(props) => props.theme.colors.error};
`

const QuantityInput = styled.input`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  padding: 0;
  border: 0;
  line-height: 1;
  text-align: center;
  color: ${(props) => props.theme.colors.light};
  background-color: ${(props) => props.theme.bgColors.dark};
  border-color: ${(props) => props.theme.bgColors.dark};
  padding-top: ${(props) => props.theme.counts.quantity.paddingTop};
  padding-bottom: ${(props) => props.theme.counts.quantity.paddingBottom};
  font-family: ${(props) => props.theme.counts.quantity.family};
  font-weight: ${(props) => props.theme.counts.quantity.weight};
  font-size: ${(props) => props.theme.counts.quantity.fontSize};
  -webkit-font-smoothing: ${(props) =>
    props.theme.counts.quantity.fontSmoothing};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.counts.quantity.fontSizeMobile};
  }

  &:active,
  &:focus,
  &:disabled,
  &:read-only {
    color: ${(props) => props.theme.buttons.colors.primary.color};
    background-color: ${(props) => props.theme.buttons.colors.primary.bgColor};
    border-color: ${(props) => props.theme.buttons.colors.primary.borderColor};
  }
`

const QuantityIncrement = styled.button`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
`

const Quantity = ({
  item,
  adjust,
  increment,
  decrement,
  incrementDisabled,
  decrementDisabled,
  isCheckbox = false,
  showAdd = true,
}: {
  item: any
  adjust?: (quantity: number) => void
  increment: () => void
  decrement: () => void
  incrementDisabled: boolean
  decrementDisabled: boolean
  showAdd?: boolean
  isCheckbox?: boolean
}) => {
  const incrementRef = useRef(null)
  const decrementRef = useRef(null)

  const handleAdd = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    increment()
  }

  const handleAdjust = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt) return
    // if (item.increment > 1 || item.min > 1 || item.max || !adjust) return
    // const value = parseInt(evt.target.value)
    // const quantity = isNaN(value) || value < 1 ? 0 : value
    // adjust(quantity)
  }

  const handleIncrement = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    increment()
  }

  const handleDecrement = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    decrement()
  }

  return showAdd && item.quantity === 0 ? (
    <QuantityView>
      <QuantityAdd
        onClick={handleAdd}
        disabled={incrementDisabled || item.isSoldOut}
        aria-label="Increase quantity"
      >
        <Plus size={16} strokeWidth={2} />
      </QuantityAdd>
    </QuantityView>
  ) : isCheckbox ? (
    <QuantityView>
      <QuantityRemove onClick={decrement} disabled={decrementDisabled}>
        <X size={16} strokeWidth={2} />
      </QuantityRemove>
    </QuantityView>
  ) : (
    <QuantityView bgColor="secondary">
      <QuantityIncrement
        ref={decrementRef}
        style={{ marginLeft: '0.2rem' }}
        onClick={handleDecrement}
        disabled={decrementDisabled}
        aria-label="Decrease quantity"
      >
        <Minus size={16} strokeWidth={2} />
      </QuantityIncrement>
      <label htmlFor={item.id}>
        <QuantityInput
          id={item.id}
          type="number"
          value={item.quantity}
          onChange={handleAdjust}
          aria-label={item.name}
          readOnly={true}
        />
      </label>
      <QuantityIncrement
        ref={incrementRef}
        style={{ marginRight: '0.2rem' }}
        onClick={handleIncrement}
        disabled={incrementDisabled}
        aria-label="Increase quantity"
      >
        <Plus size={16} strokeWidth={2} />
      </QuantityIncrement>
    </QuantityView>
  )
}

export default Quantity
