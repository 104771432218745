import styled from '@emotion/styled'
import { BgImage, Box, Heading } from 'components'
import { Star } from 'components/icons'
import { HOVER_BOX_SHADOW } from 'utils/constants'
import Body from '../Body'

export const RewardButton = styled.button<{ label: string }>`
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;

  &:hover > div {
    box-shadow: ${HOVER_BOX_SHADOW};
  }
`

export const RewardView = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;
`

export const RewardTag = styled.div`
  position: absolute;
  top: -0.9rem;
  right: 1rem;
  & > span {
    padding: 0.3rem 1rem 0.4rem;
  }
`

export const RewardImage = styled(BgImage)`
  width: 100%;
  padding: 37.5% 0;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 33.33333% 0;
  }
`

export const RewardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) =>
    props.theme.cards.default.bgColor === 'transparent'
      ? '1.1rem 0 0'
      : '1.3rem 1.3rem 1.2rem'};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) =>
      props.theme.cards.default.bgColor === 'transparent'
        ? '0.8rem 0 0'
        : '1rem 1rem 0.8rem'};
  }
`

export const RewardTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.main};
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.main};
  }
`

// export const RewardDescription = styled(Body)`
//   margin: 0.8rem 0 0;
//   font-size: ${(props) => props.theme.fonts.sizes.small};
//   @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
//     margin: 0.5rem 0 0;
//     font-size: ${(props) => props.theme.fonts.sizes.xSmall};
//   }
// `

export const RewardNote = styled(Heading)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizesMobile.small};
  }
  span {
    display: block;
    line-height: 1.4;
  }
`

export const RewardNoteIcon = styled.span`
  width: 1.4rem;
  height: 1.4rem;
  margin: -0.2rem 0.5rem 0 0;
`

export const RewardNoteDefault = styled.p`
  text-align: center;
`

export const RewardNoteStarIcon = styled(Star)`
  margin-right: 0.5rem;
`

export const RewardChannel = styled.span`
  color: ${(props) => props.theme.colors.alert};
`

export const RewardDash = styled.span`
  margin: 0 0.2rem;
`

export const Expiration = styled(Body)`
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  margin: 0.5rem 0 0;
  display: block;
  text-align: center;  

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.small};
  }
`

export const RewardLocked = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  background-color: ${(props) => props.theme.overlay.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.light};
  border-radius: ${(props) => props.theme.cards.default.borderRadius};
`

export const RewardLockedText = styled(Heading)`
  color: ${(props) => props.theme.colors.light};
`
