import styled from '@emotion/styled'
import { useOrderRatingForm } from '@open-tender/utils'
import { ButtonSubmit, FormInputsView, FormSubmit, Textarea } from 'components'

import { Star } from 'components/icons'
import { OrderRating } from '@open-tender/types'

const OrderStars = styled.div`
  display: flex;
  margin: 0.25rem 0 0.5rem;
`

const OrderStar = styled.button<{ filled?: boolean }>`
  display: block;
  margin: 0 0.5rem 0 0;
  width: 2rem;
  height: 2rem;
  color: ${(props) => props.theme.colors.primary};

  svg {
    fill: ${(props) => (props.filled ? props.theme.colors.primary : null)};
  }

  &:hover {
    color: ${(props) => props.theme.links.primary.color};

    svg {
      fill: ${(props) =>
        props.filled ? props.theme.links.primary.color : null};
    }
  }
`

const OrderRatingForm = ({
  orderId,
  orderRating,
  update,
  callback,
}: {
  orderId: number | string
  orderRating: OrderRating | null
  update: (orderId: number | string, data: OrderRating) => void
  callback?: () => void
}) => {
  const {
    submitRef,
    stars,
    rating,
    handleRating,
    comments,
    handleComments,
    handleSubmit,
  } = useOrderRatingForm(orderId, orderRating, update, callback)

  return (
    <form id="rating-form" onSubmit={handleSubmit} noValidate>
      <FormInputsView>
        <OrderStars>
          {stars.map((star, index) => {
            const filled = star <= rating
            return (
              <OrderStar
                key={star}
                type="button"
                onClick={(evt) => handleRating(star)}
                aria-label={`Give ${index + 1} star rating`}
                filled={filled}
              >
                <Star />
              </OrderStar>
            )
          })}
        </OrderStars>
        <Textarea
          type="textarea"
          label="Comments (optional)"
          name="commments"
          value={comments}
          onChange={(evt) => handleComments(evt.target.value)}
          style={{ margin: '3rem 0 0' }}
        />
      </FormInputsView>
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef}>Submit Rating</ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default OrderRatingForm
