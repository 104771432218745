import { makeRequestedAtStr } from '@open-tender/utils'
import {
  openModal,
  selectGroupOrder,
  selectOrder,
  selectTimezone,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonBoth } from '.'

const RequestedAt = ({
  useButton = false,
  style,
}: {
  useButton: boolean
  style?: React.CSSProperties
}) => {
  const dispatch = useAppDispatch()
  const { requestedAt, revenueCenter, serviceType, orderType } =
    useAppSelector(selectOrder)
  const { cartId } = useAppSelector(selectGroupOrder)
  const tz = useAppSelector(selectTimezone)
  const requestedAtText = makeRequestedAtStr(requestedAt, tz)
  const { order_times } = revenueCenter || {}
  const orderTimes =
    order_times && serviceType ? order_times[serviceType] : null
  const args = {
    focusFirst: true,
    skipClose: true,
    isGroupOrder: cartId ? true : false,
    style: orderTimes ? { alignItems: 'flex-start' } : {},
    revenueCenter,
    serviceType,
    orderType,
    requestedAt,
  }

  if (!revenueCenter || !requestedAt) return null

  return (
    <ButtonBoth
      label="Adjust Order Time"
      text={requestedAtText}
      onClick={() => dispatch(openModal({ type: 'requestedAt', args }))}
      style={style}
      useButton={useButton}
    />
  )
}

export default RequestedAt
