import AccountSection from './AccountSection'
import AccountLoyaltyProgram from './AccountLoyaltyProgram'
import AccountPointsShop from './AccountPointsShop'
import styled from '@emotion/styled'
import AccountSectionHeader from './AccountSectionHeader'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import usePointsShop from '../PointsShop/usePointsShop'

const AccountLoyaltySection = styled(AccountSection)`
  position: relative;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-bottom: 0;
  }
`

const AccountLoyaltyHeader = styled(AccountSectionHeader)``

const AccountLoyaltyContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    padding: 0;
  }
`

const AccountLoyalty = ({ withPadding = true }: { withPadding?: boolean }) => {
  const { loyalty } = useAppSelector(selectContentSection('account')) || {}
  const { rewards, pointsBalance, error } = usePointsShop()

  return (
    <AccountLoyaltySection withPadding={withPadding}>
      <AccountLoyaltyHeader
        title={loyalty?.title || 'Earn points, redeem for rewards!'}
        subtitle="$1 = 1 Points"
        to={rewards.length > 2 ? '/points-shop' : undefined}
      />
      <AccountLoyaltyContent>
        <AccountLoyaltyProgram />
        <AccountPointsShop
          error={error}
          pointsBalance={pointsBalance}
          rewards={rewards}
        />
      </AccountLoyaltyContent>
    </AccountLoyaltySection>
  )
}

export default AccountLoyalty
