import styled from '@emotion/styled'
import { Button } from 'components'
import { isMobileOnly } from 'react-device-detect'

const ButtonLargeContent = styled.span`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.theme.welcome.desktop.showIcons ? 'space-between' : 'center'};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: ${(props) =>
      props.theme.welcome.mobile.showIcons ? 'space-between' : 'center'};
  }

  span {
    display: block;
  }

  span + span {
    margin-left: 8px;
  }
`

interface ButtonLargeProps {
  onClick: () => void
  text?: string
  children: React.ReactNode
  color?: string
}

const ButtonLarge = ({
  onClick,
  text,
  children,
  color = 'secondary',
}: ButtonLargeProps) => {
  return (
    <Button
      onClick={onClick}
      size={isMobileOnly ? 'small' : 'big'}
      color={color}
    >
      <ButtonLargeContent>
        {text && <span>{text}</span>}
        <span>{children}</span>
      </ButtonLargeContent>
    </Button>
  )
}

export default ButtonLarge
