import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { selectBrand, useAppSelector } from '@open-tender/cloud'

const HeaderLogoLink = styled.a<{
  isMobile?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 2.8rem;
  }

  img {
    // border-radius: 0.4rem;
    pointer-events: none;
  }
`

const HeaderLogoSmall = () => {
  const { url, openGraphImage } = useAppSelector(selectBrand) || {}

  if (!openGraphImage) return null

  return (
    <HeaderLogoLink
      isMobile={isMobile}
      href={url || '/'}
      rel="noopener noreferrer"
    >
      <img src={openGraphImage} alt="logo" />
    </HeaderLogoLink>
  )
}

export default HeaderLogoSmall
