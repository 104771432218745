import styled from '@emotion/styled'
import { CartItem } from '@open-tender/types'
import { UpsellItem } from 'components'

const UpsellItemsContainer = styled.div`
  label: UpsellItemsContainer;
  flex-grow: 0;
  display: flex;
  justify-content: center;
`

const UpsellItemsView = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const UpsellItemsItem = styled.div<{ isSmall: boolean }>`
  flex-shrink: 0;
  width: ${(props) => (props.isSmall ? '22rem' : '30rem')};
  padding: 1.5rem 0;
  margin-right: 2rem;

  &:first-of-type {
    margin-left: 2rem;
  }

  & > div {
    height: 100%;
  }
`

const UpsellItems = ({
  upsellItems,
  isSmall = false,
  addCallback,
}: {
  upsellItems: CartItem[]
  isSmall?: boolean
  addCallback?: () => void
}) => {
  if (!upsellItems.length) return null

  return (
    <UpsellItemsContainer>
      <UpsellItemsView>
        {upsellItems.map((item) => (
          <UpsellItemsItem key={item.id} isSmall={isSmall}>
            <UpsellItem
              orderItem={item as CartItem}
              addCallback={addCallback}
            />
          </UpsellItemsItem>
        ))}
      </UpsellItemsView>
    </UpsellItemsContainer>
  )
}

export default UpsellItems
