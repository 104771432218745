import {
  Address,
  FormFieldType,
  RequestError,
  RequestStatus,
} from '@open-tender/types'
import { useAddressForm } from '@open-tender/utils'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const AddressForm = ({
  address,
  loading,
  error,
  update,
  callback,
}: {
  address: Address
  loading: RequestStatus
  error: RequestError
  update: (id: number, data: Address, callback: () => void) => void
  callback: () => void
}) => {
  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useAddressForm(address, loading, error, update, callback)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  return (
    <form id="address-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : 'Submit Updates'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default AddressForm
