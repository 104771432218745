// import {
//   ThanxProgress as ThanxProgressType,
//   ThanxReward as ThanxRewardType,
// } from '@open-tender/types'
import { Heading, ProgressCircle } from 'components'
import {
  TPLSProgressContent,
  TPLSProgressView,
} from './ThirdPartyLoyalty.styled'

const ThirdPartyLoyaltyProgress = ({
  progress,
  threshold,
}: {
  progress: any
  threshold?: string
}) => {
  const { percentage = '0', towards = null } = progress || {}
  const currentProgress = parseInt(percentage)
  const remaining = threshold
    ? ((parseFloat(threshold) * (100 - currentProgress)) / 100).toFixed(2)
    : null
  return (
    <TPLSProgressView>
      <TPLSProgressContent>
        <Heading as="p">Current Progress</Heading>
        <ProgressCircle progress={currentProgress} />
        {currentProgress ? (
          <p>
            {remaining
              ? `You're ${remaining} away from your next ${towards}`
              : `You're on your way to your next ${towards}`}
          </p>
        ) : (
          <p>Make your first purchase to start earning rewards!</p>
        )}
      </TPLSProgressContent>
    </TPLSProgressView>
  )
}

export default ThirdPartyLoyaltyProgress
