import styled from '@emotion/styled'
import Body from 'components/Body'
import Headline from 'components/Headline'

export const OrderView = styled.div`
  margin: 4rem auto;
  max-width: 54rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 3rem auto;
  }
`

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 -5rem 3rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
    margin: 0;
  }

  & > span {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    }
  }

  & > a {
    text-decoration: none;
  }

  p {
    margin: 1rem 0 0;
  }
`

export const OrderHeaderTag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 0 1rem;
`

export const OrderMessage = styled(Body)`
  color: ${(props) => props.theme.colors.error};
`

export const OrderTitle = styled(Headline)`
  margin: 1rem 0;
  font-size: ${(props) => props.theme.fonts.sizes.h1};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.h3};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.h4};
  }
`

export const OrderButtons = styled.div`
  margin: 3rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: flex-start;
    margin: 2.5rem 0 3rem;
  }

  button + button {
    margin-left: 1rem;
  }
`

export const OrderDetails = styled.div`
  margin: 3rem 0 0;
`

export const OrderItems = styled.div`
  margin: 3rem 0 0;
`

export const OrderFavorite = styled.div`
  margin: 0;
`

export const OrderCurbside = styled.div`
  margin: 3rem 0;

  div {
    margin: 0;
  }
`
