import { Header, HeaderLogoWithName } from '.'
import { Back, Cart, NavMenu, OrderNow } from './buttons'

const HeaderDefault = ({
  maxWidth = '100%',
  path,
  showOrderNow = true,
}: {
  maxWidth?: string
  path?: string
  showOrderNow?: boolean
}) => {
  return (
    <Header
      maxWidth={maxWidth}
      left={<Back path={path} />}
      title={<HeaderLogoWithName />}
      right={
        <>
          <Cart />
          {showOrderNow && <OrderNow />}
          <NavMenu />
        </>
      }
    />
  )
}

export default HeaderDefault
