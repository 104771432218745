import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { handleRespError } from '@open-tender/utils'
import {
  selectBrand,
  selectCustomer,
  selectOneTimePasscode,
  signInCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Main,
  PageTitle,
  PageContainer,
  PageContent,
  Loading,
  Message,
} from 'components'

const Punchh = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)
  const { loading, error } = useAppSelector(selectOneTimePasscode)
  const isLoading = loading === 'pending'
  const err = error ? handleRespError(error) : null
  const errMsg = err ? err.detail : null
  const title = errMsg ? 'Something went wrong' : 'Retrieving your account'
  const subtitle = errMsg
    ? 'Please review the error below and retry your request or contact support'
    : 'Please hang tight. This will only take a second.'
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('access_token')

  useEffect(() => {
    if (auth) {
      navigate('/account')
    } else if (token) {
      dispatch(signInCustomer({ data: { token } }))
    } else {
      navigate('/account')
    }
  }, [auth, token, navigate, dispatch])

  return (
    <>
      <Helmet>
        <title>Punchh | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle title={title} subtitle={subtitle} />
            <PageContent>
              {isLoading ? (
                <Loading text="Contacting Punchh..." />
              ) : errMsg ? (
                <Message color="error" style={{ width: '100%' }}>
                  {errMsg}
                </Message>
              ) : null}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Punchh
