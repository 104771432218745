import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button } from 'components'
import { Clipboard } from 'components/icons'

interface CopyResultProps {
  copied?: boolean
}

const CopyResult = styled.p<CopyResultProps>`
  margin: 1rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  ${(props) => (props.copied ? `color: ${props.theme.colors.success};` : null)}
`

interface GroupOrderLinkProps {
  token?: string
  instructions?: string
}

const GroupOrderLink = ({
  token,
  instructions = 'Click button above to copy the link to your clipboard',
}: GroupOrderLinkProps) => {
  const [copied, setCopied] = useState(false)
  const url = `${window.location.origin}/join/${token}`

  useEffect(() => {
    setCopied(false)
  }, [])

  const copy = () => {
    return
  }

  return (
    <>
      <p>
        <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
          <Button
            icon={<Clipboard size={14} strokeWidth={2} />}
            onClick={copy}
            size="small"
            color="cart"
            style={{
              textAlign: 'left',
              padding: '1rem 1rem',
            }}
          >
            {url}
          </Button>
        </CopyToClipboard>
      </p>
      {copied ? (
        <CopyResult copied={copied}>Copied to clipboard!</CopyResult>
      ) : (
        <CopyResult>{instructions || <br />}</CopyResult>
      )}
    </>
  )
}

export default GroupOrderLink
