import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  CheckoutTenderCreditCustomer,
  isCreditCustomerCheckoutTender,
} from '@open-tender/types'
import { formatDollars } from '@open-tender/utils'
import { selectCheckout, useAppSelector } from '@open-tender/cloud'
import { cardIconsMap } from 'components/cardIcons'
import CheckoutButton from './CheckoutButton/CheckoutButton'

const CheckoutCreditCardsView = styled.div``

const CheckoutCreditCards = ({
  apply,
  remove,
  isPaid,
}: {
  apply: (card: any, swap?: boolean) => void
  remove: () => void
  isPaid?: boolean
}) => {
  const { check, form } = useAppSelector(selectCheckout)
  const cards = useMemo(
    () => (check?.customer ? check.customer.credit_cards || [] : []),
    [check?.customer]
  )
  const [cardCount, setCardCount] = useState(cards.length)
  const hasCards = cards && cards.length > 0
  const appliedCard = form.tenders.find((i) =>
    isCreditCustomerCheckoutTender(i)
  ) as CheckoutTenderCreditCustomer | null
  const appliedCardId = appliedCard?.customer_card_id || null
  const defaultCard = useMemo(() => cards.find((i) => i.is_default), [cards])
  const defaultCardId = defaultCard ? defaultCard.customer_card_id : null
  const cardIds = cards.map((i) => i.customer_card_id)
  const missingCard = appliedCardId && !cardIds.includes(appliedCardId)

  // if customer adds new default card, replace the current credit tender
  useEffect(() => {
    if (cards.length > cardCount) {
      setCardCount(cards.length)
      if (defaultCard && defaultCardId && appliedCardId !== defaultCardId) {
        const card: Omit<CheckoutTenderCreditCustomer, 'amount'> = {
          tender_type: 'CREDIT',
          ...defaultCard,
        }
        apply(card, true)
      }
    }
  }, [
    appliedCardId,
    defaultCardId,
    defaultCard,
    apply,
    cardCount,
    cards.length,
  ])

  useEffect(() => {
    if (missingCard) remove()
  }, [missingCard, remove])

  if (!hasCards) return null

  return (
    <CheckoutCreditCardsView>
      {cards.map((card) => {
        const {
          card_type_name,
          card_type,
          last4,
          customer_card_id,
          is_default,
        } = card
        const title = `${card_type_name} - ${last4}`
        const isApplied = customer_card_id === appliedCard?.customer_card_id
        const subtitle = isApplied
          ? `${formatDollars(`${appliedCard.amount ?? 0}`)} applied to check`
          : is_default
          ? 'Default Card'
          : undefined
        const disabled = isPaid && !isApplied
        const tender = { tender_type: 'CREDIT', ...card }
        const onPress = isApplied ? () => remove() : () => apply(tender)
        return (
          <CheckoutButton
            key={customer_card_id}
            icon={cardIconsMap[card_type ?? 'VISA']}
            title={title}
            subtitle={subtitle}
            onPress={onPress}
            isApplied={isApplied}
            disabled={disabled}
          />
        )
      })}
    </CheckoutCreditCardsView>
  )
}

export default CheckoutCreditCards
