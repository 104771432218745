import styled from '@emotion/styled'
import NavButton, { NavButtonProps } from './NavButton'

const NavButtonsView = styled.div`
  margin: 0 auto;
`

export interface NavButtonsProps {
  buttons: NavButtonProps[]
  delay?: number
}

const NavButtons = ({ buttons, delay = 0.125 }: NavButtonsProps) => {
  return (
    <NavButtonsView>
      {buttons.map((button, index) => (
        <NavButton
          key={button.title}
          delay={`${((index + 1) * 0.125 + delay).toFixed(3)}s`}
          {...button}
        />
      ))}
    </NavButtonsView>
  )
}

export default NavButtons
