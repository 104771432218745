import styled from '@emotion/styled'
import { CommunicationPrefs, FormSection } from 'components'

const ConfirmationPrefsView = styled.div`
  max-width: 54rem;
  margin: 4rem auto;
`

const ConfirmationPrefs = () => {
  return (
    <ConfirmationPrefsView>
      <FormSection
        title="Update your communication preferences"
        subtitle="Please let us know how you'd like to hear from us"
      >
        <CommunicationPrefs />
      </FormSection>
    </ConfirmationPrefsView>
  )
}

export default ConfirmationPrefs
