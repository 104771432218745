import { Children } from 'react'
import styled from '@emotion/styled'
import Loading from './Loading'
import ScrollableSectionHeader from './ScrollableSectionHeader'

const ScrollableItemsView = styled.div`
  margin: 0 -${(props) => props.theme.layout.padding} -1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 -${(props) => props.theme.layout.paddingMobile} -1.5rem;
  }
`

const ScrollableItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: auto;
  margin: -0.5rem 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollableItemsItem = styled.div`
  flex: 0 0 31rem;
  padding: 1.5rem 0;
  margin-right: ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex: 0 0 20rem;
    margin-right: ${(props) => props.theme.layout.paddingMobile};
  }

  &:first-of-type {
    margin-left: ${(props) => props.theme.layout.padding};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin-left: ${(props) => props.theme.layout.paddingMobile};
    }
  }
`

const ScrollableItemsLoading = styled.div`
  padding: 2rem ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.layout.paddingMobile};
  }
`

const ScrollableItems = ({
  title,
  to,
  isLoading,
  loadingText,
  children,
}: {
  title?: string
  to?: string
  isLoading?: boolean
  loadingText?: string
  children: React.ReactNode
}) => {
  const arrayChildren = Children.toArray(children)
  return (
    <ScrollableItemsView>
      <ScrollableSectionHeader title={title} to={to} />
      {isLoading ? (
        <ScrollableItemsLoading>
          <Loading text={loadingText ?? ''} style={{ textAlign: 'left' }} />
        </ScrollableItemsLoading>
      ) : children ? (
        <ScrollableItemsContainer>
          {Children.map(arrayChildren, (child, index) => (
            <ScrollableItemsItem key={index}>{child}</ScrollableItemsItem>
          ))}
        </ScrollableItemsContainer>
      ) : null}
    </ScrollableItemsView>
  )
}

export default ScrollableItems
