import styled from '@emotion/styled'
import { Body, Heading } from 'components'

const LoyaltyHeaderView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0 1rem;
  }
`

const LoyaltyHeaderContent = styled.div`
  width: 100%;
`

const LoyaltyHeaderTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
  text-align: center;
  text-decoration: underline;
  text-decoration-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.big};
  }
`

const LoyaltyHeaderSubitle = styled(Body)`
  margin: 1rem 0 1.5rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

interface LoyaltyHeaderProps {
  title?: string
  subtitle?: string
  children?: React.ReactNode
}

const LoyaltyHeader = ({ title, subtitle, children }: LoyaltyHeaderProps) => {
  return (
    <LoyaltyHeaderView>
      <LoyaltyHeaderContent>
        {!!title && <LoyaltyHeaderTitle as="p">{title}</LoyaltyHeaderTitle>}
        {!!subtitle && (
          <LoyaltyHeaderSubitle as="p">{subtitle}</LoyaltyHeaderSubitle>
        )}
      </LoyaltyHeaderContent>
      {children}
    </LoyaltyHeaderView>
  )
}

export default LoyaltyHeader
