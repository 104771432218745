/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
// import { makeAddress } from '@open-tender/utils'
import { ButtonClear, Input } from 'components'
import { useGoogleMapsAutocomplete, useGoogleMapsPlace } from '.'
import { Address } from '@open-tender/types'

const keys = {
  enter: 13,
  up: 38,
  down: 40,
}

export interface AddressName {
  long_name: string
  short_name: string
}

interface GooglePlaceComponent {
  street_number: AddressName
  route: AddressName
  locality: AddressName
  sublocality_level_1: AddressName
  administrative_area_level_1: AddressName
  administrative_area_level_2: AddressName
  administrative_area_level_3: AddressName
  postal_code: AddressName
}

const makeComponents = (
  components?: google.maps.GeocoderAddressComponent[]
) => {
  const result = components?.reduce(
    (obj, i: google.maps.GeocoderAddressComponent) => {
      return {
        ...obj,
        [i.types[0]]: { long_name: i.long_name, short_name: i.short_name },
      }
    },
    {} as GooglePlaceComponent
  )
  return result
}

export const makeAddress = (place: google.maps.places.PlaceResult) => {
  const { address_components, formatted_address, geometry } = place
  const components = makeComponents(address_components)
  if (!components) return null
  const {
    street_number,
    route,
    locality: city,
    sublocality_level_1: subcity,
    administrative_area_level_1: state,
    administrative_area_level_2: subState,
    administrative_area_level_3: subSubState,
    postal_code: postalCode,
  } = components
  const streetNumber = street_number ? street_number.short_name : ''
  const street = route ? route.long_name : ''
  const addressCity = city || subcity || subSubState || subState
  return {
    street: `${streetNumber} ${street}`.trim(),
    city: addressCity ? addressCity.long_name : '',
    state: state ? state.short_name : '',
    postal_code: postalCode ? postalCode.short_name : '',
    lat: geometry?.location?.lat() || 0,
    lng: geometry?.location?.lng() || 0,
    formatted_address,
  }
}

const AutocompleteView = styled.div`
  position: relative;
  display: block;
  width: 100%;

  label {
    padding: 0;
    margin-bottom: 0;

    & > span {
      padding-left: 3.2rem;
    }

    & > span > span:first-of-type {
      margin: 0;
    }

    input {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
      padding-top: ${(props) => props.theme.inputs.paddingTop};
      padding-bottom: ${(props) => props.theme.inputs.paddingBottom};
    }

    input:focus,
    input:active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding-top: ${(props) => props.theme.inputs.paddingTop};
      padding-bottom: ${(props) => props.theme.inputs.paddingBottom};

      & + div {
        display: block;
      }
    }
  }
`

const AutocompletePredictions = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.bgColors.primary};
  border-color: ${(props) => props.theme.border.color};
  border-width: 0.1rem;
  border-style: solid;
  border-top: 0;
  border-bottom: 0;
`

const AutocompletePrediction = styled.li<{ active?: boolean }>`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  background-color: ${(props) =>
    props.active ? props.theme.bgColors.tertiary : 'transparent'};
  border-color: ${(props) => props.theme.border.color};
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;

  button {
    color: ${(props) => props.theme.fonts.body.color};
    font-size: ${(props) => props.theme.fonts.sizes.small};
    width: 100%;
    padding: 1.2rem 1.1rem 1.1rem;
    text-align: left;
  }
`

const AutocompleteIcon = styled.div`
  position: absolute;
  top: ${(props) => props.theme.inputs.paddingTop};
  bottom: ${(props) => props.theme.inputs.paddingBottom};
  left: 0;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AutocompleteSearchIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  opacity: 0.4;
  line-height: 1;
`

const AutocompleteClear = styled(AutocompleteIcon)`
  left: auto;
  right: ${(props) => props.theme.inputs.paddingHorizontal};
  width: auto;
`

const GoogleMapsAutocomplete = ({
  maps,
  map,
  sessionToken,
  autocomplete,
  formattedAddress,
  setAddress,
  setCenter,
  error,
  icon,
  placeholder = 'enter an address',
}: {
  maps: typeof google.maps | null
  map: google.maps.Map | null
  sessionToken: google.maps.places.AutocompleteSessionToken | null
  autocomplete: google.maps.places.AutocompleteService | null
  setAddress: (address: Address | null) => void
  setCenter: ({ lat, lng }: { lat: number; lng: number }) => void
  error?: string
  formattedAddress: string
  icon: JSX.Element
  placeholder: string
}) => {
  const [input, setInput] = useState(formattedAddress || '')
  const [activeIndex, setActiveIndex] = useState(0)
  const [placeId, setPlaceId] = useState<string | null>(null)
  const { predictions, setPredictions } = useGoogleMapsAutocomplete(
    sessionToken,
    autocomplete,
    input
  )
  const place = useGoogleMapsPlace(maps, map, placeId)
  const inputRef = useRef<HTMLInputElement>(null)

  const choosePlace = (
    evt: KeyboardEvent | React.MouseEvent<HTMLButtonElement>,
    placeId: string,
    description: string
  ) => {
    evt.preventDefault()
    setPlaceId(placeId)
    setInput(description)
    setActiveIndex(0)
    inputRef.current?.blur()
  }

  const clearInput = () => {
    setInput('')
    setPredictions([])
    setAddress(null)
    inputRef.current?.focus()
  }

  const handleKeyPress = useCallback(
    (evt: KeyboardEvent) => {
      const handleEnter = (evt: KeyboardEvent) => {
        if (
          !predictions ||
          !predictions.length ||
          (evt.target as Element).id !== 'address'
        )
          return
        const prediction = activeIndex
          ? predictions.find((i, index) => index === activeIndex)
          : predictions[0]
        if (prediction) {
          const { place_id, description } = prediction
          choosePlace(evt, place_id, description)
        }
      }

      const handleMove = (increment: number) => {
        evt.preventDefault()
        if (!predictions.length) setActiveIndex(0)
        let val = activeIndex === null ? 0 : activeIndex + increment
        const max = predictions.length - 1
        val = val < 0 ? 0 : val > max ? max : val
        setActiveIndex(val)
      }

      switch (evt.keyCode) {
        case keys.enter:
          return handleEnter(evt)
        case keys.up:
          return handleMove(-1)
        case keys.down:
          return handleMove(1)
        default:
          break
      }
    },
    [predictions, activeIndex]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false)
    return () => document.removeEventListener('keydown', handleKeyPress, false)
  }, [handleKeyPress])

  useEffect(() => {
    if (place) {
      const lat = place.geometry?.location?.lat()
      const lng = place.geometry?.location?.lng()
      if (lat && lng) {
        setCenter({ lat, lng })
        const address: Address | null = makeAddress(place)
        setAddress(address)
      }
    }
  }, [place])

  return (
    <AutocompleteView>
      <Input
        label="Please enter your address"
        name="address"
        type="text"
        value={input}
        placeholder={placeholder}
        onChange={(evt) => setInput(evt.target.value)}
        showLabel={false}
        error={error}
        ref={inputRef}
      >
        <>
          <AutocompletePredictions>
            {predictions ? (
              <ul>
                {predictions.map((i, index) => (
                  <AutocompletePrediction
                    key={i.place_id}
                    active={activeIndex === index}
                  >
                    <button
                      onClick={(evt) =>
                        choosePlace(evt, i.place_id, i.description)
                      }
                    >
                      {i.description}
                    </button>
                  </AutocompletePrediction>
                ))}
              </ul>
            ) : (
              <></>
            )}
          </AutocompletePredictions>
          {icon && (
            <AutocompleteIcon>
              <AutocompleteSearchIcon>{icon}</AutocompleteSearchIcon>
            </AutocompleteIcon>
          )}
          {input.length ? (
            <AutocompleteClear>
              <ButtonClear
                ariaLabel="Clear text & start over"
                onClick={clearInput}
              />
            </AutocompleteClear>
          ) : null}
        </>
      </Input>
    </AutocompleteView>
  )
}

export default GoogleMapsAutocomplete
