import styled from '@emotion/styled'
import { BgImage, Heading, MenuItemTagsAllergens } from 'components'
import MenuItemCheckbox from '../MenuItemCheckbox'
import MenuItemInfo from '../MenuItemInfo'
import {
  MenuItemOptionAlert,
  MenuItemOptionSoldOut,
} from '../MenuItemOptionAlerts'
import MenuItemOptionPrice from '../MenuItemOptionPrice'

const MenuItemFullOptionDisplayView = styled.div<{ showGroups?: boolean }>`
  label: MenuItemFullOptionDisplay;
  padding: 1.5rem;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-style: solid;
  border-width: ${(props) => props.theme.cards.modifier.borderWidth};
  border-color: ${(props) => props.theme.cards.modifier.borderColor};
  border-radius: ${(props) => props.theme.cards.modifier.borderRadius};
  background-color: ${(props) => props.theme.cards.modifier.bgColor};
  box-shadow: ${(props) => props.theme.cards.modifier.boxShadow};
`

const MenuItemFullOptionDisplayImage = styled(BgImage)`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 10rem;
  height: 8rem;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.bgColors.secondary};
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 5rem;
    height: 5rem;
  }
`

const MenuItemFullOptionDisplayContent = styled.div`
  flex: 1 1 100%;
  padding-left: 1.5rem;
  // background-color: pink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
`

const MenuItemFullOptionPriceCals = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 0 0;
  & > span + div,
  & > span + button {
    margin-left: 0.8rem;
  }
`

const MenuItemFullOptionDisplayAction = styled.div`
  flex: 0 0 auto;
  // background-color: palegreen;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MenuItemFullOptionDisplayCheckbox = styled.div`
  min-width: 5rem;
`

interface MenuItemFullOptionDisplayProps {
  modifier: any
  children?: React.ReactNode
}

const MenuItemFullOptionDisplay = ({
  modifier,
  children,
}: MenuItemFullOptionDisplayProps) => {
  const {
    name,
    isSoldOut,
    price,
    cals,
    tags,
    allergens,
    showGroups,
    showImage,
    allergenAlert,
    bgStyle,
    toggle,
    decrement,
    incrementDisabled,
  } = modifier

  return (
    <MenuItemFullOptionDisplayView showGroups={showGroups}>
      {showImage && (
        <MenuItemFullOptionDisplayImage style={bgStyle}>
          {' '}
          {isSoldOut ? (
            <MenuItemOptionSoldOut size="small" />
          ) : allergenAlert ? (
            <MenuItemOptionAlert />
          ) : null}
        </MenuItemFullOptionDisplayImage>
      )}
      <MenuItemFullOptionDisplayContent>
        <Heading as="p" size="big">
          {name}
        </Heading>
        <MenuItemFullOptionPriceCals>
          <MenuItemOptionPrice price={price} cals={cals} />
          <MenuItemInfo item={modifier} />
        </MenuItemFullOptionPriceCals>
        <MenuItemTagsAllergens
          tags={tags}
          allergens={allergens}
          size="xSmall"
          style={{ marginTop: '0.5rem' }}
        />
      </MenuItemFullOptionDisplayContent>
      <MenuItemFullOptionDisplayAction>
        {children || (
          <MenuItemFullOptionDisplayCheckbox>
            <MenuItemCheckbox
              option={modifier}
              toggle={toggle}
              decrement={decrement}
              incrementDisabled={incrementDisabled}
              isRadio={true}
            />
          </MenuItemFullOptionDisplayCheckbox>
        )}
      </MenuItemFullOptionDisplayAction>
    </MenuItemFullOptionDisplayView>
  )
}

export default MenuItemFullOptionDisplay
