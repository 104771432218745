import styled from '@emotion/styled'
import { Preface, Text } from 'components'

const FormSectionView = styled.div`
  text-align: left;
  margin: 4rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0 0;
  }

  & > p {
    margin: 0.5em 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`

const FormSectionLabel = styled.div`
  div {
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    }
  }
`

const FormSectionContent = styled.div`
  margin: 1.5rem 0 0;
`

const FormSection = ({
  title,
  subtitle,
  children,
  style = undefined,
}: {
  title?: string
  subtitle?: string
  children?: JSX.Element | JSX.Element[]
  style?: React.CSSProperties
}) => {
  return (
    <FormSectionView style={style}>
      {title ? (
        <FormSectionLabel>
          <Preface as="div" size="small" color="tertiary">
            {title}
          </Preface>
        </FormSectionLabel>
      ) : null}
      {subtitle ? (
        <Text as="p" size="small">
          {subtitle}
        </Text>
      ) : null}
      <FormSectionContent>{children}</FormSectionContent>
    </FormSectionView>
  )
}

export default FormSection
