import { useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  ConfigFulfillment,
  FormFieldType,
  OrderFulfillment,
} from '@open-tender/types'
import { useOrderFulfillmentForm } from '@open-tender/utils'
import {
  resetOrderFulfillment,
  selectOrderFulfillment,
  updateOrderFulfillment,
  useAppDispatch,
  useAppSelector,
  fetchOrderFulfillment,
} from '@open-tender/cloud'
import {
  ButtonSubmit,
  FormInputs,
  FormSection,
  FormSubmit,
  Loading,
  Text,
} from 'components'

const arrivedText =
  "Thanks for letting us know you've arrived! We'll be out with your order shortly."

const OrderFulfillmentFormView = styled.div`
  max-width: 54rem;
  margin: 4rem auto;
`

const OrderFulfillmentForm = ({
  orderId,
  settings,
  isCurbside = false,
}: {
  orderId: number
  settings: ConfigFulfillment
  isCurbside?: boolean
}) => {
  const dispatch = useAppDispatch()
  const { orderFulfillment, loading, error } = useAppSelector(
    selectOrderFulfillment
  )
  const isLoading = loading === 'pending'
  const empty = orderFulfillment
    ? Object.values(orderFulfillment).every((i) => !i)
    : false
  const arrivalInfo = settings.fields.find((i) => i.name === 'arrival_info')
  const title = isCurbside
    ? settings.title
    : 'Want to switch to Curbside Pickup?'
  const subtitle = empty
    ? settings.description
    : arrivalInfo
    ? `Please submit your ${arrivalInfo.label.toLowerCase()} below to let us know when you've arrived`
    : 'Please let us know when you arrive'

  const update = useCallback(
    (orderId: number, data: OrderFulfillment) =>
      dispatch(updateOrderFulfillment({ orderId, data })),
    [dispatch]
  )

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useOrderFulfillmentForm(
    orderId,
    orderFulfillment,
    loading,
    error,
    update,
    settings
  )

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error || !submitting) window.scrollTo(0, 0)
  }, [error, submitting])

  useEffect(() => {
    dispatch(fetchOrderFulfillment(orderId))
  }, [dispatch, orderId])

  useEffect(() => {
    return () => {
      dispatch(resetOrderFulfillment())
    }
  }, [dispatch])

  return (
    <OrderFulfillmentFormView>
      {isLoading ? (
        <Loading text="Retrieving curbside info..." />
      ) : (
        <form id="order-fulfillment-form" onSubmit={handleSubmit} noValidate>
          <FormSection title={title} subtitle={subtitle}>
            <FormInputs
              fields={fields}
              data={data}
              onChange={onChange}
              errors={errors}
            />
          </FormSection>
          <FormSubmit>
            {data.has_arrived ? (
              <Text color="alert" as="p">
                {arrivedText}
              </Text>
            ) : (
              <ButtonSubmit submitRef={submitRef} submitting={submitting}>
                {submitting ? 'Submitting' : settings.button}
              </ButtonSubmit>
            )}
          </FormSubmit>
        </form>
      )}
    </OrderFulfillmentFormView>
  )
}

export default OrderFulfillmentForm
