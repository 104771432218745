import { useEffect } from 'react'
import {
  fetchCustomerRewards,
  selectCustomerRewards,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Loading, Rewards } from 'components'

const RewardsList = () => {
  const dispatch = useAppDispatch()
  const { entities, loading, error } = useAppSelector(selectCustomerRewards)
  const hasRewards = entities.length > 0 && !error

  useEffect(() => {
    dispatch(fetchCustomerRewards())
  }, [dispatch])

  return hasRewards ? (
    <Rewards rewards={entities} />
  ) : loading === 'pending' ? (
    <Loading text="Retrieving your rewards..." />
  ) : (
    <p>
      Looks like you don't currently have any rewards. Please try back later!
    </p>
  )
}

export default RewardsList
