import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectBrand,
  selectContent,
  selectCustomer,
  useAppSelector,
} from '@open-tender/cloud'
import {
  CommunicationPrefs,
  Content,
  HeaderUser,
  Main,
  PageContainer,
  PageTitle,
} from 'components'

const CommsPrefs = () => {
  const navigate = useNavigate()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)
  const { communicationPreferences: config } =
    useAppSelector(selectContent) || {}

  useEffect(() => {
    if (!auth) return navigate('/account')
  }, [auth, navigate])

  return (
    <>
      <Helmet>
        <title>Communication Preferences | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderUser />
        <Main>
          <PageContainer style={{ maxWidth: '72rem' }}>
            <PageTitle {...config} />
            <CommunicationPrefs />
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default CommsPrefs
