import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { CartItem, CartLevels, ConfigDisplaySetting } from '@open-tender/types'
import { useAccordion } from '@open-tender/utils'
import MenuItemSizeButton from './MenuItemSizeButton'
import MenuItemInstructions from '../MenuItemInstructions'
import ButtonLink from 'components/ButtonLink'
import MenuItemIngredients from 'components/MenuItemIngredients'
import MenuItemNutrition from 'components/MenuItemNutrition'
import {
  MenuItemSizeButtons,
  MenuItemSizeButtonsHeader,
  MenuItemSizeButtonsHeaderTitle,
  MenuItemSizeSectionView,
  MenuItemSizeToggles,
  MenuItemSizeTogglesSpacer,
  MenuItemSizeView,
} from './MenuItemSize.styled'

const MenuItemSizeSection = ({
  isOpen = false,
  style,
  children,
}: {
  isOpen?: boolean
  style?: React.CSSProperties
  children?: React.ReactNode
}) => {
  return (
    <TransitionGroup component={null}>
      {isOpen ? (
        <CSSTransition
          key="nutritionalInfo"
          classNames="reveal"
          timeout={{ enter: 250, exit: 250 }}
        >
          <MenuItemSizeSectionView style={style}>
            {children}
          </MenuItemSizeSectionView>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

const MenuItemSize = ({
  builtItem,
  cartId,
  toggleOption,
  setMadeFor,
  setNotes,
  displaySettings,
  hasCustomize,
  setImageUrl,
}: {
  builtItem: CartItem
  cartId: number | null
  toggleOption: (levels: CartLevels) => void
  setMadeFor: (modeFor: string) => void
  setNotes: (notes: string) => void
  displaySettings?: ConfigDisplaySetting
  hasCustomize?: boolean
  setImageUrl?: (imageUrl: string | null) => void
}) => {
  const config = {
    hasMadeFor: !!displaySettings?.madeFor,
    hasNotes: !!displaySettings?.notes,
    hasCals: !!displaySettings?.calories,
    hasCustomize: !!hasCustomize,
  }
  const {
    open,
    setOpen,
    madeFor,
    notes,
    hasMadeFor,
    hasNotes,
    hasInstructions,
    instructionsTitle,
    hasCals,
    nutritionalInfo,
    hasIngredients,
    ingredients,
    sizeGroup,
    showAccordion,
    toggleSize,
  } = useAccordion(builtItem, toggleOption, cartId, config)

  const show = (section: string) => {
    open === section ? setOpen(null) : setOpen(section)
  }

  const appliedOption = sizeGroup?.options?.find((i) => i.quantity > 0)

  if (!showAccordion) return null

  return (
    <MenuItemSizeView>
      {!!sizeGroup && (
        <>
          <MenuItemSizeButtonsHeader>
            <MenuItemSizeButtonsHeaderTitle as="p">
              {sizeGroup.name} {!appliedOption && <span>(required)</span>}
            </MenuItemSizeButtonsHeaderTitle>
          </MenuItemSizeButtonsHeader>
          <MenuItemSizeButtons>
            {sizeGroup?.options?.map((option) => {
              return (
                <MenuItemSizeButton
                  key={option.id}
                  name={option.name}
                  description={option.description}
                  price={option.price}
                  cals={option.cals}
                  imageUrl={option.imageUrl}
                  onClick={() => toggleSize(option.id)}
                  isApplied={option.quantity > 0}
                  // disabled={sizeGroup.quantity > 0 && option.quantity === 0}
                />
              )
            })}
          </MenuItemSizeButtons>
        </>
      )}
      <MenuItemSizeToggles>
        {hasInstructions ? (
          <ButtonLink onClick={() => show('NOTES')}>
            {instructionsTitle}
          </ButtonLink>
        ) : null}
        {hasInstructions && (hasCals || hasIngredients) ? (
          <MenuItemSizeTogglesSpacer>|</MenuItemSizeTogglesSpacer>
        ) : null}
        {hasCals ? (
          <ButtonLink onClick={() => show('NUTRITION')}>
            Nutritional Info
          </ButtonLink>
        ) : null}
        {hasIngredients && (hasInstructions || hasIngredients) ? (
          <MenuItemSizeTogglesSpacer>|</MenuItemSizeTogglesSpacer>
        ) : null}
        {hasIngredients ? (
          <ButtonLink onClick={() => show('INGREDIENTS')}>
            Ingredients
          </ButtonLink>
        ) : null}
      </MenuItemSizeToggles>
      <MenuItemSizeSection isOpen={open === 'NOTES'}>
        <MenuItemInstructions
          hasMadeFor={hasMadeFor}
          madeFor={madeFor}
          setMadeFor={setMadeFor}
          hasNotes={hasNotes}
          notes={notes}
          setNotes={setNotes}
          allDone={() => setOpen(null)}
        />
      </MenuItemSizeSection>
      <MenuItemSizeSection isOpen={open === 'INGREDIENTS'}>
        <MenuItemIngredients ingredients={ingredients} />
      </MenuItemSizeSection>
      <MenuItemSizeSection isOpen={open === 'NUTRITION'}>
        <MenuItemNutrition nutritionalInfo={nutritionalInfo} />
      </MenuItemSizeSection>
    </MenuItemSizeView>
  )
}

export default MenuItemSize
