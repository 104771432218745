import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { isMobile } from 'react-device-detect'
import { makeSlides } from '@open-tender/utils'
import { BackgroundImage, BackgroundLoading, Slider, Video } from '.'
import { AnnouncementsState } from '@open-tender/cloud'
import { AnnouncementSettings } from '@open-tender/types'

interface PageHeroViewProps {
  isMobile?: boolean
  ht?: string
}

const PageHeroView = styled.div<PageHeroViewProps>`
  label: PageHero;
  flex-grow: 1;
  display: flex;
  min-height: 64rem;
  height: 100vh;
  ${(props) =>
    props.isMobile ? `height: 100%; height: -webkit-fill-available;` : ''}
  ${(props) =>
    props.ht ? `height: ${props.ht}; min-height: ${props.ht};` : ''}
`

const PageHero = ({
  announcements,
  imageUrl,
  videoUrl,
  height,
  style,
  children,
}: {
  announcements?: Partial<
    AnnouncementsState & { settings: AnnouncementSettings | null }
  >
  imageUrl?: string | null
  videoUrl?: string | null
  height?: string
  style?: React.CSSProperties
  children?: JSX.Element | JSX.Element[]
}) => {
  const theme = useTheme()
  const {
    settings = null,
    entities = [],
    loading = 'idle',
    error = null,
  } = announcements || {}
  const slides = error ? null : makeSlides(entities, isMobile)
  const isLoading = loading === 'pending'
  const hideHero = !slides && !imageUrl

  if (hideHero) return null

  return (
    <PageHeroView ht={height} isMobile={isMobile} style={style}>
      {videoUrl ? (
        <Video src={videoUrl} poster={imageUrl} />
      ) : isLoading ? (
        <BackgroundLoading />
      ) : slides ? (
        <Slider
          settings={settings}
          slides={slides}
          bgColor={theme.bgColors.dark}
        />
      ) : imageUrl ? (
        <BackgroundImage imageUrl={imageUrl} bgColor={theme.bgColors.dark}>
          {children}
        </BackgroundImage>
      ) : null}
    </PageHeroView>
  )
}

export default PageHero
