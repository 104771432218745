import { useEffect } from 'react'
import {
  addMessage,
  fetchCustomerTpls,
  logoutCustomer,
  resetCustomerTpls,
  selectCustomerTpls,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Loading } from 'components'
import ThirdPartyLoyaltyRewards from './ThirdPartyLoyaltyRewards'
import ThirdPartyLoyaltySummary from './ThirdPartyLoyaltySummary'
import AccountSection from 'components/pages/Account/AccountSection'

const ThirdPartyLoyalty = ({ isAccount = false }) => {
  const dispatch = useAppDispatch()
  const { tpls, loading, error } = useAppSelector(selectCustomerTpls)
  const isLoading = loading === 'pending'
  const { rewards = [] } = tpls || {}

  useEffect(() => {
    dispatch(fetchCustomerTpls())
  }, [dispatch])

  useEffect(() => {
    if (error) {
      dispatch(logoutCustomer())
      dispatch(resetCustomerTpls())
      dispatch(addMessage('Please login to reauthenticate your account'))
    }
  }, [error, dispatch])

  return (
    <>
      {isLoading ? (
        <AccountSection>
          <Loading text="Retrieving your rewards..." />
        </AccountSection>
      ) : tpls ? (
        <>
          {isAccount ? (
            <AccountSection>
              <ThirdPartyLoyaltySummary isAccount={isAccount} {...tpls} />
            </AccountSection>
          ) : (
            <ThirdPartyLoyaltySummary isAccount={isAccount} {...tpls} />
          )}
          <ThirdPartyLoyaltyRewards isAccount={isAccount} rewards={rewards} />
        </>
      ) : error ? (
        <p>
          Something appears to have gone wrong. Please try logging out and
          logging back in again.
        </p>
      ) : (
        <p>Looks like you don't have any rewards yet.</p>
      )}
    </>
  )
}

export default ThirdPartyLoyalty
