import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { isBrowser } from 'react-device-detect'
import styled from '@emotion/styled'
import { slugify } from '@open-tender/utils'
import { getActiveElement } from 'utils/helpers'
import { MenuNavButton } from './MenuNavButton'

const MenuNavView = styled.div`
  padding-top: 80px;
  padding-right: 12px;
  min-width: 28rem;
  max-width: 28rem;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: ${(props) => props.theme.layout.navHeightMobile};
  }
`
const MenuNavContent = styled.div`
  position: sticky;
  top: 160px;
  background-color: #febbd9; // TODO: color is not in the theme
  padding: ${(props) => props.theme.layout.margin};
  border-radius: 0 10px 10px 0;
  box-shadow: 12px 12px 0px ${(props) => props.theme.colors.primary};
  margin-left: -12px;
  padding-left: calc(
    ${(props) => props.theme.layout.margin} + 12px
  ); // hack to get the shadow to right
`

const MenuNav = ({
  items,
  offset = 0,
}: {
  items: string[]
  offset?: number
}) => {
  const [active, setActive] = useState<Element | null>(null)
  const theme = useTheme()
  const { navHeight, navHeightMobile } = theme.layout
  const height = isBrowser ? navHeight : navHeightMobile
  const heightInPixels = parseInt(height.replace('rem', '')) * 10
  const topOffset = heightInPixels * 2 + 1
  const navOffset = offset - topOffset

  useEffect(() => {
    const elements = Array.from(
      document.getElementsByClassName('section')
    ) as HTMLElement[]

    const handleScroll = () => {
      if (elements.length) {
        setActive(getActiveElement(elements, -navOffset + 1))
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [navOffset, active])

  return (
    <MenuNavView>
      <MenuNavContent>
        {items.map((name, index) => {
          const sectionId = slugify(name)
          const activeId = active ? active.id : null
          return (
            <MenuNavButton
              key={`${sectionId}-${index}`}
              name={name}
              offset={navOffset}
              active={activeId === sectionId}
            />
          )
        })}
      </MenuNavContent>
    </MenuNavView>
  )
}

export default MenuNav
