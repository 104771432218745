import styled from '@emotion/styled'

const CountView = styled.div<{
  size: string
  radius: string
  color?: string
  bgColor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: ${(props) => props.radius};
  border-width: ${(props) => props.theme.counts.alerts.borderWidth};
  padding-top: ${(props) => props.theme.counts.alerts.paddingTop};
  padding-bottom: ${(props) => props.theme.counts.alerts.paddingBottom};
  color: ${(props) => props.color || props.theme.counts.alerts.color};
  border-color: ${(props) =>
    props.bgColor || props.theme.counts.alerts.borderColor};
  background-color: ${(props) =>
    props.bgColor || props.theme.counts.alerts.bgColor};
`

const CountCount = styled.span<{
  fontSize?: number
}>`
  display: block;
  line-height: 0;
  -webkit-font-smoothing: ${(props) => props.theme.counts.alerts.fontSmoothing};
  font-family: ${(props) => props.theme.counts.alerts.family};
  font-weight: ${(props) => props.theme.counts.alerts.weight};
  font-size: ${(props) =>
    props.fontSize
      ? `${parseFloat((props.fontSize / 10).toString())}rem`
      : props.theme.fonts.sizes.xSmall};
`

const Count = ({
  count,
  color,
  bgColor,
  size = 20,
  fontSize,
}: {
  count: string | number
  color?: string
  bgColor?: string
  size?: number
  fontSize?: number
}) => {
  return (
    <CountView
      color={color}
      bgColor={bgColor}
      size={`${size / 10}rem`}
      radius={`${size / 20}rem`}
    >
      <CountCount fontSize={fontSize}>{count}</CountCount>
    </CountView>
  )
}

export default Count
