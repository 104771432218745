import { CornerDownRight } from 'react-feather'
import styled from '@emotion/styled'
import { CartItemGroup } from '@open-tender/types'
import { useModifierGroup } from '@open-tender/utils'
import { selectDisplaySettings, useAppSelector } from '@open-tender/cloud'
import { Heading } from 'components'

const MenuItemFullGroupHeaderView = styled.div<{ isChild?: boolean }>`
  display: flex;
  margin: ${(props) => (props.isChild ? '0' : '0 0 1.5rem')};
  justify-content: space-between;
  align-items: flex-end;
`

const MenuItemFullGroupHeaderArrow = styled.span`
  position: relative;
  top: 0.3rem;
`

const MenuItemFullGroupHeaderName = styled(Heading)<{ isChild: boolean }>`
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: ${(props) =>
    props.isChild ? props.theme.fonts.sizes.big : props.theme.fonts.sizes.xBig};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) =>
      props.isChild
        ? props.theme.fonts.sizes.main
        : props.theme.fonts.sizes.big};
  }
`

const MenuItemFullGroupHeaderNameQuantity = styled.span<{
  belowMin?: boolean
  belowIncluded?: boolean
}>`
  padding: 0 0 0 0.5rem;
  ${(props) =>
    props.belowMin
      ? `color: ${props.theme.colors.error};`
      : props.belowIncluded
      ? `color: ${props.theme.colors.success};`
      : ''}
`

const MenuItemFullGroupHeaderDescription = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.main};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const MenuItemFullGroupHeader = ({
  group,
  isChild,
}: {
  group: CartItemGroup
  isChild: boolean
}) => {
  const displaySettings = useAppSelector(selectDisplaySettings)
  const { name, description, count, quantity, min, max, included } =
    useModifierGroup(group, displaySettings)
  const rules = `Min: ${min} / Max: ${max} / Included: ${included}`

  return (
    <MenuItemFullGroupHeaderView isChild={isChild}>
      <div>
        <MenuItemFullGroupHeaderName as="p" isChild={isChild} title={rules}>
          {isChild ? (
            <MenuItemFullGroupHeaderArrow>
              <CornerDownRight size={18} />
            </MenuItemFullGroupHeaderArrow>
          ) : null}{' '}
          {name}
          <MenuItemFullGroupHeaderNameQuantity
            belowMin={quantity < min}
            belowIncluded={quantity < included}
          >
            ({count})
          </MenuItemFullGroupHeaderNameQuantity>
        </MenuItemFullGroupHeaderName>
        {description && (
          <MenuItemFullGroupHeaderDescription>
            {description}
          </MenuItemFullGroupHeaderDescription>
        )}
      </div>
    </MenuItemFullGroupHeaderView>
  )
}

export default MenuItemFullGroupHeader
