import { isMobile } from 'react-device-detect'
import { useTheme } from '@emotion/react'
import { formatDollars } from '@open-tender/utils'
import { Checkmark } from 'components'
import MenuItemOptionPrice from '../MenuItemOptionPrice'
import {
  MenuItemSizeButtonCheckmark,
  MenuItemSizeButtonContent,
  MenuItemSizeButtonImage,
  MenuItemSizeButtonInfo,
  MenuItemSizeButtonPrice,
  MenuItemSizeButtonSubtitle,
  MenuItemSizeButtonTitle,
  MenuItemSizeButtonView,
} from './MenuItemSize.styled'

const MenuItemSizeButton = ({
  name,
  description,
  price,
  cals,
  imageUrl,
  onClick,
  isApplied,
  disabled,
}: {
  name: string
  description: string | null
  price: number
  cals: number | null
  imageUrl: string | null
  onClick: () => void
  isApplied: boolean
  disabled?: boolean
}) => {
  const { desktop, mobile } = useTheme().item
  const { showSizeModImages } = isMobile ? mobile : desktop
  const displayPrice = price ? formatDollars(price.toString()) : '$0.00'
  const classSelected = isApplied ? 'selected' : ''
  const classHover = isMobile || disabled ? '' : ' show-hover'
  const className = classSelected + classHover
  const bgStyle =
    showSizeModImages && imageUrl
      ? { backgroundImage: `url(${imageUrl}` }
      : undefined

  const [customName, customDescription] = name.split(' ')
  return (
    <MenuItemSizeButtonView
      className={className}
      onClick={onClick}
      disabled={disabled || isApplied}
    >
      {isApplied ? (
        <MenuItemSizeButtonCheckmark>
          <Checkmark />
        </MenuItemSizeButtonCheckmark>
      ) : null}
      {bgStyle && (
        <MenuItemSizeButtonImage style={bgStyle} isApplied={isApplied} />
      )}
      <MenuItemSizeButtonContent>
        <MenuItemSizeButtonInfo>
          <MenuItemSizeButtonTitle isApplied={isApplied}>
            {customName}
          </MenuItemSizeButtonTitle>
          {customDescription ? (
            <MenuItemSizeButtonSubtitle size="xxSmall">
              {customDescription}
            </MenuItemSizeButtonSubtitle>
          ) : null}
        </MenuItemSizeButtonInfo>
        <MenuItemSizeButtonPrice>
          <MenuItemOptionPrice price={displayPrice} cals={cals} />
        </MenuItemSizeButtonPrice>
      </MenuItemSizeButtonContent>
    </MenuItemSizeButtonView>
  )
}

export default MenuItemSizeButton
