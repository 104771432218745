import styled from '@emotion/styled'
import { isBrowser } from 'react-device-detect'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { Tag } from '.'
import { AlertCircle, Slash } from './icons'

const MenuItemTagAlertView = styled.div`
  position: absolute;
  z-index: 2;
  top: -1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface MenuItemTagAlertProps {
  isSoldOut?: boolean
  allergenAlert?: string
}

const MenuItemTagAlert = ({
  isSoldOut,
  allergenAlert,
}: MenuItemTagAlertProps) => {
  const { soldOutMessage } = useAppSelector(selectContentSection('menu')) || {}
  const soldOutMsg = isBrowser
    ? soldOutMessage || 'Sold out for day'
    : 'Sold out'

  const itemTag = isSoldOut ? (
    <Tag
      icon={<Slash size={14} strokeWidth={1.5} />}
      text={soldOutMsg}
      color="alert"
    />
  ) : allergenAlert ? (
    <Tag
      icon={<AlertCircle size={14} strokeWidth={1.5} />}
      text={allergenAlert}
      color="alert"
    />
  ) : null

  return itemTag ? <MenuItemTagAlertView>{itemTag}</MenuItemTagAlertView> : null
}

export default MenuItemTagAlert
