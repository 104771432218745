import { isMobile } from 'react-device-detect'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { Header, HeaderLogoWithName, Stack } from 'components'
import { Cart, NavMenu, User } from 'components/buttons'

const HeaderGuest = ({
  maxWidth = '76.8rem',
  to,
}: {
  maxWidth?: string
  to?: string
}) => {
  const { displayLogoMobile } =
    useAppSelector(selectContentSection('guest')) || {}
  const showLogo = isMobile ? displayLogoMobile : false

  return (
    <Header
      isStatic
      maxWidth={maxWidth}
      // borderColor={'transparent'}
      title={showLogo ? <HeaderLogoWithName /> : undefined}
      left={isMobile ? <User /> : <HeaderLogoWithName />}
      right={
        <Stack gap={0}>
          {!isMobile && <User to={to} />}
          <Cart />
          <NavMenu />
        </Stack>
      }
    />
  )
}

export default HeaderGuest
