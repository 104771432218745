import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import {
  closeModal,
  removeCustomerGroupOrder,
  selectContent,
  selectBrand,
  selectCateringOnly,
  selectGroupOrder,
  useAppDispatch,
  useAppSelector,
  selectCustomer,
  selectCustomerGroupOrders,
  fetchCustomerGroupOrders,
  setOrderServiceType,
  openModal,
  selectSettings,
} from '@open-tender/cloud'
import { Back, NavMenu } from 'components/buttons'
import {
  Background,
  Button,
  Content,
  Header,
  HeaderGuest,
  HeaderLogo,
  HtmlContent,
  Main,
  PageTitle,
} from 'components'
import OrderTypes from './OrderTypes'
import { ServiceType } from '@open-tender/types'

const OrderTypeView = styled.div<{ showGuest?: boolean }>`
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

const OrderTypeContent = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

const GroupOrderButtons = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-wrap: wrap;
    gap: 1rem;
  }
  margin: 0 0 2rem 0;
  button {
    margin: 0 1rem 0 0;
  }
`

const OrderType = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const { auth } = useAppSelector(selectCustomer)
  const { title: siteTitle, pilot_slug } = useAppSelector(selectBrand) || {}
  const cateringOnly = useAppSelector(selectCateringOnly)
  const { orderType, guest } = useAppSelector(selectContent) || {}
  const { showGuest } = guest || {}
  const { background, title, subtitle, content, videoUrl } = orderType || {}
  const hasContent = !!(content && content.length)
  const { entities: customerGroupOrders } = useAppSelector(
    selectCustomerGroupOrders
  )
  const { orderTypes } = useAppSelector(selectSettings) || {}
  const { cartId, serviceType: groupOrderServiceType } =
    useAppSelector(selectGroupOrder)
  const [signedIn, setSignedIn] = useState(true)

  useEffect(() => {
    dispatch(closeModal())
  }, [dispatch])

  useEffect(() => {
    if (cateringOnly) {
      navigate('/catering-address')
    } else if (pilot_slug) {
      navigate(`/locations/${pilot_slug}`)
    }
  }, [cateringOnly, pilot_slug, navigate])

  useEffect(() => {
    dispatch(fetchCustomerGroupOrders())
  }, [dispatch])

  useEffect(() => {
    if (!auth) setSignedIn(false)
  }, [auth])

  useEffect(() => {
    if (!showGuest && !signedIn && auth) {
      navigate('/account')
    }
  }, [signedIn, auth, navigate, showGuest])

  let serviceTypes: ServiceType[] = []
  if (orderTypes?.includes('PICKUP')) serviceTypes.push('PICKUP')
  if (orderTypes?.includes('DELIVERY')) serviceTypes.push('DELIVERY')

  const continueGroupOrder = () => {
    if (!groupOrderServiceType) {
      dispatch(openModal({ type: 'groupOrderType', args: { serviceTypes } }))
    } else {
      dispatch(
        setOrderServiceType({
          orderType: 'OLO',
          serviceType: groupOrderServiceType,
        })
      )
      navigate('/locations')
    }
  }

  const cancelGroupOrder = () => {
    dispatch(removeCustomerGroupOrder({ cartId: cartId ?? 0 }))
  }

  return (
    <>
      <Helmet>
        <title>Order Type | {siteTitle}</title>
      </Helmet>
      <Background
        imageUrl={background}
        videoUrl={videoUrl}
        announcements={undefined}
        style={showGuest ? undefined : { top: theme.layout.navHeight }}
      />
      <Content maxWidth="76.8rem">
        {showGuest ? (
          <Header
            maxWidth="76.8rem"
            borderColor="transparent"
            title={<HeaderLogo />}
            left={<Back label="Back to Homepage" />}
            right={<NavMenu />}
          />
        ) : (
          <HeaderGuest maxWidth="100%" to="/account" />
        )}
        <Main>
          <OrderTypeView showGuest={showGuest}>
            <PageTitle
              title={title}
              subtitle={subtitle}
              style={{ textAlign: 'left', maxWidth: '100%' }}
            />
            {customerGroupOrders.length > 0 && (
              <GroupOrderButtons>
                <Button
                  onClick={() => navigate('/group-orders')}
                  size={isMobile ? 'small' : 'default'}
                >
                  See saved group orders
                </Button>
                {cartId !== null && (
                  <>
                    <Button
                      onClick={continueGroupOrder}
                      size={isMobile ? 'small' : 'default'}
                    >
                      Continue Group Order
                    </Button>
                    <Button
                      onClick={() => cancelGroupOrder()}
                      size={isMobile ? 'small' : 'default'}
                      color="secondary"
                    >
                      Cancel Group Order
                    </Button>
                  </>
                )}
              </GroupOrderButtons>
            )}
            <OrderTypes />
            {hasContent && (
              <OrderTypeContent>
                <HtmlContent content={content} />
              </OrderTypeContent>
            )}
          </OrderTypeView>
        </Main>
      </Content>
    </>
  )
}

export default OrderType
