import styled from '@emotion/styled'
import { CartItemGroups, CartItemOptions, CartLevels } from '@open-tender/types'
import MenuItemOptionSimpleOption from './MenuItemOptionSimpleOption'

const MenuItemOptionSimpleGroupsView = styled.div`
  label: MenuItemOptionSimpleGroups;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: auto;
    left: 2rem;
    bottom: 2rem;
    justify-content: space-between;
  }
`

const MenuItemOptionSimpleGroups = ({
  groups,
  levels,
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
  isChild,
}: {
  groups: CartItemGroups
  levels: CartLevels
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  setOptionQuantity: (
    levels: CartLevels,
    quantity: number,
    nested?: CartItemOptions
  ) => void
  scrollContainer?: HTMLDivElement | null
  topOffset?: number | null
  headerHeight?: number
  isChild?: boolean
}) => {
  return (
    <MenuItemOptionSimpleGroupsView>
      {groups.map((group) =>
        group.options.map((option) => (
          <MenuItemOptionSimpleOption
            key={`${group.id}-${option.id}`}
            group={group}
            option={option}
            levels={levels}
            toggleOption={toggleOption}
            incrementOption={incrementOption}
            decrementOption={decrementOption}
            setOptionQuantity={setOptionQuantity}
          />
        ))
      )}
    </MenuItemOptionSimpleGroupsView>
  )
}

export default MenuItemOptionSimpleGroups
