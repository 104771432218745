import styled from '@emotion/styled'
import { ThemeFontSize } from '@open-tender/types'

const Preface = styled.span<{ size?: ThemeFontSize; color?: string }>`
  font-family: ${(props) => props.theme.fonts.preface.family};
  font-weight: ${(props) => props.theme.fonts.preface.weight};
  font-style: ${(props) => props.theme.fonts.preface.fontStyle};
  letter-spacing: ${(props) => props.theme.fonts.preface.letterSpacing};
  line-height: ${(props) => props.theme.fonts.preface.lineHeight};
  text-transform: ${(props) => props.theme.fonts.preface.textTransform};
  -webkit-font-smoothing: ${(props) => props.theme.fonts.preface.fontSmoothing};
  font-size: ${({ size, theme }) =>
    size ? theme.fonts.sizes[size] : theme.fonts.preface.fontSize};
  color: ${({ color, theme }) => theme.colors[color || 'tertiary']};
`

export default Preface
