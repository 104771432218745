import { useEffect, useCallback, useMemo } from 'react'
import { Allergens, CustomerAllergens } from '@open-tender/types'
import { useAllergenForm } from '@open-tender/utils'
import {
  fetchAllergens,
  fetchCustomerAllergens,
  selectAllergens,
  selectCustomer,
  selectCustomerAllergens,
  setSelectedAllergens,
  updateCustomerAllergens,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonSubmit,
  FormError,
  FormInputsView,
  FormSubmit,
  Loading,
  Switch,
} from 'components'
import { isEqual } from 'lodash'

const AllergenForm = ({
  windowRef,
  callback,
}: {
  windowRef?: React.RefObject<HTMLDivElement>
  callback?: () => void
}) => {
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector(selectCustomer)
  const brandAllergens = useAppSelector(selectAllergens)
  const customerAllergens = useAppSelector(selectCustomerAllergens)
  const isLoading =
    brandAllergens.loading === 'pending' ||
    customerAllergens.loading === 'pending'
  const error = brandAllergens.error || customerAllergens.error
  const setAllergens = useCallback(
    (data: Allergens) => dispatch(setSelectedAllergens(data)),
    [dispatch]
  )
  const update = useCallback(
    (data: CustomerAllergens) => dispatch(updateCustomerAllergens(data)),
    [dispatch]
  )
  const updateAllergens = auth ? update : null
  const allergens = brandAllergens.entities
  const selectedAllergens = useMemo(() => {
    return auth
      ? allergens.filter((i) =>
          customerAllergens.entities
            .map((i) => i.allergen_id)
            .includes(i.allergen_id)
        )
      : brandAllergens.selectedAllergens || []
  }, [customerAllergens, allergens, auth, brandAllergens.selectedAllergens])

  const {
    submitRef,
    submitting,
    allergenIds,
    errors,
    handleChange,
    handleSubmit,
  } = useAllergenForm(
    allergens,
    selectedAllergens,
    isLoading,
    error,
    setAllergens,
    updateAllergens,
    callback
  )

  useEffect(() => {
    if (!submitting) {
      if (windowRef?.current) {
        windowRef.current.scrollTop = 0
      } else if (!callback) {
        window.scroll(0, 0)
      }
    }
  }, [windowRef, callback, submitting])

  useEffect(() => {
    dispatch(fetchAllergens())
    if (auth) dispatch(fetchCustomerAllergens())
  }, [dispatch, auth])

  const isSubmitDisabled = isEqual(
    allergenIds,
    selectedAllergens.map((allergen) => allergen.allergen_id)
  )

  return allergens.length > 0 ? (
    <form id="allergen-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputsView>
        {allergens.map((allergen) => (
          <Switch
            key={allergen.allergen_id}
            type="switch"
            label={allergen.name ?? ''}
            name={`${allergen.allergen_id}`}
            on={allergenIds.includes(allergen.allergen_id)}
            onChange={(evt) =>
              handleChange(allergen.allergen_id, evt.target.checked)
            }
          />
        ))}
      </FormInputsView>
      <FormSubmit>
        <ButtonSubmit
          submitRef={submitRef}
          submitting={submitting}
          disabled={isLoading || isSubmitDisabled}
        >
          {submitting ? 'Submitting...' : 'Submit Updates'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  ) : isLoading ? (
    <Loading text="Retrieving dietary preferences..." />
  ) : (
    <p>This brand {"doesn't"} currently have any allergens configured</p>
  )
}

export default AllergenForm
