import { formatDollars } from '@open-tender/utils'
import styled from '@emotion/styled'

const CheckSummaryItemView = styled.span<{ isBold?: boolean }>`
  width: 100%;
  margin: 1rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) =>
    props.theme.colors[props.isBold ? 'primary' : 'secondary']};
  ${(props) =>
    props.isBold ? `font-weight: ${props.theme.boldWeight};` : null}

  span {
    display: block;
  }
`

const CheckSummaryItem = ({
  label,
  value,
  isBold = false,
  style,
}: {
  label: string
  value: string
  isBold?: boolean
  style?: React.CSSProperties
}) => {
  return (
    <CheckSummaryItemView isBold={isBold} style={style}>
      <span>{label}</span>
      <span>{formatDollars(value)}</span>
    </CheckSummaryItemView>
  )
}

export default CheckSummaryItem
