import { CSSProperties, ReactElement } from 'react'
import styled from '@emotion/styled'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { CartItem, CartLevels, ConfigDisplaySetting } from '@open-tender/types'
import { formatDollars, useAccordion } from '@open-tender/utils'
import {
  Body,
  Button,
  Checkmark,
  Heading,
  Input,
  MenuItemIngredients,
  MenuItemNutrition,
  MenuItemPriceCals,
} from 'components'
import { ChevronDown, ChevronUp } from 'components/icons'
import MenuItemQuantity from './MenuItemQuantity'

const MenuItemAccordionPlaceholder = styled.div`
  padding-top: 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-top: 2rem;
  }
`

const MenuItemAccordionView = styled.div`
  padding: 2rem ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.item.mobile.padding};
  }
`

const MenuItemAccordionContainer = styled.div`
  border-bottom: ${(props) => props.theme.border.width} solid
    ${(props) => props.theme.border.color};
`

const MenuItemAccordionRow = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${(props) => props.theme.border.width} solid
    ${(props) => props.theme.border.color};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 3.6rem;
  }
`

const MenuItemAccordionRowButton = ({
  name,
  open,
  setOpen,
  children,
}: {
  name: string
  open: string | null
  setOpen: (open: string | null) => void
  children: ReactElement | ReactElement[]
}) => {
  const isOpen = name === open
  const onClick = () => {
    setOpen(isOpen ? null : name)
  }
  return (
    <MenuItemAccordionRow as="button" onClick={onClick}>
      {children}
    </MenuItemAccordionRow>
  )
}

const MenuItemAccordionLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  span {
    display: block;
  }
`

const MenuItemAccordionLabelRequired = styled.span`
  color: ${(props) => props.theme.colors.error};
  margin: 0 0 0 0.3rem;
`

const MenuItemAccordionLabelCheckmark = styled.span`
  margin: 0 0 0 0.5rem;

  & > span {
    border-color: ${(props) => props.theme.colors.success};
    background-color: ${(props) => props.theme.colors.success};
  }
`

const MenuItemAccordionToggleView = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const MenuItemAccordionToggleIcon = styled.div`
  width: 2.4rem;
  padding: 0 0 0 0.5rem;
  margin: 0.2rem 0 0 0;
`

const MenuItemAccordionToggle = ({
  isOpen,
  children,
}: {
  isOpen?: boolean
  children?: ReactElement | ReactElement[]
}) => {
  return (
    <MenuItemAccordionToggleView>
      {children}
      <MenuItemAccordionToggleIcon>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </MenuItemAccordionToggleIcon>
    </MenuItemAccordionToggleView>
  )
}

const MenuItemAccordionQuantity = styled.div`
  height: 100%;
  margin-right: -0.8rem;
`

const MenuItemAccordionSelectedSize = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

const MenuItemAccordionMissingSize = styled(Body)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.colors.error};
`

const MenuItemAccordionSectionView = styled.div`
  padding: 0 0 1rem;
  margin: 0 0 0;
`

const MenuItemAccordionSection = ({
  isOpen = false,
  style,
  children,
}: {
  isOpen?: boolean
  style?: CSSProperties
  children?: ReactElement | ReactElement[]
}) => {
  return (
    <TransitionGroup component={null}>
      {isOpen ? (
        <CSSTransition
          key="nutritionalInfo"
          classNames="reveal"
          timeout={{ enter: 250, exit: 250 }}
        >
          <MenuItemAccordionSectionView style={style}>
            {children}
          </MenuItemAccordionSectionView>
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

const MenuItemAccordionOptionButton = styled.button<{
  checked?: boolean
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0.8rem;
  margin: 0.6rem 0 0;
  border-style: solid;
  border-width: ${(props) => props.theme.border.width};
  border-color: ${(props) => props.theme.bgColors.highlight};
  color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) =>
    props.checked ? props.theme.bgColors.highlight : 'transparent'};

  &:first-of-type {
    margin: 0;
  }

  span {
    transition: ${(props) => props.theme.links.transition};
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover,
  &:active {
    border-color: ${(props) => props.theme.bgColors.highlight};
    background-color: ${(props) => props.theme.bgColors.highlight};

    span {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const MenuItemAccordionOptionName = styled(Heading)`
  display: block;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  text-transform: uppercase;
`

const MenuItemAccordionOption = ({
  name,
  price,
  cals,
  quantity,
  toggle,
}: {
  name: string
  price: number
  cals: number | null
  quantity: number
  toggle: () => void
}) => {
  const displayPrice = price ? formatDollars(price.toString()) : null
  return (
    <MenuItemAccordionOptionButton onClick={toggle} checked={quantity >= 1}>
      <MenuItemAccordionOptionName>{name}</MenuItemAccordionOptionName>
      <MenuItemPriceCals price={displayPrice} cals={cals} />
    </MenuItemAccordionOptionButton>
  )
}

const MenuItemAccordionInstructionsView = styled.div`
  padding: ${(props) => props.theme.layout.padding};
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
  }
`

const MenuItemAccordionInstructionsContainer = styled.div`
  border-radius: ${(props) => props.theme.border.radius};
  padding: ${(props) => props.theme.layout.padding};
  background-color: ${(props) => props.theme.bgColors.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
  }
`

const MenuItemAccordionInstructionsInput = styled.div`
  width: 100%;
  margin: 0 0 2rem;

  label {
    margin: 0;
  }
`

const MenuItemAccordionInstructionsFooter = styled.div`
  margin: 3rem 0 0;
  text-align: center;
`

interface MenuItemAccordionInstructionsProps {
  hasMadeFor?: boolean
  madeFor: string | null
  setMadeFor: (madeFor: string) => void
  hasNotes?: boolean
  notes: string | null
  setNotes: (notes: string) => void
  allDone?: () => void
}

const MenuItemAccordionInstructions = ({
  hasMadeFor,
  madeFor,
  setMadeFor,
  hasNotes,
  notes,
  setNotes,
  allDone,
}: MenuItemAccordionInstructionsProps) => {
  return (
    <MenuItemAccordionInstructionsView>
      <MenuItemAccordionInstructionsContainer>
        {hasMadeFor && (
          <MenuItemAccordionInstructionsInput>
            {/* <MenuItemAccordionInstructionsTitle>
            Who is this order for?
          </MenuItemAccordionInstructionsTitle> */}
            <Input
              label="Who is this order for?"
              name="made-for"
              type="text"
              value={madeFor || ''}
              onChange={(evt) => setMadeFor(evt.target.value)}
            />
          </MenuItemAccordionInstructionsInput>
        )}
        {hasNotes && (
          <MenuItemAccordionInstructionsInput>
            {/* <MenuItemAccordionInstructionsTitle>
            Any special instructions?
          </MenuItemAccordionInstructionsTitle> */}
            <Input
              label="Any special instructions?"
              name="notes"
              type="text"
              value={notes || ''}
              onChange={(evt) => setNotes(evt.target.value)}
            />
          </MenuItemAccordionInstructionsInput>
        )}
        <MenuItemAccordionInstructionsFooter>
          <Button onClick={allDone}>All Done</Button>
        </MenuItemAccordionInstructionsFooter>
      </MenuItemAccordionInstructionsContainer>
    </MenuItemAccordionInstructionsView>
  )
}

const MenuItemAccordion = ({
  hasCustomize,
  builtItem,
  setQuantity,
  increment,
  decrement,
  toggleOption,
  setMadeFor,
  setNotes,
  displaySettings,
  cartId,
}: {
  hasCustomize?: boolean
  builtItem: CartItem
  setQuantity: (quantity: number) => void
  increment: () => void
  decrement: () => void
  toggleOption: (levels: CartLevels) => void
  setMadeFor: (modeFor: string) => void
  setNotes: (notes: string) => void
  displaySettings?: ConfigDisplaySetting
  cartId: number | null
}) => {
  const config = {
    hasMadeFor: !!displaySettings?.madeFor,
    hasNotes: !!displaySettings?.notes,
    hasCals: !!displaySettings?.calories,
    hasCustomize: !!hasCustomize,
  }
  const {
    open,
    setOpen,
    ingredients,
    madeFor,
    notes,
    hasMadeFor,
    hasNotes,
    hasInstructions,
    hasCals,
    nutritionalInfo,
    hasIngredients,
    instructionsTitle,
    sizeGroup,
    selectedSize,
    showAccordion,
    toggleSize,
  } = useAccordion(builtItem, toggleOption, cartId, config)

  if (!showAccordion) return <MenuItemAccordionPlaceholder />

  return (
    <MenuItemAccordionView>
      <MenuItemAccordionContainer>
        {!!sizeGroup && (
          <>
            <MenuItemAccordionRowButton
              name="SIZE"
              open={open}
              setOpen={setOpen}
            >
              <MenuItemAccordionLabel>
                Size
                {!selectedSize ? (
                  <MenuItemAccordionLabelRequired>
                    *
                  </MenuItemAccordionLabelRequired>
                ) : (
                  <MenuItemAccordionLabelCheckmark>
                    <Checkmark />
                  </MenuItemAccordionLabelCheckmark>
                )}
              </MenuItemAccordionLabel>
              <MenuItemAccordionToggle isOpen={open === 'SIZE'}>
                {selectedSize ? (
                  <MenuItemAccordionSelectedSize>
                    {selectedSize.name}
                  </MenuItemAccordionSelectedSize>
                ) : (
                  <MenuItemAccordionMissingSize>
                    Select Size...
                  </MenuItemAccordionMissingSize>
                )}
              </MenuItemAccordionToggle>
            </MenuItemAccordionRowButton>
            <MenuItemAccordionSection isOpen={open === 'SIZE'}>
              {sizeGroup?.options?.map((option) => (
                <MenuItemAccordionOption
                  key={option.id}
                  toggle={() => toggleSize(option.id)}
                  name={option.name}
                  price={option.price}
                  cals={option.cals}
                  quantity={option.quantity}
                />
              ))}
            </MenuItemAccordionSection>
          </>
        )}
        {hasCustomize && (
          <MenuItemAccordionRow>
            <MenuItemAccordionLabel>Quantity</MenuItemAccordionLabel>
            <MenuItemAccordionQuantity>
              <MenuItemQuantity
                item={builtItem}
                adjust={setQuantity}
                increment={increment}
                decrement={decrement}
              />
            </MenuItemAccordionQuantity>
          </MenuItemAccordionRow>
        )}
        {hasInstructions && (
          <>
            <MenuItemAccordionRowButton
              name="INSTRUCTIONS"
              open={open}
              setOpen={setOpen}
            >
              <MenuItemAccordionLabel>
                <span>{instructionsTitle}</span>
                {madeFor || notes ? (
                  <MenuItemAccordionLabelCheckmark>
                    <Checkmark />
                  </MenuItemAccordionLabelCheckmark>
                ) : null}
              </MenuItemAccordionLabel>
              <MenuItemAccordionToggle isOpen={open === 'INSTRUCTIONS'} />
            </MenuItemAccordionRowButton>
            <MenuItemAccordionSection isOpen={open === 'INSTRUCTIONS'}>
              <MenuItemAccordionInstructions
                hasMadeFor={hasMadeFor}
                madeFor={madeFor}
                setMadeFor={setMadeFor}
                hasNotes={hasNotes}
                notes={notes}
                setNotes={setNotes}
                allDone={() => setOpen(null)}
              />
            </MenuItemAccordionSection>
          </>
        )}
        {hasIngredients && (
          <>
            <MenuItemAccordionRowButton
              name="INGREDIENTS"
              open={open}
              setOpen={setOpen}
            >
              <MenuItemAccordionLabel>Ingredients</MenuItemAccordionLabel>
              <MenuItemAccordionToggle isOpen={open === 'INGREDIENTS'} />
            </MenuItemAccordionRowButton>
            <MenuItemAccordionSection isOpen={open === 'INGREDIENTS'}>
              <MenuItemIngredients ingredients={ingredients} />
            </MenuItemAccordionSection>
          </>
        )}
        {hasCals && (
          <>
            <MenuItemAccordionRowButton
              name="INFO"
              open={open}
              setOpen={setOpen}
            >
              <MenuItemAccordionLabel>
                Nutritional Information
              </MenuItemAccordionLabel>
              <MenuItemAccordionToggle isOpen={open === 'INFO'} />
            </MenuItemAccordionRowButton>
            <MenuItemAccordionSection isOpen={open === 'INFO'}>
              <MenuItemNutrition nutritionalInfo={nutritionalInfo} />
            </MenuItemAccordionSection>
          </>
        )}
      </MenuItemAccordionContainer>
    </MenuItemAccordionView>
  )
}

export default MenuItemAccordion
