import styled from '@emotion/styled'
import { slugify } from '@open-tender/utils'
import MenuItemFullOption from './MenuItemFullOption'
import MenuItemFullGroupHeader from './MenuItemFullGroupHeader'
import { CartItemGroups, CartLevels } from '@open-tender/types'

const MenuItemFullGroupsView = styled.div`
  label: MenuItemFullGroups;
`

const MenuItemFullGroupsList = styled.div<{ isChild?: boolean }>`
  ${(props) =>
    props.isChild
      ? `padding: 2rem 2rem 1rem;
      margin: 1rem 2rem;
      border-radius: ${props.theme.cards.modifier.borderRadius};
      background-color: ${props.theme.bgColors.tertiary};`
      : ``}
`

const MenuItemFullGroup = styled.div<{ name?: string }>`
  margin: 0 0 4rem;
  // margin: 0 0 ${(props) => props.theme.layout.margin};
  &:last-of-type {
    margin: 0;
  }
`

const MenuItemFullOptions = styled.div`
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`

const MenuItemFullGroups = ({
  groups,
  levels,
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
  isChild = false,
}: {
  groups: CartItemGroups
  levels: CartLevels
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  setOptionQuantity: (levels: CartLevels, quantity: number) => void
  isChild?: boolean
}) => {
  const nonSizeGroups = groups.filter((i) => !i.isSize)

  return (
    <MenuItemFullGroupsView>
      <MenuItemFullGroupsList isChild={isChild}>
        {nonSizeGroups.map((group) => (
          <MenuItemFullGroup
            key={group.id}
            id={slugify(group.name)}
            name="section"
          >
            <MenuItemFullGroupHeader group={group} isChild={isChild} />
            <MenuItemFullOptions>
              {group.options.map((option, index) => (
                <MenuItemFullOption
                  key={`${group.id}-${option.id}`}
                  index={index}
                  group={group}
                  option={option}
                  levels={levels}
                  toggleOption={toggleOption}
                  incrementOption={incrementOption}
                  decrementOption={decrementOption}
                  setOptionQuantity={setOptionQuantity}
                />
              ))}
            </MenuItemFullOptions>
          </MenuItemFullGroup>
        ))}
      </MenuItemFullGroupsList>
    </MenuItemFullGroupsView>
  )
}

export default MenuItemFullGroups
