import { useEffect } from 'react'
import {
  selectMenu,
  setOffsetTop,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { animateScroll as scroll } from 'react-scroll'
import styled from '@emotion/styled'
import { MenuCategory as MenuCategoryType } from '@open-tender/types'
import { slugify } from '@open-tender/utils'
import CategoryNav from '../Category/CategoryNav'
import MenuCategory from './MenuCategory'
import MenuNav from './MenuNav'
import { isMobile } from 'react-device-detect'
import MenuGroupOrder from './MenuGroupOrder'

const MenuScrollableView = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const MenuScrollableCategories = styled.div`
  flex: 1 1 auto;
  max-width: 100%;
`

const MenuScrollable = ({
  menuSections,
}: {
  menuSections: Partial<MenuCategoryType>[]
}) => {
  const dispatch = useAppDispatch()
  const { categories, offsetTop } = useAppSelector(selectMenu)
  const other = menuSections.map((i: any) => ({
    ...i,
    items: i.items.slice(0, 8),
  }))
  const navItems = [
    ...other.map((i) => i.name),
    ...categories.map((i) => i.name),
  ]
  const navType: any = isMobile ? 'TOP' : 'TOP'

  useEffect(() => {
    if (offsetTop) {
      scroll.scrollTo(offsetTop, {
        duration: 0,
        smooth: false,
        offset: 0,
      })
      dispatch(setOffsetTop(null))
    }
  }, [offsetTop, dispatch])

  return (
    <MenuScrollableView>
      {navType === 'LEFT' && <MenuNav items={navItems} />}
      <MenuScrollableCategories>
        {navType === 'TOP' && <CategoryNav items={navItems} />}
        <MenuGroupOrder />
        {other.map((section) => (
          <div
            key={section.name}
            id={slugify(section.name)}
            className="section"
          >
            <MenuCategory category={section} />
          </div>
        ))}
        {categories.map((category) => (
          <div
            key={category.id}
            id={slugify(category.name)}
            className="section"
          >
            <MenuCategory category={category} />
            {category.children.map((child) => (
              <MenuCategory key={child.id} category={child} isChild={true} />
            ))}
          </div>
        ))}
      </MenuScrollableCategories>
    </MenuScrollableView>
  )
}

export default MenuScrollable
