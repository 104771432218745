import {
  MenuItemNutritionView,
  MenuItemNutritionList,
} from './MenuItemNutrition'

const MenuItemIngredients = ({
  ingredients,
}: {
  ingredients: string | null
}) => {
  if (!ingredients) return null
  return (
    <MenuItemNutritionView>
      <MenuItemNutritionList>
        <p>{ingredients}</p>
      </MenuItemNutritionList>
    </MenuItemNutritionView>
  )
}

export default MenuItemIngredients
