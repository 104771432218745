import { isMobileOnly } from 'react-device-detect'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { makeSlides } from '@open-tender/utils'
import { BackgroundImage, BackgroundLoading, Slider, Video, News } from '.'
import { AnnouncementsState } from '@open-tender/cloud'
import { AnnouncementSettings } from '@open-tender/types'

interface BackgroundViewProps {
  isSlides?: boolean
}

const BackgroundView = styled.div<BackgroundViewProps>`
  position: fixed;
  z-index: ${(props) => (props.isSlides ? '1' : '-1')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 76.8rem;
  display: flex;
`
export type BackgroundProps = {
  imageUrl?: string
  videoUrl?: string
  announcements?: Partial<
    AnnouncementsState & { settings: AnnouncementSettings | null }
  >
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

const Background = ({
  imageUrl,
  videoUrl,
  announcements,
  children,
  style,
}: BackgroundProps) => {
  const { settings = null, entities, loading, error } = announcements || {}
  const slides = error || !entities ? null : makeSlides(entities, isMobile)
  const isLoading = loading === 'pending'
  const hideHero = !slides && !imageUrl
  const isNews = true
  if (isMobileOnly || hideHero) return null

  return (
    <BackgroundView isSlides={!!slides} style={style}>
      {videoUrl ? (
        <Video src={videoUrl} poster={imageUrl} />
      ) : isLoading ? (
        <BackgroundLoading />
      ) : announcements && isNews ? (
        <News announcements={announcements} />
      ) : slides ? (
        <Slider settings={settings} slides={slides} />
      ) : imageUrl ? (
        <BackgroundImage imageUrl={imageUrl}>{children}</BackgroundImage>
      ) : null}
    </BackgroundView>
  )
}

export default Background
