import styled from '@emotion/styled'
import { NutritionalInfo } from '@open-tender/types'
import {
  MenuItemIngredients,
  MenuItemNutrition,
  ModalContent,
  ModalView,
} from 'components'

const ItemInfoNutrition = styled.div`
  & > div {
    padding: 0;
  }
  & > div > ul {
    padding: 0;
  }
`

const ItemInfoIngredients = styled.div`
  margin: 2rem 0 0;
  & > div {
    padding: 0;
  }
  & > div > ul {
    padding: 0;
  }
`

export interface ItemInfoModalProps extends Record<string, unknown> {
  name: string
  description: string
  nutritionalInfo: NutritionalInfo
  ingredients: string
}

const ItemInfo = ({
  name,
  description,
  nutritionalInfo,
  ingredients,
}: ItemInfoModalProps) => {
  return (
    <ModalView>
      <ModalContent title={name} subtitle={<p>{description}</p>}>
        {nutritionalInfo && (
          <ItemInfoNutrition>
            <MenuItemNutrition nutritionalInfo={nutritionalInfo} />
          </ItemInfoNutrition>
        )}
        {ingredients && (
          <ItemInfoIngredients>
            <MenuItemIngredients ingredients={ingredients} />
          </ItemInfoIngredients>
        )}
      </ModalContent>
    </ModalView>
  )
}

export default ItemInfo
