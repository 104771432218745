import styled from '@emotion/styled'
import { BackgroundCta, Container } from 'components'
import Headline from 'components/Headline'

interface BackgroundContentViewProps {
  paddingVertical?: string
  alignItems: string
  textAlign: string
  justifyContent: string
}

const BackgroundContentView = styled.div<BackgroundContentViewProps>`
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: ${(props) => props.theme.layout.padding};
  padding-top: ${(props) =>
    props.paddingVertical || props.theme.layout.headerHeightSite};
  padding-bottom: ${(props) =>
    props.paddingVertical || props.theme.layout.headerHeightSite};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  text-align: ${(props) => props.textAlign};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
    padding-top: ${(props) =>
      props.paddingVertical || props.theme.layout.headerHeightSiteMobile};
    padding-bottom: ${(props) =>
      props.paddingVertical || props.theme.layout.headerHeightSiteMobile};
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    text-align: center;
  }
`

const BackgroundContentText = styled.div`
  max-width: 96rem;
  // padding: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    // padding: ${(props) => props.theme.layout.paddingMobile} 0;
  }
`

interface BackgroundContentTitleProps {
  textColor: string
  fontSize: string | null
}

const BackgroundContentTitle = styled(Headline)<BackgroundContentTitleProps>`
  color: #${(props) => props.textColor};
  font-size: ${(props) => props.fontSize || props.theme.fonts.sizes.mega};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.xBig};
  }
`

const BackgroundContentSubtitle = styled.p<BackgroundContentTitleProps>`
  margin: 0.5em 0;
  line-height: ${(props) => props.theme.fonts.body.lineHeight};
  color: #${(props) => props.textColor};
  font-size: ${(props) => props.fontSize || props.theme.fonts.sizes.xBig};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }
`

const makeAlignment = (alignment: string) => {
  switch (alignment) {
    case 'TOP':
    case 'LEFT':
      return 'flex-start'
    case 'BOTTOM':
    case 'RIGHT':
      return 'flex-end'
    default:
      return 'center'
  }
}
interface BackgroundContentProps {
  title: string
  subtitle: string
  title_color: string
  subtitle_color: string
  title_size?: string | number | null
  subtitle_size?: string | number | null
  vertical?: string
  horizontal?: string
  hide_text?: boolean
  children?: JSX.Element | JSX.Element[]
  url?: string | null
  url_text?: string | null
  paddingVertical?: string
}

const BackgroundContent = ({
  title,
  subtitle,
  url,
  url_text,
  title_color = 'ffffff',
  subtitle_color = 'ffffff',
  title_size = null,
  subtitle_size = null,
  vertical = 'BOTTOM',
  horizontal = 'CENTER',
  hide_text = false,
  paddingVertical,
  children,
}: BackgroundContentProps) => {
  if (!title && !subtitle) return null
  const justifyContent = makeAlignment(horizontal)
  const alignItems = makeAlignment(vertical)
  const titleSize = title_size
    ? `${((title_size as number) / 10).toFixed(1)}rem`
    : null
  const subtitleSize = subtitle_size
    ? `${((subtitle_size as number) / 10).toFixed(1)}rem`
    : null

  if (hide_text) return null

  return (
    <BackgroundContentView
      justifyContent={justifyContent}
      alignItems={alignItems}
      textAlign={horizontal}
      paddingVertical={paddingVertical}
    >
      <Container>
        <BackgroundContentText>
          {title && (
            <BackgroundContentTitle
              as="p"
              textColor={title_color.replace('#', '')}
              fontSize={titleSize}
            >
              {title}
            </BackgroundContentTitle>
          )}
          {subtitle && (
            <BackgroundContentSubtitle
              textColor={subtitle_color.replace('#', '')}
              fontSize={subtitleSize}
            >
              {subtitle}
            </BackgroundContentSubtitle>
          )}
          <BackgroundCta url={url ?? ''} urlText={url_text ?? ''}>
            {children}
          </BackgroundCta>
        </BackgroundContentText>
      </Container>
    </BackgroundContentView>
  )
}

export default BackgroundContent
