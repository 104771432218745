import { MenuVars } from '@open-tender/types'
import {
  closeModal,
  fetchMenu,
  revertMenu,
  selectContent,
  selectMenu,
  setCart,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  CartErrors as CartErrorsComponent,
  ModalContent,
  ModalView,
} from 'components'

const CartErrors = () => {
  const dispatch = useAppDispatch()
  const { cartErrors, previousMenuVars, menuVars } = useAppSelector(selectMenu)
  const { newCart, errors } = cartErrors || {}
  const { menu } = useAppSelector(selectContent) || {}

  const handleRevert = (menuVars: MenuVars) => {
    dispatch(revertMenu(menuVars))
    dispatch(fetchMenu({ ...menuVars }))
    dispatch(closeModal())
  }

  const handleProceed = () => {
    dispatch(setCart(newCart))
    dispatch(closeModal())
  }

  if (!menuVars || !previousMenuVars || !errors) return null

  return (
    <ModalView>
      <ModalContent
        title={menu?.cartErrors.title}
        subtitle={<p>{menu?.cartErrors.subtitle}</p>}
      >
        <CartErrorsComponent
          errors={errors}
          revert={handleRevert}
          proceed={handleProceed}
          previousMenuVars={previousMenuVars}
          menuVars={menuVars}
        />
      </ModalContent>
    </ModalView>
  )
}

export default CartErrors
