import styled from '@emotion/styled'
import { animateScroll as scroll } from 'react-scroll'
import { slugify } from '@open-tender/utils'

const MenuNavButtonView = styled.button<{ active?: boolean }>`
  label: MenuNavScrollButtonView;
  display: block;
  padding: 1rem 0;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.preface.family};
  font-weight: ${(props) => props.theme.fonts.preface.weight};
  font-style: ${(props) => props.theme.fonts.preface.fontStyle};
  letter-spacing: ${(props) => props.theme.fonts.preface.letterSpacing};
  text-transform: ${(props) => props.theme.fonts.preface.textTransform};
  -webkit-font-smoothing: ${(props) => props.theme.fonts.preface.fontSmoothing};
  font-size: ${(props) => props.theme.fonts.preface.fontSize};
  color: ${(props) => props.theme.links.dark.color};
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.links.dark.hover};
  }
`

export const MenuNavButton = ({
  name,
  active = false,
  offset = 0,
}: {
  name: string
  active?: boolean
  offset?: number
}) => {
  const id = slugify(name)

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.currentTarget.blur()
    const element = document.getElementById(id)
    const position = (element?.offsetTop ?? 0) + offset
    scroll.scrollTo(position, {
      duration: 500,
      smooth: true,
      offset: 0,
    })
    const items = element?.querySelectorAll('button')
    const firstItem = items?.length ? items[0] : null
    if (firstItem) firstItem.focus()
  }

  return (
    <MenuNavButtonView onClick={onClick} active={active}>
      {name}
    </MenuNavButtonView>
  )
}
