import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { ISOString, RequestedAt, Timezone } from '@open-tender/types'
import {
  currentLocalDate,
  isoToDate,
  makeFormErrors,
  makeReadableDateStrFromIso,
} from '@open-tender/utils'
import {
  fetchGroupOrder,
  fetchLocation,
  logoutCustomer,
  resetGroupOrder,
  resetOrder,
  selectBrand,
  selectCartTotal,
  selectCustomer,
  selectGroupOrder,
  selectRevenueCenter,
  selectTimezone,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  CartGuestForm,
  Content,
  FormWrapper,
  Header,
  Loading,
  Main,
  PageContainer,
  PageTitle,
  PageContent,
  HeaderLogoWithName,
} from 'components'
import GroupOrderError from './GroupOrderError'

const formatTime = (time: string) => {
  return time
    ? time.replace('Today', 'today').replace('Tomorrow', 'tomorrow')
    : ''
}

const makeSubtitle = (
  tz: Timezone,
  requestedAt: RequestedAt | null,
  cutoffAt: string | null
) => {
  const orderTime =
    requestedAt !== 'asap'
      ? requestedAt && tz
        ? makeReadableDateStrFromIso(requestedAt, tz, true)
        : null
      : requestedAt
  const cutoffTime =
    cutoffAt && tz ? makeReadableDateStrFromIso(cutoffAt, tz, true) : null
  if (orderTime === 'asap') {
    return 'This order is scheduled for ASAP and will be closed by the cart owner when all orders have been submitted.'
  } else if (orderTime && cutoffTime) {
    let subtitle = `This order is current scheduled for ${formatTime(
      orderTime
    )}`
    subtitle += cutoffTime
      ? `, and orders must be submitted by ${formatTime(cutoffTime)}.`
      : '.'
    return subtitle
  }
}

const makeTitle = (
  error: string,
  closed: boolean,
  pastCutoff: boolean,
  cartOwnerName: string,
  tz: Timezone,
  requestedAt: RequestedAt | null,
  cutoffAt: string | null,
  atCapacity: boolean
) => {
  const defaultTitle = {
    title: `Welcome to ${cartOwnerName}'s group order!`,
    subtitle: makeSubtitle(tz, requestedAt, cutoffAt),
  }
  if (error) {
    if (error.includes('The parameters of your request were invalid.')) {
      return defaultTitle
    } else if (error.includes('does not exist')) {
      return {
        title: 'Group order not found',
        subtitle:
          'Please double check the link you were sent and contact the group owner if necessary.',
      }
    }
    return { title: 'Group order closed', subtitle: error }
  } else if (closed) {
    return {
      title: 'Group order closed',
      subtitle: 'Sorry, but this group order has already been closed.',
    }
  } else if (pastCutoff) {
    return {
      title: 'Order cutoff time has passed',
      subtitle: 'Sorry, but this group order is no longer open to new guests.',
    }
  } else if (atCapacity) {
    return {
      title: 'Group order oversubscribed',
      subtitle:
        'Sorry, but the guest limit has already been reached for this group order.',
    }
  } else {
    return defaultTitle
  }
}

const GroupOrderGuestIntro = styled.div`
  text-align: center;

  p {
    margin: 1em 0;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`

const GroupOrderGuest = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { token } = useParams()
  const tokenLower = token ? token.toLowerCase() : null
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const {
    revenueCenterId,
    isCartOwner,
    cartOwner,
    cartGuest,
    cartId,
    closed,
    loading,
    error,
    requestedAt,
    cutoffAt,
    spendingLimit,
    guestLimit,
    guestCount,
    token: currentToken,
  } = useAppSelector(selectGroupOrder)
  const currentTokenLower = currentToken ? currentToken.toLowerCase() : null
  const isLoading = loading === 'pending'
  const cartOwnerName = cartOwner
    ? `${cartOwner.first_name} ${cartOwner.last_name}`
    : ''
  const { cartGuestId } = cartGuest || {}
  const revenueCenter = useAppSelector(selectRevenueCenter)
  const { slug } = revenueCenter || {}
  const tz = useAppSelector(selectTimezone)
  const cartTotal = useAppSelector(selectCartTotal).toFixed(2)
  const { profile } = useAppSelector(selectCustomer)
  const cutoffDate = cutoffAt ? isoToDate(cutoffAt as ISOString, tz) : null
  const pastCutoff = cutoffDate ? currentLocalDate(tz) > cutoffDate : false
  const spotsRemaining = guestLimit ? guestLimit - (guestCount ?? 0) : null
  const atCapacity = spotsRemaining !== null && spotsRemaining <= 0
  const errors = makeFormErrors(error)
  const { title, subtitle } = makeTitle(
    errors.form,
    closed,
    pastCutoff,
    cartOwnerName,
    tz,
    requestedAt,
    cutoffAt,
    atCapacity
  )
  const hasError = error || closed || pastCutoff || atCapacity

  useEffect(() => {
    if (isCartOwner) {
      navigate(`/menu/${slug}`)
    } else if (!cartId && tokenLower && currentTokenLower !== tokenLower) {
      if (profile) dispatch(logoutCustomer(undefined))
      dispatch(resetGroupOrder())
      dispatch(fetchGroupOrder(tokenLower))
    }
  }, [
    tokenLower,
    currentTokenLower,
    cartId,
    isCartOwner,
    profile,
    slug,
    navigate,
    dispatch,
  ])

  useEffect(() => {
    if (revenueCenterId) {
      dispatch(fetchLocation({ revenueCenterId, cartTotal: cartTotal }))
    }
  }, [cartTotal, dispatch, revenueCenterId])

  useEffect(() => {
    if (cartGuestId && slug && currentTokenLower === tokenLower) {
      navigate(`/menu/${slug}`)
    }
  }, [navigate, cartGuestId, slug, currentTokenLower, tokenLower])

  useEffect(() => {
    if (
      cartId &&
      currentTokenLower &&
      tokenLower &&
      currentTokenLower !== tokenLower
    ) {
      dispatch(resetGroupOrder())
      dispatch(resetOrder())
    }
  }, [cartId, currentTokenLower, tokenLower, dispatch])

  const startOver = () => {
    dispatch(resetGroupOrder())
    dispatch(resetOrder())
    navigate('/guest')
  }

  return (
    <>
      <Helmet>
        <title>Join Group Order | {siteTitle}</title>
      </Helmet>
      <Content>
        <Header title={<HeaderLogoWithName />} />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle title={title} subtitle={subtitle} />
            {hasError ? (
              <PageContent>
                <GroupOrderError
                  cartId={cartId}
                  error={error}
                  closed={closed}
                  pastCutoff={pastCutoff}
                  atCapacity={atCapacity}
                  cartOwnerName={cartOwnerName}
                />
                <p>
                  <Button onClick={startOver}>Start A New Order</Button>
                </p>
              </PageContent>
            ) : isLoading && !cartId ? (
              <Loading text="Retrieving group order info..." />
            ) : (
              <FormWrapper>
                <GroupOrderGuestIntro>
                  <p>
                    {spotsRemaining && (
                      <span>Only {spotsRemaining} spots left! </span>
                    )}{' '}
                    {spendingLimit && (
                      <span>
                        There is a spending limit of ${spendingLimit} for this
                        order.
                      </span>
                    )}
                  </p>
                  <p>Please enter a first and last name to get started.</p>
                </GroupOrderGuestIntro>
                <CartGuestForm />
              </FormWrapper>
            )}
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default GroupOrderGuest
