import { useTheme } from '@emotion/react'
import { ClipLoader } from 'react-spinners'

const ImageSpinner = ({
  size = 30,
  color,
  loading = true,
}: {
  size?: number
  color?: string
  loading?: boolean
}) => {
  const theme = useTheme()
  return (
    <ClipLoader
      color={color || theme.colors.primary}
      size={size}
      loading={loading}
    />
  )
}

export default ImageSpinner
