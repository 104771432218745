import styled from '@emotion/styled'
import { ThemeFontSize } from '@open-tender/types'
import { ColorName } from 'utils/types'

const Headline = styled.span<{
  size?: ThemeFontSize
  color?: ColorName | string
}>`
  ${({ theme, size, color }) => `
    font-family: ${theme.fonts.headline.family};
    font-weight: ${theme.fonts.headline.weight};
    font-style: ${theme.fonts.headline.fontStyle};
    letter-spacing: ${theme.fonts.headline.letterSpacing};
    line-height: ${theme.fonts.headline.lineHeight};
    text-transform: ${theme.fonts.headline.textTransform};
    -webkit-font-smoothing: ${theme.fonts.headline.fontSmoothing};
    color: ${color ? theme.colors[color] : theme.fonts.headline.color};
    ${size ? `font-size: ${theme.fonts.sizes[size]};` : ''}
  `}
`

export default Headline
