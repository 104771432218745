import styled from '@emotion/styled'
import Heading from 'components/Heading'

export const UpsellItemView = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.menuItems.box.padding};
  border-style: solid;
  border-width: ${(props) => props.theme.menuItems.box.border};
  border-color: ${(props) => props.theme.menuItems.box.borderColor};
  border-radius: ${(props) => props.theme.menuItems.box.borderRadius};
  background-color: ${(props) => props.theme.menuItems.box.bgColor};
  box-shadow: ${(props) => props.theme.menuItems.box.boxShadow};
`

export const UpsellItemButtons = styled.div`
  flex-grow: 0;
  padding: ${(props) => props.theme.menuItems.buttons.padding};
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.menuItems.buttons.paddingMobile};
    padding-left: 0;
    padding-right: 0;
  }

  button {
    display: block;
  }
`

export const UpsellItemSizes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.25rem -0.5rem;

  button {
    margin: 0.25rem 0.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`

export const UpsellItemCardView = styled.div`
  // flex-grow: 1;
  display: block;
  width: 100%;
`

export const UpsellItemContainer = styled.div`
  // height: 100%;
  display: flex;
  flex-direction: column;
`

export const UpsellItemImage = styled.div`
  display: flex;
  overflow: hidden;
  align-self: flex-start;
  aspect-ratio: ${(props) =>
    props.theme.menuItems.image.asImg ? 'auto' : '1.5'};
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  padding: ${(props) => props.theme.menuItems.image.padding};
  border-style: solid;
  border-width: ${(props) => props.theme.menuItems.image.border};
  border-radius: ${(props) => props.theme.menuItems.image.borderRadius};
  border-color: ${(props) => props.theme.menuItems.image.borderColor};
  box-shadow: ${(props) => props.theme.menuItems.image.boxShadow};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.menuItems.image.paddingMobile};
    border-width: ${(props) => props.theme.menuItems.image.borderMobile};
    border-radius: ${(props) => props.theme.menuItems.image.borderRadiusMobile};
  }
`

export const UpsellItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 0.6rem;
  }
`

export const UpsellItemName = styled(Heading)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.menuItems.title.color};
  font-size: ${(props) => props.theme.fonts.sizes.main};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }
`

export const UpsellItemPriceCals = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UpsellItemPriceSep = styled.span`
  display: block;
  width: 8px;
  height: 1px;
  margin: 0 3px;
  background-color: ${(props) => props.theme.menuItems.text.color};
`

export const UpsellItemPrice = styled(Heading)`
  color: ${(props) => props.theme.menuItems.price.color};
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

export const UpsellItemCals = styled(Heading)`
  color: ${(props) => props.theme.menuItems.calories.color};
  font-size: ${(props) => props.theme.fonts.sizes.small};
`
