import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import {
  selectBrand,
  selectContent,
  selectCustomer,
  useAppSelector,
} from '@open-tender/cloud'
import {
  AllergenForm,
  Content,
  FormWrapper,
  HeaderUser,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
} from 'components'

const AllergenFormView = styled.div`
  max-width: 48rem;
  margin: 0 auto;
`

const AccountAllergens = () => {
  const navigate = useNavigate()
  const { title: siteTitle, has_allergens } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)
  const { allergens: config } = useAppSelector(selectContent) || {}

  useEffect(() => {
    if (!auth || !has_allergens) return navigate('/account')
  }, [auth, has_allergens, navigate])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderUser />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config} />
            <PageContent>
              <AllergenFormView>
                <FormWrapper>
                  <AllergenForm />
                </FormWrapper>
              </AllergenFormView>
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default AccountAllergens
