import styled from '@emotion/styled'
import { AnnouncementHorizontal } from '@open-tender/types'
import BgImage from 'components/BgImage'
import Body from 'components/Body'
import Box from 'components/Box'

import Heading from 'components/Heading'

export const NewsView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin: ${(props) => props.theme.layout.margin} 0;
  // gap: ${(props) => props.theme.layout.margin};
  gap: ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: ${(props) => props.theme.layout.paddingMobile};
  }
`

export const NewsCard = styled(Box)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 24rem;
  }
`

export const NewsImage = styled(BgImage)`
  flex: 1;
  width: 100%;
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

export const NewsContent = styled.div<{ align: AnnouncementHorizontal }>`
  flex: 0 0 6rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 1.5rem 1.2rem;
  }
`

export const NewsDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const NewsTitle = styled(Heading)<{ color: string }>`
  color: #${(props) => props.color};
`

export const NewsSubtitle = styled(Body)<{ color: string }>`
  color: #${(props) => props.color};
`
