import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import {
  selectNavSite,
  toggleNavSite,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'

const NavSiteOverlayContainer = styled.div`
  position: fixed;
  z-index: 16;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.overlay.dark};
`

const NavSiteOverlay = () => {
  const dispatch = useAppDispatch()
  const { isOpen } = useAppSelector(selectNavSite)
  return (
    <TransitionGroup component={null}>
      {isOpen ? (
        <CSSTransition key="nav-overlay" classNames="md" timeout={250}>
          <NavSiteOverlayContainer onClick={() => dispatch(toggleNavSite())} />
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

export default NavSiteOverlay
