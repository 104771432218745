import { useEffect, useMemo } from 'react'
import { Global } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { RequestStatus } from '@open-tender/types'
import {
  fetchConfig,
  incrementRetries,
  selectConfigRetries,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Loading } from '../..'
import {
  FatalErrorView,
  LoadingContainer,
  makeStyles,
} from './ErrorFatal.styled'

interface ErrorFatalPageProps {
  error: any
  loading?: RequestStatus
}

const ErrorFatalPage = ({ error, loading }: ErrorFatalPageProps) => {
  const dispatch = useAppDispatch()
  const retries = useAppSelector(selectConfigRetries)
  const { status, title, detail, message } = error || {}
  const errMsg = detail || message || JSON.stringify(error)
  const is500 = Number.isInteger(status) && status >= 500
  const isLoading = loading === 'pending'
  const isRetrying = is500 && retries < 5
  const showLoading = isLoading || isRetrying
  const memoizedError = useMemo(() => error, [error])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (is500 && retries < 5) {
        setTimeout(() => {
          const baseUrl = process.env.REACT_APP_API_URL
          const authUrl = process.env.REACT_APP_AUTH_URL
          const clientId = process.env.REACT_APP_CLIENT_ID
          const brandId = process.env.REACT_APP_BRAND_ID
          const options = { baseUrl, authUrl, clientId, brandId }
          dispatch(fetchConfig(options))
          dispatch(incrementRetries())
        }, 1000 * (retries + 1))
      } else if (memoizedError && status !== 403) {
        Sentry.withScope((scope) => {
          scope.setExtras(memoizedError)
          const errType = is500 ? '500' : 'Fatal'
          const err = new Error(`${errType} Error - ${errMsg}`)
          Sentry.captureException(err)
        })
      }
    }
  }, [errMsg, memoizedError, isLoading, is500, retries, dispatch, status])

  if (!showLoading && !error) return null

  return (
    <>
      <Global styles={makeStyles} />
      {showLoading ? (
        <LoadingContainer>
          <Loading type="Clip" size={32} />
          {isRetrying ? <p>Trying to connect with our servers...</p> : null}
        </LoadingContainer>
      ) : status === 403 ? (
        <FatalErrorView>
          <div>
            <h1>Inactive Merchant</h1>
            <p>
              This merchant account is no longer active. Please contact the
              restaurant brand if you think this is a mistake.
            </p>
          </div>
        </FatalErrorView>
      ) : error ? (
        <FatalErrorView>
          <div>
            <h1>Unsupported Browser</h1>
            <p>
              Hi, there. Unfortunately it looks like you're using an outdated,
              unsupported browser.
            </p>
            <p>
              <strong>
                Please try ordering on your phone, updating your browser to the
                latest version, or downloading the latest version of Google
                Chrome.
              </strong>
            </p>
            <p>
              If you've updated your browser and the issue persists, please
              contact Open Tender support at{' '}
              <a
                href="mailto:help@opentender.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                help@opentender.io
              </a>{' '}
              and provide the details of the error message below.
            </p>
            <pre>
              <h2>{title}</h2>
              {errMsg}
            </pre>
          </div>
        </FatalErrorView>
      ) : null}
    </>
  )
}

export default ErrorFatalPage
