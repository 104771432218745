import { useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  closeModal,
  selectResetPassword,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Helmet } from 'react-helmet'
import { selectBrand, selectContent, selectCustomer } from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
  ResetPasswordForm,
} from 'components'

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { hash } = useLocation()
  const resetToken = hash.includes('#') ? hash.split('#')[1] : ''
  const { auth } = useAppSelector(selectCustomer)
  const { resetPassword: config } = useAppSelector(selectContent) || {}
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { success } = useAppSelector(selectResetPassword)

  useEffect(() => {
    dispatch(closeModal())
  }, [dispatch])

  useEffect(() => {
    if (auth || !resetToken) return navigate('/account')
  }, [auth, resetToken, navigate])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config} />
            <PageContent>
              <ResetPasswordForm resetToken={resetToken} />
              {!success && (
                <div style={{ margin: '3rem 0' }}>
                  <p>
                    <Link to="/account">{config?.back}</Link>
                  </p>
                </div>
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default ResetPassword
