import { ISOString, TimezonePython } from '@open-tender/types'
import { timezoneMap, makeRequestedAtStr } from '@open-tender/utils'

const OrderRequestedAt = ({
  estimated_at,
  requested_time,
  timezone,
  is_asap,
  status,
}: {
  estimated_at: ISOString
  requested_time: string
  timezone: TimezonePython
  is_asap: boolean
  status: string
}) => {
  const tz = timezone && timezoneMap[timezone]
  let estimatedAt = estimated_at && makeRequestedAtStr(estimated_at, tz, true)
  let msg = '(give or take a few minutes)'
  if (requested_time && requested_time.includes('-')) {
    const [dateStr] = estimatedAt.split('@')
    if (dateStr) estimatedAt = `${dateStr} @ ${requested_time.toLowerCase()}`
    msg = ''
  }
  return is_asap && status === 'OPEN' ? (
    <>
      <p>ASAP</p>
      <p>
        {estimatedAt} {msg}
      </p>
    </>
  ) : (
    <p>{estimatedAt}</p>
  )
}

export default OrderRequestedAt
