import { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

type ColorName =
  | 'alert'
  | 'allergens'
  | 'dark'
  | 'error'
  | 'light'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'tertiary'
  | 'toast'

type CircleProps = PropsWithChildren<{
  strokeWidth?: number
  width?: number | string
  color?: ColorName
  style?: React.CSSProperties
  className?: string
}>

const CircleView = styled.div<{
  strokeWidth?: number
  width?: number | string
  color?: ColorName
}>`
  aspect-ratio: 1;
  width: ${({ width }) => width}px;
  height: fit-content;
  border-radius: 1000px;
  border-style: solid;
  border-width: ${({ strokeWidth }) => strokeWidth}px;
  border-color: ${({ theme, color }) => theme.colors[color ?? 'primary']};
  overflow: hidden;
`

const Circle: FC<CircleProps> = ({
  children,
  width = '100%',
  strokeWidth = 7,
  color = 'primary',
  style,
  className,
}) => {
  return (
    <CircleView
      width={width}
      strokeWidth={strokeWidth}
      color={color}
      style={style}
      className={className}
    >
      {children}
    </CircleView>
  )
}

export default Circle
