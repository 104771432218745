import styled from '@emotion/styled'
import { Heading, Headline } from 'components'

const WelcomeView = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.125s forwards;
  padding: 0 ${(props) => props.theme.layout.padding};
  padding-top: ${(props) => props.theme.welcome.desktop.marginTop};
  padding-bottom: ${(props) => props.theme.welcome.desktop.marginBottom};
  text-align: ${(props) => props.theme.welcome.desktop.textAlign};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    padding-top: ${(props) => props.theme.welcome.mobile.marginTop};
    text-align: ${(props) => props.theme.welcome.mobile.textAlign};
  }
`

const WelcomeOverline = styled(Heading)`
  font-size: ${(props) => props.theme.welcome.desktop.titleSize};
  margin-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.welcome.mobile.titleSize};
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }
`

const WelcomeTitle = styled(Headline)`
  line-height: ${(props) => props.theme.welcome.desktop.titleLineHeight};
  font-size: ${(props) => props.theme.welcome.desktop.titleSize};
  margin-left: ${(props) => props.theme.welcome.desktop.titleMarginLeft};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.welcome.mobile.titleSize};
    line-height: ${(props) => props.theme.welcome.mobile.titleLineHeight};
    margin-left: ${(props) => props.theme.welcome.mobile.titleMarginLeft};
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }
`

const WelcomeSubtitle = styled.p`
  line-height: ${(props) => props.theme.welcome.desktop.subtitleLineHeight};
  font-size: ${(props) => props.theme.welcome.desktop.subtitleSize};
  margin-top: ${(props) => props.theme.welcome.desktop.subtitleMarginTop};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.welcome.mobile.subtitleSize};
    line-height: ${(props) => props.theme.welcome.mobile.subtitleLineHeight};
    margin-top: ${(props) => props.theme.welcome.mobile.subtitleMarginTop};
  }
`

interface WelcomeProps {
  overline?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
}

const Welcome = ({ title, subtitle, overline }: WelcomeProps) => {
  return (
    <WelcomeView>
      {overline && (
        <>
          <WelcomeOverline color="alert">{overline}</WelcomeOverline>
          <br />
        </>
      )}
      <WelcomeTitle>{title}</WelcomeTitle>
      {subtitle && <WelcomeSubtitle>{subtitle}</WelcomeSubtitle>}
    </WelcomeView>
  )
}

export default Welcome
