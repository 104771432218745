import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  selectCurrentItem,
  setCurrentItem,
  toggleSidebarModal,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { MenuItem } from '../..'

const ItemSidebarView = styled.aside`
  position: fixed;
  z-index: 18;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${(props) => props.theme.item.desktop.maxWidth};
  max-width: 100%;
  background-color: ${(props) => props.theme.bgColors.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: ${(props) => props.theme.item.mobile.maxWidth};
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
`

const ItemSidebar = React.forwardRef(
  (props, ref: React.LegacyRef<HTMLElement> | undefined) => {
    const dispatch = useAppDispatch()
    const item = useAppSelector(selectCurrentItem)
    const [imageUrl, setImageUrl] = useState(item ? item.imageUrl : null)
    const imageUrlWithFallback = (imageUrl || item?.imageUrl) ?? null

    const cancel = useCallback(() => {
      dispatch(toggleSidebarModal())
      setTimeout(() => {
        dispatch(setCurrentItem(null))
      }, 275)
    }, [dispatch])

    useEffect(() => {
      return () => {
        dispatch(setCurrentItem(null))
      }
    }, [dispatch])

    useEffect(() => {
      if (item?.imageUrl) {
        setImageUrl(item.imageUrl)
      }
    }, [item?.imageUrl])

    if (!item) return null

    return (
      <ItemSidebarView ref={ref}>
        <div>
          <MenuItem
            cancel={cancel}
            imageUrlWithFallback={imageUrlWithFallback}
            setImageUrl={setImageUrl}
          />
        </div>
      </ItemSidebarView>
    )
  }
)

export default ItemSidebar
