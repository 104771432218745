import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from '../icons'
import { Icon } from '.'
import Button from 'components/Button'
import { isMobile } from 'react-device-detect'

const Back = ({
  path = '/account',
  label = 'Back',
  onClick,
  text,
}: {
  path?: string
  label?: string
  onClick?: () => void
  text?: string | null
}) => {
  const navigate = useNavigate()
  const back = () => navigate(path)

  return text ? (
    <Button
      icon={<ArrowLeft size={22} strokeWidth={2} />}
      onClick={onClick || back}
      color="header"
      size="small"
    >
      {!isMobile && <>&nbsp;{text}</>}
    </Button>
  ) : (
    <Icon aria-label={label} margin="0 0 0 -1.25rem" onClick={onClick || back}>
      <ArrowLeft size={24} />
    </Icon>
  )
}

export default Back
