import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { GroupOrderExpanded, GroupOrdersExpanded } from '@open-tender/types'
import {
  OrderCardGroup,
  OrderCardGroupSimple,
  ScrollableSection,
} from 'components'
import AccountSection from './AccountSection'

const OrderCardGroupItem = ({ item }: { item: GroupOrderExpanded }) => (
  <OrderCardGroup order={item} />
)

const OrderCardGroupSimpleItem = ({ item }: { item: GroupOrderExpanded }) => (
  <OrderCardGroupSimple order={item} />
)

const AccountGroupOrders = ({ orders }: { orders: GroupOrdersExpanded }) => {
  const account = useAppSelector(selectContentSection('account'))
  const { title } = account?.groupOrders || {}
  const useSimple = true
  const displayed = orders.slice(0, 5)

  return (
    <AccountSection>
      <ScrollableSection
        title={title}
        items={displayed}
        renderItem={useSimple ? OrderCardGroupSimpleItem : OrderCardGroupItem}
        keyName="cart_id"
      />
    </AccountSection>
  )
}

export default AccountGroupOrders
