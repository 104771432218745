import styled from '@emotion/styled'
import {
  selectCustomerPointsProgram,
  selectOrder,
  useAppSelector,
} from '@open-tender/cloud'
import { Star } from 'components/icons'
import { MenuItemPriceCals, Points } from 'components'
import { ThemeFontSize } from '@open-tender/types'

const MenuItemPoints = styled.span`
  display: inline-block;
  margin: 0 0 0 1.5rem;
`

const MenuItemPrice = ({
  price,
  cals,
  points,
  size,
  sizePoints,
  sizePointsMobile,
  style = {},
  children,
}: {
  price: string | null
  cals: number | null
  points?: number | null
  size?: ThemeFontSize
  sizePoints?: string
  sizePointsMobile?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}) => {
  const { orderType } = useAppSelector(selectOrder)
  const pointsProgram = useAppSelector(selectCustomerPointsProgram(orderType))

  return (
    <MenuItemPriceCals price={price} cals={cals} size={size} style={style}>
      {!!pointsProgram && points ? (
        <MenuItemPoints>
          <Points
            points={points}
            icon={<Star size={12} strokeWidth={2} />}
            title="Points can be applied at checkout"
            size={sizePoints}
            sizeMobile={sizePointsMobile}
          />
        </MenuItemPoints>
      ) : null}
      {children}
    </MenuItemPriceCals>
  )
}

export default MenuItemPrice
