import styled from '@emotion/styled'

export const RequestedAtDateTimeView = styled.div`
  label: RequestedAtDateTimeView;
  text-align: center;
`

export const RequestedAtDateTimeTitle = styled.p`
  width: 100%;
  margin: 0 0 1rem;
  text-align: center;
  line-height: ${(props) => props.theme.lineHeight};
  font-size: ${(props) => props.theme.fonts.sizes.h3};
`

export const RequestedAtDateTimeAsap = styled.div`
  label: RequestedAtDateTimeAsap;
  text-align: center;

  button {
    min-width: 32rem;
    max-width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      min-width: 100%;
    }
  }
`

export const RequestedAtDateTimeSelects = styled.div`
  label: RequestedAtDateTimeSelects;

  width: 100%;
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
  }

  & > div + div {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin: 2rem 0 0;
    }
  }
`

export const RequestedAtDateTimeSelect = styled.div`
  width: 47.5%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }

  select {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

export const RequestedAtDateTimeNevermind = styled.div`
  margin: 3rem 0 0;

  button {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`
