import styled from '@emotion/styled'

const ItemCardsView = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -0.5rem;
  }
`
const ItemCardsItem = styled.div`
  width: 22rem;
  max-width: 100%;
  padding: 0 1rem;
  margin: 0 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 50%;
    padding: 0 0.5rem;
  }
`

const ItemCards = <T extends { id: string }>({
  items,
  renderItem,
}: {
  items: T[]
  delay: number
  sequential: boolean
  renderItem: (item: any) => JSX.Element
}) => {
  return (
    <ItemCardsView>
      {items.map((item, index) => {
        return (
          <ItemCardsItem key={`${item.id}-${index}`}>
            {renderItem({ item })}
          </ItemCardsItem>
        )
      })}
    </ItemCardsView>
  )
}

export default ItemCards
