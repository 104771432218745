import { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  closeModal,
  resetPasswordReset,
  resetLoginError,
  selectCustomer,
  selectResetPassword,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { SendResetForm, Button, ModalContent, ModalView } from 'components'

const messaging: {
  [key: string]: { title: string; subtitle: string }
} = {
  reset: {
    title: 'Reset your password',
    subtitle: 'Please enter the email address associated with your account',
  },
  resetSent: {
    title: 'Password reset email sent',
    subtitle:
      'A reset password email was just sent to the email address you provided. Please check your inbox and click on the link in the email in order to reset your password.',
  },
}

const ForgotPasswordClose = styled.div`
  label: LoginModalClose;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ForgotPassword = ({ callback }: { callback?: () => void }) => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector(selectCustomer)
  const { resetSent } = useAppSelector(selectResetPassword)
  const mode = resetSent ? 'resetSent' : 'reset'
  const { title, subtitle } = messaging[mode]

  useEffect(() => {
    if (profile) dispatch(closeModal())
    dispatch(resetPasswordReset())
    dispatch(resetLoginError())
    return () => {
      dispatch(resetPasswordReset())
      dispatch(resetLoginError())
    }
  }, [profile, dispatch])

  return (
    <ModalView>
      <ModalContent title={title} subtitle={<p>{subtitle}</p>}>
        {resetSent ? (
          <ForgotPasswordClose>
            <Button onClick={() => dispatch(closeModal())}>Close</Button>
          </ForgotPasswordClose>
        ) : (
          <SendResetForm callback={callback} />
        )}
      </ModalContent>
    </ModalView>
  )
}

export default ForgotPassword
