import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { ServiceType } from '@open-tender/types'
import {
  removeCustomerGroupOrder,
  resetOrder,
  selectBrand,
  selectCateringOnly,
  selectContent,
  selectGroupOrder,
  selectHasCatering,
  selectOrder,
  setServiceType,
  setOrderType,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Background,
  Button,
  ButtonLink,
  Content,
  Header,
  HeaderGuest,
  HtmlContent,
  Main,
  Message,
  PageTitle,
  ScreenreaderTitle,
} from 'components'
import { Back, NavMenu } from 'components/buttons'
import CateringAutocomplete from './CateringAutocomplete'

const CateringView = styled.div`
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

const CateringContent = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.125s forwards;
  position: relative;
  z-index: 2;
`

const CateringError = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.125s forwards;
  margin: -3rem 0 0;

  & > p {
    width: 100%;
    margin: 0 0 3rem;
  }
`

const CateringButtons = styled.div`
  margin: 2rem 0 0;

  button {
    margin: 0 1rem 0 0;
  }
`

const CateingContent = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

const CateringStartOver = styled.div`
  margin: 3rem 0 0;

  button {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const GroupOrderButtons = styled.div`
  button {
    margin: 0 1rem 0 0;
  }
`

const CateringPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const hasCatering = useAppSelector(selectHasCatering)
  const cateringOnly = useAppSelector(selectCateringOnly)
  const { catering: config, guest } = useAppSelector(selectContent) || {}
  const { showGuest } = guest || {}
  const showGuestHeader = !showGuest && cateringOnly
  const { title, subtitle, background, content } = config || {}
  const { orderType, address } = useAppSelector(selectOrder)
  const hasContent = !!(content && content.length)
  const { cartId } = useAppSelector(selectGroupOrder)

  useEffect(() => {
    if (!orderType) dispatch(setOrderType('CATERING'))
  }, [orderType, dispatch])

  const chooseServiceType = (serviceType: ServiceType) => {
    dispatch(setServiceType(serviceType))
    navigate('/locations')
  }

  const startOver = () => {
    dispatch(resetOrder())
    navigate(`/order-type`)
  }

  const cancelGroupOrder = () => {
    dispatch(removeCustomerGroupOrder({ cartId: cartId ?? 0 }))
  }

  return (
    <>
      <Helmet>
        <title>
          {title} | {siteTitle}
        </title>
      </Helmet>
      <Background imageUrl={background} />
      <Content maxWidth="76.8rem">
        {showGuestHeader ? (
          <HeaderGuest />
        ) : (
          <Header
            maxWidth="76.8rem"
            borderColor="transparent"
            title={isMobile ? 'Order Catering' : undefined}
            left={
              <Back label="Back to Order Type Selection" path="/order-type" />
            }
            right={<NavMenu />}
          />
        )}
        <Main>
          <ScreenreaderTitle>Catering</ScreenreaderTitle>
          <CateringView>
            <PageTitle
              title={title}
              subtitle={subtitle}
              style={{ textAlign: 'left', maxWidth: '100%' }}
            />
            {cartId !== null && (
              <GroupOrderButtons>
                <Button
                  onClick={() => navigate('/locations')}
                  size={isMobile ? 'small' : 'default'}
                >
                  Continue Group Order
                </Button>
                <Button
                  onClick={() => cancelGroupOrder()}
                  size={isMobile ? 'small' : 'default'}
                  color="secondary"
                >
                  Cancel Group Order
                </Button>
              </GroupOrderButtons>
            )}
            {hasCatering ? (
              <CateringContent>
                <CateringAutocomplete />
                <CateringButtons>
                  <Button
                    onClick={() => chooseServiceType('DELIVERY')}
                    disabled={!address || cartId !== null}
                    size={isMobile ? 'small' : 'default'}
                  >
                    Order Delivery
                  </Button>
                  <Button
                    onClick={() => chooseServiceType('PICKUP')}
                    disabled={!address || cartId !== null}
                    size={isMobile ? 'small' : 'default'}
                    color="secondary"
                  >
                    Order Pickup
                  </Button>
                </CateringButtons>
                {!cateringOnly && (
                  <CateringStartOver>
                    <ButtonLink onClick={startOver}>
                      Switch to a regular Pickup or Delivery order
                    </ButtonLink>
                  </CateringStartOver>
                )}
              </CateringContent>
            ) : (
              <CateringError>
                <Message color="alert" as="p">
                  This merchant isn't currently accepting catering orders.
                </Message>
                <Button onClick={startOver}>Start Over</Button>
              </CateringError>
            )}
            {hasContent && (
              <CateingContent>
                <HtmlContent content={content} />
              </CateingContent>
            )}
          </CateringView>
        </Main>
      </Content>
    </>
  )
}

export default CateringPage
