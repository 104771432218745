import React, { ErrorInfo } from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'
import ErrorReport from './pages/ErrorReport'
import packageJson from '../../package.json'
import { AppState } from '@open-tender/cloud/dist/esm/app'
import { Customer } from '@open-tender/types'

interface ErrorBoundaryState {
  hasError: boolean
  error: string
  errorInfo: ErrorInfo | null
  eventId: string | null
}

interface ErrorBoundaryProps {
  user: Customer | null
  children: JSX.Element | JSX.Element[]
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: '',
    errorInfo: null,
    eventId: null,
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo })
    Sentry.withScope((scope) => {
      const extras = { version: packageJson.version, ...errorInfo }
      scope.setExtras(extras)
      if (this.props.user) scope.setUser(this.props.user)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { hasError, error, errorInfo, eventId } = this.state
    return hasError ? (
      <ErrorReport error={error} errorInfo={errorInfo} eventId={eventId} />
    ) : (
      this.props.children
    )
  }
}

export default connect(
  (state: AppState) => ({
    user: state ? state.customer.account.profile : null,
  }),
  {}
)(ErrorBoundary)
