import styled from '@emotion/styled'

const AccountSection = styled.div<{ withPadding?: boolean }>`
  width: 100%;
  padding: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile} 0;
  }
`

export default AccountSection
