import {
  FormWrapper,
  OneTimePasscodeForm,
  PageContainer,
  PageTitle,
} from 'components'
import CheckoutSignInFooter from './CheckoutSignInFooter'
import styled from '@emotion/styled'

const defaultText = {
  title: 'Sign into your account',
  subtitle:
    "Enter your phone number, and we'll text you an SMS with a one time passcode.",
}

const CheckoutSignInOTPFormWrapper = styled(FormWrapper)`
  form + div > p {
    text-align: center;
  }
`

const CheckoutSignInOTP = () => {
  return (
    <PageContainer style={{ maxWidth: '76.8rem' }}>
      <PageTitle {...defaultText} />
      <CheckoutSignInOTPFormWrapper>
        <OneTimePasscodeForm signUpFooter={<CheckoutSignInFooter />} />
      </CheckoutSignInOTPFormWrapper>
    </PageContainer>
  )
}

export default CheckoutSignInOTP
