import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { CartItemGroups, CartItemOptions, CartLevels } from '@open-tender/types'
import { slugify } from '@open-tender/utils'
import MenuItemGroupHeaderAlt from './MenuItemGroupHeaderAlt'
import MenuItemGroupsNav from './MenuItemGroupsNav'
import MenuItemOption from './MenuItemOption'
import MenuItemOptionSquare from './MenuItemOptionSquare/MenuItemOptionSquare'
import MenuItemOptionCard from './MenuItemOptionCard/MenuItemOptionCard'
import MenuItemOptionSimple from './MenuItemOptionSimple'

const MenuItemGroupsView = styled.div`
  label: MenuItemGroups;
`

const MenuItemGroupsList = styled.div<{ isChild?: boolean }>`
  ${(props) =>
    props.isChild
      ? `padding: 2rem ${props.theme.item.desktop.padding};
         padding-bottom: 0;
         margin-bottom: ${props.theme.item.desktop.padding};
         background-color: ${props.theme.bgColors.tertiary};`
      : `padding: 0 ${props.theme.item.desktop.padding};
  margin: 4.5rem 0 2rem;`}
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    ${(props) =>
      props.isChild
        ? `padding: 2rem ${props.theme.item.mobile.padding};
           padding-bottom: 0;`
        : `padding: 0 ${props.theme.item.mobile.padding};
           margin: 4.5rem 0 2rem;`}
  }
`

const MenuItemGroup = styled.div<{ name: string }>`
  margin: 0 0 3rem;

  &:last-of-type {
    margin: 0;
  }
`

const MenuItemOptions = styled.div`
  width: 100%;
`

const MenuItemOptionsSquare = styled.div`
  margin: 2rem -0.6rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
`

const MenuItemOptionsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${(props) => props.theme.modifiers.box.gap};
`

const MenuItemGroups = ({
  groups,
  levels,
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
  scrollContainer,
  topOffset,
  headerHeight,
  isChild,
}: {
  groups: CartItemGroups
  levels: CartLevels
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  setOptionQuantity: (
    levels: CartLevels,
    quantity: number,
    nested?: CartItemOptions
  ) => void
  scrollContainer?: HTMLDivElement | null
  topOffset?: number | null
  headerHeight?: number
  isChild?: boolean
}) => {
  const { layout } = useTheme().modifiers.box
  const nonSizeGroups = groups.filter((i) => !i.isSize)
  const scrollOffset = headerHeight
    ? (topOffset ?? 0) + headerHeight
    : topOffset ?? 0

  return (
    <MenuItemGroupsView>
      {scrollContainer ? (
        <MenuItemGroupsNav
          items={nonSizeGroups}
          scrollContainer={scrollContainer}
          scrollOffset={scrollOffset}
        />
      ) : null}
      <MenuItemGroupsList isChild={isChild}>
        {nonSizeGroups.map((group) => (
          <MenuItemGroup
            key={group.id}
            id={slugify(group.name ?? '')}
            name="section"
          >
            <MenuItemGroupHeaderAlt group={group} isChild={isChild} />
            {layout === 'SIMPLE' ? (
              <MenuItemOptionsCard>
                {group.options.map((option) => (
                  <MenuItemOptionSimple
                    key={`${group.id}-${option.id}`}
                    group={group}
                    option={option}
                    levels={levels}
                    toggleOption={toggleOption}
                    incrementOption={incrementOption}
                    decrementOption={decrementOption}
                    setOptionQuantity={setOptionQuantity}
                  />
                ))}
              </MenuItemOptionsCard>
            ) : layout === 'CARDS' ? (
              <MenuItemOptionsCard>
                {group.options.map((option) => (
                  <MenuItemOptionCard
                    key={`${group.id}-${option.id}`}
                    group={group}
                    option={option}
                    levels={levels}
                    toggleOption={toggleOption}
                    incrementOption={incrementOption}
                    decrementOption={decrementOption}
                    setOptionQuantity={setOptionQuantity}
                  />
                ))}
              </MenuItemOptionsCard>
            ) : layout === 'SQUARES' ? (
              <MenuItemOptionsSquare>
                {group.options.map((option) => (
                  <MenuItemOptionSquare
                    key={`${group.id}-${option.id}`}
                    group={group}
                    option={option}
                    levels={levels}
                    toggleOption={toggleOption}
                    incrementOption={incrementOption}
                    decrementOption={decrementOption}
                    setOptionQuantity={setOptionQuantity}
                  />
                ))}
              </MenuItemOptionsSquare>
            ) : (
              <MenuItemOptions>
                {group.options?.map((option, index) => (
                  <MenuItemOption
                    key={`${group.id}-${option.id}`}
                    index={index}
                    group={group}
                    option={option}
                    levels={levels}
                    toggleOption={toggleOption}
                    incrementOption={incrementOption}
                    decrementOption={decrementOption}
                    setOptionQuantity={setOptionQuantity}
                  />
                ))}
              </MenuItemOptions>
            )}
          </MenuItemGroup>
        ))}
      </MenuItemGroupsList>
    </MenuItemGroupsView>
  )
}

export default MenuItemGroups
