import styled from '@emotion/styled'

const PointsView = styled.span<{
  size: string
  bgColor: string
  sizeMobile: string
}>`
  display: block;
  height: 2em;
  padding: 0 1em;
  border-radius: 1em;
  line-height: 1;
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
  background-color: ${(props) => props.theme.bgColors[props.bgColor]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes[props.sizeMobile]};
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`

const PointsTitle = styled.span<{ color: string }>`
  display: block;
  color: ${(props) => props.theme.colors[props.color]};
  line-height: 1;
`

const PointsIcon = styled.span<{ size: string; color: string }>`
  display: block;
  color: ${(props) => props.theme.colors[props.color]};
  line-height: 0;
  margin: 0 0 0 0.4rem;
`

const Points = ({
  points,
  icon,
  size = 'small',
  sizeMobile = 'xSmall',
  color = 'primary',
  bgColor = 'tertiary',
}: {
  points: number
  icon: JSX.Element
  size?: string
  sizeMobile?: string
  color?: string
  bgColor?: string
  title?: string
}) => {
  return (
    <PointsView size={size} sizeMobile={sizeMobile} bgColor={bgColor}>
      <span>
        <PointsTitle color={color}>{points}</PointsTitle>
        <PointsIcon size={size} color={color}>
          {icon}
        </PointsIcon>
      </span>
    </PointsView>
  )
}

export default Points
