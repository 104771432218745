import styled from '@emotion/styled'
import BgImage from 'components/BgImage'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const MenuItemOptionSimpleView = styled.div<{ showGroups: boolean }>`
  label: MenuItemOptionSimpleView;
  position: relative;
  width: ${(props) =>
    props.showGroups ? '100%' : props.theme.modifiers.box.width};
  min-width: ${(props) => props.theme.modifiers.box.minWidth};
  min-height: ${(props) => props.theme.modifiers.box.minHeightWithGap};
  padding: ${(props) => props.theme.modifiers.box.gapHalf};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

export const MenuItemOptionSimpleCount = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.2rem;
  right: -0.2rem;
`

export const MenuItemOptionSimpleRemove = styled.button`
  position: absolute;
  z-index: 2;
  top: -0.8rem;
  right: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MenuItemOptionSimpleRemoveIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.theme.modifiers.box.transition};
  color: ${(props) => props.theme.error.color};
  background-color: ${(props) => props.theme.error.bgColor};

  button:hover & {
    color: ${(props) => props.theme.error.bgColor};
    background-color: ${(props) => props.theme.error.color};
  }
`

export const MenuItemOptionSimpleContent = styled.div``

export const MenuItemOptionSimpleButton = styled.button<{
  showGroups: boolean
}>`
  width: 100%;
  // height: ${(props) => (props.showGroups ? 'auto' : '100%')};
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  transition: ${(props) => props.theme.modifiers.box.transition};
  padding: ${(props) => props.theme.modifiers.box.padding};
  margin: ${(props) => props.theme.modifiers.box.margin};
  border-radius: ${(props) => props.theme.modifiers.box.borderRadius};
  border-style: solid;
  border-width: ${(props) => props.theme.modifiers.box.border};
  border-color: ${(props) => props.theme.modifiers.colors.borderColor};
  background-color: ${(props) => props.theme.modifiers.colors.bgColor};
  box-shadow: ${(props) => props.theme.modifiers.box.boxShadow};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.modifiers.box.paddingMobile};
    ${(props) => (props.showGroups ? 'padding-bottom: 6rem;' : '')};
    border-width: ${(props) => props.theme.modifiers.box.borderMobile};
    margin: ${(props) => props.theme.modifiers.box.marginMobile};
  }

  &.show-hover:not(.selected):hover,
  &.show-hover:not(.selected):active {
    border-color: ${(props) => props.theme.modifiers.hoverColors.borderColor};
    background-color: ${(props) => props.theme.modifiers.hoverColors.bgColor};
  }

  &.selected {
    border-color: ${(props) =>
      props.theme.modifiers.selectedColors.borderColor};
    background-color: ${(props) =>
      props.theme.modifiers.selectedColors.bgColor};
  }
`

export const MenuItemOptionSimpleImage = styled(BgImage)`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  width: ${(props) => props.theme.modifiers.image.width};
  min-height: ${(props) => props.theme.modifiers.image.height};
  padding: ${(props) => props.theme.modifiers.image.padding};
  margin: ${(props) => props.theme.modifiers.image.margin};
  border-radius: ${(props) => props.theme.modifiers.image.borderRadius};
  border-style: solid;
  border-width: ${(props) => props.theme.modifiers.image.border};
  border-color: ${(props) => props.theme.modifiers.image.borderColor};
  background-color: ${(props) => props.theme.modifiers.image.bgColor};
  background-size: ${(props) => props.theme.modifiers.image.bgSize};
  box-shadow: ${(props) => props.theme.modifiers.image.boxShadow};
`

export const MenuItemOptionSimpleText = styled.div`
  label: MenuItemOptionSimpleText;
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.modifiers.content.padding};
`

export const MenuItemOptionSimpleInfo = styled.div`
  label: MenuItemOptionSimpleInfo;
  flex: 1 1 auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.3rem;
`

export const MenuItemOptionSimpleName = styled(Heading)`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.title.fontSize};
  color: ${(props) => props.theme.modifiers.title.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.title.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.title.selectedColor};
  }
`

export const MenuItemOptionSimpleDescription = styled(Body)`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.text.fontSize};
  color: ${(props) => props.theme.modifiers.text.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.text.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.text.selectedColor};
  }
`

export const MenuItemOptionSimpleSoldOut = styled(Body)`
  color: ${(props) => props.theme.colors.error};
`

export const MenuItemOptionSimplePrice = styled(Heading)`
  flex: 0 0 auto;
  display: block;
  padding-left: 0.5rem;
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.price.fontSize};
  color: ${(props) => props.theme.modifiers.price.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.price.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.price.selectedColor};
  }
`

export const MenuItemOptionSimpleColories = styled(Body)`
  display: block;
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.calories.fontSize};
  color: ${(props) => props.theme.modifiers.calories.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.calories.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.calories.selectedColor};
  }
`

export const MenuItemOptionSimpleNested = styled.div`
  position: absolute;
  top: 0;
  right: 2rem;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
