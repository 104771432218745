import styled from '@emotion/styled'

export const CheckoutView = styled.div`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

export const CheckoutTitle = styled.div`
  label: CheckoutTitle;
  padding: 0 ${(props) => props.theme.layout.padding} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile} 0 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.layout.paddingMobile} 0 0;
  }

  h1 {
    @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
      font-size: ${(props) => props.theme.fonts.sizes.h2};
    }
  }

  & > p:first-of-type {
    margin: 0.5rem 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`

export const CheckoutContent = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.125s forwards;
  flex: 1 1 auto;
  padding: ${(props) => props.theme.layout.navHeight} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    flex: 0 0 55%;
    padding: 1rem 0;
    margin-bottom: ${(props) => props.theme.layout.marginMobile};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex: 0 0 100%;
  }
`

export const CheckoutInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
  }

  & > div {
    flex-grow: 1;
    min-width: 50%;
  }
`

export const CheckoutSidebar = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
  position: relative;
  flex: 0 0 48rem;
  padding: 0 0 0 ${(props) => props.theme.layout.padding};
  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props.theme.border.color};
  border-left-width: ${(props) => props.theme.border.width};
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    flex: 0 0 45%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300%;
  }
`

export const CheckoutSidebarContent = styled.div`
  position: relative;
  z-index: 2;
`
