import styled from '@emotion/styled'
import { Preface } from 'components'
import { selectOrder, useAppSelector } from '@open-tender/cloud'
import { selectAutoSelect, selectGroupOrder } from '@open-tender/cloud'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import {
  CancelEdit,
  GroupGuest,
  GroupOrder,
  LeaveGroup,
  RequestedAt,
  RevenueCenter,
  ServiceType,
} from '../../buttons'

const MenuMobileMenuView = styled.div<{ show?: boolean }>`
  label: MenuMobileMenuView;
  position: fixed;
  z-index: 12;
  top: ${(props) => props.theme.layout.navHeight};
  left: 0;
  right: 0;
  padding: ${(props) => props.theme.layout.paddingMobile};
  transition: all 0.125s ease;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transform: translateY(${(props) => (props.show ? '0' : '-100%')});
  background-color: ${(props) => props.theme.header.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: ${(props) => props.theme.layout.navHeightMobile};
  }
`

const MenuMobileMenuRowLabel = styled(Preface)`
  color: ${(props) => props.theme.buttons.colors.header.color};
`

const MenuMobileMenuOverlay = styled.div`
  position: fixed;
  z-index: 11;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.overlay.dark};
`

const MenuMobileMenuContainer = styled.div`
  width: 100%;
  max-width: 48rem;
  // padding: ${(props) => props.theme.layout.paddingMobile};
  margin: 0 auto;
`

const MenuMobileMenuRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top-style: solid;
  border-top-width: ${(props) => props.theme.border.width};
  border-top-color: ${(props) => props.theme.border.color};

  &:first-of-type {
    border: 0;
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  & > span {
    display: block;
  }
`

const MenuMobileMenu = ({
  showMenu,
  setShowMenu,
}: {
  showMenu: boolean
  setShowMenu: (show: boolean) => void
}) => {
  const { orderId, revenueCenter, serviceType, requestedAt } =
    useAppSelector(selectOrder)
  const autoSelect = useAppSelector(selectAutoSelect)
  const { cartGuest, isCartOwner } = useAppSelector(selectGroupOrder)
  const hasGroupOrdering = !!(
    revenueCenter?.group_ordering && revenueCenter.revenue_center_type === 'OLO'
  )

  if (cartGuest) return null

  return (
    <>
      <MenuMobileMenuView show={showMenu}>
        <MenuMobileMenuContainer>
          {cartGuest ? (
            <>
              <MenuMobileMenuRow>
                <MenuMobileMenuRowLabel size="small">
                  Submit your order
                </MenuMobileMenuRowLabel>
                <GroupGuest />
              </MenuMobileMenuRow>
              <MenuMobileMenuRow>
                <MenuMobileMenuRowLabel size="small">
                  Change your mind?
                </MenuMobileMenuRowLabel>
                <LeaveGroup />
              </MenuMobileMenuRow>
            </>
          ) : (
            <>
              {orderId && (
                <MenuMobileMenuRow>
                  <MenuMobileMenuRowLabel size="small">
                    Editing Order #{orderId}
                  </MenuMobileMenuRowLabel>
                  <CancelEdit />
                </MenuMobileMenuRow>
              )}
              {revenueCenter && !autoSelect ? (
                <MenuMobileMenuRow>
                  <MenuMobileMenuRowLabel size="small">
                    Location
                  </MenuMobileMenuRowLabel>
                  <RevenueCenter useButton={true} />
                </MenuMobileMenuRow>
              ) : null}
              {serviceType && !isCartOwner ? (
                <MenuMobileMenuRow>
                  <MenuMobileMenuRowLabel size="small">
                    Order Type
                  </MenuMobileMenuRowLabel>
                  <ServiceType useButton={true} />
                </MenuMobileMenuRow>
              ) : null}
              {requestedAt && (
                <MenuMobileMenuRow>
                  <MenuMobileMenuRowLabel size="small">
                    Requested Time
                  </MenuMobileMenuRowLabel>
                  <RequestedAt useButton={true} />
                </MenuMobileMenuRow>
              )}
              {hasGroupOrdering && (
                <MenuMobileMenuRow>
                  <MenuMobileMenuRowLabel size="small">
                    Group Ordering
                  </MenuMobileMenuRowLabel>
                  <GroupOrder showIcon={false} />
                </MenuMobileMenuRow>
              )}
            </>
          )}
        </MenuMobileMenuContainer>
      </MenuMobileMenuView>
      <TransitionGroup component={null}>
        {showMenu ? (
          <CSSTransition
            key="mobile-menu-overlay"
            classNames="overlay"
            timeout={250}
          >
            <MenuMobileMenuOverlay onClick={() => setShowMenu(false)} />
          </CSSTransition>
        ) : null}
      </TransitionGroup>
    </>
  )
}

export default MenuMobileMenu
