import { openModal } from '@open-tender/cloud'
import { Sliders } from '../icons'
import Icon from './Icon'
import { useAppDispatch } from '@open-tender/cloud'
import { isMobileOnly } from 'react-device-detect'

const Allergens = ({ style }: { style?: React.CSSProperties }) => {
  const dispatch = useAppDispatch()

  return (
    <Icon
      aria-label="Allergen Filter"
      onClick={() => dispatch(openModal({ type: 'allergens' }))}
      style={style}
    >
      <Sliders size={isMobileOnly ? 17 : 19} />
    </Icon>
  )
}

export default Allergens
