import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Address } from '@open-tender/types'
import { inZone } from '@open-tender/utils'
import {
  closeModal,
  selectOrder,
  selectSettings,
  setAddress,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  FormError,
  GoogleMap,
  GoogleMapsAutocomplete,
} from 'components'
import { ClipLoader } from 'react-spinners'
import { Navigation } from '../icons'
import { ModalContent, ModalView } from '..'

const MapView = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
`

const Map = ({ mapRef }: { mapRef: React.RefObject<HTMLDivElement> }) => (
  <MapView>
    <div ref={mapRef} />
  </MapView>
)

const MapsAutocompleteView = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 24rem;
`

const MapsAutocompleteError = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 2rem 0 0;
`

const MapsAutocompleteInputView = styled.div`
  width: 100%;
`

const MapsAutocompleteInput = ({
  maps,
  map,
  sessionToken,
  autocomplete,
  formattedAddress,
  setCenter,
  setAddress,
}: {
  maps?: typeof google.maps | null
  map?: google.maps.Map | null
  sessionToken?: google.maps.places.AutocompleteSessionToken | null
  autocomplete?: google.maps.places.AutocompleteService | null
  formattedAddress: string
  setCenter: ({ lat, lng }: { lat: number; lng: number }) => void
  setAddress: (address: Address | null) => void
}) => {
  if (!maps || !map || !sessionToken || !autocomplete) return null
  return (
    <MapsAutocompleteInputView>
      <GoogleMapsAutocomplete
        maps={maps}
        map={map}
        sessionToken={sessionToken}
        autocomplete={autocomplete}
        formattedAddress={formattedAddress}
        setCenter={setCenter}
        setAddress={setAddress}
        icon={<Navigation size={14} strokeWidth={2} />}
        placeholder="please enter your address"
      />
    </MapsAutocompleteInputView>
  )
}

const nonInZone =
  'Address not in delivery zone. Please try again or try a different location.'

const MapsAutocomplete = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { googleMaps } = useAppSelector(selectSettings) || {}
  const { apiKey, defaultCenter, zoom, styles } = googleMaps || {}
  const [, setCenter] = useState(defaultCenter)
  const [hasAddress, setHasAdddres] = useState(false)
  const [error, setError] = useState<any>(null)
  const { address, revenueCenter } = useAppSelector(selectOrder)
  const { lat, lng } = address || {}
  const latLng = lat && lng ? { lat, lng } : null
  const formattedAddress = address ? address.formatted_address : ''
  const { delivery_zone, slug } = revenueCenter || {}
  const { coordinates } = delivery_zone || {}
  const isInZone = latLng && coordinates ? inZone(latLng, coordinates) : false
  const menuSlug = `/menu/${slug}`
  const hasSettings = !!(apiKey && defaultCenter && zoom && styles)

  const applyAddress = (address: Address | null) => {
    dispatch(setAddress(address))
    setHasAdddres(address ? true : false)
  }

  useEffect(() => {
    if (hasAddress) {
      if (isInZone) {
        dispatch(closeModal())
        navigate(menuSlug)
      } else {
        setError(nonInZone)
      }
    }
  }, [hasAddress, isInZone, dispatch, navigate, menuSlug])

  return (
    <ModalView>
      <ModalContent
        title="Enter your address"
        footer={<Button onClick={() => dispatch(closeModal())}>Close</Button>}
      >
        <MapsAutocompleteView>
          {hasSettings && (
            <GoogleMap
              apiKey={apiKey}
              zoom={zoom}
              styles={styles}
              center={defaultCenter}
              loader={<ClipLoader size={30} loading={true} />}
              renderMap={(props) => <Map {...props} />}
            >
              <MapsAutocompleteInput
                formattedAddress={formattedAddress ?? ''}
                setCenter={setCenter}
                setAddress={(address) => applyAddress(address)}
              />
            </GoogleMap>
          )}
          <MapsAutocompleteError>
            <FormError errMsg={error} />
          </MapsAutocompleteError>
        </MapsAutocompleteView>
      </ModalContent>
    </ModalView>
  )
}

export default MapsAutocomplete
