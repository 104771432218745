import styled from '@emotion/styled'
import { GiftCard, GiftCards } from '@open-tender/types'
import { formatDateStr, dateStrToDate } from '@open-tender/utils'
import {
  openModal,
  removeCustomerGiftCard,
  selectBrand,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, ButtonLink, LinkSeparator, QRCode, Row } from 'components'

const GiftCardButton = styled.button`
  width: 8rem;
`

interface GiftCardButtonProps {
  giftCards: GiftCards
  isLoading?: boolean
}

const GiftCardsList = ({ giftCards, isLoading }: GiftCardButtonProps) => {
  const dispatch = useAppDispatch()
  const { has_pos } = useAppSelector(selectBrand) || {}

  const handleAddValue = (giftCard: GiftCard) => {
    dispatch(openModal({ type: 'giftCard', args: { giftCard } }))
  }

  const handleAssign = (giftCardId: number) => {
    dispatch(openModal({ type: 'giftCardAssignOther', args: { giftCardId } }))
  }

  const handleDelete = (giftCard: GiftCard) => {
    const giftCardId = giftCard.gift_card_id
    dispatch(removeCustomerGiftCard({ giftCardId }))
  }

  const expand = (
    evt: React.MouseEvent<HTMLButtonElement>,
    src: string,
    alt: string
  ) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!src) return
    const title = alt
    const alert = 'QR codes are for in-store use only'
    const footnote =
      'To use online, simply apply this gift card on the checkout page'
    dispatch(
      openModal({ type: 'qrCode', args: { src, alt, title, alert, footnote } })
    )
  }

  return (
    <div>
      {giftCards.map((giftCard) => {
        const expired =
          dateStrToDate(giftCard.expiration as string) < new Date()
        const removeable = expired || giftCard.balance === '0.00'
        const src = giftCard.qr_code_url
        const alt = `Gift Card ${giftCard.card_number}`
        const icon =
          has_pos && src ? (
            <GiftCardButton onClick={(evt) => expand(evt, src, alt)}>
              <QRCode src={src} alt={alt} />
            </GiftCardButton>
          ) : undefined
        return (
          <Row
            key={giftCard.card_number}
            icon={icon}
            content={
              <>
                <p className="title">{giftCard.card_number}</p>
                <p>${giftCard.balance} remaining balance</p>
                {giftCard.expiration && (
                  <p>
                    {expired ? 'Expired ' : 'Expires '}
                    {formatDateStr(giftCard.expiration, 'MMM d, yyyy')}
                  </p>
                )}
                <p>
                  <ButtonLink
                    onClick={() => handleAssign(giftCard.gift_card_id)}
                    disabled={isLoading}
                  >
                    Assign to someone else
                  </ButtonLink>
                  {removeable && (
                    <>
                      <LinkSeparator />
                      <ButtonLink
                        onClick={() => handleDelete(giftCard)}
                        disabled={isLoading}
                      >
                        remove
                      </ButtonLink>
                    </>
                  )}
                </p>
              </>
            }
            actions={
              !expired ? (
                <Button
                  onClick={() => handleAddValue(giftCard)}
                  size="small"
                  disabled={isLoading}
                >
                  Add Value
                </Button>
              ) : undefined
            }
          ></Row>
        )
      })}
    </div>
  )
}

export default GiftCardsList
