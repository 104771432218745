import { useNavigate } from 'react-router-dom'
import {
  closeModal,
  resetOrderType,
  resetRevenueCenter,
  selectContent,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, ModalContent, ModalView } from 'components'
import { RevenueCenterStatus } from '@open-tender/types'

const defaultText = {
  title: 'Location currently closed',
  subtitle: "We're sorry, but this location is currently closed.",
}

export interface ClosedModalProps extends Record<string, unknown> {
  status: RevenueCenterStatus
  isCancel?: boolean
}

const Closed = ({ status, isCancel }: ClosedModalProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { revenueCenters: rcConfig } = useAppSelector(selectContent) || {}
  const { statusMessages } = rcConfig || {}
  // status can be OPEN, which is not included in statusMessages
  // this happens when first_times is null for a restaurant because it's
  // after close and days ahead is set to 0
  const statusMsg = statusMessages
    ? statusMessages[status] || defaultText
    : defaultText

  const changeLocation = () => {
    dispatch(resetRevenueCenter())
    dispatch(closeModal())
    navigate('/locations')
  }

  const startOver = () => {
    dispatch(resetOrderType())
    dispatch(closeModal())
  }

  const cancel = () => {
    dispatch(closeModal())
  }

  return (
    <ModalView>
      <ModalContent
        title={statusMsg.title}
        footer={
          <div>
            <Button onClick={changeLocation} color="primary">
              Change Location
            </Button>
            {isCancel ? (
              <Button onClick={cancel}>Cancel</Button>
            ) : (
              <Button onClick={startOver}>Start Over</Button>
            )}
          </div>
        }
      >
        <div>
          <p>{statusMsg.subtitle}</p>
        </div>
      </ModalContent>
    </ModalView>
  )
}

export default Closed
