import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { handleRespError } from '@open-tender/utils'
import { FormError } from './inputs'
import { ConfigBrand, RequestError } from '@open-tender/types'

const ApplePayView = styled.div`
  margin: 2rem 0;

  > span > p {
    margin: 0;
  }
`

const ApplePayButton = styled.button`
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  width: 100%;
  height: 4.5rem;
`

const ApplePayChecking = styled.div`
  width: 100%;
  padding: 0 0 1rem;
  text-align: center;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.colors.primary};

  > div {
    display: inline-block;
    margin: 0 0 1rem;
  }
`

const paymentSessionConfig = {
  countryCode: 'US',
  currencyCode: 'USD',
  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
  merchantCapabilities: ['supports3DS'],
  // total: { label: 'Your Merchant Name', amount: '10.00' },
}

// https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
const checkApplePayWithActiveCard = (
  applePayMerchantId: string,
  setChecking: (value: boolean) => void
) => {
  if (applePayMerchantId && (window as any).ApplePaySession) {
    setChecking(true)
    const promise = (
      window as any
    ).ApplePaySession.canMakePaymentsWithActiveCard(applePayMerchantId)
    return promise
      .then((canMakePayments: boolean) => {
        const canPay = (window as any).ApplePaySession.canMakePayments()
        // eslint-disable-next-line no-console
        console.log(canMakePayments, canPay)
        return canMakePayments || canPay
      })
      .catch(() => false)
      .finally(() => setChecking(false))
  } else {
    return new Promise((resolve) => resolve(false))
  }
}

const validateSession = async (
  api: any,
  validationURL: string,
  callback: (merchant_session: any) => void
) => {
  try {
    const host = window.location.hostname
    const { merchant_session } = await api.postApplePayValidate(
      host,
      validationURL
    )
    callback(merchant_session)
  } catch (err) {
    const error = err as RequestError
    if (error && error.detail) {
      throw new Error(error.detail)
    } else if (error && error.message) {
      throw new Error(error.message)
    }
  }
}

const processPayment = async (
  api: any,
  token: any,
  amount: string,
  customerId: number
) => {
  try {
    return await api.postApplePayPayment(token, amount, customerId)
  } catch (err) {
    const error = handleRespError(err as RequestError)
    throw new Error(error.detail)
  }
}

interface AuthApplePayProps {
  api: any
  brand: ConfigBrand
  customerId: number
  spinner: JSX.Element
  amount?: string
  message?: string
  callback: () => void
}

const AuthApplePay = ({
  api,
  spinner,
  brand,
  customerId,
  amount = '0.00',
  message,
  callback,
}: AuthApplePayProps) => {
  const [checking, setChecking] = useState(false)
  const [showApplePay, setShowApplePay] = useState(false)
  const [errMsg, setErrMsg] = useState(null)
  const { title: label, applePayMerchantId } = brand
  const config = { ...paymentSessionConfig, total: { label, amount } }
  // const show = checking || showApplePay || errMsg

  useEffect(() => {
    checkApplePayWithActiveCard(applePayMerchantId, setChecking).then(
      (show: boolean) => setShowApplePay(show)
    )
  }, [applePayMerchantId])

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    const applePaySession = new (window as any).ApplePaySession(6, config)
    applePaySession.begin()

    applePaySession.onvalidatemerchant = (
      evt: ApplePayJS.ApplePayValidateMerchantEvent
    ) => {
      validateSession(api, evt.validationURL, (merchantSession: any) => {
        applePaySession.completeMerchantValidation(merchantSession)
      }).catch((err) => {
        applePaySession.abort()
        setErrMsg(err.detail || err.message)
      })
    }

    applePaySession.onpaymentauthorized = (
      evt: ApplePayJS.ApplePayPaymentAuthorizedEvent
    ) => {
      const token = evt.payment.token
      processPayment(api, token, amount, customerId)
        .then(() => {
          applePaySession.completePayment(
            (window as any).ApplePaySession.STATUS_SUCCESS
          )
          if (callback) callback()
        })
        .catch((err) => {
          applePaySession.completePayment(
            (window as any).ApplePaySession.STATUS_FAILURE
          )
          setErrMsg(err.detail || err.message)
        })
    }
  }

  return (
    <ApplePayView>
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      {checking ? (
        <ApplePayChecking>
          {spinner}
          <p>Checking for Apple Pay support...</p>
        </ApplePayChecking>
      ) : showApplePay ? (
        <>
          {!!message && <p>{message}</p>}
          <ApplePayButton onClick={onClick} />
        </>
      ) : (
        <FormError
          errMsg="Apple Pay is not available in this browser. Please try a different device or browser."
          style={{ margin: '0 0 2rem' }}
        />
      )}
    </ApplePayView>
  )
}

export default AuthApplePay
