import { MenuItemAllergens, MenuItemImage, MenuItemTags } from 'components'
import {
  UpsellItemCals,
  UpsellItemCardView,
  UpsellItemContainer,
  UpsellItemContent,
  UpsellItemImage,
  UpsellItemName,
  UpsellItemPrice,
  UpsellItemPriceCals,
  UpsellItemPriceSep,
} from './UpsellItem.styled'

const UpsellItemCard = ({
  showImage,
  imageUrl,
  imageOverlay,
  name,
  price,
  cals,
  tags,
  allergens,
}: {
  showImage?: boolean
  imageUrl: string
  imageOverlay: JSX.Element | null
  name: string
  price: string
  cals: string
  tags: string[]
  allergens: string[]
}) => {
  return (
    <UpsellItemCardView>
      <UpsellItemContainer>
        {showImage && (
          <UpsellItemImage>
            <MenuItemImage imageUrl={imageUrl} alt={name}>
              {imageOverlay}
            </MenuItemImage>
          </UpsellItemImage>
        )}
        <UpsellItemContent>
          <UpsellItemName>{name}</UpsellItemName>
          {price && price !== '$0.00' ? (
            <UpsellItemPriceCals>
              {price ? <UpsellItemPrice>{price}</UpsellItemPrice> : null}
              {price && cals ? <UpsellItemPriceSep /> : null}
              {cals ? <UpsellItemCals>{cals} Cal</UpsellItemCals> : null}
            </UpsellItemPriceCals>
          ) : null}
          <MenuItemTags tags={tags} />
          <MenuItemAllergens allergens={allergens} />
        </UpsellItemContent>
      </UpsellItemContainer>
    </UpsellItemCardView>
  )
}

export default UpsellItemCard
