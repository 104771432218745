import { useEffect, useCallback } from 'react'
import { FormFieldType, GiftCardPurchase } from '@open-tender/types'
import { useGiftCardForm } from '@open-tender/utils'
import {
  closeModal,
  selectCustomerCreditCardsForPayment,
  selectCustomerGiftCards,
  updateCustomerGiftCard,
  addCustomerGiftCard,
  resetCustomerGiftCardsError,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const GiftCardForm = ({
  windowRef,
  giftCardId,
}: {
  windowRef: React.RefObject<HTMLDivElement>
  giftCardId?: number
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectCustomerGiftCards)
  const creditCards = useAppSelector(selectCustomerCreditCardsForPayment)
  const update = useCallback(
    (id: number, data: GiftCardPurchase, callback?: () => void) =>
      dispatch(updateCustomerGiftCard({ giftCardId: id, data, callback })),
    [dispatch]
  )
  const add = useCallback(
    (data: GiftCardPurchase, callback?: () => void) =>
      dispatch(addCustomerGiftCard({ data, callback })),
    [dispatch]
  )
  const callback = useCallback(() => dispatch(closeModal()), [dispatch])

  const {
    submitRef,
    fields,
    errors,
    data,
    submitting,
    handleChange,
    handleSubmit,
  } = useGiftCardForm(
    creditCards,
    loading,
    error,
    update,
    add,
    callback,
    giftCardId
  )

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error && windowRef.current) windowRef.current.scrollTop = 0
  }, [error, windowRef])

  useEffect(() => {
    return () => {
      dispatch(resetCustomerGiftCardsError())
    }
  }, [dispatch])

  return (
    <form id="gift-card-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={(errors as any).form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : giftCardId ? 'Add Value' : 'Purchase'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default GiftCardForm
