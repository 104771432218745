import {
  selectCustomerCreditCardsForPayment,
  useAppSelector,
} from '@open-tender/cloud'
import { GiftCard as GiftCardType } from '@open-tender/types'
import { GiftCardForm, ModalContent, ModalView } from 'components'

export interface GiftCardModalProps extends Record<string, unknown> {
  windowRef: React.RefObject<HTMLDivElement>
  giftCard?: GiftCardType
}

const GiftCard = ({ windowRef, giftCard }: GiftCardModalProps) => {
  const creditCards = useAppSelector(selectCustomerCreditCardsForPayment)

  return (
    <ModalView>
      <ModalContent
        title={
          giftCard
            ? `Add value to gift card ${giftCard.card_number}`
            : 'Purchase a new gift card'
        }
      >
        {!creditCards || !creditCards.length ? (
          <p>
            Please save a credit card to your account before purchasing a gift
            card.
          </p>
        ) : (
          <GiftCardForm
            windowRef={windowRef}
            giftCardId={giftCard?.gift_card_id}
          />
        )}
      </ModalContent>
    </ModalView>
  )
}

export default GiftCard
