import { useTheme } from '@emotion/react'
import { isEmpty } from '@open-tender/utils'
import { selectTags, useAppSelector } from '@open-tender/cloud'
import {
  MenuItemTagText,
  MenuItemTagImage,
  MenuItemTag,
} from './MenuItemTags.styled'

const MenuItemTags = ({ tags }: { tags: string[] }) => {
  const theme = useTheme()
  const { displayType, prefix } = theme.menuItems.tags
  const { lookup } = useAppSelector(selectTags)
  const hasLookup = lookup && !isEmpty(lookup)
  const showTagImages = hasLookup && displayType === 'IMAGE'
  const tagsString = prefix ? `${prefix} ${tags.join(', ')}` : null

  if (!tags.length || displayType === 'HIDDEN') return null

  return (
    <>
      {showTagImages ? (
        tags.map((tag) => (
          <MenuItemTagImage key={tag}>
            <img src={lookup[tag]?.imageUrl} alt={lookup[tag]?.name} />
          </MenuItemTagImage>
        ))
      ) : tagsString ? (
        <MenuItemTagText>{tagsString}</MenuItemTagText>
      ) : (
        tags.map((tag) => (
          <MenuItemTag key={tag}>
            <MenuItemTagText>{tag}</MenuItemTagText>
          </MenuItemTag>
        ))
      )}
    </>
  )
}

export default MenuItemTags
