import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import {
  checkout,
  selectBrand,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Content, HeaderCheckout, Main } from 'components'
import CheckoutSignUpDefault from './CheckoutSignUpDefault'
import CheckoutSignUpGuest from './CheckoutSignUpGuest'

const CheckoutSignUp = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { title: siteTitle, tpls } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)

  useEffect(() => {
    if (auth) {
      dispatch(checkout())
      navigate('/checkout')
    }
  }, [auth, dispatch, navigate])

  return (
    <>
      <Helmet>
        <title>Checkout Sign Up | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderCheckout />
        <Main>
          {tpls === 'COMO' ? (
            <CheckoutSignUpGuest />
          ) : (
            <CheckoutSignUpDefault />
          )}
        </Main>
      </Content>
    </>
  )
}

export default CheckoutSignUp
