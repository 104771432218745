import styled from '@emotion/styled'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const MenuItemPriceCals = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.menuItems.price.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.menuItems.price.paddingMobile};
  }
`

export const MenuItemPriceCalsSimple = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.menuItems.price.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.menuItems.price.paddingMobile};
  }
`

export const MenuItemPriceSep = styled.span`
  display: block;
  width: 8px;
  height: 1px;
  margin: 0 3px;
  transition: ${(props) => props.theme.menuItems.box.transition};
  background-color: ${(props) => props.theme.menuItems.text.color};

  .show-hover:hover & {
    background-color: ${(props) => props.theme.menuItems.text.colorHover};
  }
`

export const MenuItemPriceText = styled(Heading)`
  transition: ${(props) => props.theme.menuItems.box.transition};
  color: ${(props) => props.theme.menuItems.price.color};
  font-size: ${(props) => props.theme.menuItems.price.fontSize};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.price.fontSizeMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.price.colorHover};
  }
`

export const MenuItemCalsText = styled(Body)`
  transition: ${(props) => props.theme.menuItems.box.transition};
  color: ${(props) => props.theme.menuItems.calories.color};
  font-size: ${(props) => props.theme.menuItems.calories.fontSize};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.calories.fontSizeMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.calories.colorHover};
  }
`
