import { useState, useRef, useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { CartItemGroup, CartItemGroups } from '@open-tender/types'
import { slugify } from '@open-tender/utils'
import { getActiveElement, smoothHorizontalScrolling } from 'utils/helpers'
import { Count } from 'components'
import { Check } from 'components/icons'

const MenuItemGroupsNavScrollButtonView = styled.button<{
  belowMin?: boolean
  active?: boolean
}>`
  position: relative;
  height: 4.5rem;
  padding: 1rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) =>
    props.belowMin ? props.theme.colors.error : props.theme.colors.primary};
`

const MenuItemGroupsNavScrollButtonContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    display: block;
  }

  span + span {
    margin-left: 0.5rem;
  }
`

const MenuItemGroupsNavScrollButtonCompleted = styled.span`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.8rem;
  color: ${(props) => props.theme.success.color};
  background-color: ${(props) => props.theme.success.bgColor};

  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const MenuItemGroupsNavScrollButtonActive = styled.span<{ belowMin?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.2rem;
  background-color: ${(props) =>
    props.belowMin ? props.theme.colors.error : props.theme.colors.primary};
`

const MenuItemGroupsNavScrollButton = ({
  item,
  active,
  offset = 0,
}: {
  item: CartItemGroup
  active: boolean
  offset: number
}) => {
  const { error } = useTheme()
  const { name, quantity, min } = item
  const id = slugify(name)
  const groupQuantity = quantity ?? 0
  const belowMin = groupQuantity < min
  const remaining = min - groupQuantity
  const isCompleted = min > 0 && groupQuantity >= min

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    const element = document.getElementById(id)
    const position = (element?.offsetTop ?? 0) - offset
    scroll.scrollTo(position, {
      containerId: 'menu-item-content',
      duration: 500,
      smooth: true,
      offset: 0,
    })
    const items = element?.querySelectorAll('button')
    const firstItem = items?.length ? items[0] : null
    if (firstItem) firstItem.focus()
  }

  return (
    <MenuItemGroupsNavScrollButtonView
      onClick={onClick}
      active={active}
      belowMin={belowMin}
    >
      <MenuItemGroupsNavScrollButtonContainer>
        <span>{name}</span>
        {belowMin && (
          <span>
            <Count
              count={remaining}
              size={16}
              color={error.color}
              bgColor={error.bgColor}
              fontSize={8}
            />
          </span>
        )}
        {isCompleted && (
          <MenuItemGroupsNavScrollButtonCompleted>
            <span>
              <Check size={10} strokeWidth={3} />
            </span>
          </MenuItemGroupsNavScrollButtonCompleted>
        )}
      </MenuItemGroupsNavScrollButtonContainer>
      {active && <MenuItemGroupsNavScrollButtonActive belowMin={belowMin} />}
    </MenuItemGroupsNavScrollButtonView>
  )
}

const MenuItemGroupsNavScrollView = styled.div`
  width: 100%;
  overflow-x: scroll;
  transition: all 500ms ease;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 4.5rem;

    li {
      display: block;
      flex-shrink: 0;
      padding-left: ${(props) => props.theme.item.desktop.padding};
      padding-right: ${(props) => props.theme.item.desktop.padding};
      @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding-left: ${(props) => props.theme.item.mobile.padding};
        padding-right: ${(props) => props.theme.item.mobile.padding};
      }

      &:last-of-type {
        padding-right: ${(props) => props.theme.item.desktop.padding};
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
          padding-right: ${(props) => props.theme.item.mobile.padding};
        }
      }
    }
  }
`

const MenuItemGroupsNavScroll = ({
  items,
  scrollContainer,
  scrollOffset,
}: {
  items: CartItemGroups
  scrollContainer: HTMLDivElement
  scrollOffset: number
}) => {
  const navRef = useRef<HTMLDivElement | null>(null)
  const listRef = useRef<HTMLUListElement | null>(null)
  const [active, setActive] = useState<HTMLElement | null>(null)
  const [sections, setSections] = useState<{ name: string; offset: number }[]>(
    []
  )
  const [current, setCurrent] = useState<string | null>(null)
  const navBarHeight = navRef.current?.offsetHeight || 45
  const topOffset = scrollOffset + navBarHeight
  const paddingTop = 20
  const navOffset = topOffset + paddingTop
  const elements = Array.from(
    document.getElementsByName('section')
  ) as HTMLElement[]
  const buttonOffset = (navBarHeight || 45) + 45 + paddingTop - 1

  useEffect(() => {
    const handleScroll = () => {
      if (elements.length) {
        setActive(getActiveElement(elements, navOffset + 10))
      }
    }
    scrollContainer.addEventListener('scroll', handleScroll)
    return () => {
      scrollContainer.removeEventListener('scroll', () => handleScroll)
    }
  }, [navOffset, elements, active, scrollContainer])

  useEffect(() => {
    const navSections = Array.from(
      document.getElementsByClassName('nav-section')
    ).reduce((arr: any[], i) => {
      return [
        ...arr,
        { name: i.id.replace('nav-', ''), offset: i.getBoundingClientRect().x },
      ]
    }, [])
    setSections(navSections)
  }, [])

  useEffect(() => {
    if (active && sections.length) {
      if (active.id === current) return
      const currentSection =
        sections.find((i) => i.name === current) || sections[0]
      const activeSection = sections.find((i) => i.name === active.id)
      if (!currentSection || !activeSection) return
      const start = currentSection.offset - sections[0].offset
      const amountToMove = activeSection.offset - currentSection.offset
      if (navRef.current) {
        smoothHorizontalScrolling(navRef.current, 250, amountToMove, start)
      }
      setCurrent(active.id)
    }
  }, [active, current, sections])

  return (
    <MenuItemGroupsNavScrollView ref={navRef}>
      <ul ref={listRef}>
        {items.map((i, index) => {
          const sectionId = slugify(i.name ?? '')
          const activeId = active ? active.id : null
          return (
            <li
              key={`${sectionId}-${index}`}
              id={`nav-${sectionId}`}
              className="nav-section"
            >
              <MenuItemGroupsNavScrollButton
                item={i}
                offset={buttonOffset}
                active={activeId === sectionId}
              />
            </li>
          )
        })}
      </ul>
    </MenuItemGroupsNavScrollView>
  )
}

export default MenuItemGroupsNavScroll
