import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectBrand,
  selectContent,
  selectCustomer,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Main,
  PageContainer,
  PageTitle,
} from 'components'
import LoyaltyPrograms from './LoyaltyProgams'

const Loyalty = () => {
  const navigate = useNavigate()
  const { rewards: rewardsConfig } = useAppSelector(selectContent) || {}
  const { title: siteTitle, has_loyalty } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)

  useEffect(() => {
    if (!auth || !has_loyalty) return navigate('/account')
  }, [auth, has_loyalty, navigate])

  return auth ? (
    <>
      <Helmet>
        <title>Loyalty | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageTitle {...rewardsConfig?.loyalty} />
            <LoyaltyPrograms />
          </PageContainer>
        </Main>
      </Content>
    </>
  ) : null
}

export default Loyalty
