import styled from '@emotion/styled'
import ArcProgressBar from 'components/ArcProgressBar'
import Body from 'components/Body'
import Headline from 'components/Headline'

export const LoyaltyPointsView = styled.div`
  label: LoyaltyPoints;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
`

export const LoyaltyPointsContent = styled.div`
  height: 180px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoyaltyPointsArcProgressBarView = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoyaltyPointsArcProgressBar = styled(ArcProgressBar)`
  max-width: 400px;
  margin: 0 auto;
`

export const LoyaltyPointsProgressLabelsView = styled.div`
  width: 100%;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  max-height: 175px;
`

export const LoyaltyPointsProgressLeftLabel = styled(Headline)`
  margin-left: -10px;
`

export const LoyaltyPointsProgressRightLabel = styled(Headline)`
  margin-right: -10px;
  min-width: 40px;
  text-align: center;
`

export const LoyaltyPointsRedeemButtonView = styled.div`
  position: relative;
  z-index: 2;
  top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const LoyaltyPointsRedeemButton = styled.button<{ disabled: boolean }>`
  margin-top: 8px;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.bgColors.secondary
      : props.theme.colors.primary};
  border-radius: 24px;
  padding: 4px 16px;
  opacity: ${(props) => (props.disabled ? '0.8' : '1')};
`

export const LoyaltyPointsRedeemButtonText = styled(Body)`
  color: ${(props) => props.theme.colors.light};
  font-weight: bold;
`
