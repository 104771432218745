import { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { Background, BackgroundProps, Content, Circle } from 'components'
import styled from '@emotion/styled'

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`

const ContentWrapper = styled.div`
  flex: 4;
  display: flex;
  position: relative;
`

const BlueCircle = styled(Circle)`
  position: absolute;
  left: -120px;
  top: -400px;
  min-width: 1200px;
  z-index: -1;
`

const HeroWrapper = styled.div`
  flex: 3;
  position: relative;
`

const HeroCircle = styled(Circle)`
  margin-top: 150px;
  position: absolute;
  right: 50px;
  min-width: 1000px;
`

const HeroBackgroundImage = styled(Background)`
  position: relative;
  width: 100%;
  height: 100%;
`

type LandingPageLayoutProps = Pick<
  BackgroundProps,
  'announcements' | 'imageUrl' | 'videoUrl'
> & {
  children: React.ReactNode
  withBlueCircle?: boolean
}

const LandingPageLayout: FC<LandingPageLayoutProps> = ({
  children,
  announcements,
  imageUrl,
  videoUrl,
  withBlueCircle = true,
}) => {
  return (
    <PageWrapper>
      <ContentWrapper>
        {withBlueCircle && !isMobile && (
          <BlueCircle width={180} color="primary" strokeWidth={14} />
        )}

        <Content hasFooter={!isMobile}>{children}</Content>
      </ContentWrapper>
      {!isMobile && (
        <HeroWrapper>
          <HeroCircle width={200} color="alert" strokeWidth={14}>
            <HeroBackgroundImage
              imageUrl={imageUrl}
              videoUrl={videoUrl}
              announcements={announcements}
            />
          </HeroCircle>
        </HeroWrapper>
      )}
    </PageWrapper>
  )
}

export default LandingPageLayout
