import styled from '@emotion/styled'

const FormWrapper = styled.div`
  max-width: ${(props) =>
    props.theme.cards.form.bgColor === 'transparent' ? '54rem' : '60rem'};
  border-style: solid;
  border-width: ${(props) => props.theme.cards.form.borderWidth};
  border-color: ${(props) => props.theme.cards.form.borderColor};
  border-radius: ${(props) => props.theme.cards.form.borderRadius};
  background-color: ${(props) => props.theme.cards.form.bgColor};
  box-shadow: ${(props) => props.theme.cards.form.boxShadow};
  padding: ${(props) =>
    props.theme.cards.form.bgColor === 'transparent'
      ? '0'
      : props.theme.layout.padding};
  margin: 0 auto ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
    border: 0;
    margin: 0 auto ${(props) => props.theme.layout.marginMobile};
    box-shadow: none;
  }
`

export default FormWrapper
