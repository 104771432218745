import { useTheme } from '@emotion/react'
import { ClipLoader } from 'react-spinners'
import styled from '@emotion/styled'

interface BackgroundLoadingViewProps {
  zIndex?: number | null
}

const BackgroundLoadingView = styled.div<BackgroundLoadingViewProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.zIndex || 1};
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

const BackgroundLoading = ({ zIndex = null }: BackgroundLoadingViewProps) => {
  const theme = useTheme()
  const loaderColor = theme.colors.primary

  return (
    <BackgroundLoadingView zIndex={zIndex}>
      <ClipLoader size={30} loading={true} color={`${loaderColor}`} />
    </BackgroundLoadingView>
  )
}

export default BackgroundLoading
