import { closeModal, openModal, useAppDispatch } from '@open-tender/cloud'
import { Button, ButtonLink, ModalContent } from 'components'

const GroupOrderGuest = () => {
  const dispatch = useAppDispatch()

  const callback = () => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'groupOrder' }))
    }, 300)
  }

  const login = (type: string) => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type, args: { callback } }))
    }, 300)
  }

  return (
    <ModalContent
      title="Start a group order"
      footer={
        <div>
          <Button color="secondary" onClick={() => dispatch(closeModal())}>
            Nevermind
          </Button>
        </div>
      }
    >
      <div>
        <p>You must be logged into your accout to start a group order.</p>
        <p>
          <Button onClick={() => login('login')} color="primary">
            Click here to login
          </Button>
        </p>
      </div>
      <div style={{ margin: '3rem 0 0' }}>
        <p>
          Don't have an account?{' '}
          <ButtonLink onClick={() => login('signUp')}>
            Click here to create one.
          </ButtonLink>
        </p>
      </div>
    </ModalContent>
  )
}

export default GroupOrderGuest
