import { useCallback, useEffect } from 'react'
import {
  closeModal,
  selectCustomer,
  // selectOptIns,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ModalContent, ModalView, ProfileForm } from 'components'
import { CustomerCreate } from '@open-tender/types'

interface ProfileProps {
  windowRef: React.RefObject<HTMLDivElement>
  callback?: (data: CustomerCreate) => void
}

const Profile = ({ windowRef, callback }: ProfileProps) => {
  const dispatch = useAppDispatch()
  // const optIns = useAppSelector(selectOptIns)
  const { profile } = useAppSelector(selectCustomer)
  const { customer_id } = profile || {}
  const closeCallback = useCallback(
    (data: CustomerCreate) => {
      if (callback) callback(data)
      dispatch(closeModal())
    },
    [dispatch, callback]
  )

  useEffect(() => {
    if (!customer_id) dispatch(closeModal())
  }, [customer_id, dispatch])

  return (
    <ModalView>
      <ModalContent title="Update your contact info">
        <ProfileForm callback={closeCallback} windowRef={windowRef} />
      </ModalContent>
    </ModalView>
  )
}

export default Profile
