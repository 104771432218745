import styled from '@emotion/styled'
import { ThemeAlertType } from '@open-tender/types'
import Preface from 'components/Preface'

const TagView = styled.span<{ color?: ThemeAlertType }>`
  display: inline-block;
  padding: 0.5rem 1rem 0.5rem;
  border-radius: 1.5rem;
  line-height: 0;
  color: ${(props) =>
    props.color ? props.theme[props.color].color : props.theme.colors.light};
  background-color: ${(props) =>
    props.color ? props.theme[props.color].bgColor : props.theme.bgColors.dark};
`

const TagContainer = styled.span`
  display: flex;
  align-items: center;
  line-height: 0;

  span {
    display: inline-block;
  }
`

const TagIcon = styled.span<{
  color?: ThemeAlertType
}>`
  position: relative;
  padding: 0;
  margin-right: 0.5rem;
  flex-shrink: 0;
  color: ${(props) =>
    props.color ? props.theme[props.color].color : props.theme.colors.light};
`

const TagText = styled(Preface)<{
  color?: ThemeAlertType
}>`
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  color: ${(props) =>
    props.color ? props.theme[props.color].color : props.theme.colors.light};
  line-height: 1.2;
`

const Tag = ({
  text,
  icon,
  color,
}: {
  text: string
  icon?: JSX.Element
  color?: ThemeAlertType
}) => {
  return (
    <TagView color={color}>
      <TagContainer>
        {icon && <TagIcon color={color}>{icon}</TagIcon>}
        <TagText color={color}>{text}</TagText>
      </TagContainer>
    </TagView>
  )
}

export default Tag
