import styled from '@emotion/styled'
import { CartItem, CartItemGroups, CartLevels } from '@open-tender/types'
import { useBuilder } from '@open-tender/utils'
import { Container } from 'components'
import MenuItemFullGroups from './MenuItemFullGroups'
import MenuItemFullHeader from './MenuItemFullHeader'

const MenuItemFullView = styled.div`
  label: MenuItemFullView;
  margin: ${(props) => props.theme.layout.margin} 0;
  & > div {
    position: relative;
  }
`

const MenuItemFull = ({
  builder,
  modifierGroups,
  levels,
  addItem,
}: {
  builder: ReturnType<typeof useBuilder>
  modifierGroups: CartItemGroups
  levels: CartLevels
  addItem: (builtItem: CartItem) => void
}) => {
  const { toggleOption, incrementOption, decrementOption, setOptionQuantity } =
    builder

  return (
    <MenuItemFullView>
      <Container>
        <MenuItemFullHeader builder={builder} addItem={addItem} />
        <MenuItemFullGroups
          groups={modifierGroups}
          levels={levels}
          toggleOption={toggleOption}
          incrementOption={incrementOption}
          decrementOption={decrementOption}
          setOptionQuantity={setOptionQuantity}
        />
      </Container>
    </MenuItemFullView>
  )
}

export default MenuItemFull
