import styled from '@emotion/styled'
import LoyaltyStatus from '../../LoyaltyStatus'

export const PointsShopRewards = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
`

export const PointsShopRewardsContainer = styled.div`
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -0.5rem;
  }
`

export const PointsShopRewardsReward = styled.div`
  width: 33.33333%;
  padding: 0 1rem 2.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 0 0.5rem 2.5rem;
  }
`

export const PointsShopBalanceView = styled.div`
  display: inline-flex;
  min-width: 30rem;
  align-items: center;
  text-align: left;
  column-gap: 2rem;
  margin-top: 3rem;
  border-radius: ${(props) => props.theme.cards.default.borderRadius};
  background-color: ${(props) => props.theme.bgColors.tertiary};
  padding: 1rem ${(props) => props.theme.layout.paddingMobile};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: ${(props) => props.theme.layout.marginMobile};
    min-width: 100%;
  }
`

export const PointsShopBalanceLoyaltyStatus = styled(LoyaltyStatus)`
  margin-top: 0;
`
