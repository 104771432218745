import { AnnouncementsState } from '@open-tender/cloud'
import { makeImageUrl } from '@open-tender/utils'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { ColorName } from 'utils/types'
import {
  NewsCard,
  NewsContent,
  NewsDescription,
  NewsImage,
  NewsSubtitle,
  NewsTitle,
  NewsView,
} from './News.styled'
import Button from 'components/Button'

const News = ({
  announcements,
}: {
  announcements: Partial<AnnouncementsState>
}) => {
  const navigate = useNavigate()
  const { entities } = announcements || {}

  return (
    <NewsView>
      {entities?.map((announcement) => {
        const imageURL = makeImageUrl(announcement.images, isMobile)
        const isNavigate = announcement.url
          ? announcement.url.includes(window.location.origin)
          : false
        const linkUrl = isNavigate
          ? announcement.url?.replace(window.location.origin, '')
          : announcement.url
        const bgStyle = imageURL
          ? { backgroundImage: `url(${imageURL}` }
          : undefined
        return (
          <NewsCard key={announcement.announcement_id}>
            {imageURL && <NewsImage style={bgStyle} />}
            <NewsContent align={announcement.horizontal}>
              <NewsDescription>
                <NewsTitle
                  size={isMobile ? 'main' : 'big'}
                  color={announcement.title_color}
                >
                  {announcement.title}
                </NewsTitle>
                <NewsSubtitle
                  size={isMobile ? 'xSmall' : 'main'}
                  color={announcement.subtitle_color as ColorName}
                >
                  {announcement.subtitle}
                </NewsSubtitle>
              </NewsDescription>
              {announcement.url && (
                <Button
                  size="small"
                  // icon={<ArrowRight />}
                  onClick={() => navigate(linkUrl ?? '')}
                >
                  {announcement.url_text}
                </Button>
              )}
            </NewsContent>
          </NewsCard>
        )
      })}
    </NewsView>
  )
}

export default News
