import { useTheme } from '@emotion/react'
import { isEmpty } from '@open-tender/utils'
import { selectAllergens, useAppSelector } from '@open-tender/cloud'
import {
  MenuItemAllergenText,
  MenuItemAllergenImage,
  MenuItemAllergen,
} from './MenuItemAllergens.styled'

const MenuItemAllergens = ({ allergens }: { allergens: string[] }) => {
  const theme = useTheme()
  const { displayType, prefix } = theme.menuItems.allergens
  const { lookup } = useAppSelector(selectAllergens)
  const hasLookup = lookup && !isEmpty(lookup)
  const showTagImages = hasLookup && displayType === 'IMAGE'
  const allergensString = prefix ? `${prefix} ${allergens.join(', ')}` : null

  if (!allergens.length || displayType === 'HIDDEN') return null

  return (
    <>
      {showTagImages ? (
        allergens.map((allergen) => (
          <MenuItemAllergenImage key={allergen}>
            <img
              src={lookup[allergen]?.imageUrl}
              alt={lookup[allergen]?.name}
            />
          </MenuItemAllergenImage>
        ))
      ) : allergensString ? (
        <MenuItemAllergenText>{allergensString}</MenuItemAllergenText>
      ) : (
        allergens.map((allergen) => (
          <MenuItemAllergen key={allergen}>
            <MenuItemAllergenText>{allergen}</MenuItemAllergenText>
          </MenuItemAllergen>
        ))
      )}
    </>
  )
}

export default MenuItemAllergens
