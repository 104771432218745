import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import {
  selectBrand,
  selectCustomer,
  fetchCustomerOrder,
  selectCustomerOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Loading,
  Main,
  Order as OrderSummary,
  PageContainer,
} from 'components'

const OrderCentered = styled.div`
  display: flex;
  justify-content: center;
  max-width: 54rem;
  margin: 4rem auto;
  text-align: center;
`

const Order = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id: orderId } = useParams()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)
  const { order, loading, error } = useAppSelector(selectCustomerOrder)
  const isLoading = loading === 'pending'
  const errMsg = error
    ? "We couldn't find this order. Please double check your order ID and give it another try."
    : null

  const title = `Order #${orderId}`

  useEffect(() => {
    if (!auth) return navigate('/guest')
  }, [auth, navigate])

  useEffect(() => {
    dispatch(fetchCustomerOrder(parseInt(orderId ?? '0')))
  }, [dispatch, orderId])

  return (
    <>
      <Helmet>
        <title>
          {title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault path="/orders" />
        <Main>
          <PageContainer>
            {error ? (
              <OrderCentered>
                <p>{errMsg}</p>
              </OrderCentered>
            ) : order ? (
              <OrderSummary order={order} />
            ) : isLoading ? (
              <OrderCentered>
                <Loading text="Retrieving your order..." />
              </OrderCentered>
            ) : null}
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Order
