import React from 'react'
import * as shape from 'd3-shape'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

const ArcProgressBarSvg = styled.svg`
  width: 100%;
  aspect-ratio: 2;
`

const BAR_WIDTH = 30
const CORNER_RADIUS = 50
const FROM_ANGLE = -Math.PI / 2
const TO_ANGLE = Math.PI / 2
const SVG_WIDTH = 400
const SVG_HEIGHT = 200

const ArcProgressBar = ({
  color = null,
  style,
  className,
  progress = 0,
  barWidth = BAR_WIDTH,
  cornerRadius = CORNER_RADIUS,
}: {
  color?: string | null
  style?: React.CSSProperties
  className?: string
  progress?: number
  barWidth?: number
  cornerRadius?: number
}) => {
  const progressAngle = progress * (TO_ANGLE - FROM_ANGLE) + FROM_ANGLE

  const theme = useTheme()

  const baseArc = shape
    .arc()
    .innerRadius(SVG_HEIGHT - barWidth)
    .outerRadius(SVG_HEIGHT)
    .startAngle(FROM_ANGLE)
    .endAngle(TO_ANGLE)
    .cornerRadius(cornerRadius)

  const progressArc = shape
    .arc()
    .innerRadius(SVG_HEIGHT - barWidth)
    .outerRadius(SVG_HEIGHT)
    .startAngle(FROM_ANGLE)
    .endAngle(Math.min(TO_ANGLE, progressAngle))
    .cornerRadius(cornerRadius)

  return (
    <ArcProgressBarSvg
      width="100%"
      viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
      fill="none"
      style={style}
      className={className}
    >
      <path
        d={baseArc(undefined as any) as string}
        fill={theme.bgColors.tertiary}
        transform={`translate(${SVG_WIDTH / 2}, ${SVG_HEIGHT})`}
        strokeLinecap="round"
      />
      <path
        d={progressArc(undefined as any) as string}
        fill={color ?? theme.colors.tertiary}
        transform={`translate(${SVG_WIDTH / 2}, ${SVG_HEIGHT})`}
        strokeLinecap="round"
      />
    </ArcProgressBarSvg>
  )
}

export default ArcProgressBar
