import { Select as SelectType } from '@open-tender/types'
import { Label, SelectOnly } from '.'

const Select = ({
  label,
  name,
  value,
  onChange,
  error,
  options,
  disabled = false,
  required = false,
  showLabel = false,
}: SelectType) => {
  return (
    <Label
      htmlFor={name}
      text={label}
      showLabel={showLabel}
      value={true}
      required={required}
      errMsg={error}
    >
      <SelectOnly
        label={label}
        showLabel={showLabel}
        name={name}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        options={options}
      />
    </Label>
  )
}

export default Select
