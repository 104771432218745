import { useTheme } from '@emotion/react'

interface UserCircleProps {
  size?: number
  strokeWidth?: number
  isFilled?: boolean
}

const UserCircle = ({
  size,
  strokeWidth,
  isFilled = false,
}: UserCircleProps) => {
  const { header, icons } = useTheme()
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      overflow="visible"
    >
      <path
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
        stroke={isFilled ? header.primary : 'currentColor'}
        strokeWidth={strokeWidth || icons.strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.271 18.3457C4.271 18.3457 6.50002 15.5 12 15.5C17.5 15.5 19.7291 18.3457 19.7291 18.3457"
        stroke={isFilled ? header.primary : 'currentColor'}
        strokeWidth={strokeWidth || icons.strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
        stroke={isFilled ? header.primary : 'currentColor'}
        strokeWidth={strokeWidth || icons.strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserCircle
