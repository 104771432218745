import { ReactElement, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { hasEntities } from '@open-tender/utils'
import {
  closeModal,
  fetchAnnouncementPage,
  fetchCustomer,
  fetchCustomerCreditCards,
  fetchCustomerFavorites,
  fetchCustomerGroupOrders,
  fetchCustomerOrders,
  fetchCustomerRewards,
  fetchDeals,
  selectAnnouncementsPage,
  selectBrand,
  selectContentSection,
  selectCustomer,
  selectCustomerGroupOrders,
  selectCustomerOrders,
  selectCustomerRewards,
  selectDeals,
  selectHasAnnouncementsPage,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  // Background,
  BackgroundImage,
  Content,
  Header,
  HeaderLogo,
  Main,
  Welcome,
  Video,
} from '../..'
import { Cart, NavMenu, User } from '../../buttons'
import AccountButtons from './AccountButtons'
import AccountContent from './AccountContent'
import AccountLoyalty from './AccountLoyalty'
import AccountRewards from './AccountRewards'
import AccountDeals from './AccountDeals'
import AccountGroupOrders from './AccountGroupOrders'
import AccountOrders from './AccountOrders'
import { Discounts, Orders } from '@open-tender/types'
import { AccountHero, AccountMobile, AccountView, AccountWrapper } from '.'
import AccountNews from './AccountNews'
// const backgroundImage = require('../../../assets/background-blue.jpg')
const backgroundImage = require('../../../assets/background-small.jpg')

const Account = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { auth, profile } = useAppSelector(selectCustomer)
  const token = auth ? auth.access_token : null
  const {
    title: siteTitle,
    has_deals,
    has_rewards,
    has_loyalty,
    has_thanx,
    tpls,
  } = useAppSelector(selectBrand) || {}
  const {
    title,
    subtitle,
    background,
    mobile,
    content,
    videoUrlMobile,
    displayLogoMobile,
    displayed: displayedDesktop,
    displayedMobile,
    showFirstName,
    punctuation,
  } = useAppSelector(selectContentSection('account')) || {}
  const showLogo = isMobile ? displayLogoMobile : false
  const firstName = profile ? profile.first_name : null
  const greeting =
    firstName && showFirstName
      ? `${title}, ${firstName}${punctuation}`
      : `${title}${punctuation}`
  const displayed = isMobile ? displayedMobile : displayedDesktop

  const hasContent = displayed?.includes('CONTENT') && content && content.length
  const contentSection = hasContent
    ? (key: string) => <AccountContent key={key} content={content} />
    : null

  const deals = useAppSelector(selectDeals)
  const hasDeals = has_deals && displayed?.includes('DEALS')
  const displayDeals = hasDeals && hasEntities<Discounts>(deals)
  const dealsSection = displayDeals
    ? (key: string) => <AccountDeals key={key} deals={deals.entities} />
    : null

  const rewards = useAppSelector(selectCustomerRewards)
  const hasRewards = has_rewards && displayed?.includes('REWARDS')
  const displayRewads = hasRewards && hasEntities<Discounts>(rewards)
  const rewardsSection = displayRewads
    ? (key: string) => <AccountRewards key={key} rewards={rewards.entities} />
    : null

  const orders = useAppSelector(selectCustomerOrders)
  const hasOrders = displayed?.includes('ORDERS')
  const displayOrders = hasOrders && hasEntities<Orders>(orders)
  const ordersSection = displayOrders
    ? (key: string) => <AccountOrders key={key} orders={orders.entities} />
    : null

  const groupOrders = useAppSelector(selectCustomerGroupOrders)
  const hasGroupOrders = displayed?.includes('GROUP_ORDERS')
  const displayGroupOrders = hasGroupOrders && hasEntities(groupOrders)
  const groupOrdersSection = displayGroupOrders
    ? (key: string) => (
        <AccountGroupOrders key={key} orders={groupOrders.entities} />
      )
    : null

  // const loyalty = useAppSelector(selectCustomerLoyaltyProgram)
  const hasLoyalty = has_loyalty || has_thanx || tpls
  const displayLoyalty = displayed?.includes('LOYALTY') && hasLoyalty
  const loyaltySection = displayLoyalty
    ? (key: string) => <AccountLoyalty key={key} />
    : null

  const hasHero = isMobile ? mobile || videoUrlMobile : background
  const displayHero = displayed?.includes('HERO') && hasHero
  const heroSection = displayHero
    ? (key: string) => (
        <AccountHero key={key}>
          {videoUrlMobile ? (
            <Video src={videoUrlMobile} poster={mobile} />
          ) : (
            <BackgroundImage imageUrl={mobile} />
          )}
        </AccountHero>
      )
    : null

  const announcements = useAppSelector(selectAnnouncementsPage('ACCOUNT'))
  const hasAnnouncements = useAppSelector(selectHasAnnouncementsPage('ACCOUNT'))
  const displayAnnouncements =
    displayed?.includes('ANNOUNCEMENTS') && hasAnnouncements
  const announcementsSection = displayAnnouncements
    ? (key: string) => <AccountNews announcements={announcements} key={key} />
    : null

  const sections: Record<string, ((key: string) => ReactElement) | null> = {
    CONTENT: contentSection,
    LOYALTY: loyaltySection,
    REWARDS: rewardsSection,
    DEALS: dealsSection,
    ORDERS: ordersSection,
    GROUP_ORDERS: groupOrdersSection,
    HERO: heroSection,
    ANNOUNCEMENTS: announcementsSection,
  }
  const displayedSections = displayed
    ? displayed
        .map((i) => {
          const func = sections[i]
          return func ? func(i) : null
        })
        .filter(Boolean)
    : null

  // const isLoading = checkLoading(deals, rewards, orders, loyalty)

  const buttons = useRef<any>(null)

  useEffect(() => {
    dispatch(closeModal())
  }, [dispatch])

  useEffect(() => {
    if (!auth) navigate('/guest')
  }, [auth, navigate])

  useEffect(() => {
    dispatch(fetchCustomer())
    dispatch(fetchCustomerCreditCards(true))
    dispatch(fetchCustomerOrders(20))
    dispatch(fetchCustomerFavorites())
    dispatch(fetchCustomerGroupOrders())
  }, [token, dispatch, navigate])

  useEffect(() => {
    dispatch(fetchAnnouncementPage('ACCOUNT'))
  }, [dispatch])

  useEffect(() => {
    if (hasDeals) dispatch(fetchDeals())
  }, [hasDeals, dispatch])

  useEffect(() => {
    if (hasRewards) dispatch(fetchCustomerRewards())
  }, [hasRewards, dispatch])

  if (!auth) return null

  return (
    <>
      <Helmet>
        <title>
          {greeting} | {siteTitle}
        </title>
      </Helmet>
      {/* <Background
        imageUrl={background}
        videoUrl={videoUrl}
        announcements={displayAnnouncements ? announcements : undefined}
        style={{ top: theme.layout.navHeight }}
      /> */}
      <Content maxWidth="100%">
        <Header
          maxWidth="100%"
          borderColor={isMobile ? 'transparent' : undefined}
          title={showLogo ? <HeaderLogo /> : undefined}
          left={isMobile ? <User /> : <HeaderLogo />}
          right={
            <>
              {!isMobile && <User />}
              <Cart />
              <NavMenu />
            </>
          }
        />
        <Main bgImage={backgroundImage}>
          <AccountWrapper>
            <AccountView>
              <Welcome
                title={greeting}
                subtitle={!isMobile ? subtitle : undefined}
              />
              <AccountButtons ref={buttons} />
              <AccountMobile>{displayedSections}</AccountMobile>
            </AccountView>
          </AccountWrapper>
        </Main>
      </Content>
    </>
  )
}

export default Account
