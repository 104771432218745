import { RequestError } from '@open-tender/types'

const GroupOrderError = ({
  error,
  cartId,
  closed,
  pastCutoff,
  atCapacity,
  cartOwnerName,
}: {
  error: RequestError
  cartId: number | null
  closed: boolean
  pastCutoff: boolean
  atCapacity: boolean
  cartOwnerName: string
}) => {
  return error && !cartId ? (
    <p>
      Please double check the link you were provided to make sure it's accurate.
    </p>
  ) : closed || pastCutoff ? (
    <p>
      Please get in touch with {cartOwnerName} to see if it's not too late to
      reopen it.
    </p>
  ) : atCapacity ? (
    <p>
      Please get in touch with {cartOwnerName} to see if they can possibly free
      up a spot.
    </p>
  ) : null
}

export default GroupOrderError
