import { useCallback } from 'react'
import { FormFieldType } from '@open-tender/types'
import { useSendResetForm } from '@open-tender/utils'
import {
  selectResetPassword,
  sendPasswordResetEmail,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const SendResetForm = ({ callback }: { callback?: () => void }) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectResetPassword)

  const sendReset = useCallback(
    (email: string, linkUrl: string) =>
      dispatch(sendPasswordResetEmail({ email, link_url: linkUrl })),
    [dispatch]
  )

  const { submitRef, fields, data, errors, handleChange, handleSubmit } =
    useSendResetForm(loading, error, sendReset, callback, window)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  const errMsg = errors.form && !errors.email ? errors.form : null

  return (
    <form id="send-reset-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={loading === 'pending'}>
          {loading === 'pending' ? 'Submitting...' : 'Submit'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default SendResetForm
