import styled from '@emotion/styled'
import {
  LoyaltyThreshold,
  LoyaltyThresholds,
  LoyaltyThresholdsAugmented,
  Money,
} from '@open-tender/types'
import { formatQuantity } from '@open-tender/utils'
import { TriangleDown } from 'components/icons'
import { Heading } from 'components'

export const ProgressPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2.5rem auto 4.5rem auto;
  max-width: 420px;
`

export const ProgressPointsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ProgressPointsBar = styled.div`
  position: relative;
  width: 100%;
  background-color: #e6e6e6; // TODO: this color is not on the theme
  border-radius: 1000px;
  height: 10px;
  margin: 0 1rem;
`

export const ProgressPointsFill = styled.div<{ progress: number }>`
  width: ${(props) => props.progress}%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 1000px;
  transition: width 0.5s ease-in-out;
`

export const ProgressPoint = styled.div<{
  isFirst?: boolean
  isLast?: boolean
  progress?: number
}>`
  position: absolute;
  left: ${(props) => props.progress || 0}%;
  bottom: -3.2rem;
  padding: 0 0 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: ${(props) =>
    props.isFirst ? 'left' : props.isLast ? 'right' : 'center'};
  margin-left: ${(props) =>
    props.isFirst ? '0' : props.isLast ? '-4rem' : '-2rem'};
`

export const ProgressPointCircle = styled.div<{
  isFilled?: boolean
}>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.theme.bgColors.dark};
  border-radius: 1000px;
`

export const ProgressPointText = styled(Heading)`
  display: block;
  width: 100%;
  margin-top: 4px;
`

export const ProgressPointPoints = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  background-color: ${(props) => props.theme.bgColors.primary};
`

export const ProgressPointFootnote = styled.div`
  margin: 1rem 0 0;
  text-align: left;

  p {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const ProgressPoints = ({
  name,
}: // points,
// thresholds = [],
{
  name: string
  points: number
  threshold: Money | null
  thresholds?: LoyaltyThresholds
}) => {
  // TODO: mocking credit for now
  const points: number = 0
  const thresholds: LoyaltyThreshold[] = [
    {
      name: 'test',
      description: 'test description',
      points: 20,
    },
    {
      name: 'test',
      description: 'test description',
      points: 60,
    },
  ]

  const maxThreshold = thresholds.length
    ? Math.max(...thresholds.map((i) => i.points))
    : 0
  const total = maxThreshold * 1.1
  const progress = Math.min((points / total) * 100, 100) || 4
  const isOverMax = maxThreshold ? points > maxThreshold : false

  const progressPoints: LoyaltyThresholdsAugmented = thresholds.reduce<any>(
    (arr, i) => {
      return [
        ...arr,
        {
          ...i,
          percentage: (i.points / total) * 100,
          isFilled: points > i.points,
        },
      ]
    },
    []
  )

  return (
    <ProgressPointsContainer>
      <ProgressPointsView>
        <Heading size="h5" color="alert">
          0
        </Heading>
        <ProgressPointsBar>
          <ProgressPointsFill progress={progress} />

          {progressPoints.map((point, index) => (
            <ProgressPoint
              key={point.percentage}
              style={{ left: `${point.percentage.toFixed(5)}%` }}
              isFirst={index === 0}
              // isLast={index === thresholds.length}
            >
              <ProgressPointCircle {...point} />
              <ProgressPointText size="h6" color="alert">
                {point.points}
              </ProgressPointText>
            </ProgressPoint>
          ))}
          {points > 0 ? (
            <ProgressPoint style={{ left: `${progress}%` }}>
              <ProgressPointPoints>
                {formatQuantity(`${points}`)}
              </ProgressPointPoints>
              <TriangleDown />
            </ProgressPoint>
          ) : null}
        </ProgressPointsBar>
        <Heading size="h5" color="alert">
          {total}
        </Heading>
      </ProgressPointsView>
      {isOverMax ? (
        <ProgressPointFootnote>
          <p>You're off the charts! Start using those {name.toLowerCase()}!</p>
        </ProgressPointFootnote>
      ) : points === 0 && thresholds?.length === 0 ? (
        <ProgressPointFootnote>
          <p>Place your first order to start earning!</p>
        </ProgressPointFootnote>
      ) : null}
    </ProgressPointsContainer>
  )
}

export default ProgressPoints
