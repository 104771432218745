import styled from '@emotion/styled'

const ButtonPointsView = styled.button<{ size: string; applied?: boolean }>`
  display: inline-block;
  height: 2em;
  padding: 0 1em;
  border-radius: 1em;
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
  color: ${(props) =>
    props.theme.buttons.colors[`${props.color}${props.applied ? 'Hover' : ''}`]
      .color};
  background-color: ${(props) =>
    props.theme.buttons.colors[`${props.color}${props.applied ? 'Hover' : ''}`]
      .bgColor};
  border-color: ${(props) =>
    props.theme.buttons.colors[`${props.color}${props.applied ? 'Hover' : ''}`]
      .borderColor};

  &:hover:enabled,
  &:active:enabled {
    color: ${(props) =>
      props.theme.buttons.colors[
        `${props.color}${props.applied ? '' : 'Hover'}`
      ].color};
    background-color: ${(props) =>
      props.theme.buttons.colors[
        `${props.color}${props.applied ? '' : 'Hover'}`
      ].bgColor};
    border-color: ${(props) =>
      props.theme.buttons.colors[
        `${props.color}${props.applied ? '' : 'Hover'}`
      ].borderColor};
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
      margin: 0;
    }

    span + span {
      margin: 0 0 0 0.4rem;
    }
  }
`

const ButtonPointsTitle = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.light};
`

const ButtonPointsIcon = styled.span<{ size: string }>`
  display: block;
  width: ${(props) => props.theme.fonts.sizes[props.size]};
  height: ${(props) => props.theme.fonts.sizes[props.size]};
  color: ${(props) => props.theme.colors.light};
`

interface ButtonPointsProps {
  points: number
  icon?: JSX.Element
  color?: string
  size: string
  onClick?: (evt: any) => void
  applied?: boolean
  style?: React.CSSProperties
  disabled?: boolean
}

const ButtonPoints = ({
  points,
  icon,
  color = 'primary',
  size = 'small',
  onClick = undefined,
  applied = false,
  style = undefined,
}: ButtonPointsProps) => {
  return (
    <ButtonPointsView
      onClick={onClick}
      disabled={onClick ? false : true}
      color={color}
      size={size}
      applied={applied}
      style={style}
    >
      <span>
        <ButtonPointsTitle>{points}</ButtonPointsTitle>
        <ButtonPointsIcon size={size}>{icon}</ButtonPointsIcon>
      </span>
    </ButtonPointsView>
  )
}

export default ButtonPoints
