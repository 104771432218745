import { roundPoints } from '@open-tender/utils'
import {
  PointsShopBalanceLoyaltyStatus,
  PointsShopBalanceView,
} from './PointsShop.styled'
import Loading from 'components/Loading'
import { Star } from '../../icons'

const PointsShopBalance = ({
  pointsBalance,
  isLoading,
}: {
  pointsBalance: number | null
  isLoading: boolean
}) => {
  if (pointsBalance === null) return null
  return (
    <>
      <PointsShopBalanceView>
        <Star fill="#FFBF00" color="#FFBF00" size={28} />
        <PointsShopBalanceLoyaltyStatus
          count={roundPoints(pointsBalance)}
          name="Points Available"
        />
      </PointsShopBalanceView>
      {isLoading && <Loading />}
    </>
  )
}

export default PointsShopBalance
