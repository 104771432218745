import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { makeSlides } from '@open-tender/utils'
import { BackgroundLoading, Slider } from '../..'
import { AnnouncementSettings } from '@open-tender/types'
import { AnnouncementsState } from '@open-tender/cloud'

const AccountSliderView = styled.div`
  label: AccountSlider;
  // flex: 1;
  height: 300px;
  width: 100%;
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    min-height: 16rem;
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    // margin: calc(${(props) => props.theme.layout.marginMobile} / 2) 0;
    margin: 2rem 0;
  }

  & > div {
    height: 100%;
    overflow: hidden;
    border-radius: ${(props) => props.theme.border.radius};
  }
`

const AccountSlider = ({
  announcements,
  style,
}: {
  announcements?: Partial<
    AnnouncementsState & { settings: AnnouncementSettings | null }
  >
  style?: React.CSSProperties
}) => {
  const { settings = null, entities, loading, error } = announcements || {}
  const slides = error || !entities ? null : makeSlides(entities, isMobile)
  const isLoading = loading === 'pending'

  if (!slides) return null

  return (
    <AccountSliderView style={style}>
      {isLoading ? (
        <BackgroundLoading />
      ) : slides && settings ? (
        <Slider settings={settings} slides={slides} />
      ) : null}
    </AccountSliderView>
  )
}

export default AccountSlider
