import { LoyaltyReward } from '@open-tender/types'
import {
  RewardContent,
  RewardNote,
  RewardTitle,
  RewardView,
} from './Reward.styled'

const RewardLoyalty = ({ reward }: { reward: LoyaltyReward }) => {
  return (
    <RewardView>
      <RewardContent>
        <RewardTitle as="p" className="title">
          {reward.title}
        </RewardTitle>
        <RewardNote as="div">{reward.description}</RewardNote>
      </RewardContent>
    </RewardView>
  )
}

export default RewardLoyalty
