import { useNavigate } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import { Users } from '../icons'
import Icon from './Icon'

interface GroupOrderProps {
  style?: React.CSSProperties
  isActive?: boolean
}

const GroupOrderButton = styled.span`
  position: relative;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.buttons.colors.primary.bgColor};
`

const GroupOrder = ({ style, isActive }: GroupOrderProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { primary } = useTheme().buttons.colors

  const review = () => {
    const reviewOrders = () => navigate(`/review`)
    dispatch(openModal({ type: 'groupOrder', args: { reviewOrders } }))
  }

  return (
    <Icon
      aria-label={isActive ? 'Group Order Details' : 'Start Group Order'}
      onClick={review}
      style={style}
    >
      {isActive ? (
        <GroupOrderButton>
          <Users size={isMobileOnly ? 18 : 22} color={primary.color} />
        </GroupOrderButton>
      ) : (
        <Users size={isMobileOnly ? 18 : 22} />
      )}
    </Icon>
  )
}

export default GroupOrder
