import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  resetCheckout,
  resetOrder,
  selectOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink, Heading } from 'components'

const CheckoutCancelView = styled.div`
  margin: 3rem 0 0;

  p {
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
  }
`

const CheckoutCancelEdit = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { orderId } = useAppSelector(selectOrder)

  const handleCancelEdit = () => {
    dispatch(resetOrder())
    dispatch(resetCheckout())
    navigate(`/account`)
  }

  return orderId ? (
    <CheckoutCancelView>
      <Heading as="p">
        You're currently editing order #{orderId}.{' '}
        <ButtonLink onClick={handleCancelEdit}>
          Click here to cancel this edit.
        </ButtonLink>
      </Heading>
    </CheckoutCancelView>
  ) : null
}

export default CheckoutCancelEdit
