import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { selectBrand, selectContent, useAppSelector } from '@open-tender/cloud'
import {
  Content,
  GiftCardsForm,
  FormWrapper,
  Main,
  PageTitle,
  HeaderDefault,
  PageContainer,
} from 'components'

const GiftCards = () => {
  const navigate = useNavigate()
  const { giftCards: config } = useAppSelector(selectContent) || {}
  const { title, has_gift_cards } = useAppSelector(selectBrand) || {}

  useEffect(() => {
    if (!has_gift_cards) return navigate('/account')
  }, [has_gift_cards, navigate])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {title}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config} />
            <FormWrapper>
              <GiftCardsForm />
            </FormWrapper>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default GiftCards
