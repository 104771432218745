import styled from '@emotion/styled'
import { Body } from 'components'
import { ArrowRight } from '../icons'
import { useTheme } from '@emotion/react'
import { isMobile } from 'react-device-detect'

const NavButtonView = styled.button<{
  isButton?: boolean
  delay?: string | number
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  margin: 0 0 1rem;
  opacity: 0;
  min-height: ${(props) => props.theme.buttons.sizes.large.minHeight};
  padding: ${(props) => props.theme.buttons.sizes.large.padding};
  padding-right: 2rem;
  padding-left: 2.5rem;
  border-style: solid;
  border-width: ${(props) => props.theme.buttons.sizes.large.borderWidth};
  border-radius: ${(props) => props.theme.buttons.sizes.large.borderRadius};
  color: ${(props) => props.theme.buttons.colors.primarycolor};
  border-color: ${(props) => props.theme.buttons.colors.large.borderColor};
  background-color: ${(props) => props.theme.buttons.colors.large.bgColor};
  transition: ${(props) => props.theme.links.transition};
  animation: slide-up 0.25s ease-in-out ${(props) => props.delay} forwards;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    ${(props) =>
      !props.isButton
        ? `
    padding: 0 0 0 1.75rem;
    margin: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    background-color: transparent;
    border-bottom: ${props.theme.border.width} solid ${props.theme.border.color};`
        : `
    min-height: ${props.theme.buttons.sizes.large.minHeightMobile};
    padding-right: 1.5rem;
    padding-left: 2rem;`}
  }

  &:hover {
    border-color: ${(props) =>
      props.theme.buttons.colors.largeHover.borderColor};
    background-color: ${(props) =>
      props.theme.buttons.colors.largeHover.bgColor};

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      ${(props) =>
        !props.isButton
          ? `
      border-color: ${props.theme.border.color};
      background-color: transparent;`
          : `
          border-color: ${props.theme.buttons.colors.large.borderColor};
          background-color: ${props.theme.buttons.colors.large.bgColor};
      `}
    }
  }

  &:last-of-type {
    margin-bottom: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      ${(props) => (!props.isButton ? `border-bottom: 0;` : '')}
    }
  }
`

const NavButtonIcon = styled.span`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.buttons.colors.large.iconColor};

  button:hover & {
    color: ${(props) => props.theme.buttons.colors.largeHover.iconColor};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      color: ${(props) => props.theme.buttons.colors.large.iconColor};
    }
  }
`

const NavButtonText = styled.span<{ showIcon: boolean }>`
  flex-grow: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 2.5rem;
  padding-left: ${(props) => (props.showIcon ? '2.5rem' : '0')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-right: 2.25rem;
    padding-left: ${(props) => (props.showIcon ? '2.25rem' : '0')};
  }
`

const NavButtonTitle = styled.span`
  display: block;
  margin: 0;
  margin-top: ${(props) => props.theme.buttons.sizes.large.titleMarginTop};
  margin-left: ${(props) => props.theme.buttons.sizes.large.titleMarginLeft};
  transition: ${(props) => props.theme.links.transition};
  font-family: ${(props) => props.theme.buttons.sizes.large.family};
  font-weight: ${(props) => props.theme.buttons.sizes.large.weight};
  font-style: ${(props) => props.theme.buttons.sizes.large.fontStyle};
  letter-spacing: ${(props) => props.theme.buttons.sizes.large.letterSpacing};
  line-height: 1;
  text-transform: ${(props) => props.theme.buttons.sizes.large.textTransform};
  -webkit-font-smoothing: ${(props) =>
    props.theme.buttons.sizes.large.fontSmoothing};
  font-size: ${(props) => props.theme.buttons.sizes.large.titleFontSize};
  color: ${(props) => props.theme.buttons.colors.large.color};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: ${(props) =>
      props.theme.buttons.sizes.large.titleMarginTopMobile};
    margin-left: ${(props) =>
      props.theme.buttons.sizes.large.titleMarginLeftMobile};
    font-size: ${(props) =>
      props.theme.buttons.sizes.large.titleFontSizeMobile};
  }

  button:hover & {
    color: ${(props) => props.theme.buttons.colors.largeHover.color};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      color: ${(props) => props.theme.buttons.colors.large.color};
    }
  }
`

const NavButtonSubtitle = styled(Body)`
  display: block;
  margin: 0;
  margin-top: ${(props) => props.theme.buttons.sizes.large.subtitleMarginTop};
  transition: ${(props) => props.theme.links.transition};
  font-size: ${(props) => props.theme.buttons.sizes.large.subtitleFontSize};
  font-weight: ${(props) => props.theme.buttons.sizes.large.subtitleFontWeight};
  color: ${(props) => props.theme.buttons.colors.large.color};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: ${(props) =>
      props.theme.buttons.sizes.large.subtitleMarginTopMobile};
    font-size: ${(props) =>
      props.theme.buttons.sizes.large.subtitleFontSizeMobile};
  }

  button:hover & {
    color: ${(props) => props.theme.buttons.colors.largeHover.color};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      color: ${(props) => props.theme.buttons.colors.large.color};
    }
  }
`

const NavButtonArrow = styled.span`
  position: relative;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 0;
  flex-shrink: 0;
  color: ${(props) => props.theme.buttons.colors.large.iconColor};
  transition: ${(props) => props.theme.links.transition};
  transform: translateX(0);

  button:hover & {
    transform: translateX(1rem);
    color: ${(props) => props.theme.buttons.colors.largeHover.iconColor};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      color: ${(props) => props.theme.buttons.colors.large.iconColor};
      transform: translateX(0);
    }
  }
`

export interface NavButtonProps {
  title?: string
  handler?: () => void
  iconName?: string
  subtitle?: string | null
  icon?: JSX.Element
  onClick: () => void
  delay?: string
}

const NavButton = ({
  title,
  subtitle,
  icon,
  onClick,
  delay = '0.125s',
}: NavButtonProps) => {
  const theme = useTheme()
  const {
    showSubtitle,
    showSubtitleMobile,
    showButtonApp,
    showIcons,
    showIconsMobile,
  } = theme.buttons.sizes.large
  const show = isMobile ? showSubtitleMobile : showSubtitle
  const showIcon = isMobile ? showIconsMobile : showIcons

  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    onClick()
  }

  return (
    <NavButtonView onClick={handleClick} delay={delay} isButton={showButtonApp}>
      {showIcon && <NavButtonIcon>{icon}</NavButtonIcon>}
      <NavButtonText showIcon={showIcon}>
        <NavButtonTitle>{title}</NavButtonTitle>
        {subtitle && show ? (
          <NavButtonSubtitle>{subtitle}</NavButtonSubtitle>
        ) : null}
      </NavButtonText>
      <NavButtonArrow>
        <ArrowRight strokeWidth={2} />
      </NavButtonArrow>
    </NavButtonView>
  )
}

export default NavButton
