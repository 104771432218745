import { OrderRevenueCenter as OrderRevenueCenterType } from '@open-tender/types'

const OrderRevenueCenter = ({
  revenueCenter,
}: {
  revenueCenter: OrderRevenueCenterType
}) => {
  const { address: rcAddr } = revenueCenter || {}
  return (
    <>
      <p>{revenueCenter.name}</p>
      <p>
        {rcAddr.street}, {rcAddr.city}, {rcAddr.state} {rcAddr.postal_code}
      </p>
      <p>{rcAddr.phone}</p>
    </>
  )
}

export default OrderRevenueCenter
