import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  selectBrand,
  selectCustomerTpls,
  useAppDispatch,
  useAppSelector,
  verifyCustomerTpls,
} from '@open-tender/cloud'
import { Body, ButtonLink, Loading } from 'components'
import { useState } from 'react'

const VerifyAccountTplsView = styled.div`
  margin: 0 0 ${(props) => props.theme.layout.padding};
  margin: 2rem 0;

  p + p {
    margin: 1rem 0 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const VerifyAccountTpls = ({ style }: { style?: React.CSSProperties }) => {
  const dispatch = useAppDispatch()
  const { colors } = useTheme()
  const [sent, setSent] = useState(false)
  const { tpls } = useAppSelector(selectBrand) || {}
  const { tpls: profile, loading } = useAppSelector(selectCustomerTpls)
  const isLoading = loading === 'pending'
  const isVerified = profile?.is_verified

  const verifyAccount = () => {
    dispatch(verifyCustomerTpls())
    setSent(true)
  }

  if (tpls !== 'PUNCHH' || isVerified) return null

  return (
    <VerifyAccountTplsView style={style}>
      {isLoading ? (
        <Loading />
      ) : sent ? (
        <>
          <Body as="p">Please check your email for the verification link!</Body>
          <Body as="p">
            <ButtonLink onClick={() => setSent(false)}>
              Or click here to resend the verification email.
            </ButtonLink>
          </Body>
        </>
      ) : (
        <>
          <Body as="p" style={{ marginTop: 10, color: colors.error }}>
            Your account has not yet been verfied, which is required for earning
            & redeeming rewards.{' '}
          </Body>
          <Body as="p">
            <ButtonLink onClick={verifyAccount}>
              Click here to verify your account.
            </ButtonLink>
          </Body>
        </>
      )}
    </VerifyAccountTplsView>
  )
}

export default VerifyAccountTpls
