import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuCategory } from '@open-tender/types'
import { slugify } from '@open-tender/utils'
import {
  selectCurrentCategory,
  selectMenu,
  selectMenuSlug,
  setCurrentCategory,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  CategorySwitchButton,
  CategorySwitchNavButton,
  CategorySwitchScroll,
  CategorySwitchView,
} from './CategorySwitch.styled'
import { isMobile } from 'react-device-detect'
import { ChevronLeft, ChevronRight } from 'components/icons'

const CategorySwitch = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const stickyRef = useRef<HTMLDivElement | null>(null)
  const navRef = useRef<HTMLDivElement | null>(null)
  const [offset, setOffset] = useState(0)
  const { categories } = useAppSelector(selectMenu)
  const category = useAppSelector(selectCurrentCategory)
  const menuSlug = useAppSelector(selectMenuSlug)
  const [contentIsOverFlowing, setContentIsOverFlowing] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const showArrows = !isMobile && contentIsOverFlowing && navRef.current

  const handleScroll = (scrollOffset: number) => {
    if (!navRef.current) return
    navRef.current.scrollLeft += scrollOffset
  }

  const browse = (category: MenuCategory) => {
    window.scrollTo(0, 0)
    dispatch(setCurrentCategory(category))
    navigate(`${menuSlug}/category/${slugify(category.name)}`)
  }

  useEffect(() => {
    if (offset && navRef.current) {
      navRef.current.scrollLeft = offset
    }
  }, [offset])

  useEffect(() => {
    const navActive = document.getElementById(`nav-${category?.id}`)
    if (navActive) {
      const navOffset = navActive.getBoundingClientRect().x
      const parentOffset = navActive?.offsetParent?.getBoundingClientRect().x
      setOffset(navOffset - (parentOffset ?? 0))
    }
  }, [category?.id])

  useEffect(() => {
    const updateArrowsVisibility = () => {
      if (navRef.current) {
        const { scrollWidth, clientWidth } = navRef.current
        setContentIsOverFlowing(scrollWidth > clientWidth)
      }
    }
    updateArrowsVisibility()
    window.addEventListener('resize', updateArrowsVisibility)
    return () => window.removeEventListener('resize', updateArrowsVisibility)
  }, [])

  useEffect(() => {
    const navRefCurrent = navRef.current
    if (!navRefCurrent) return
    const handleScroll = () => {
      setScrollPosition(navRefCurrent.scrollLeft)
    }
    navRefCurrent.addEventListener('scroll', handleScroll)
    return () => navRefCurrent.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <CategorySwitchView ref={stickyRef}>
      {showArrows && (
        <CategorySwitchNavButton
          onClick={() => handleScroll(-500)}
          disabled={scrollPosition === 0}
        >
          <ChevronLeft size={25} />
        </CategorySwitchNavButton>
      )}
      <CategorySwitchScroll ref={navRef}>
        <ul>
          {categories.map((i) => (
            <li key={i.id} id={`nav-${i.id}`}>
              <CategorySwitchButton
                onClick={() => browse(i)}
                active={i.id === category?.id}
              >
                {i.name}
              </CategorySwitchButton>
            </li>
          ))}
        </ul>
      </CategorySwitchScroll>
      {showArrows && (
        <CategorySwitchNavButton onClick={() => handleScroll(500)}>
          <ChevronRight size={25} />
        </CategorySwitchNavButton>
      )}
    </CategorySwitchView>
  )
}

export default CategorySwitch
