import { useEffect, useCallback } from 'react'
import { FormFieldType } from '@open-tender/types'
import { useGiftCardAssignForm } from '@open-tender/utils'
import {
  assignCustomerGiftCard,
  closeModal,
  resetCustomerGiftCardsError,
  selectCustomerGiftCards,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const GiftCardAssignForm = ({
  windowRef,
}: {
  windowRef: React.RefObject<HTMLDivElement>
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectCustomerGiftCards)
  const callback = useCallback(() => dispatch(closeModal()), [dispatch])
  const assign = useCallback(
    (cardNumber: number, callback: () => void) =>
      dispatch(assignCustomerGiftCard({ cardNumber, callback })),
    [dispatch]
  )

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useGiftCardAssignForm(loading, error, assign, callback)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error && windowRef.current) windowRef.current.scrollTop = 0
  }, [error, windowRef])

  useEffect(() => {
    return () => {
      dispatch(resetCustomerGiftCardsError())
    }
  }, [dispatch])

  return (
    <form id="gift-card-assign-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : 'Assign Gift Card'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default GiftCardAssignForm
