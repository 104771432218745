import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { selectBrand, useAppSelector } from '@open-tender/cloud'

const HeaderLogoLink = styled.a<{
  isMobile?: boolean
}>`
  display: block;
  line-height: 0;
  max-width: ${(props) => props.theme.logo.width};
  margin: ${(props) => props.theme.logo.marginTop} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: ${(props) => props.theme.logo.widthMobile};
    margin: ${(props) => props.theme.logo.marginTopMobile} 0 0;
  }

  img {
    pointer-events: none;
  }
`

const HeaderLogo = ({ useLight = false }: { useLight?: boolean }) => {
  const { url, logo, logoLight } = useAppSelector(selectBrand) || {}
  const logoUrl = useLight ? logoLight : logo

  return (
    <HeaderLogoLink
      isMobile={isMobile}
      href={url || '/'}
      rel="noopener noreferrer"
    >
      <img src={logoUrl} alt="logo" />
    </HeaderLogoLink>
  )
}

export default HeaderLogo
