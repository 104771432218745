import { ElementType } from 'react'
import styled from '@emotion/styled'
import { FormError } from '.'

const FormRowView = styled('label')<{ isInput?: boolean }>`
  display: block;
  padding: 1.25rem 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0.75rem 0;
    ${(props) => (props.isInput ? `border: 0 !important;` : null)}
  }

  & + * {
    border-top-style: solid;
    border-top-width: ${(props) => props.theme.border.width};
    border-top-color: ${(props) => props.theme.border.color};
  }
`

const FormRowContainer = styled.span<{
  isInput?: boolean
  labelWidth?: string
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    ${(props) =>
      props.isInput ? `flex-direction: column; align-items: flex-start;` : null}
  }

  > span {
    display: block;

    &:first-of-type {
      width: ${(props) => props.labelWidth || '15rem'};
      flex-shrink: ${(props) => (props.labelWidth ? '1' : '0')};
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        ${(props) =>
          props.isInput ? `width: 100%; margin: 0 0 0.75rem;` : null}
      }
    }

    &:last-of-type {
      position: relative;
      flex: 1;
      flex-shrink: ${(props) => (props.labelWidth ? '0' : '1')};
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        ${(props) => (props.isInput ? `width: 100%;` : null)}
      }
    }
  }
`

const FormRowLabel = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;

  > span {
    display: block;
  }
`

const FormRowInput = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    display: block;
  }

  input,
  select,
  textarea {
    line-height: ${(props) => props.theme.lineHeight};
  }
`

const FormRow = ({
  label,
  input,
  errMsg,
  as = 'label',
  isInput = false,
  labelWidth,
  style,
}: {
  label: JSX.Element | JSX.Element[] | string | any
  input: JSX.Element | JSX.Element[] | string | any
  errMsg?: string
  as?: ElementType
  isInput?: boolean
  labelWidth?: string
  style?: React.CSSProperties
}) => {
  return (
    <FormRowView as={as} isInput={isInput} style={style}>
      <FormRowContainer isInput={isInput} labelWidth={labelWidth}>
        {label && (
          <span>
            <FormRowLabel>{label}</FormRowLabel>
          </span>
        )}
        <span>
          <FormRowInput>{input}</FormRowInput>
        </span>
      </FormRowContainer>
      <FormError errMsg={errMsg} />
    </FormRowView>
  )
}

export default FormRow
