import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useResetPasswordForm } from '@open-tender/utils'
import {
  selectResetPassword,
  resetPassword,
  resetPasswordReset,
  useAppDispatch,
  useAppSelector,
  openModal,
} from '@open-tender/cloud'
import {
  ButtonLink,
  ButtonSubmit,
  FormError,
  FormInputs,
  FormSubmit,
  FormWrapper,
} from 'components'
import { FormFieldType } from '@open-tender/types'

const ResetPasswordForm = ({ resetToken }: { resetToken: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { success, loading, error } = useAppSelector(selectResetPassword)
  const reset = useCallback(
    (new_password: string, resetToken: string) =>
      dispatch(resetPassword({ new_password, resetToken })),
    [dispatch]
  )

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useResetPasswordForm(loading, error, reset, resetToken)

  const handleLogin = () => {
    const args = {
      type: 'login',
      args: { callback: () => navigate('/account') },
    }
    dispatch(openModal(args))
  }

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    dispatch(resetPasswordReset())
    return () => {
      dispatch(resetPasswordReset())
    }
  }, [dispatch])

  useEffect(() => {
    if (error) window.scrollTo(0, 0)
  }, [error])

  return (
    <FormWrapper>
      {success ? (
        <>
          <p style={{ margin: '0 0 2rem' }}>
            Success! Your password has been reset.
          </p>
          <ButtonLink onClick={handleLogin}>
            Click here to log into your account
          </ButtonLink>
        </>
      ) : (
        <form id="reset-password-form" onSubmit={handleSubmit} noValidate>
          <FormError
            errMsg={errors.token || errors.form}
            style={{ margin: '0 0 2rem' }}
          />
          <FormInputs
            fields={fields}
            data={data}
            onChange={onChange}
            errors={errors}
          />
          <FormSubmit>
            <ButtonSubmit submitRef={submitRef} submitting={submitting}>
              {submitting ? 'Submitting...' : 'Submit'}
            </ButtonSubmit>
          </FormSubmit>
        </form>
      )}
    </FormWrapper>
  )
}

export default ResetPasswordForm
