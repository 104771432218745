import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { selectBrand, useAppDispatch, useAppSelector } from '@open-tender/cloud'
import {
  CreditCard,
  Gift,
  Home,
  Mail,
  MapPin,
  Sliders,
  User,
} from 'components/icons'
import { NavButtons } from 'components'

const AccountButtonsView = styled.div`
  max-width: 54rem;
  margin: 0 auto;

  button {
    height: 6rem;
  }
`

interface NavButton {
  icon: JSX.Element
  title: string
  path: string
  func?: any
}

const iconProps = isMobile ? { size: 18 } : { size: 20 }

const navButtons: Array<NavButton> = [
  {
    icon: <User {...iconProps} />,
    title: 'Profile',
    path: '/account/profile',
  },
  {
    icon: <Mail {...iconProps} />,
    title: 'Communicaton Preferences',
    path: '/account/communications',
  },
  {
    icon: <Sliders {...iconProps} />,
    title: 'Dietary Preferences',
    path: '/account/allergens',
  },
  {
    icon: <Gift {...iconProps} />,
    title: 'Gift Cards',
    path: '/account/gift-cards',
  },
  {
    icon: <CreditCard {...iconProps} />,
    title: 'Payment Methods',
    path: '/account/credit-cards',
  },
  {
    icon: <MapPin {...iconProps} />,
    title: 'Addresses',
    path: '/account/addresses',
  },
  {
    icon: <Home {...iconProps} />,
    title: 'House Accounts',
    path: '/account/house-accounts',
  },
]

const AccountSettingsButtons = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { has_allergens, has_house_accounts, has_gift_cards } =
    useAppSelector(selectBrand) || {}
  let removed: string[] = []
  if (!has_gift_cards) removed.push('/account/gift-cards')
  if (!has_allergens) removed.push('/account/allergens')
  if (!has_house_accounts) removed.push('/account/house-accounts')
  const filteredButtons = navButtons.filter((i) => !removed.includes(i.path))
  const buttons = filteredButtons.map((i) => ({
    ...i,
    onClick: i.path
      ? () => navigate(i.path)
      : () => i.func && dispatch(i.func()),
  }))

  return (
    <AccountButtonsView>
      <NavButtons buttons={buttons} />
    </AccountButtonsView>
  )
}

export default AccountSettingsButtons
