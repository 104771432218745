import styled from '@emotion/styled'
import { ThemeFontSize } from '@open-tender/types'
import { ColorName } from 'utils/types'

const Body = styled.span<{ size?: ThemeFontSize; color?: ColorName }>`
  ${({ theme, size, color }) => `
    font-family: ${theme.fonts.body.family};
    font-weight: ${theme.fonts.body.weight};
    font-style: ${theme.fonts.body.fontStyle};
    letter-spacing: ${theme.fonts.body.letterSpacing};
    line-height: ${theme.fonts.body.lineHeight};
    text-transform: ${theme.fonts.body.textTransform};
    -webkit-font-smoothing: ${theme.fonts.body.fontSmoothing};
    color: ${color ? theme.colors[color] : theme.fonts.body.color};
    ${size ? `font-size: ${theme.fonts.sizes[size]};` : ''}
  `}
`

export default Body
