import styled from '@emotion/styled'
import { MenuCategory } from '@open-tender/types'
import MenuListItem from './MenuListItem'

const MenuListView = styled.div`
  max-width: ${(props) => props.theme.categories.desktop.containerMaxWidth};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: ${(props) => props.theme.categories.mobile.containerMaxWidth};
  }
`

const MenuListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.theme.categories.desktop.justifyContent};
  margin: 0 -${(props) => props.theme.categories.desktop.gap};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: ${(props) => props.theme.categories.mobile.justifyContent};
    margin: 0 -${(props) => props.theme.categories.mobile.gap};
  }
`

const MenuList = ({
  sections,
  categories,
}: {
  sections: Partial<MenuCategory>[]
  categories: Partial<MenuCategory>[]
}) => {
  if (!categories || !categories.length) return null

  return (
    <MenuListView>
      <MenuListContainer>
        {sections.map((section) => (
          <MenuListItem
            key={section.name}
            category={section}
            section={section.name}
          />
        ))}
        {categories.map((category, index) => (
          <MenuListItem
            key={category.name}
            category={category}
            isLast={categories.length - 1 === index}
          />
        ))}
      </MenuListContainer>
    </MenuListView>
  )
}

export default MenuList
