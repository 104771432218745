import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { OrderCreateDetails, OrderDetails } from '@open-tender/types'
import { isEmpty } from '@open-tender/utils'
import {
  selectCheckout,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Checkbox, Input } from 'components'
import CheckoutSection from './CheckoutSection'
import CheckoutInputs from './CheckoutInputs'

const CheckoutCurbsideView = styled.div`
  margin: 1.5rem 0 0;
`

const CheckoutCurbsideCheckbox = styled.div`
  label {
    margin: 0 0 2rem;
  }
`

const CheckoutCurbsideInputs = styled.div<{ show: boolean }>`
  opacity: ${(props) => (props.show ? '1' : '0')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.show ? '100%' : '0')};
`

const curbsideEmpty = {
  vehicle_color: '',
  vehicle_id: '',
  vehicle_type: '',
}

const hasOrderFulfillment = (details: OrderCreateDetails): boolean => {
  const values = Object.entries(details).reduce((arr, [key, value]) => {
    return key.startsWith('vehicle') ? [...arr, value] : arr
  }, [] as string[])
  const nonEmpty = values.filter((val) => val && val.length > 0)
  return nonEmpty.length ? true : false
}

const makeCurbsideDetails = (details: OrderDetails) => {
  return Object.entries(details).reduce((obj, [key, value]) => {
    return key.startsWith('vehicle') ? { ...obj, [key]: value } : obj
  }, {})
}

const CheckoutCurbside = ({
  setInitialValue,
}: {
  setInitialValue: boolean
}) => {
  const dispatch = useAppDispatch()
  const [showCurbside, setShowCurbside] = useState(false)
  const [isInitial, setIsInitial] = useState(true)
  const { check, form, errors } = useAppSelector(selectCheckout)
  const curbside = check?.config.order_fulfillment
  const hasCurbside = hasOrderFulfillment(form.details)
  const formDetails = useMemo(() => form.details, [form.details])
  const shouldPrefill =
    (!formDetails || isEmpty(formDetails)) && setInitialValue
  const detailsErrors =
    typeof errors.details !== 'string' ? errors.details || {} : {}

  const handleChange = (name: string, value: string) => {
    const details = { ...form.details, [name]: value }
    const order_fulfillment = hasOrderFulfillment(details)
    dispatch(updateForm({ details: { ...details, order_fulfillment } }))
  }

  const handleCheckbox = () => {
    if (showCurbside) {
      const details = { ...form.details, ...curbsideEmpty }
      const order_fulfillment = hasOrderFulfillment(details)
      dispatch(updateForm({ details: { ...details, order_fulfillment } }))
    }
    setShowCurbside(!showCurbside)
  }

  useEffect(() => {
    if (shouldPrefill && check?.details) {
      const checkDetails = makeCurbsideDetails(check.details)
      dispatch(updateForm({ details: checkDetails }))
    }
  }, [shouldPrefill, check?.details, dispatch])

  useEffect(() => {
    if (hasCurbside && !showCurbside && isInitial) {
      setShowCurbside(true)
      setIsInitial(false)
    }
  }, [isInitial, showCurbside, hasCurbside])

  if (!curbside) return null

  return (
    <CheckoutSection
      title={curbside.title}
      subtitle={curbside.description}
      style={{ padding: '0' }}
    >
      <CheckoutCurbsideView>
        <CheckoutCurbsideCheckbox>
          <Checkbox
            type="checkbox"
            showLabel={true}
            required={true}
            name="showCurbside"
            on={showCurbside}
            onChange={handleCheckbox}
            description="Use Curbside Pickup"
          />
        </CheckoutCurbsideCheckbox>
        <CheckoutCurbsideInputs show={showCurbside}>
          <CheckoutInputs>
            {curbside?.fields?.map((field) => {
              if (field.name === 'acknowledged') return null
              return (
                <Input
                  key={field.name}
                  type="text"
                  label={field.label}
                  name={field.name}
                  value={(form.details[field.name] as string) || ''}
                  onChange={(evt) => handleChange(field.name, evt.target.value)}
                  error={detailsErrors[field.name] as string}
                />
              )
            })}
          </CheckoutInputs>
        </CheckoutCurbsideInputs>
      </CheckoutCurbsideView>
    </CheckoutSection>
  )
}

export default CheckoutCurbside
