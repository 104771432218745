import styled from '@emotion/styled'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const HistoryEvents = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
  max-width: ${(props) => props.theme.layout.maxWidth};
  margin: 0 auto;
`

export const HistoryEventView = styled.div`
  display: flex;
  justify-content: space-between
  align-items: flex-start;
  gap: ${(props) => props.theme.layout.padding};
  margin: 0 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
  }
`

export const HistoryEventDate = styled(Body)`
  flex: 0 0 14rem;
  text-align: left;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex: 0 0 auto;
  }
`

export const HistoryEventContent = styled.div`
  flex: 1 1 100%;
  text-align: left;
`

export const HistoryEventTitle = styled(Heading)`
  display: block;
`

export const HistoryEventDescription = styled(Body)`
  display: block;
  margin: 0.5rem 0 0;
`

export const HistoryEventCounts = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0 0;
`

export const HistoryEventCount = styled(Heading)`
  padding: 0.6rem 1rem;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  border-style: solid;
  border-color: ${(props) => props.theme.border.color};
  border-width: ${(props) => props.theme.border.width};
  border-radius: ${(props) => props.theme.border.radius};
`
