import styled from '@emotion/styled'
import { Button } from 'components'
import { Input } from 'components/inputs'

const MenuItemInstructionsView = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.layout.padding};
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
  }
`

const MenuItemInstructionsContainer = styled.div`
  border-radius: ${(props) => props.theme.border.radius};
  padding: ${(props) => props.theme.layout.padding};
  background-color: ${(props) => props.theme.bgColors.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
  }
`

const MenuItemInstructionsInput = styled.div`
  width: 100%;
  margin: 0 0 2rem;

  label {
    margin: 0;
  }
`

const MenuItemInstructionsFooter = styled.div`
  margin: 3rem 0 0;
  text-align: center;
`

interface MenuItemInstructionsProps {
  hasMadeFor: boolean
  madeFor: string | null
  setMadeFor: (madeFor: string) => void
  hasNotes: boolean
  notes: string | null
  setNotes: (notes: string) => void
  allDone?: () => void
}

const MenuItemInstructions = ({
  hasMadeFor,
  madeFor,
  setMadeFor,
  hasNotes,
  notes,
  setNotes,
  allDone,
}: MenuItemInstructionsProps) => {
  return (
    <MenuItemInstructionsView>
      <MenuItemInstructionsContainer>
        {hasMadeFor && (
          <MenuItemInstructionsInput>
            <Input
              label="Who is this order for?"
              name="made-for"
              type="text"
              value={madeFor || ''}
              onChange={(evt) => setMadeFor(evt.target.value)}
            />
          </MenuItemInstructionsInput>
        )}
        {hasNotes && (
          <MenuItemInstructionsInput>
            <Input
              label="Any special instructions?"
              name="notes"
              type="text"
              value={notes || ''}
              onChange={(evt) => setNotes(evt.target.value)}
            />
          </MenuItemInstructionsInput>
        )}
        <MenuItemInstructionsFooter>
          <Button onClick={allDone}>All Done</Button>
        </MenuItemInstructionsFooter>
      </MenuItemInstructionsContainer>
    </MenuItemInstructionsView>
  )
}

export default MenuItemInstructions
