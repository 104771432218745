import {
  ProgressPointsView,
  ProgressPointsFill,
  ProgressPointsContainer,
  ProgressPointsBar,
  ProgressPoint,
  ProgressPointText,
} from './ProgressPoints'
import { LoyaltyType } from '@open-tender/types'
import Heading from './Heading'

const ProgressSpend = ({
  progress,
  threshold,
}: {
  spendName: string
  progress: string
  threshold: string
  loyaltyType: LoyaltyType
}) => {
  const s = parseFloat(progress)
  const t = parseFloat(threshold)
  const progressPercent = Math.max(Math.min((s / t) * 100, 100), 4) // show at least 4% for the progress bar

  return (
    <ProgressPointsContainer>
      <ProgressPointsView>
        <Heading size="h5" color="alert">
          0
        </Heading>
        <ProgressPointsBar>
          <ProgressPointsFill progress={progressPercent} />
          {!!s && (
            <ProgressPoint
              progress={progressPercent}
              style={{ marginLeft: '-2rem' }}
            >
              {/* <ProgressPointCircle /> */}
              <ProgressPointText>{s}</ProgressPointText>
            </ProgressPoint>
          )}
        </ProgressPointsBar>
        <Heading size="h5" color="alert">
          {t}
        </Heading>
      </ProgressPointsView>
    </ProgressPointsContainer>
  )
}

export default ProgressSpend
