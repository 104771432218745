import styled from '@emotion/styled'
import { Body } from 'components'

export const MenuItemWrapper = styled.div`
  label: MenuItemWrapper;
  position: relative;
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
`

export const MenuItemView = styled.div`
  label: MenuItemView;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: ${(props) => props.theme.menuItems.box.transition};
  min-width: ${(props) => props.theme.menuItems.box.minWidth};
  padding: ${(props) => props.theme.menuItems.box.padding};

  border-style: solid;
  border-width: ${(props) => props.theme.menuItems.box.border};
  border-color: ${(props) => props.theme.menuItems.box.borderColor};
  border-radius: ${(props) => props.theme.menuItems.box.borderRadius};

  margin: ${(props) => props.theme.menuItems.box.margin};
  background-color: ${(props) => props.theme.menuItems.box.bgColor};
  box-shadow: ${(props) => props.theme.menuItems.box.boxShadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: ${(props) => props.theme.menuItems.box.minWidthMobile};
    padding: ${(props) => props.theme.menuItems.box.paddingMobile};
    border-width: ${(props) => props.theme.menuItems.box.borderMobile};
    border-radius: ${(props) => props.theme.menuItems.box.borderRadiusMobile};
    margin: ${(props) => props.theme.menuItems.box.marginMobile};
  }

  &.show-hover:hover {
    border-color: ${(props) => props.theme.menuItems.box.borderColorHover};
    background-color: ${(props) => props.theme.menuItems.box.bgColorHover};
    box-shadow: ${(props) => props.theme.menuItems.box.boxShadowHover};
  }
`

export const MenuItemButtons = styled.div`
  label: MenuItemButtons;
  flex-grow: 0;
  padding: ${(props) => props.theme.menuItems.buttons.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.menuItems.buttons.paddingMobile};
  }
`

export const MenuItemButtonsContainer = styled.div`
  padding: ${(props) => props.theme.menuItems.buttons.padding};
  label: MenuItemButtonsContainer;
  display: flex;
  flex-direction: ${(props) => props.theme.menuItems.buttons.flexDirection};
  justify-content: ${(props) => props.theme.menuItems.buttons.justifyContent};
  align-items: center;
  gap: ${(props) => props.theme.menuItems.buttons.gap};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: ${(props) => props.theme.menuItems.buttons.gapMobile};
  }
`

export const MenuItemButtonsWarning = styled(Body)`
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  color: ${(props) => props.theme.colors.error};
`

export const MenuItemButtonsAdd = styled.div<{ disabled?: boolean }>``

export const MenuItemButtonsCustomize = styled.div<{
  customizeIsPrimary?: boolean
}>`
  button,
  button:active,
  button:hover,
  button:disabled {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: ${(props) => props.theme.links.customize.color};
  }

  button:hover {
    color: ${(props) => props.theme.links.customize.hover};
  }
`

export const MenuItemButtonsQuickAdd = styled.div`
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 4rem;
    height: 4rem;
  }

  button {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 2.8rem;
    padding: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`
