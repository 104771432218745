import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { MenuCategory } from '@open-tender/types'
import {
  selectCategoryType,
  selectMenu,
  useAppSelector,
} from '@open-tender/cloud'
import MenuCards from './MenuCards'
import MenuList from './MenuList'
import MenuSquares from './MenuSquares'
import MenuGroupOrder from './MenuGroupOrder'

const MenuBrowseView = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: ${(props) => props.theme.layout.padding} 0;
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.paddingMobile} 0;
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const MenuBrowse = ({
  menuSections,
}: {
  menuSections: Partial<MenuCategory>[]
}) => {
  const displayType = useAppSelector(selectCategoryType(isMobile))
  const { categories, revenueCenters } = useAppSelector(selectMenu)
  const sections = revenueCenters ? [] : menuSections
  const menuCategories = revenueCenters || categories

  if (!categories || !categories.length) return null

  return (
    <>
      <MenuGroupOrder />
      <MenuBrowseView>
        {displayType === 'CARDS' ? (
          <MenuCards sections={sections} categories={menuCategories} />
        ) : displayType === 'SQUARES' ? (
          <MenuSquares sections={sections} categories={menuCategories} />
        ) : (
          <MenuList sections={sections} categories={menuCategories} />
        )}
      </MenuBrowseView>
    </>
  )
}

export default MenuBrowse
