import { toggleNav, useAppDispatch } from '@open-tender/cloud'
import { Hamburger } from 'components/icons'
import Icon from './Icon'

const NavMenu = () => {
  const dispatch = useAppDispatch()

  return (
    <Icon
      aria-label={'Menu'}
      margin="0 -1.25rem 0 0"
      onClick={() => dispatch(toggleNav())}
    >
      <Hamburger size={24} />
    </Icon>
  )
}

export default NavMenu
