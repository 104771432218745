import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import {
  setGeoLatLng,
  setGeoError,
  setGeoLoading,
  useAppDispatch,
} from '@open-tender/cloud'
import useGeolocation from 'components/maps/useGeolocation'

const Geolocation = () => {
  const dispatch = useAppDispatch()
  const { geoLatLng, geoError } = useGeolocation()

  useEffect(() => {
    dispatch(setGeoLoading())
  }, [dispatch])

  useEffect(() => {
    if (geoLatLng) {
      dispatch(setGeoLatLng(geoLatLng))
    } else if (geoError) {
      dispatch(setGeoError(geoError))
    }
  }, [geoLatLng, geoError, dispatch])

  return <Outlet />
}

export default Geolocation
