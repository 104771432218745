import styled from '@emotion/styled'
import { Theme, ThemeAlertType } from '@open-tender/types'

const Message = styled.span<{ color?: ThemeAlertType; size?: string }>`
  display: inline-block;
  ${({
    theme,
    color = 'alert',
    size = 'main',
  }: {
    theme: Theme
    color?: ThemeAlertType
    size?: string
  }) => `
    padding: ${size === 'small' ? '0.6em 1.2rem' : '1em 1em'};
    border-radius: ${theme.border.radiusSmall};
    font-size: ${theme.fonts.sizes[size]};
    line-height: ${theme.lineHeight};
    color: ${theme[color].color};
    background-color: ${theme[color].bgColor};
  `}
`

export default Message
