import { useNavigate } from 'react-router-dom'
import { Address, Addresses as AddressesType } from '@open-tender/types'
import {
  openModal,
  removeCustomerAddress,
  setAddress,
  updateCustomerAddress,
  useAppDispatch,
} from '@open-tender/cloud'
import {
  ButtonLink,
  Button,
  LinkSeparator,
  OrderAddress,
  Preface,
  Row,
} from 'components'

interface AddressesProps {
  addresses: AddressesType
  isLoading?: boolean
}

const Addresses = ({ addresses, isLoading }: AddressesProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleEdit = (address: Address) => {
    dispatch(openModal({ type: 'address', args: { address } }))
  }

  const handleDefault = (address: Address) => {
    const data = { ...address, is_default: true }
    const addressId = address.customer_address_id
    delete data.customer_address_id
    delete data.created_at
    delete data.last_used_at
    if (addressId) dispatch(updateCustomerAddress({ addressId, data }))
  }

  const handleDelete = (address: Address) => {
    const addressId = address.customer_address_id
    if (addressId) dispatch(removeCustomerAddress({ addressId }))
  }

  const handleReorder = (address: Address) => {
    dispatch(setAddress(address))
    navigate('/order-type')
  }

  return (
    <div>
      {addresses.map((address) => {
        let preface: string[] = []
        if (address.description) preface.push(address.description)
        if (address.is_default) preface.push('Default')
        return (
          <Row
            key={address.customer_address_id}
            content={
              <>
                {preface.length > 0 && (
                  <Preface
                    size="xSmall"
                    color="tertiary"
                    style={{ display: 'inline-block', margin: '0 0 0.5rem' }}
                  >
                    {preface.join(' | ')}
                  </Preface>
                )}
                <OrderAddress address={address} isCard={true}>
                  <p>
                    <ButtonLink
                      onClick={() => handleEdit(address)}
                      disabled={isLoading}
                    >
                      edit
                    </ButtonLink>
                    <LinkSeparator />
                    <ButtonLink
                      onClick={() => handleDefault(address)}
                      disabled={address.is_default || isLoading}
                    >
                      make primary
                    </ButtonLink>
                    <LinkSeparator />
                    <ButtonLink
                      onClick={() => handleDelete(address)}
                      disabled={isLoading}
                    >
                      remove
                    </ButtonLink>
                  </p>
                </OrderAddress>
              </>
            }
            actions={
              <Button
                onClick={() => handleReorder(address)}
                size="small"
                disabled={isLoading}
              >
                Order from here
              </Button>
            }
          />
        )
      })}
    </div>
  )
}

export default Addresses
