import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { formatDollars } from '@open-tender/utils'
import {
  selectCheckout,
  selectContent,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import CheckoutSection from './CheckoutSection'
import CheckoutButton from './CheckoutButton/CheckoutButton'
import { CheckoutErrorMessages } from '@open-tender/types'
import { FormError } from 'components/inputs'

const CheckoutSurchargesView = styled.div`
  margin: 1.5rem 0 0;
`

const makeErrorMsgs = (errors: CheckoutErrorMessages) => {
  if (!errors.surcharges) return null
  return Object.values(errors.surcharges).filter(
    (i) => typeof i === 'string'
  ) as string[]
}

const CheckoutSurcharges = () => {
  const dispatch = useAppDispatch()
  const { check, form, loading, errors } = useAppSelector(selectCheckout)
  const { checkout: config } = useAppSelector(selectContent) || {}
  const [pendingSurcharge, setPendingSurcharge] = useState<number | null>(null)
  const surchargeIds = form.surcharges.map((i) => i.id)
  const errMsgs = makeErrorMsgs(errors)

  useEffect(() => {
    if (loading !== 'pending') setPendingSurcharge(null)
  }, [loading])

  const surchargesOptional = check?.config.surcharges.length
    ? check.config.surcharges
    : null

  const applySurcharge = async (surchargeId: number) => {
    setPendingSurcharge(surchargeId)
    const newSurcharge = { id: surchargeId }
    await dispatch(
      updateForm({ surcharges: [...form.surcharges, newSurcharge] })
    )
    dispatch(validateOrder())
  }

  const removeSurcharge = async (surchargeId: number) => {
    const filtered = form.surcharges.filter((i) => i.id !== surchargeId)
    await dispatch(updateForm({ surcharges: filtered }))
    dispatch(validateOrder())
  }

  if (!surchargesOptional && !errMsgs) return null

  return (
    <CheckoutSection title={config?.surcharges.title}>
      <CheckoutSurchargesView>
        {errMsgs?.map((errMsg) => (
          <FormError errMsg={errMsg} />
        ))}
        {surchargesOptional?.map((i) => {
          const label = i.label || i.name
          const isApplied = surchargeIds.includes(i.id)
          const isPending = i.id === pendingSurcharge
          const onPress = isApplied
            ? () => removeSurcharge(i.id)
            : () => applySurcharge(i.id)
          const cost =
            parseFloat(i.amount) > 0
              ? `${formatDollars(i.amount)}`
              : 'no charge'
          return (
            <CheckoutButton
              key={i.id}
              title={`${label} (${cost})`}
              subtitle={i.description}
              onPress={isPending ? undefined : onPress}
              isApplied={isApplied}
              disabled={!i.is_optional}
            />
          )
        })}
      </CheckoutSurchargesView>
    </CheckoutSection>
  )
}

export default CheckoutSurcharges
