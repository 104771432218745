import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  checkout,
  resetLoginError,
  selectContent,
  selectCustomer,
  selectGuest,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { FormWrapper, GuestForm, PageTitle } from 'components'
import CheckoutMagicLink from './CheckoutMagicLink'

const defaultText = {
  title: "Let's see if you have an account",
  subtitle: 'Please enter your email address below.',
}

const CheckoutGuestForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { checkoutFlow } = useAppSelector(selectContent) || {}
  const config = checkoutFlow?.sections?.email || defaultText
  const { auth } = useAppSelector(selectCustomer)
  const { showMagicLink } = useAppSelector(selectGuest)
  const signIn = useCallback(() => navigate('/checkout/signin'), [navigate])
  const signUp = useCallback(() => navigate('/checkout/signup'), [navigate])

  useEffect(() => {
    if (auth) {
      dispatch(checkout())
      navigate('/checkout')
    }
  }, [auth, dispatch, navigate])

  useEffect(() => {
    dispatch(resetLoginError())
    return () => {
      dispatch(resetLoginError())
    }
  }, [dispatch])

  return showMagicLink ? (
    <CheckoutMagicLink />
  ) : (
    <>
      <PageTitle {...config} />
      <FormWrapper>
        <GuestForm signIn={signIn} signUp={signUp} submitText="Continue" />
      </FormWrapper>
    </>
  )
}

export default CheckoutGuestForm
