import { CartItemOptions } from '@open-tender/types'
import { MenuItemPizzaAmountView } from './MenuItemPizza.styled'
import Button from 'components/Button'

const MenuItemPizzaAmount = ({
  quantity,
  options,
  changeOption,
  selectedId,
}: {
  quantity: number
  options: CartItemOptions
  changeOption: (optionId: number) => void
  selectedId: number | null
}) => {
  return quantity > 0 ? (
    <MenuItemPizzaAmountView>
      {options.map((option) => (
        <Button
          key={option.id}
          onClick={() => changeOption(option.id)}
          size="small"
          color="secondary"
          disabled={selectedId === option.id}
        >
          {option.name}
        </Button>
      ))}
    </MenuItemPizzaAmountView>
  ) : null
}

export default MenuItemPizzaAmount
