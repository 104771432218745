import { RequestedAt, RevenueCenter, ServiceType } from '@open-tender/types'
import {
  capitalize,
  makeFirstTimes,
  makeReadableDateStrFromIso,
  timezoneMap,
} from '@open-tender/utils'
import {
  closeModal,
  fetchMenu,
  resetCart,
  resetMenuVars,
  resetRevenueCenter,
  setRequestedAt,
  setServiceType,
  useAppDispatch,
} from '@open-tender/cloud'
import { Button, ModalContent, ModalView } from 'components'

export interface AdjustRequestedAtProps extends Record<string, unknown> {
  firstTimes: ReturnType<typeof makeFirstTimes>
  revenueCenter: RevenueCenter
}

const AdjustRequestedAt = ({
  firstTimes,
  revenueCenter,
}: AdjustRequestedAtProps) => {
  const dispatch = useAppDispatch()
  const tz = timezoneMap[revenueCenter.timezone]
  const revenueCenterId = revenueCenter.revenue_center_id

  const handleUpdate = ({
    serviceType,
    requestedAt,
  }: {
    serviceType: ServiceType
    requestedAt: RequestedAt
  }) => {
    dispatch(setServiceType(serviceType))
    dispatch(setRequestedAt(requestedAt))
    dispatch(resetCart())
    dispatch(resetMenuVars())
    dispatch(fetchMenu({ revenueCenterId, serviceType, requestedAt }))
    dispatch(closeModal())
  }

  const changeLocation = () => {
    dispatch(resetRevenueCenter())
    dispatch(closeModal())
  }

  const [current, other] = firstTimes || []
  const m = makeReadableDateStrFromIso
  const currentStr = current
    ? `${capitalize(current.serviceType)} ${m(current.requestedAt, tz, true)}`
    : ''
  const otherStr = other
    ? `${capitalize(other.serviceType)} ${m(other.requestedAt, tz, true)}`
    : ''

  return (
    <ModalView>
      <ModalContent
        title="Order time not currently available"
        footer={
          <div>
            {current && (
              <Button onClick={() => handleUpdate(current)}>
                {currentStr}
              </Button>
            )}
            {other && (
              <Button onClick={() => handleUpdate(other)}>{otherStr}</Button>
            )}
            <Button onClick={changeLocation}>Change Location</Button>
          </div>
        }
      >
        <div>
          <p>
            This location can't accommodate your currently selected order time
            and service type. Please choose an option below.
          </p>
        </div>
      </ModalContent>
    </ModalView>
  )
}

export default AdjustRequestedAt
