import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'
import { RevenueCenter, OrderType } from '@open-tender/types'
import {
  openModal,
  setAddress,
  setOrderServiceType,
  setRevenueCenter,
  useAppDispatch,
  useAppSelector,
  selectContent,
  selectSettings,
  selectGroupOrder,
  selectIsGroupOrder,
} from '@open-tender/cloud'
import { NavButtons } from 'components'
import { Coffee, ShoppingBag, Truck, UserPlus } from 'components/icons'
import { NavButtonProps } from 'components/NavButtons/NavButton'

const RevenueCenterButtonsView = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 2rem 0 ${(props) => props.theme.layout.marginMobile};
  }
`

export const RevenueCenterButtons = ({
  revenueCenter,
}: {
  revenueCenter: RevenueCenter
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { orderType: orderTypeContent } = useAppSelector(selectContent) || {}
  const contentTypes = orderTypeContent?.orderTypes
  const { showDescriptions, showDescriptionsMobile } = orderTypeContent || {}
  const showDesc = isMobile ? showDescriptionsMobile : showDescriptions
  const { cartId } = useAppSelector(selectGroupOrder)
  const isGroupOrder = useAppSelector(selectIsGroupOrder)
  const { orderTypes } = useAppSelector(selectSettings) || {}
  const {
    slug,
    revenue_center_type: rcType,
    is_outpost: isOutpost,
    address,
    group_ordering,
    first_times: ft,
    order_times: ot,
  } = revenueCenter
  const menuSlug = `/menu/${slug}`
  let serviceTypes: string[] = []
  const hasPickup = (ft && ft.PICKUP) || (ot && ot.PICKUP)
  if (hasPickup) serviceTypes.push('PICKUP')
  const hasWalkin = (ft && ft.WALKIN) || (ot && ot.WALKIN)
  if (hasWalkin) serviceTypes.push('WALKIN')
  const hasDelivery = (ft && ft.DELIVERY) || (ot && ot.DELIVERY)
  if (hasDelivery) serviceTypes.push('DELIVERY')
  if (group_ordering) serviceTypes.push('GROUP')

  const handleWalkin = () => {
    const args = {
      revenueCenter,
      serviceType: 'WALKIN',
      orderType: rcType as OrderType,
      isGroupOrder: isGroupOrder || cartId ? true : false,
      focusFirst: true,
      skipClose: true,
      style: ot.PICKUP ? { alignItems: 'flex-start' } : {},
    }
    dispatch(openModal({ type: 'requestedAt', args }))
  }

  const handlePickup = () => {
    const args = {
      revenueCenter,
      serviceType: 'PICKUP',
      orderType: rcType as OrderType,
      isGroupOrder: isGroupOrder || cartId ? true : false,
      focusFirst: true,
      skipClose: true,
      style: ot.PICKUP ? { alignItems: 'flex-start' } : {},
    }
    dispatch(openModal({ type: 'requestedAt', args }))
  }

  const handleDelivery = () => {
    dispatch(
      setOrderServiceType({
        orderType: rcType as OrderType,
        serviceType: 'DELIVERY',
        isOutpost,
      })
    )
    if (isOutpost) {
      dispatch(setAddress(address))
      dispatch(setRevenueCenter(revenueCenter))
      navigate(menuSlug)
    } else {
      dispatch(setAddress(null))
      dispatch(setRevenueCenter(revenueCenter))
      dispatch(openModal({ type: 'mapsAutocomplete' }))
    }
  }

  const handleGroupOrder = () => {
    dispatch(
      openModal({
        type: 'groupOrderType',
        args: { revenueCenter, serviceTypes },
      })
    )
  }

  const handlers = {
    OUTPOST: null,
    WALKIN: handleWalkin,
    PICKUP: handlePickup,
    DELIVERY: handleDelivery,
    GROUP: handleGroupOrder,
    CATERING: null,
    MERCH: null,
    GIFT_CARDS: null,
    DONATIONS: null,
  }

  const iconProps = isMobile ? { size: 18 } : { size: 20 }

  const icons = {
    OUTPOST: null,
    WALKIN: <Coffee {...iconProps} />,
    PICKUP: <ShoppingBag {...iconProps} />,
    DELIVERY: <Truck {...iconProps} />,
    GROUP: <UserPlus {...iconProps} />,
    CATERING: null,
    MERCH: null,
    GIFT_CARDS: null,
    DONATIONS: null,
  }

  if (!orderTypes || !contentTypes) return null

  const buttons = orderTypes
    .filter((orderType) => serviceTypes.includes(orderType))
    .map((orderType) => ({
      ...contentTypes[orderType],
      icon: icons[orderType] || undefined,
      onClick: handlers[orderType] || undefined,
    }))
    .map((orderType) => ({
      ...orderType,
      subtitle: showDesc ? orderType.subtitle : null,
    }))
    .filter((orderType) => orderType.icon)

  return (
    <RevenueCenterButtonsView>
      <NavButtons buttons={buttons as NavButtonProps[]} />
    </RevenueCenterButtonsView>
  )
}

export default RevenueCenterButtons
