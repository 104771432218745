import styled from '@emotion/styled'
import Body from 'components/Body'
import Heading from 'components/Heading'

// export const MenuItemOptionSimpleOptionView = styled.div`
//   label: MenuItemOptionSimpleOptionView;
//   // padding: 1rem;
// `

export const MenuItemOptionSimpleOptionButton = styled.button<{
  showGroups: boolean
}>`
  padding: 0.8rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: stretch;
  transition: ${(props) => props.theme.modifiers.box.transition};
  border-style: solid;
  border-color: ${(props) => props.theme.modifiers.colors.borderColor};
  border-width: ${(props) => props.theme.modifiers.box.border};
  border-left-width: 0;
  background-color: ${(props) => props.theme.modifiers.colors.bgColor};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0.8rem;
    flex-grow: 1;
  }

  &:first-of-type {
    border-width: ${(props) => props.theme.modifiers.box.border};
    border-top-left-radius: ${(props) =>
      props.theme.modifiers.box.borderRadius};
    border-bottom-left-radius: ${(props) =>
      props.theme.modifiers.box.borderRadius};
  }

  &:last-of-type {
    border-top-right-radius: ${(props) =>
      props.theme.modifiers.box.borderRadius};
    border-bottom-right-radius: ${(props) =>
      props.theme.modifiers.box.borderRadius};
  }

  &.show-hover:not(.selected):hover,
  &.show-hover:not(.selected):active {
    background-color: ${(props) => props.theme.modifiers.hoverColors.bgColor};
  }

  &.selected {
    background-color: ${(props) => props.theme.modifiers.hoverColors.bgColor};
  }
`

export const MenuItemOptionSimpleOptionText = styled.div`
  label: MenuItemOptionSimpleOptionText;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MenuItemOptionSimpleOptionName = styled(Heading)`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.title.fontSize};
  color: ${(props) => props.theme.modifiers.title.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.title.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.title.selectedColor};
  }
`

export const MenuItemOptionSimpleOptionSoldOut = styled(Body)`
  color: ${(props) => props.theme.colors.error};
`

export const MenuItemOptionSimpleOptionPrice = styled(Heading)`
  flex: 0 0 auto;
  display: block;
  padding-left: 0.5rem;
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.price.fontSize};
  color: ${(props) => props.theme.modifiers.price.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.price.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.price.selectedColor};
  }
`

export const MenuItemOptionSimpleOptionColories = styled(Body)`
  display: block;
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.calories.fontSize};
  color: ${(props) => props.theme.modifiers.calories.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.calories.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.calories.selectedColor};
  }
`
