import styled from '@emotion/styled'
import { CheckoutCheck, Customer } from '@open-tender/types'
import {
  openModal,
  selectCheckout,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink } from 'components'
import { AlertCircle } from 'components/icons'

const makeCompanyConfig = (check: CheckoutCheck | null) => {
  if (!check || !check.config) return {}
  let displayed = false,
    required = false
  try {
    displayed = check.config.displayed.customer.includes('company')
    required = check.config.required.customer.includes('company')
  } catch {
    // nothing to do here
  }
  return { displayed, required }
}

const CheckoutCompanyView = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.colors.error};

  span {
    display: block;
    margin: 0 0 0 0.5rem;
    color: ${(props) => props.theme.colors.error};
  }

  button {
    display: block;
    margin: 0 0 0 1rem;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.error};
  }
`

const CheckoutCompany = ({
  callback,
}: {
  callback: (data: Customer) => void
}) => {
  const dispatch = useAppDispatch()
  const { check } = useAppSelector(selectCheckout)
  const { profile } = useAppSelector(selectCustomer)
  const { company } = profile || {}
  const { displayed, required } = makeCompanyConfig(check)

  const add = () => {
    dispatch(openModal({ type: 'profile', args: { callback } }))
  }

  if (!required) {
    return !displayed ? null : company ? <p>{company}</p> : null
  }

  return company ? (
    <p>{company}</p>
  ) : (
    <CheckoutCompanyView>
      <AlertCircle size={14} />
      <span>company required</span>
      <ButtonLink onClick={add}>add one</ButtonLink>
    </CheckoutCompanyView>
  )
}

export default CheckoutCompany
