import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import {
  selectSidebarModal,
  toggleSidebarModal,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'

const SidebarModalOverlayView = styled.div`
  position: fixed;
  z-index: 16;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.overlay.dark};
`

const SidebarModalOverlay = () => {
  const dispatch = useAppDispatch()
  const { isOpen } = useAppSelector(selectSidebarModal)
  return (
    <TransitionGroup component={null}>
      {isOpen ? (
        <CSSTransition key="sidebar-overlay" classNames="overlay" timeout={500}>
          <SidebarModalOverlayView
            onClick={() => dispatch(toggleSidebarModal())}
          />
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

export default SidebarModalOverlay
