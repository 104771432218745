import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectAccountConfig,
  selectBrand,
  selectCustomer,
  selectCustomerGroupOrders,
  fetchCustomerGroupOrders,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Loading,
  Main,
  PageContainer,
  PageContent,
  PageError,
  PageTitle,
} from 'components'
import GroupOrdersList from './GroupOrdersList'

const GroupOrders = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const orders = useAppSelector(selectCustomerGroupOrders)
  const { entities: groupOrders, loading, error } = orders
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const config = useAppSelector(selectAccountConfig)
  const { auth } = useAppSelector(selectCustomer)
  const isLoading = loading === 'pending'

  useEffect(() => {
    if (!auth) return navigate('/account')
  }, [auth, navigate])

  useEffect(() => {
    dispatch(fetchCustomerGroupOrders())
  }, [dispatch])

  if (!auth) return null

  return (
    <>
      <Helmet>
        <title>Order History | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageTitle {...config?.groupOrders} />
            <PageContent>
              <PageError error={error} />
              {groupOrders.length ? (
                <GroupOrdersList orders={groupOrders} />
              ) : isLoading ? (
                <Loading text="Retrieving your group orders..." />
              ) : (
                <p>Looks like you don't have any group orders yet</p>
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default GroupOrders
