import { useCallback } from 'react'
import { closeModal, useAppDispatch } from '@open-tender/cloud'
import { CreditCardForm, ModalContent, ModalView } from 'components'

export interface CreditCardModalProps extends Record<string, unknown> {
  callback: () => void
  revenue_center_id: number
}

const CreditCard = ({
  windowRef,
  callback,
  revenue_center_id,
}: CreditCardModalProps & { windowRef: React.RefObject<HTMLDivElement> }) => {
  const dispatch = useAppDispatch()

  const addCallback = useCallback(() => {
    if (callback) callback()
    dispatch(closeModal())
  }, [dispatch, callback])

  return (
    <ModalView>
      <ModalContent title="Add a new credit card">
        <CreditCardForm
          windowRef={windowRef}
          callback={addCallback}
          revenue_center_id={revenue_center_id}
        />
      </ModalContent>
    </ModalView>
  )
}

export default CreditCard
