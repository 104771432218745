import { Helmet } from 'react-helmet'
import { selectBrand, selectContent, useAppSelector } from '@open-tender/cloud'
import {
  Content,
  Main,
  PageTitle,
  PageContainer,
  PageContent,
  HeaderDefault,
} from 'components'

const Refunds = () => {
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { refunds: config } = useAppSelector(selectContent) || {}
  const content =
    config?.content && config.content.length > 0 ? config.content : []

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config} />
            <PageContent style={{ textAlign: 'left', marginTop: '3rem' }}>
              {content.map((i, index) => (
                <p key={index}>{i}</p>
              ))}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Refunds
