import { selectBrand, useAppSelector } from '@open-tender/cloud'
import { ModalView } from 'components'
import LoginEmail from './LoginEmail'
import LoginOTP from './LoginOTP'

const Login = ({
  callback,
  isForgotPassword = false,
}: {
  callback?: () => void
  isForgotPassword?: boolean
}) => {
  const { tpls } = useAppSelector(selectBrand) || {}

  return (
    <ModalView>
      {tpls === 'COMO' ? (
        <LoginOTP callback={callback} />
      ) : (
        <LoginEmail callback={callback} isForgotPassword={isForgotPassword} />
      )}
    </ModalView>
  )
}

export default Login
