import {
  fetchGuestThanx,
  resetGuest,
  selectContent,
  selectGuest,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink, PageTitle, PageContent } from 'components'

const defaultText = {
  title: 'Account found!',
  subtitle:
    'We just sent you a magic sign in link! Please open your email on this device and click the link to sign in and continue your order.',
  resend: 'Click here to resend the magic link.',
  changeEmail: 'Or click here to enter a different email address.',
}

const CheckoutMagicLink = () => {
  const dispatch = useAppDispatch()
  const { checkoutFlow } = useAppSelector(selectContent) || {}
  const config = checkoutFlow?.sections?.magicLink || defaultText
  const { email, loading } = useAppSelector(selectGuest)
  const resend = () =>
    dispatch(fetchGuestThanx({ email: email ?? '', callback: () => {} }))
  const reset = () => dispatch(resetGuest())

  return (
    <>
      <PageTitle {...config} />
      <PageContent>
        <p>
          <ButtonLink onClick={resend} disabled={loading === 'pending'}>
            {config.resend}
          </ButtonLink>
        </p>
        <p>
          <ButtonLink onClick={reset} disabled={loading === 'pending'}>
            {config.changeEmail}
          </ButtonLink>
        </p>
      </PageContent>
    </>
  )
}

export default CheckoutMagicLink
