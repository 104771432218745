import styled from '@emotion/styled'

export const AccountWrapper = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 84rem;
  margin: 0 auto;
  background-color: ${(props) => props.theme.bgColors.primary};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  // border: 0;
  // border-style: solid;
  // border-color: ${(props) => props.theme.border.color};
  // border-left-width: ${(props) => props.theme.border.width};
  // border-right-width: ${(props) => props.theme.border.width};
  // @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  //   padding: 0 0 10rem;
  // }
`

export const AccountView = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const AccountMobile = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AccountButtonsView = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: 1rem 0 4rem;
  gap: 2rem;

  button {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`

export const AccountHero = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 32rem;
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: ${(props) => props.theme.layout.margin} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-height: 16rem;
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    margin: 0;
  }

  & > div {
    overflow: hidden;
    border-radius: ${(props) => props.theme.border.radius};
  }

  & > video {
    border-radius: ${(props) => props.theme.border.radius};
  }
`
