import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  addCustomerFavorite,
  removeCustomerFavorite,
  useAppDispatch,
} from '@open-tender/cloud'
import { makeSimpleCart } from '@open-tender/utils'
import { Heart } from './icons'
import { CartItem } from '@open-tender/types'

const MenuItemFavoriteView = styled.button`
  padding: 0.5rem;
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};
`

const MenuItemFavoriteContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MenuItemFavorite = ({
  builtItem,
  favoriteId,
  disabled,
  size,
  children,
}: {
  builtItem: CartItem
  favoriteId: number | null
  disabled?: boolean
  size?: number
  children?: JSX.Element | JSX.Element[]
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [isFavorite, setIsFavorite] = useState(favoriteId ? true : false)
  const color = isFavorite ? theme.colors.primary : theme.colors.primary
  const fill = isFavorite ? theme.colors.primary : theme.bgColors.primary

  useEffect(() => setIsFavorite(favoriteId ? true : false), [favoriteId])

  const addFavorite = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setIsFavorite(true)
    const cart = makeSimpleCart([builtItem])
    dispatch(addCustomerFavorite({ item: cart[0] }))
  }

  const removeFavorite = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (!favoriteId) return
    setIsFavorite(false)
    dispatch(removeCustomerFavorite({ favoriteId }))
  }

  return (
    <MenuItemFavoriteView
      aria-label={favoriteId ? 'Remove Favorite' : 'Add Favorite'}
      disabled={disabled}
      onClick={favoriteId ? removeFavorite : addFavorite}
    >
      <MenuItemFavoriteContainer>
        <Heart size={size ?? 26} color={color} fill={fill} />
        {children}
      </MenuItemFavoriteContainer>
    </MenuItemFavoriteView>
  )
}

export default MenuItemFavorite
