import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from '@emotion/styled'
import { CartItem } from '@open-tender/types'
import { formatDollars, hasGroupsBelowMin } from '@open-tender/utils'
import { selectDisplaySettings, useAppSelector } from '@open-tender/cloud'
import { Button, Heading } from 'components'
import { Minus, Plus, X } from 'components/icons'

const MenuItemFooterView = styled.div`
  label: MenuItemFooter;
  flex: 0 0 auto;
  position: relative;
  padding: 2rem ${(props) => props.theme.item.desktop.padding};
  background-color: ${(props) => props.theme.bgColors.primary};
  box-shadow: 0 -15px 30px ${(props) => props.theme.overlay.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 2rem ${(props) => props.theme.item.mobile.padding};
  }
`

const MenuItemFooterButtons = styled.div`
  margin: 0 -0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MenuItemFooterButton = styled.div`
  flex: 1 1 50%;
  padding: 0 0.6rem;

  button {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const MenuItemFooterDefault = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MenuItemFooterDefaultCircle = styled.div`
  flex: 0 0 5rem;
  width: 5rem;
  height: 5rem;
  margin-right: 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex: 0 0 4rem;
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
  }

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 2.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      border-radius: 2rem;
    }
  }
`

const MenuItemFooterDefaultQuantity = styled.div`
  min-width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: 4rem;
    height: 4rem;
  }
`

const MenuItemFooterDefaultCount = styled(Heading)`
  display: block;
`

const MenuItemFooterDefaultButton = styled.div`
  flex: 1 1 auto;

  button {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const MenuItemFooterDefaultPrice = styled.span`
  // @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  //   display: none;
  // }

  span {
    padding: 0 0.5rem;
  }
`

const MenuItemFooter = ({
  builtItem,
  increment,
  decrement,
  addItem,
  cancel,
  hasCustomize,
  isCustomize,
  setIsCustomize,
}: {
  builtItem: CartItem
  increment: () => void
  decrement: () => void
  addItem: (item: CartItem) => void
  cancel: () => void
  hasCustomize?: boolean
  isCustomize?: boolean
  setIsCustomize: (isCustomize: boolean) => void
}) => {
  const [init, setInit] = useState(true)
  const { skipToCustomize, skipToCustomizeMobile } =
    useAppSelector(selectDisplaySettings) || {}
  const skip = isMobile ? skipToCustomizeMobile : skipToCustomize
  const { groups, quantity, totalPrice } = builtItem
  const sizeGroup = groups ? groups.find((g) => g.isSize) : null
  const missingSize = sizeGroup
    ? !sizeGroup?.options?.find((i) => (i.quantity ?? 0) >= 1)
    : false
  const hasGroups = groups ? groups.filter((g) => !g.isSize).length > 0 : false
  const groupsBelowMin = hasGroupsBelowMin(null, groups)
  const isIncomplete = `${quantity}` === '' || quantity === 0 || groupsBelowMin
  const requiresCustomization = isIncomplete && !missingSize
  const shouldSkip = hasCustomize ? hasGroups && skip : false

  useEffect(() => {
    if (init) {
      setInit(false)
      if (shouldSkip) setIsCustomize(true)
    }
  }, [init, shouldSkip, setIsCustomize])

  return (
    <MenuItemFooterView>
      {!hasCustomize ? (
        <MenuItemFooterDefault>
          <MenuItemFooterDefaultCircle>
            <Button
              label="Cancel & Return to Menu"
              onClick={cancel}
              size="big"
              color="secondary"
            >
              <X size={25} />
            </Button>
          </MenuItemFooterDefaultCircle>
          <MenuItemFooterDefaultCircle style={{ margin: 0 }}>
            <Button
              label="Decrease Quantity"
              onClick={decrement}
              size="big"
              color="primary"
            >
              <Minus size={25} />
            </Button>
          </MenuItemFooterDefaultCircle>
          <MenuItemFooterDefaultQuantity>
            <MenuItemFooterDefaultCount size="xBig">
              {quantity}
            </MenuItemFooterDefaultCount>
          </MenuItemFooterDefaultQuantity>
          <MenuItemFooterDefaultCircle>
            <Button
              label="Increase Quantity"
              onClick={increment}
              size="big"
              color="primary"
            >
              <Plus size={25} />
            </Button>
          </MenuItemFooterDefaultCircle>
          <MenuItemFooterDefaultButton>
            <Button
              onClick={() => addItem(builtItem)}
              disabled={isIncomplete}
              size={isMobile ? 'default' : 'big'}
            >
              <MenuItemFooterDefaultPrice>
                {isMobile ? 'Add' : 'Add To Order'}
                <span>&mdash;</span>
                {formatDollars(`${totalPrice}`)}
              </MenuItemFooterDefaultPrice>
            </Button>
          </MenuItemFooterDefaultButton>
        </MenuItemFooterDefault>
      ) : isCustomize ? (
        <MenuItemFooterButtons>
          <MenuItemFooterButton>
            <Button
              onClick={() => setIsCustomize(false)}
              size="big"
              color="secondary"
            >
              Cancel
            </Button>
          </MenuItemFooterButton>
          <MenuItemFooterButton>
            <Button
              onClick={() => setIsCustomize(false)}
              disabled={isIncomplete}
              size="big"
            >
              All Done
            </Button>
          </MenuItemFooterButton>
        </MenuItemFooterButtons>
      ) : (
        <MenuItemFooterButtons>
          {hasGroups && (
            <MenuItemFooterButton>
              <Button
                onClick={() => setIsCustomize(true)}
                size="big"
                color={requiresCustomization ? 'primary' : 'secondary'}
              >
                Customize
                {/* {requiresCustomization ? (
                  <MenuItemFooterRequired>
                    <span>Customize</span>
                    <MenuItemFooterRequiredAlert color="error">
                      (required)
                    </MenuItemFooterRequiredAlert>
                  </MenuItemFooterRequired>
                ) : (
                  'Customize'
                )} */}
              </Button>
            </MenuItemFooterButton>
          )}
          <MenuItemFooterButton>
            <Button
              onClick={() => addItem(builtItem)}
              disabled={isIncomplete}
              size="big"
            >
              Add To Order
              {!isIncomplete && (
                <MenuItemFooterDefaultPrice>
                  <span>&mdash;</span>
                  {formatDollars(`${totalPrice}`)}
                </MenuItemFooterDefaultPrice>
              )}
            </Button>
          </MenuItemFooterButton>
        </MenuItemFooterButtons>
      )}
    </MenuItemFooterView>
  )
}

export default MenuItemFooter
