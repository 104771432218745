import styled from '@emotion/styled'
import { CartWithPoints, CheckoutPoints } from '@open-tender/types'
import CartSummaryItem from './CartSummaryItem'

const CartSummaryView = styled.div`
  position: relative;
  z-index: 2;
`

const CartSummary = ({
  cart,
  pointsObj,
}: {
  cart: CartWithPoints
  pointsObj: CheckoutPoints | null
}) => {
  return (
    <CartSummaryView>
      {cart.map((item, index) => (
        <CartSummaryItem
          key={`${item.id}-${index}`}
          item={item}
          pointsObj={pointsObj}
        />
      ))}
    </CartSummaryView>
  )
}

export default CartSummary
