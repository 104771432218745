import { selectCart, selectContent, useAppSelector } from '@open-tender/cloud'
import {
  CartCounts as CartCountsComponent,
  ModalContent,
  ModalView,
} from 'components'

const CartCounts = ({ errors }: any) => {
  const { menu } = useAppSelector(selectContent) || {}
  const cart = useAppSelector(selectCart)

  return (
    <ModalView>
      <ModalContent
        title={menu?.cartErrors.title}
        subtitle={<p>{menu?.cartErrors.subtitle}</p>}
      >
        {cart && <CartCountsComponent cart={cart} errors={errors} />}
      </ModalContent>
    </ModalView>
  )
}

export default CartCounts
