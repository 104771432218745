import {
  CheckoutCheck,
  CheckoutErrorMessages,
  ServiceType,
} from '@open-tender/types'
import { isString } from 'lodash'

export const makeFormTitle = (
  check: CheckoutCheck | null,
  serviceType: ServiceType | null
) => {
  if (!check || !check.config) return {}
  const displayed = check.config.displayed
  const required = check.config.required
  const hasAddress =
    serviceType === 'DELIVERY' &&
    (displayed.address.length !== undefined ||
      required.address.length !== undefined)
  const hasDetails = displayed.details.length || required.details.length
  const formTitle =
    hasAddress && hasDetails
      ? 'Address & Order Details'
      : hasAddress
      ? 'Address Details'
      : hasDetails
      ? 'Order Details'
      : null
  return { hasAddress, hasDetails, formTitle }
}

export const makeAddressError = (errors: CheckoutErrorMessages) => {
  if (!errors || !errors.address) return null
  const addressError = isString(errors.address)
    ? errors.address
    : errors.address.address || null
  if (!addressError || typeof addressError !== 'string') return null
  const errMsg = addressError.includes('state')
    ? 'your state or province appears to be invalid'
    : addressError.includes('code')
    ? 'your postal code appears to be invalid'
    : addressError
  return `There's something wrong with your address (${errMsg}). Please try changing your address.`
}

export const makeCustomerError = (errors: CheckoutErrorMessages) => {
  const err = errors?.customer
  if (!err || typeof err === 'string') return err
  if (!err.account || typeof err.account !== 'string') {
    return null
  }
  return err.account
}
