import { useNavigate } from 'react-router-dom'
import { Button } from 'components'
import { ArrowRightLong } from 'components/icons'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'

const SkipText = styled.span`
  display: block;
  margin: 0 1rem 0 0;
`

const SkipArrow = styled.span`
  display: block;
  line-height: 0;
`

const Skip = ({ path, text }: { path: string; text: string }) => {
  const navigate = useNavigate()

  return (
    <Button onClick={() => navigate(path)} color="header" size="header">
      <SkipText>{text}</SkipText>
      <SkipArrow>
        <ArrowRightLong size={isMobile ? 16 : 30} />
      </SkipArrow>
    </Button>
  )
}

export default Skip
