import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { selectBrand, selectCustomer, useAppSelector } from '@open-tender/cloud'
import {
  Content,
  DeleteAccount,
  HeaderUser,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
} from 'components'

const AccountDelete = () => {
  const navigate = useNavigate()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)

  useEffect(() => {
    if (!auth) return navigate('/guest')
  }, [auth, navigate])

  return (
    <>
      <Helmet>
        <title>Delete Account | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderUser />
        <Main>
          <PageContainer style={{ maxWidth: '72rem' }}>
            <PageTitle
              title="Delete your account"
              subtitle="Use the button below to permanently delete your account. There is no undo."
            />
            <PageContent>
              <DeleteAccount />
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default AccountDelete
