import {
  joinGroupOrder,
  selectGroupOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { FormFieldType, GroupOrderGuestCreate } from '@open-tender/types'
import { useCartGuestForm } from '@open-tender/utils'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'
import { useCallback } from 'react'

const CartGuestForm = () => {
  const dispatch = useAppDispatch()
  const { cartId, loading, error } = useAppSelector(selectGroupOrder)
  const joinCart = useCallback(
    (data: GroupOrderGuestCreate) => dispatch(joinGroupOrder(data)),
    [dispatch]
  )

  const {
    submitRef,
    data,
    errors,
    submitting,
    fields,
    handleChange,
    handleSubmit,
  } = useCartGuestForm(loading, error, cartId ?? 0, joinCart)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  return (
    <form id="cart-guest-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data || {}}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default CartGuestForm
