import { CSSProperties } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  selectOrder,
  selectAutoSelect,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonBoth } from '.'

const RevenueCenter = ({
  useButton = false,
  style,
}: {
  useButton?: boolean
  style?: CSSProperties
}) => {
  const navigate = useNavigate()
  const { revenueCenter } = useAppSelector(selectOrder)
  const autoSelect = useAppSelector(selectAutoSelect)

  const change = () => {
    navigate(`/locations`)
  }

  if (!revenueCenter || autoSelect) return null

  return (
    <ButtonBoth
      label="Go to Locations"
      text={revenueCenter.name}
      onClick={change}
      style={style}
      useButton={useButton}
    />
  )
}

export default RevenueCenter
