import styled from '@emotion/styled'
import { ThemeLinkType } from '@open-tender/types'

const ButtonLinkView = styled.button<{ color: ThemeLinkType }>`
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: inline;
  font-size: inherit;
  letter-spacing: inherit;
  text-decoration: ${(props) => props.theme.links.textDecoration};
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.links[props.color].color};

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.links[props.color].hover};
  }

  &:disabled {
    color: ${(props) => props.theme.links[props.color].color};
    opacity: 0.5;
  }
`

const ButtonLink = ({
  label,
  children,
  disabled,
  onClick,
  color = 'primary',
  style = undefined,
}: {
  label?: string
  children: React.ReactNode
  disabled?: boolean
  onClick: () => void
  color?: ThemeLinkType
  style?: React.CSSProperties
}) => {
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (!disabled) onClick()
  }

  return (
    <ButtonLinkView
      type="button"
      aria-label={label}
      onClick={(evt) => handleClick(evt)}
      disabled={disabled}
      color={color}
      style={style}
    >
      {children}
    </ButtonLinkView>
  )
}

export default ButtonLink
