import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  fetchCustomerGiftCards,
  openModal,
  selectBrand,
  selectContent,
  selectCustomer,
  selectCustomerGiftCards,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  Content,
  HeaderUser,
  Loading,
  Main,
  PageTitle,
  PageContainer,
  PageContent,
  PageTitleButtons,
} from 'components'
import GiftCardsList from './GiftCardsList'

const AccountGiftCards = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { giftCardsAccount: config } = useAppSelector(selectContent) || {}
  const { entities, loading } = useAppSelector(selectCustomerGiftCards)
  const isLoading = loading === 'pending'
  const { auth } = useAppSelector(selectCustomer)

  useEffect(() => {
    if (!auth) return navigate('/account')
  }, [auth, navigate])

  useEffect(() => {
    dispatch(fetchCustomerGiftCards())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderUser />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config}>
              <PageTitleButtons>
                <Button onClick={() => navigate('/gift-cards')}>
                  Buy Gift Cards For Others
                </Button>
                <Button
                  onClick={() => dispatch(openModal({ type: 'giftCard' }))}
                  color="secondary"
                >
                  Buy a New Gift Card
                </Button>
                <Button
                  onClick={() =>
                    dispatch(openModal({ type: 'giftCardAssign' }))
                  }
                  color="secondary"
                >
                  Add Gift Card To Account
                </Button>
              </PageTitleButtons>
            </PageTitle>
            {entities.length ? (
              <GiftCardsList giftCards={entities} isLoading={isLoading} />
            ) : (
              <PageContent>
                {isLoading ? (
                  <Loading text="Retrieving your gift cards..." />
                ) : (
                  <p>Looks like you haven't added any gift cards yet.</p>
                )}
              </PageContent>
            )}
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default AccountGiftCards
