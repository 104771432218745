import styled from '@emotion/styled'
import {
  openModal,
  selectContent,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink, Text } from 'components'
import CheckoutSection from './CheckoutSection'

const CheckoutPromoCodesGuestView = styled.div`
  margin: 1.5rem 0 0;
`

const CheckoutPromoCodesGuest = () => {
  const dispatch = useAppDispatch()
  const { checkout: config } = useAppSelector(selectContent) || {}

  const signUp = () => {
    dispatch(openModal({ type: 'signUp' }))
  }

  return (
    <CheckoutSection title={config?.promoCodes.title}>
      <CheckoutPromoCodesGuestView>
        <Text size="small">
          Have a promo code?{' '}
          <ButtonLink onClick={signUp}>
            Create an account to apply it.
          </ButtonLink>
        </Text>
      </CheckoutPromoCodesGuestView>
    </CheckoutSection>
  )
}

export default CheckoutPromoCodesGuest
