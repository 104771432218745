/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'

const eventMapping: Record<string, string> = {
  onClick: 'click',
  onDoubleClick: 'dblclick',
}

// add useRef on March 18, 2021 to allow marker to be removed
// https://www.timveletta.com/blog/2020-07-14-accessing-react-state-in-your-component-cleanup-with-hooks/

const GoogleMapsMarker = ({
  maps,
  map,
  title,
  position,
  icon,
  size = { width: 30, height: 40 },
  anchor = null,
  drop = true,
  events,
}: {
  maps?: any
  map?: any
  title?: string
  position?: { lat: number; lng: number } | null
  icon?: string
  size?: { width: number; height: number }
  anchor?: any
  drop?: boolean
  events?: any
}) => {
  const [marker, setMarker] = useState<any>(null)
  const markerRef = useRef()

  useEffect(() => {
    if (marker && position) {
      marker.setPosition(position)
    }
  }, [position?.lat, position?.lng])

  useEffect(() => {
    if (marker) {
      marker.setIcon({
        url: icon,
        scaledSize: new maps.Size(size.width, size.height),
      })
    }
  }, [icon])

  useEffect(() => {
    if (maps && position) {
      const newMarker = new maps.Marker({
        position,
        map,
        title,
        animation: drop ? maps.Animation.DROP : null,
        icon: {
          url: icon,
          scaledSize: new maps.Size(size.width, size.height),
          anchor: anchor ? new maps.Point(anchor.x, anchor.y) : null,
        },
      })
      if (events) {
        Object.keys(events).forEach((eventName) =>
          newMarker.addListener(eventMapping[eventName], events[eventName])
        )
      }
      setMarker(newMarker)
      markerRef.current = newMarker
      return () => {
        ;(markerRef?.current as any).setMap(null)
        setMarker(null)
      }
    }
  }, [maps, position?.lat, position?.lng])

  return null
}

export default GoogleMapsMarker
