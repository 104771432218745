import styled from '@emotion/styled'
import { Checkbox as CheckboxType } from '@open-tender/types'
import { Label } from '.'

const CheckboxInput = styled.input`
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
`

const CheckboxContainer = styled.span`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    align-items: flex-start;
  }
`

const CheckboxView = styled.span`
  content: '';
  flex-shrink: 0;
  display: block;
  position: relative;
  width: 1.9rem;
  height: 1.9rem;
  padding: 0;
  margin: 0 1rem 0 0;
  border-radius: 0.2rem;
  background-color: ${(props) => props.theme.inputs.bgColor};
  border: ${(props) => props.theme.inputs.borderWidth} solid
    ${(props) => props.theme.inputs.borderColor};
  box-shadow: ${(props) => props.theme.inputs.boxShadow};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 1.5rem 0 0;
  }

  input:focus + & {
    outline-color: ${(props) => props.theme.inputs.color};
    outline-style: ${(props) =>
      props.theme.inputs.showOutline ? 'auto' : 'none'};
    outline-width: 5px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 1.3rem;
    height: 0.7rem;
    background: transparent;
    top: 0.3rem;
    left: 0.2rem;
    border-width: 0.2rem;
    border-style: solid;
    border-top: none;
    border-right: none;
    transform: scale(1) rotate(-45deg);
    opacity: 0;
    color: ${(props) => props.theme.inputs.color};
  }

  input:checked + &:before {
    opacity: 1;
  }

  input:disabled + & {
    opacity: 0.5;
  }

  input:disabled + &:before {
    opacity: 0.5;
  }
`

const CheckboxDescription = styled.span<{ fontSize?: string }>`
  display: block;
  color: ${(props) => props.theme.inputs.placeholderColor};
  line-height: ${(props) => props.theme.lineHeight};
  font-size: ${(props) => props.theme.fonts.sizes[props.fontSize || 'main']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0.3rem 0 0;
  }
`

const Checkbox = ({
  label,
  id,
  name,
  on,
  onChange,
  disabled,
  showLabel = false,
  required = false,
  description,
}: CheckboxType) => {
  return (
    <Label
      htmlFor={id || name}
      text={label}
      showLabel={false}
      required={required}
      style={{ cursor: 'pointer' }}
    >
      <CheckboxContainer>
        <CheckboxInput
          type="checkbox"
          aria-label={label}
          id={id || name}
          checked={on}
          disabled={disabled}
          onChange={onChange}
        />
        <CheckboxView />
        {label && !showLabel ? (
          <CheckboxDescription fontSize="small">{label}</CheckboxDescription>
        ) : description ? (
          <CheckboxDescription fontSize="small">
            {description}
          </CheckboxDescription>
        ) : null}
      </CheckboxContainer>
    </Label>
  )
}

export default Checkbox
