import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  CustomerNotificationPrefsCreate,
  NotificationArea,
  NotificationChannel,
  ConfigNotificationPreferences,
} from '@open-tender/types'
import {
  addCustomerCommunicationPreference,
  fetchCustomer,
  fetchCustomerCommunicationPreferences,
  removeCustomerCommunicationPreference,
  selectBrand,
  selectCustomer,
  selectCustomerCommunicationPreferences,
  setCustomerCommunicationDefaultPreferences,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  CommunicationPreferences,
  FormWrapper,
  Message,
  Loading,
  Body,
} from 'components'

const defaultPrefs: CustomerNotificationPrefsCreate = []

const CommunicationPrefsDefaults = styled.div`
  margin: 2rem 0;
  text-align: center;
`

export type NotificationPrefsLookup = Record<
  NotificationArea,
  NotificationChannel[]
>

const makeNotificationPrefs = (
  notificationPrefs?: ConfigNotificationPreferences
) => {
  if (!notificationPrefs) return { ORDER: [], MARKETING: [], RATING: [] }
  return notificationPrefs.reduce((obj, i) => {
    const existing = obj[i.notification_area] || []
    const updated = [...existing, i.notification_channel]
    return { ...obj, [i.notification_area]: updated }
  }, {} as NotificationPrefsLookup)
}

const CommunicationPrefs = ({ style }: { style?: React.CSSProperties }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { accepts_marketing, order_notifications, notification_preferences } =
    useAppSelector(selectBrand) || {}
  const { auth, profile } = useAppSelector(selectCustomer)
  const { is_notification_set } = profile || {}
  const [isSet, setIsSet] = useState(is_notification_set)
  const [isNew, setIsNew] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const {
    entities: prefs,
    loading,
    error,
  } = useAppSelector(selectCustomerCommunicationPreferences)
  const isLoading = loading === 'pending'
  const errMsg = error ? error.message || null : null
  const notificationPrefsLookup = makeNotificationPrefs(
    notification_preferences
  )
  const allPrefs = Object.values(notificationPrefsLookup).reduce((arr, i) => {
    return [...arr, ...i]
  }, [] as NotificationChannel[])
  const hasPrefs = allPrefs.length > 0
  // console.log(notificationPrefsLookup)

  const add = useCallback(
    (area: NotificationArea, channel: NotificationChannel) =>
      dispatch(addCustomerCommunicationPreference({ area, channel })),
    [dispatch]
  )

  const remove = useCallback(
    (prefId: number) =>
      dispatch(removeCustomerCommunicationPreference({ prefId })),
    [dispatch]
  )

  useEffect(() => {
    if (auth && isSet) {
      setHasLoaded(true)
      dispatch(fetchCustomerCommunicationPreferences())
    }
  }, [auth, navigate, dispatch, isSet])

  useEffect(() => {
    if (!is_notification_set) {
      dispatch(
        setCustomerCommunicationDefaultPreferences({ prefs: defaultPrefs })
      ).then(() => {
        dispatch(fetchCustomer())
        setIsSet(true)
        setIsNew(true)
      })
    }
  }, [dispatch, is_notification_set])

  if (!auth) return null

  return !hasLoaded && isLoading ? (
    <Loading text="Retrieving your communication preferences..." />
  ) : errMsg ? (
    <Message color="error">{errMsg}</Message>
  ) : (
    <>
      {isNew && (
        <CommunicationPrefsDefaults>
          <Message color="alert" as="p" size="small">
            Please choose how and when you want to be contacted.
          </Message>
        </CommunicationPrefsDefaults>
      )}
      {hasPrefs ? (
        <FormWrapper style={style}>
          <CommunicationPreferences
            brandPrefs={notificationPrefsLookup}
            prefs={prefs}
            add={add}
            remove={remove}
            accepts_marketing={accepts_marketing}
            order_notifications={order_notifications}
          />
        </FormWrapper>
      ) : (
        <CommunicationPrefsDefaults>
          <Body as="p">
            This brand does not currently have any communications preferences
            configured.
          </Body>
        </CommunicationPrefsDefaults>
      )}
    </>
  )
}

export default CommunicationPrefs
