import styled from '@emotion/styled'
import { HouseAccounts } from '@open-tender/types'
import { orderTypeNamesMap, serviceTypeNamesMap } from '@open-tender/utils'
import { Row } from 'components'

const HouseAccountFinePrint = styled.div`
  & > p:first-of-type {
    margin: 1rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const HouseAccountsList = ({
  houseAccounts,
}: {
  houseAccounts: HouseAccounts
}) => {
  return (
    <div>
      {houseAccounts.map((houseAccount) => {
        const orderType = houseAccount.order_type
          ? orderTypeNamesMap[houseAccount.order_type]
          : null
        const orderTypes = orderType ? `${orderType} orders` : 'all order types'
        const serviceType = houseAccount.service_type
          ? serviceTypeNamesMap[houseAccount.service_type]
          : null
        const serviceTypes = serviceType
          ? `the ${serviceType} service type`
          : 'all service types'
        return (
          <Row
            key={houseAccount.house_account_id}
            content={
              <>
                <p className="title">{houseAccount.name}</p>
                <p>
                  {houseAccount.approved_contact
                    ? 'Your account has been specifically approved for this house account'
                    : `This house account is approved for all email addresses ending in ${houseAccount.domain}`}
                </p>
                <HouseAccountFinePrint>
                  <p>
                    Approved for <span>{orderTypes}</span> and{' '}
                    <span>{serviceTypes}</span>
                  </p>
                </HouseAccountFinePrint>
              </>
            }
          />
        )
      })}
    </div>
  )
}

export default HouseAccountsList
