import styled from '@emotion/styled'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  selectSidebar,
  toggleSidebar,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'

const SidebarOverlayView = styled.div`
  position: fixed;
  z-index: 16;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.overlay.dark};
`

const SidebarOverlay = () => {
  const dispatch = useAppDispatch()
  const { isOpen } = useAppSelector(selectSidebar)
  return (
    <TransitionGroup component={null}>
      {isOpen ? (
        <CSSTransition key="sidebar-overlay" classNames="overlay" timeout={250}>
          <SidebarOverlayView onClick={() => dispatch(toggleSidebar())} />
        </CSSTransition>
      ) : null}
    </TransitionGroup>
  )
}

export default SidebarOverlay
