import styled from '@emotion/styled'
// import { ArrowRight } from '../icons'

const RevenueCenterActionView = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 0 0;
`

const RevenueCenterActionIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  line-height: 0;
  color: ${(props) => props.theme.fonts.body.color};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`

// const RevenueCenterActionArrow = styled(RevenueCenterActionIcon)`
//   transition: all 0.15s ease;
//   line-height: 0;
//   transform: translateX(0);
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     display: none;
//   }

//   a:hover &,
//   button:hover & {
//     transform: translateX(0.5rem);
//   }
// `

const RevenueCenterActionArrowText = styled.div`
  width: 100%;
  padding: 0 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }

  p {
    color: ${(props) => props.theme.fonts.body.color};
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.xxSmall};
    }
  }
`

interface RevenueCenterActionProps {
  icon: JSX.Element
  text: string | JSX.Element
  style?: React.CSSProperties
  arrow?: JSX.Element | null
}

const RevenueCenterAction = ({
  icon,
  text,
  style = undefined,
}: RevenueCenterActionProps) => {
  return (
    <RevenueCenterActionView style={style}>
      <RevenueCenterActionIcon>{icon}</RevenueCenterActionIcon>
      <RevenueCenterActionArrowText>
        <p>{text}</p>
      </RevenueCenterActionArrowText>
      {/* <RevenueCenterActionArrow>
        <ArrowRight size={14} />
      </RevenueCenterActionArrow> */}
    </RevenueCenterActionView>
  )
}

export default RevenueCenterAction
