import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckoutFormCustomer, FormFieldType } from '@open-tender/types'
import { useGuestCheckoutForm } from '@open-tender/utils'
import {
  resetSignUp,
  selectCheckout,
  selectSignUp,
  updateForm,
  useAppSelector,
  useAppDispatch,
  resetCheckout,
  validateOrder,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const cleanGuestCheckoutForm = (
  formCustomer: CheckoutFormCustomer
): CheckoutFormCustomer => {
  const {
    first_name = '',
    last_name = '',
    email = '',
    phone = '',
  } = formCustomer
  return { first_name, last_name, email, phone }
}

const GuestCheckoutForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const { form, check, errors: errs } = useAppSelector(selectCheckout)
  const { loading, error } = useAppSelector(selectSignUp)
  const guestErrors = (errs.customer as Record<string, string>) || null
  const hasGuestErrors = guestErrors ? true : false
  const formCustomer = cleanGuestCheckoutForm(form.customer)

  const submit = useCallback(
    async (data: CheckoutFormCustomer) => {
      setSubmitted(true)
      dispatch(resetSignUp())
      await dispatch(updateForm({ customer: data }))
      dispatch(validateOrder())
    },
    [dispatch]
  )

  useEffect(() => {
    if (submitted && check && !hasGuestErrors) {
      navigate('/checkout')
    }
  }, [submitted, check, hasGuestErrors, navigate])

  useEffect(() => {
    if (hasGuestErrors) {
      setSubmitted(false)
      dispatch(resetCheckout())
    }
  }, [dispatch, hasGuestErrors])

  const {
    submitRef,
    fields,
    data,
    disabled,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useGuestCheckoutForm(formCustomer, loading, error, guestErrors, submit)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  return (
    <form id="signup-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={disabled || submitting}>
          {submitting ? 'Submitting...' : 'Continue'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default GuestCheckoutForm
