import styled from '@emotion/styled'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const UpsellView = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

export const UpsellHeader = styled.div`
  text-align: center;
  margin: 0 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

export const UpsellTitle = styled(Heading)`
  display: block;
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
`

export const UpsellSubtitle = styled(Body)`
  display: block;
  margin: 0.75rem 0 0;
`

export const UpsellFooter = styled.div`
  text-align: center;
  padding: ${(props) => props.theme.layout.padding} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.layout.paddingMobile} 0 0;
  }
`
