import styled from '@emotion/styled'
import { Box, Button, Heading } from 'components'
import { closeModal, useAppDispatch } from '@open-tender/cloud'
import { Award } from '../icons'
import { ModalContent, ModalView } from '..'
import Tag from '../Tag'
import { LoyaltyTier as LoyaltyTierType } from '@open-tender/types'

const TierDiscount = styled(Box)`
  position: relative;
  padding: 1rem 1.5rem;
  margin: 0 0 2rem;

  & > p {
    margin: 0 !important;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  & > p:first-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }
`

const TierTagView = styled.div`
  position: absolute;
  top: -1.1rem;
  right: 1.5rem;
`

const TierDescription = styled.div`
  & > p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    margin: 0.5em 0;
  }
`

export interface LoyaltyTierModalProps extends Record<string, unknown> {
  tier: LoyaltyTierType
}

const LoyaltyTier = ({ tier }: LoyaltyTierModalProps) => {
  const dispatch = useAppDispatch()
  const paragraphs = tier.description
    ? tier.description.split('\n').filter((i) => i)
    : null

  return (
    <ModalView style={{ width: '60rem' }}>
      <ModalContent
        title={
          <span>
            <span style={{ color: `${(tier as any).color}` }}>{tier.name}</span>
          </span>
        }
        footer={
          <div>
            <Button onClick={() => dispatch(closeModal())}>Close</Button>
          </div>
        }
      >
        <>
          {tier.reward ? (
            <TierDiscount>
              <TierTagView>
                <Tag icon={<Award />} text="Reward" />
              </TierTagView>
              <Heading as="p">{tier.reward.title}</Heading>
              <p>{tier.reward.description}</p>
            </TierDiscount>
          ) : null}
          {paragraphs && (
            <TierDescription>
              {paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </TierDescription>
          )}
        </>
      </ModalContent>
    </ModalView>
  )
}

export default LoyaltyTier
