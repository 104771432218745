import { useState, useRef, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { RevenueCenter } from '@open-tender/types'
import { makeImageMap } from '@open-tender/utils'
import { Button, NavScroll } from 'components'

const NavStickyView = styled.div`
  width: 100%;
  height: ${(props) => props.theme.layout.navHeight};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    height: ${(props) => props.theme.layout.navHeightMobile};
  }
`

interface NavStickyInnerProps {
  stuck?: boolean
}

const NavStickyInner = styled.div<NavStickyInnerProps>`
  width: 100%;
  background-color: ${(props) => props.theme.bgColors.dark};
  ${(props) =>
    props.stuck &&
    `position: fixed;
      z-index: 10;
      top: ${props.theme.layout.navHeight};
      left: 0;
      right: 0;`}
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    ${(props) =>
      props.stuck &&
      `position: fixed;
      z-index: 10;
      top: ${props.theme.layout.navHeightMobile};
      left: 0;
      right: 0;`}
  }
`

const NavStickyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const NavStickyFilter = styled.div`
  height: 100%;
  flex: 0 0;
  padding: 0 0 0 ${(props) => props.theme.layout.padding};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    min-height: ${(props) => props.theme.layout.navHeight};
    padding: 0;
    justify-content: center;
  }
`

const NavStickyLogo = styled.div`
  min-width: 4rem;
  padding: 0;
  margin: 0 2rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};

  img {
    display: block;
    width: auto;
    max-width: none;
    height: 4rem;
  }
`

const NavSticky = ({
  items,
  offset = 0,
  revenueCenter,
  change,
}: {
  items: any
  offset: number
  revenueCenter: RevenueCenter
  change: (item: any) => void
}) => {
  const [stuck, setStuck] = useState(false)
  const stickyRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()
  const { navHeight, navHeightMobile } = theme.layout
  const height = isMobile ? navHeightMobile : navHeight
  const heightInPixels = parseInt(height.replace('rem', '')) * 10
  const topOffset = heightInPixels
  const showNav = !(isMobile && revenueCenter)
  const imagesMap = makeImageMap(revenueCenter.images)
  const imageUrl =
    imagesMap.SMALL_IMAGE || imagesMap.APP_IMAGE || imagesMap.LARGE_IMAGE

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        setStuck(stickyRef.current.getBoundingClientRect().top <= topOffset)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [topOffset])

  return (
    <NavStickyView ref={stickyRef}>
      <NavStickyInner stuck={stuck}>
        <NavStickyContainer>
          {revenueCenter && (
            <NavStickyFilter>
              <NavStickyLogo>
                <img src={imageUrl} alt={revenueCenter.name} />
              </NavStickyLogo>
              <div>
                <Button
                  onClick={() => change(null)}
                  size="small"
                  color="secondary"
                >
                  Switch
                </Button>
              </div>
            </NavStickyFilter>
          )}
          {showNav && <NavScroll items={items} offset={offset} />}
        </NavStickyContainer>
      </NavStickyInner>
    </NavStickyView>
  )
}

export default NavSticky
