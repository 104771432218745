import {
  MenuItemCalsText,
  MenuItemPriceCals,
  MenuItemPriceCalsSimple,
  MenuItemPriceSep,
  MenuItemPriceText,
} from './MenuItemPrice.styled'

const MenuItemPrice = ({
  price,
  cals,
  isSimple,
  className,
}: {
  price: string
  cals: string
  isSimple?: boolean
  className?: string
}) => {
  const showPrice = price && price !== '$0.00'

  if (!showPrice) return null

  const content = (
    <>
      {price ? <MenuItemPriceText>{price}</MenuItemPriceText> : null}
      {price && cals ? <MenuItemPriceSep /> : null}
      {cals ? <MenuItemCalsText>{cals} Cal</MenuItemCalsText> : null}
    </>
  )

  return isSimple ? (
    <MenuItemPriceCalsSimple className={className}>
      {content}
    </MenuItemPriceCalsSimple>
  ) : (
    <MenuItemPriceCals className={className}>{content}</MenuItemPriceCals>
  )
}

export default MenuItemPrice
