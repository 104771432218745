import { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import { CheckoutErrorMessages, CreditCardValidate } from '@open-tender/types'
import {
  capitalize,
  checkAmountRemaining,
  validateCreditCard,
} from '@open-tender/utils'
import {
  selectCheckout,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { CreditCard } from 'components'

const CheckoutGuestCreditCardView = styled.div`
  margin: 1.5rem 0 0;
`

const makeCardErrors = (errors: CheckoutErrorMessages) => {
  const tenderErrors = errors.tenders
  if (!tenderErrors || typeof tenderErrors === 'string') return undefined
  return tenderErrors[0] as Record<string, string>
}

const CheckoutGuestCreditCard = () => {
  const dispatch = useAppDispatch()
  const { check, errors: checkoutErrors } = useAppSelector(selectCheckout)
  // const card = form.tenders.find((i) => isCreditGuestCheckoutTender(i))
  // const guestCard = card ? (card as CheckoutTenderCreditGuest) : null
  const [newCard, setNewCard] = useState<CreditCardValidate | null>(null)
  const cardErrors = makeCardErrors(checkoutErrors)
  const total = check?.totals.total
  const deposit = check?.deposit
  const amountDue = deposit || total
  const amount = amountDue
    ? checkAmountRemaining(amountDue, []).toFixed(2)
    : null
  const hasAmount = amount ? parseFloat(amount) > 0 : false

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((tenders) => dispatch(updateForm({ tenders })), 500),
    [dispatch]
  )

  useEffect(() => {
    if (hasAmount && newCard) {
      const { card, cardType } = newCard
      const { card: cc, errors: errs } = validateCreditCard(card, cardType)
      if (cc.acct && cc.exp && cc.cvv && cc.zip && !errs) {
        const card_type_name = capitalize(cardType)
        const last4 = cc.acct.slice(-4)
        const tender = {
          tender_type: 'CREDIT',
          amount,
          card_type_name,
          last4,
          ...cc,
        }
        debouncedUpdate([tender])
      } else {
        debouncedUpdate([])
      }
    }
  }, [amount, hasAmount, debouncedUpdate, newCard])

  return (
    <CheckoutGuestCreditCardView>
      <CreditCard
        setCard={setNewCard}
        formErrors={cardErrors}
        showDefault={false}
      />
    </CheckoutGuestCreditCardView>
  )
}

export default CheckoutGuestCreditCard
