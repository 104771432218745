import styled from '@emotion/styled'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import { useMenuItem } from '@open-tender/utils'
import { ButtonLink } from 'components'
import { Info } from 'components/icons'

const MenuItemInfoView = styled.div`
  margin: 0 0 0.2rem;
  button {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const MenuItemInfoIcon = styled.button`
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 0;
  color: ${(props) => props.theme.colors.tertiary};
`

const MenuItemInfo = ({
  item,
  isIcon = false,
}: {
  item: ReturnType<typeof useMenuItem>
  isIcon?: boolean
}) => {
  const dispatch = useAppDispatch()

  const {
    name,
    description,
    tags,
    allergens,
    nutritionalInfo,
    ingredients,
    hasCals,
    hasIngredients,
    hasInfo,
  } = item

  if (!hasInfo) return null

  const showInfo = () => {
    if (!hasInfo) return
    const args = {
      name,
      tags,
      allergens,
      description: description || null,
      nutritionalInfo: hasCals ? nutritionalInfo : null,
      ingredients: hasIngredients ? ingredients : null,
    }
    dispatch(openModal({ type: 'itemInfo', args }))
  }

  return isIcon ? (
    <MenuItemInfoIcon onClick={showInfo}>
      <Info size={18} strokeWidth={1.5} />
    </MenuItemInfoIcon>
  ) : (
    <MenuItemInfoView>
      <ButtonLink onClick={showInfo}>more info</ButtonLink>
    </MenuItemInfoView>
  )
}

export default MenuItemInfo
