import { useCallback } from 'react'
import { Customer } from '@open-tender/types'
import { closeModal, useAppDispatch } from '@open-tender/cloud'
import { ModalContent, ModalView, SignUpForm } from 'components'

export interface SignUpProps extends Record<string, unknown> {
  windowRef: React.RefObject<HTMLDivElement>
  callback?: (data: Customer) => void
}

const SignUp = ({ windowRef, callback }: SignUpProps) => {
  const dispatch = useAppDispatch()
  const signUpCallback = useCallback(
    (data: Customer) => {
      if (callback) callback(data)
      dispatch(closeModal())
    },
    [dispatch, callback]
  )

  return (
    <ModalView>
      <ModalContent
        title="Sign up for an account"
        subtitle={
          <p>Please provide the info below, and you'll be off to the races!</p>
        }
      >
        <SignUpForm windowRef={windowRef} callback={signUpCallback} />
      </ModalContent>
    </ModalView>
  )
}

export default SignUp
