import { ModalContent, OneTimePasscodeForm, Body } from 'components'

const LoginOTP = ({ callback }: { callback?: () => void }) => {
  return (
    <ModalContent
      title="Sign into your account"
      subtitle={
        <Body as="p" size="small">
          Enter your phone number or email address, and we'll send you a
          security code to complete the sign in process.
        </Body>
      }
    >
      <OneTimePasscodeForm callback={callback} />
    </ModalContent>
  )
}

export default LoginOTP
