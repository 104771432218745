import { useNavigate } from 'react-router-dom'
import {
  closeGroupOrder,
  selectGroupOrder,
  selectMenuSlug,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Back } from 'components/buttons'

const BackToMenu = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { cartId } = useAppSelector(selectGroupOrder)
  const menuSlug = useAppSelector(selectMenuSlug)

  const reopen = () => {
    if (!cartId) return
    dispatch(closeGroupOrder({ cartId, closed: false })).then(() =>
      navigate('/review')
    )
  }

  return cartId ? (
    <Back label="Reopen Group Order" onClick={reopen} />
  ) : (
    <Back label="Back to Menu" path={menuSlug} />
  )
}

export default BackToMenu
