import Tag from './Tag'
import { OrderStatus, ThemeAlertType } from '@open-tender/types'

const OrderTag = ({
  isUpcoming,
  status,
  inQueue,
}: {
  isUpcoming: boolean
  status: OrderStatus
  inQueue: boolean
}) => {
  const tag: { text: string; icon?: JSX.Element; color: ThemeAlertType } =
    status === 'VOID'
      ? {
          text: 'Cancelled',
          color: 'error',
        }
      : status === 'PENDING'
      ? {
          text: 'Pending',
          color: 'error',
        }
      : status === 'HELD'
      ? {
          text: 'Saved',
          color: 'success',
        }
      : isUpcoming
      ? {
          text: inQueue ? 'In Progress' : 'Coming up',
          color: 'alert',
        }
      : {
          text: 'Completed',
          color: 'success',
        }

  if (tag.text === 'Completed') return null

  return <Tag text={tag.text} icon={tag.icon} color={tag.color} />
}

export default OrderTag
