import {
  Cart,
  CartItem,
  CartItemGroups,
  CartItemOption,
  CheckoutCheck,
  ConfigBrand,
  Customer,
  MenuItems,
} from '@open-tender/types'
import { makeOrderItem } from '@open-tender/utils'
import { Middleware } from '@reduxjs/toolkit'
import { isMobile, isTablet } from 'react-device-detect'
import { sha256, UpsellMenuPosition } from 'utils/helpers'
import { AppState } from '@open-tender/cloud'
import store from './store'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export const sendPageView = ({
  customer,
  brand,
}: {
  customer: Customer | null
  brand: ConfigBrand | null
}) => {
  const event = {
    event: 'page_view',
    page_url: window.location.href,
    page_path: window.location.pathname,
    page_title: document.title,
    user_id: customer?.customer_id,
    user_login_status: customer ? 'logged_in' : 'logged_out',
    device_type: isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop',
    device_os: navigator.userAgent,
    brand_id: brand?.brandId,
    account_id: customer?.customer_id,
    account_name: `${customer?.first_name} ${customer?.last_name}`,
  }
  sendEventToGTM(event)
}

export const sendModifierEvent = ({
  type,
  modifier,
  item,
}: {
  type: 'increment' | 'decrement' | 'toggle'
  modifier: Record<string, string | number>
  item: CartItem
}) => {
  const event = {
    event: `modifier_${type}`,
    modifier,
    ecommerce: {
      items: [makeProduct(item)],
    },
  }
  sendEventToGTM(event)
}

export const sendUpsillsMenuView = ({
  items,
  position,
}: {
  items: CartItem[]
  position: UpsellMenuPosition
}) => {
  const event = {
    event: 'view_item_list',
    ecommerce: {
      item_list_id: 'upsells',
      item_list_name: 'Upsells',
      upsells_postion: position,
      items: items.map(makeProduct),
    },
  }

  sendEventToGTM(event)
}

export const sendMenuView = ({
  items,
  category,
}: {
  items: MenuItems
  category: { name?: string; id?: string | number }
}) => {
  const event = {
    event: 'view_item_list',
    ecommerce: {
      item_list_id: category.id,
      item_list_name: category.name,
      items: items.map((item) => {
        const cartItem = makeOrderItem(item) as CartItem
        return makeProduct(cartItem)
      }),
    },
  }
  sendEventToGTM(event)
}

export const sendExpriementBannerView = (
  variant: 'A' | 'B',
  item: CartItem
) => {
  const event = {
    event: 'experiment_impression',
    ecommerce: {
      currency: 'USD',
      value: item.totalPrice,
      items: [makeProduct(item)],
    },
    experimentName: 'banner_visibility',
    experimentId: 'EXP001',
    experimentVariant: variant,
    pageType: 'menu',
    bannerShown: true,
  }
  sendEventToGTM(event)
}

export const sendExpriementBannerClick = (
  variant: 'A' | 'B',
  item: CartItem
) => {
  const event = {
    event: 'banner_click',
    ecommerce: {
      currency: 'USD',
      value: item.totalPrice,
      items: [makeProduct(item)],
    },
    experimentName: 'banner_visibility',
    experimentId: 'EXP001',
    experimentVariant: variant,
  }
  sendEventToGTM(event)
}
export const sendMenuScroll = () => {
  const event = {
    event: 'scroll',
    scroll_depth: window.scrollY,
    scroll_percentage:
      (window.scrollY /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight)) *
      100,
  }
  sendEventToGTM(event)
}

export const sendItemView = (item: CartItem) => {
  const event = {
    event: 'view_item',
    ecommerce: {
      currency: 'USD',
      value: item.totalPrice,
      items: [makeProduct(item)],
    },
  }
  sendEventToGTM(event)
}

export const sendCartView = (cart: Cart) => {
  const totalPrice = cart.reduce(
    (t, i) => (i.totalPrice ? t + i.totalPrice : 0),
    0
  )
  const event = {
    event: 'view_cart',
    ecommerce: {
      currency: 'USD',
      value: totalPrice,
      items: cart.map((i) => makeProduct(i)),
    },
  }
  sendEventToGTM(event)
}

export const sendCheckoutView = (check: CheckoutCheck) => {
  const cart = store.getState().order.cart
  const totalPrice = cart?.reduce(
    (t, i) => (i.totalPrice ? t + i.totalPrice : 0),
    0
  )
  const event = {
    event: 'begin_checkout',
    ecommerce: {
      currency: 'USD',
      value: totalPrice,
      items: cart?.map((item) => makeProduct(item)),
    },
    customer: check.customer,
  }
  sendEventToGTM(event)
}
export const sendAddPaymentInfo = () => {
  const cart = store.getState().order.cart
  const totalPrice = cart?.reduce(
    (t, i) => (i.totalPrice ? t + i.totalPrice : 0),
    0
  )
  const event = {
    event: 'add_payment_info',
    ecommerce: {
      currency: 'USD',
      payment_type: 'Credit Card',
      value: totalPrice,
      items: cart?.map((item) => makeProduct(item)),
    },
  }
  sendEventToGTM(event)
}
interface SimpleOption {
  name: string
  id: number
  quantity: number
  pos_ext_id: string | null
  price: number
  isDefault: boolean
  groups: SimpleGroup[]
}
interface SimpleGroup {
  name: string
  id: number
  options: SimpleOption[]
}

const getSelectedModifiers = (groups: CartItemGroups): SimpleGroup[] => {
  const simpleGroups = groups.map((group) => {
    return {
      name: group.name,
      id: group.id,
      options: group.options
        .filter((o: CartItemOption) => o.quantity > 0)
        .map((op: CartItemOption) => ({
          name: op.name,
          id: op.id,
          quantity: op.quantity,
          pos_ext_id: op.pos_ext_id,
          price: parseFloat(op.price as unknown as string),
          isDefault: Boolean(op.isDefault),
          groups: getSelectedModifiers(op.groups),
        })),
    }
  })
  return simpleGroups
}

const makeProduct = (
  item: CartItem & {
    parent_item_name?: string
    parent_item_id?: string
    upsells_position?: UpsellMenuPosition
  }
) => {
  const { id, name, quantity } = item
  const totalPrice = (item as CartItem).totalPrice
  const selectedGroups = getSelectedModifiers(item.groups)
  const isItemWithDefaultModifiers = selectedGroups.every((g) =>
    g.options.every((o) => o.isDefault)
  )
  const data = {
    item_id: id.toString(),
    item_name: name,
    item_list_id: item.list_id,
    item_list_name: item.list_name,
    parent_item_name: item.parent_item_name,
    parent_item_id: item.parent_item_id,
    upsells_position: item.upsells_position,
    index: item.menu_position,
    quantity,
    pos_ext_id: item.pos_ext_id,
    groups: selectedGroups,
    isDefault: isItemWithDefaultModifiers,
  }
  const price = totalPrice
    ? parseFloat((totalPrice / quantity).toFixed(2))
    : null
  return price ? { ...data, price } : data
}

const makeProducts = (cart: Cart) => {
  return cart.map((item) => makeProduct(item))
}

const incrementProduct = (item: CartItem) => {
  return {
    ...makeProduct(item),
    quantity: item.increment,
  }
}

export const sendLogin = async (email: string) => {
  const event = {
    event: 'login',
    email: await sha256(email),
  }
  setTimeout(() => {
    sendEventToGTM(event)
  }, 1000)
}
export const sendSignup = async (email: string) => {
  const event = {
    event: 'sign_up',
    email: await sha256(email),
  }
  sendEventToGTM(event)
}

export const sendOrderTypeSelected = (data: {
  orderType: string
  serviceType: string
}) => {
  const event = {
    event: 'order_type_selected',
    ...data,
  }
  sendEventToGTM(event)
}
export const sendLocationSelected = (data: {
  name: string
  revenue_center_id: number
}) => {
  const event = {
    event: 'location_selected',
    location_name: data?.name,
    location_id: data?.revenue_center_id,
  }
  sendEventToGTM(event)
}

const makeGtmEvent = async ({
  type,
  payload,
  meta,
}: {
  type: string
  payload: any
  meta: any
}) => {
  switch (type) {
    case 'order/addItemToCart': {
      const item: CartItem = payload
      return {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: parseFloat(payload.totalPrice),
          items: [makeProduct(item)],
        },
      }
    }
    case 'order/incrementItemInCart': {
      return {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: parseFloat(payload.totalPrice),
          items: [incrementProduct(payload)],
        },
      }
    }
    case 'order/removeItemFromCart': {
      return {
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'USD',
          value: parseFloat(payload.totalPrice),
          items: [makeProduct(payload)],
        },
      }
    }
    case 'order/decrementItemInCart': {
      return {
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'USD',
          value: parseFloat(payload.totalPrice),
          items: [incrementProduct(payload)],
        },
      }
    }
    case 'confirmation/setConfirmationOrder': {
      const { order_id, totals, cart, delivery, customer } = payload
      const orderCart = store.getState().order.cart
      if (!totals) return null
      const { tax } = totals
      return {
        event: 'purchase',
        ecommerce: {
          transaction_id: order_id.toString(),
          value: parseFloat(totals.subtotal),
          tax: parseFloat(tax),
          totals: Object.keys(totals).reduce(
            (t, v) => ({ ...t, [v]: parseFloat(totals[v]) }),
            {}
          ),
          currency: 'USD',
          shippment: delivery,
          items: makeProducts(orderCart ?? cart),
        },
        customer,
      }
    }
    case 'guest/fetchGuest/rejected': {
      return {
        event: 'guest_email',
        email_id: await sha256(payload.email),
      }
    }

    default:
      return null
  }
}

const sendEventToGTM = async (event: any) => {
  if (!event) return

  let reduxState: AppState = store.getState()
  let profile = reduxState.customer.account.profile

  const eventWithSessionId = {
    ...event,
    session_id: window.localStorage.getItem('session_id'),
    user_id: event.user_id ?? profile?.customer_id,
    timestamp: new Date().toISOString(),
    order_type: reduxState.order.orderType
      ? `${reduxState.order.orderType} - ${reduxState.order.serviceType}`
      : '',
    location_id:
      event.location_id ?? reduxState.order.revenueCenter?.revenue_center_id,
    location_name: event.location_name ?? reduxState.order.revenueCenter?.name,
    brand_id: process.env.REACT_APP_BRAND_ID,
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(eventWithSessionId)
  window.dataLayer.push(function (this: any) {
    this.reset()
  })
  return
}

export const analytics: Middleware = (s) => (next) => async (action) => {
  const event = await makeGtmEvent(action)
  if (!event) return next(action)
  sendEventToGTM(event)
  return next(action)
}
