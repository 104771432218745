import styled from '@emotion/styled'
import { GroupOrdersExpanded } from '@open-tender/types'
import { OrderCardGroup } from 'components'

const GroupOrdersListView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem -1rem;
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
`

const GroupOrdersListItem = styled.div`
  width: 36rem;
  max-width: 100%;
  padding: 0 1rem;
  margin: 0 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

const GroupOrdersList = ({ orders }: { orders: GroupOrdersExpanded }) => {
  return (
    <GroupOrdersListView>
      {orders.map((order) => {
        return (
          <GroupOrdersListItem key={order.token}>
            <OrderCardGroup order={order} />
          </GroupOrdersListItem>
        )
      })}
    </GroupOrdersListView>
  )
}

export default GroupOrdersList
