import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  openModal,
  selectCheckout,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink, Text } from 'components'

const CheckoutGiftCardsGuestView = styled.div``

const CheckoutGiftCardsGuest = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { form } = useAppSelector(selectCheckout)
  const { email } = form.customer

  const signUp = () => {
    email
      ? navigate('/checkout/signup')
      : dispatch(openModal({ type: 'signUp' }))
  }

  return (
    <CheckoutGiftCardsGuestView>
      <Text size="small">
        Have a gift card number?{' '}
        <ButtonLink onClick={signUp}>Create an account to apply it.</ButtonLink>
      </Text>
    </CheckoutGiftCardsGuestView>
  )
}

export default CheckoutGiftCardsGuest
