import { isoToDateStr } from '@open-tender/utils'
import {
  HistoryEventContent,
  HistoryEventCount,
  HistoryEventCounts,
  HistoryEventDate,
  HistoryEventDescription,
  HistoryEventTitle,
  HistoryEventView,
} from './History.styled'
import { TPLSHistoryEvent } from '@open-tender/types'

const HistoryEvent = ({ event }: { event: TPLSHistoryEvent }) => {
  const { date, description, value, items, points, title } = event
  return (
    <HistoryEventView>
      <HistoryEventDate size="xSmall">
        {isoToDateStr(date, null, 'MMM d, yyyy')}
      </HistoryEventDate>
      <HistoryEventContent>
        <HistoryEventTitle size="big">{title}</HistoryEventTitle>
        <HistoryEventDescription size="small">{value}</HistoryEventDescription>
        <HistoryEventDescription size="small">
          {description}
        </HistoryEventDescription>
        <HistoryEventCounts>
          <HistoryEventCount size="xSmall">Items: {items}</HistoryEventCount>
          <HistoryEventCount size="xSmall">Points: {points}</HistoryEventCount>
        </HistoryEventCounts>
      </HistoryEventContent>
    </HistoryEventView>
  )
}

export default HistoryEvent
