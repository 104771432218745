import { useEffect, useCallback } from 'react'
import { FormFieldType } from '@open-tender/types'
import { useGiftCardAssignOtherForm } from '@open-tender/utils'
import {
  assignCustomerGiftCardOther,
  closeModal,
  resetCustomerGiftCardsError,
  selectCustomerGiftCards,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const GiftCardAssignOtherForm = ({
  windowRef,
  giftCardId,
}: {
  windowRef: React.RefObject<HTMLDivElement>
  giftCardId: number
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectCustomerGiftCards)
  const callback = useCallback(() => dispatch(closeModal()), [dispatch])
  const assign = useCallback(
    (giftCardId: number, email: string, callback: () => void) =>
      dispatch(assignCustomerGiftCardOther({ giftCardId, email, callback })),
    [dispatch]
  )

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useGiftCardAssignOtherForm(loading, error, giftCardId, assign, callback)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error && windowRef.current) windowRef.current.scrollTop = 0
  }, [error, windowRef])

  useEffect(() => {
    return () => {
      dispatch(resetCustomerGiftCardsError())
    }
  }, [dispatch])

  return (
    <form id="gift-card-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      {/* <FormInputs>
        <Input
          ref={inputRef}
          label="Email Address"
          name="email"
          type="email"
          value={email}
          onChange={(evt) => handleChange(evt.target.value)}
          error={(errors as any).email}
          required={true}
        />
      </FormInputs> */}
      <FormSubmit>
        <ButtonSubmit
          submitRef={submitRef}
          submitting={submitting || !data.email}
        >
          {submitting ? 'Submitting...' : 'Assign Gift Card'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default GiftCardAssignOtherForm
