import styled from '@emotion/styled'
import { useImage } from '@open-tender/utils'
import { BackgroundLoading } from 'components'
import BgImage from 'components/BgImage'

const BackgroundImageView = styled.div<{
  bgColor?: string
}>`
  position: relative;
  flex-grow: 1;
  background-color: ${(props) =>
    props.bgColor || props.theme.bgColors.tertiary};
`

const BackgroundImageImage = styled(BgImage)<{ bgSize: string }>`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  animation: fade-in 0.25s ease-in-out 0s forwards;
  background-size: ${(props) => props.bgSize};
`

const BackgroundOverlay = styled.div<{
  color?: string
}>`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.color || 'rgba(0, 0, 0, 0.3)'};
`

const makeOverlayColor = (color?: string | null, opacity?: number) => {
  if (!color || !opacity) return null
  const r = parseInt(color.slice(0, 2), 16)
  const g = parseInt(color.slice(2, 4), 16)
  const b = parseInt(color.slice(4, 6), 16)
  const o = ((opacity ?? 0) / 100.0).toFixed(2)
  return `rgba(${r}, ${g}, ${b}, ${o})`
}

const BackgroundImage = ({
  imageUrl,
  show_overlay = false,
  overlay_color,
  overlay_opacity,
  bgColor,
  bgSize = 'cover',
  style = {},
  children,
}: {
  imageUrl?: string | null
  show_overlay?: boolean
  overlay_color?: string | null
  overlay_opacity?: number
  bgColor?: string
  bgSize?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}) => {
  const { hasLoaded, hasError } = useImage(imageUrl)
  if (!imageUrl) return null

  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl}` } : undefined
  const isLoading = !hasLoaded && !hasError
  // const isLoading = true
  const overlayColor = makeOverlayColor(overlay_color, overlay_opacity)

  return (
    <BackgroundImageView bgColor={bgColor} style={style}>
      {isLoading ? (
        <BackgroundLoading />
      ) : (
        <>
          <BackgroundImageImage bgSize={bgSize} style={bgStyle} />
          {show_overlay && (
            <BackgroundOverlay color={overlayColor ?? undefined} />
          )}
        </>
      )}
      {children}
    </BackgroundImageView>
  )
}

export default BackgroundImage
