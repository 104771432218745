import { useCallback, useEffect } from 'react'
import { FormFieldType } from '@open-tender/types'
import { checkNotFound, useGuestForm } from '@open-tender/utils'
import {
  fetchGuest,
  fetchGuestThanx,
  resetGuestErrors,
  selectBrand,
  selectGuest,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const GuestForm = ({
  signIn,
  signUp,
  submitText = 'Submit Email',
}: {
  signIn: () => void
  signUp: () => void
  submitText?: string
}) => {
  const dispatch = useAppDispatch()
  const { has_thanx } = useAppSelector(selectBrand) || {}
  const { email, loading, error } = useAppSelector(selectGuest)
  const checkGuestDefault = useCallback(
    (email: string) => dispatch(fetchGuest({ email, callback: signIn })),
    [dispatch, signIn]
  )
  const checkGuestThanx = useCallback(
    (email: string) => dispatch(fetchGuestThanx({ email })),
    [dispatch]
  )
  const checkGuest = has_thanx ? checkGuestThanx : checkGuestDefault

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useGuestForm(email ?? '', loading, error, checkGuest)
  const notFound = checkNotFound(errors)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error) {
      notFound ? signUp() : window.scroll(0, 0)
    }
  }, [error, notFound, signUp])

  useEffect(() => {
    return () => {
      dispatch(resetGuestErrors())
    }
  }, [dispatch])

  return (
    <form id="guest-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit
          submitRef={submitRef}
          submitting={submitting}
          disabled={!data.email}
        >
          {submitting ? 'Submitting...' : submitText}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default GuestForm
