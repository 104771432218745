import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import TagManager from 'react-gtm-module'
import {
  fetchConfig,
  useAppDispatch,
  selectConfig,
  useAppSelector,
  selectCustomer,
} from '@open-tender/cloud'
import GlobalStyles from './GlobalStyles'
import AppRoutes from './components/AppRoutes'
import {
  Alerts,
  CartButton,
  ErrorBoundary,
  Modal,
  Nav,
  NavSite,
  Notifications,
  Sidebar,
} from './components'
import { ErrorFatal } from './components/pages'
import { generateRandomSessionID } from 'utils/helpers'

type AppContextValue = {
  scrollContainerRef: HTMLElement | null
}

export const AppContext = createContext<AppContextValue>({
  scrollContainerRef: null,
})

export const useAppContext = () => useContext(AppContext)

export const AppView = styled.div`
  label: AppView;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`

const baseUrl = process.env.REACT_APP_API_URL
const authUrl = process.env.REACT_APP_AUTH_URL
const clientId = process.env.REACT_APP_CLIENT_ID
const brandId = process.env.REACT_APP_BRAND_ID

const sessionID = generateRandomSessionID().toString()

const App = () => {
  const dispatch = useAppDispatch()
  const windowRef = useRef<HTMLDivElement>(null)
  const [gtmId, setGtmId] = useState<boolean>(false)
  const { loading, theme, brand, error } = useAppSelector(selectConfig)
  const { profile } = useAppSelector(selectCustomer)
  const { body, headings } = theme?.fonts || {}
  const isLoading = loading === 'pending'
  const hasTheme = !isLoading && !error && theme
  const hasBrand = brand && body && headings

  useEffect(() => {
    const options = { baseUrl, authUrl, clientId, brandId }
    dispatch(fetchConfig({ ...options, isApp: false }))
  }, [dispatch])

  useEffect(() => {
    window.localStorage.setItem('session_id', sessionID)
  }, [])

  useLayoutEffect(() => {
    if (loading !== 'pending' && brand && !gtmId) {
      setGtmId(true)
      const tagManagerArgs = {
        gtmId: brand.gtmContainerId,
        dataLayer: {
          user_id: profile?.customer_id,
          session_id: sessionID,
        },
      }
      TagManager.initialize(tagManagerArgs)
      TagManager.initialize({ ...tagManagerArgs, gtmId: brand.gtmContainer2Id })
    }
  }, [loading, brand, gtmId, profile?.customer_id])

  return (
    <>
      <ErrorFatal error={error} loading={loading} />
      {hasTheme && (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <AppView ref={windowRef} id="app">
            {hasBrand ? (
              <Helmet>
                <title>{brand.title}</title>
                <meta name="description" content={brand.description} />
                <link rel="icon" href={brand.favicon} />
                <link rel="apple-touch-icon" href={brand.appleTouchIcon} />
                <link href={body.url} rel="stylesheet" />
                {headings.url && body.url !== headings.url && (
                  <link href={headings.url} rel="stylesheet" />
                )}
              </Helmet>
            ) : null}
            <AppContext.Provider
              value={{ scrollContainerRef: window as unknown as HTMLElement }}
            >
              <ErrorBoundary>
                <BrowserRouter>
                  <Modal />
                  <Alerts />
                  <Notifications />
                  <CartButton />
                  <AppRoutes />
                  <Sidebar />
                  <Nav />
                  <NavSite />
                </BrowserRouter>
              </ErrorBoundary>
            </AppContext.Provider>
          </AppView>
        </ThemeProvider>
      )}
    </>
  )
}

export default App
