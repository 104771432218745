import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { closeModal, deleteCustomer, useAppDispatch } from '@open-tender/cloud'
import { ButtonLink, Button, ModalContent, ModalView } from 'components'

const DeleteAccountContent = styled.div`
  p {
    color: ${(props) => props.theme.colors.error};
  }

  button {
    color: ${(props) => props.theme.colors.light};
    background-color: ${(props) => props.theme.colors.error};
    border-color: ${(props) => props.theme.colors.error};

    &:hover,
    &:active {
      color: ${(props) => props.theme.colors.light};
      background-color: ${(props) => props.theme.colors.dark};
      border-color: ${(props) => props.theme.colors.dark};
    }
  }
`

const DeleteAccountFooter = styled.div`
  justify-content: center !important;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

const DeleteAccount = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const deleteCustomerAccount = async () => {
    await dispatch(deleteCustomer({}))
    navigate(`/guest`)
  }

  const cancel = () => {
    dispatch(closeModal())
  }

  return (
    <ModalView>
      <ModalContent
        title="Delete Your Account"
        subtitle={
          <p>Are you sure you want to permanently delete your account?</p>
        }
        style={{ textAlign: 'center' }}
        footer={
          <DeleteAccountFooter>
            <ButtonLink onClick={cancel}>
              Nevermind, I'll keep my account
            </ButtonLink>
          </DeleteAccountFooter>
        }
      >
        <DeleteAccountContent>
          <p>
            This action is permanent and there is no undo. Please make sure you
            want to do this.
          </p>
          <Button onClick={deleteCustomerAccount}>
            Yes, Delete My Account Forever
          </Button>
        </DeleteAccountContent>
      </ModalContent>
    </ModalView>
  )
}

export default DeleteAccount
