import styled from '@emotion/styled'

const FormFieldset = styled.fieldset`
  & + fieldset {
    margin: ${(props) => props.theme.layout.margin} 0 0;
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: ${(props) => props.theme.layout.marginMobile} 0 0;
    }
  }
`

export default FormFieldset
