import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import {
  selectBrand,
  selectContentSection,
  selectMenu,
  useAppSelector,
} from '@open-tender/cloud'
import { Content, Loading, Main, ScreenreaderTitle } from 'components'
import { Back } from 'components/buttons'
import MenuHeader from './MenuHeader'
import MenuTop from './MenuTop'

const MenuLoading = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

const MenuNew = () => {
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { loading } = useAppSelector(selectMenu) || {}
  const isLoading = loading === 'pending'
  const { loadingMessage } = useAppSelector(selectContentSection('menu')) || {}

  return (
    <>
      <Helmet>
        <title>Menu | {siteTitle}</title>
      </Helmet>
      <Content>
        <MenuHeader
          back={<Back label="Back to Locations" path="/locations" />}
        />
        <Main>
          <ScreenreaderTitle>Menu</ScreenreaderTitle>
          {isLoading ? (
            <MenuLoading>
              <Loading text={loadingMessage} />
            </MenuLoading>
          ) : (
            <MenuTop />
          )}
        </Main>
      </Content>
    </>
  )
}

export default MenuNew
