import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { BarLoader, ClipLoader } from 'react-spinners'

const loader = (props: { color: string; size: number }, type?: string) => {
  switch (type) {
    case 'Clip':
      return <ClipLoader {...props} loading={true} />
    default:
      return <BarLoader {...props} loading={true} />
  }
}

const LoadingView = styled.div`
  text-align: center;
`

const LoadingLoader = styled.div`
  display: inline-block;

  & > span {
    display: block;
  }
`

const LoadingMessage = styled('p')`
  margin: 1rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

const Loading = ({
  type,
  text,
  color,
  size = 100,
  style,
}: {
  type?: string
  text?: string | null
  size?: number
  color?: string
  style?: React.CSSProperties
}) => {
  const theme = useTheme()
  const themeColor = theme.colors ? theme.colors.primary : null
  const props = { color: color || themeColor || '#000000', size }
  return (
    <LoadingView style={style}>
      <LoadingLoader>{loader(props, type)}</LoadingLoader>
      {text && text.length > 0 && <LoadingMessage>{text}</LoadingMessage>}
    </LoadingView>
  )
}

export default Loading
