import styled from '@emotion/styled'
import { Checkbox } from '../inputs'
import Preface from 'components/Preface'
import { NotificationPrefsLookup } from 'components/CommunicationPrefs'

const orderNotifications = [
  { notification_area: 'ORDER', notification_channel: 'EMAIL', title: 'Email' },
  { notification_area: 'ORDER', notification_channel: 'SMS', title: 'SMS' },
  {
    notification_area: 'ORDER',
    notification_channel: 'PUSH',
    title: 'Push Notifications',
  },
]

const marketing = [
  {
    notification_area: 'MARKETING',
    notification_channel: 'EMAIL',
    title: 'Email',
  },
  { notification_area: 'MARKETING', notification_channel: 'SMS', title: 'SMS' },
  {
    notification_area: 'MARKETING',
    notification_channel: 'PUSH',
    title: 'Push Notifications',
  },
]

const ratings = [
  {
    notification_area: 'RATING',
    notification_channel: 'EMAIL',
    title: 'Email',
  },
  // { notification_area: 'RATING', notification_channel: 'SMS', title: 'SMS' },
  // {
  //   notification_area: 'RATING',
  //   notification_channel: 'PUSH',
  //   title: 'Push Notifications',
  // },
]

const CommunicationPreferencesView = styled.div``

const CommunicationPreferencesHeader = styled.div`
  margin: 0 0 1.5rem;
`

const CommunicationPreferencesTitle = styled(Preface)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

const CommunicationPreferencesSubtitle = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  margin: 0.5rem 0 0;
`

const CommunicationPreferencesOptions = styled.div`
  & > label {
    margin: 1.3rem 0 0;
  }
`

const CommunicationPreferencesGroupView = styled.div`
  margin: 0 0 3.5rem;
`

const CommunicationPreferencesGroup = ({
  channelTypes,
  prefs,
  add,
  remove,
  fields,
  title,
  subtitle,
}: {
  channelTypes: string[]
  prefs: any[]
  add: (area: string, type: string) => void
  remove: (id: string) => void
  fields: any[]
  title: string
  subtitle: string
}) => {
  const filtered = fields.filter((i) =>
    channelTypes.includes(i.notification_channel)
  )
  return (
    <CommunicationPreferencesGroupView>
      <CommunicationPreferencesHeader>
        <CommunicationPreferencesTitle>{title}</CommunicationPreferencesTitle>
        {subtitle && (
          <CommunicationPreferencesSubtitle>
            {subtitle}
          </CommunicationPreferencesSubtitle>
        )}
      </CommunicationPreferencesHeader>
      <CommunicationPreferencesOptions>
        {filtered.map((field) => {
          const name = `${field.notification_area}_${field.notification_channel}`
          const pref = prefs.find(
            (i) =>
              i.notification_area === field.notification_area &&
              i.notification_channel === field.notification_channel
          )
          return (
            <Checkbox
              key={name}
              type="checkbox"
              showLabel={true}
              required={true}
              name={name}
              on={pref ? true : false}
              onChange={
                pref
                  ? () => remove(pref.customer_notification_preference_id)
                  : () =>
                      add(field.notification_area, field.notification_channel)
              }
              description={field.title}
            />
          )
        })}
      </CommunicationPreferencesOptions>
    </CommunicationPreferencesGroupView>
  )
}

const CommunicationPreferences = ({
  brandPrefs,
  prefs,
  add,
  remove,
  accepts_marketing = {},
  order_notifications = {},
}: {
  brandPrefs: NotificationPrefsLookup
  prefs: any[]
  add: (area: any, channel: any) => void
  remove: (prefId: any) => void
  accepts_marketing: any
  order_notifications: any
}) => {
  return (
    <CommunicationPreferencesView>
      {brandPrefs.ORDER && brandPrefs.ORDER.length > 0 && (
        <CommunicationPreferencesGroup
          channelTypes={brandPrefs.ORDER}
          prefs={prefs}
          add={add}
          remove={remove}
          fields={orderNotifications}
          title={order_notifications?.title || 'Order Notifications'}
          subtitle={order_notifications?.description || null}
        />
      )}
      {brandPrefs.MARKETING && brandPrefs.MARKETING.length > 0 && (
        <CommunicationPreferencesGroup
          channelTypes={brandPrefs.MARKETING}
          prefs={prefs}
          add={add}
          remove={remove}
          fields={marketing}
          title={accepts_marketing?.title || 'Marketing & Promotions'}
          subtitle={accepts_marketing?.description || null}
        />
      )}
      {brandPrefs.RATING && brandPrefs.RATING.length > 0 && (
        <CommunicationPreferencesGroup
          channelTypes={brandPrefs.RATING}
          prefs={prefs}
          add={add}
          remove={remove}
          fields={ratings}
          title="Order Ratings"
          subtitle="Rate orders & provide reviews"
        />
      )}
    </CommunicationPreferencesView>
  )
}

export default CommunicationPreferences
