import {
  exchangeTplsPointsShopReward,
  fetchCustomerTpls,
  fetchTplsPointsShop,
  selectBrand,
  selectCustomerTpls,
  selectTplsPointsShop,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { useEffect } from 'react'
import { getIsPointShowRewardExpired } from '@open-tender/utils'

const useTplsPointsShop = () => {
  const dispatch = useAppDispatch()

  const { tpls } = useAppSelector(selectBrand) || {}
  const { entities, loading, error } = useAppSelector(selectTplsPointsShop)

  const rewards = entities?.filter(
    (entity) => !getIsPointShowRewardExpired(entity)
  )

  const customerTpls = useAppSelector(selectCustomerTpls) || {}

  const { balance: pointsBalance = null } = customerTpls.tpls
    ? customerTpls.tpls.points || {}
    : {}

  const hasTplPointsShop =
    tpls === 'COMO' || tpls === 'PUNCHH' || tpls === 'SPARKFLY'

  const hasPointsRewards = rewards.length > 0
  const isLoading = loading === 'pending'
  const updatingBalance = customerTpls.loading === 'pending'

  useEffect(() => {
    dispatch(fetchTplsPointsShop())
  }, [dispatch, hasTplPointsShop])

  const handleExchangePoints = async (id: string) => {
    await dispatch(exchangeTplsPointsShopReward(id))
    dispatch(fetchCustomerTpls())
  }
  return {
    rewards,
    pointsBalance,
    hasPointsRewards,
    isLoading,
    updatingBalance,
    error,
    handleExchangePoints,
  }
}

export default useTplsPointsShop
