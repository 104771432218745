import styled from '@emotion/styled'
import { Button, Heading } from 'components'
import { Minus, Plus } from 'components/icons'

const MenuItemFullAddView = styled.div`
  margin: ${(props) => props.theme.layout.padding} 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const MenuItemFullAddButton = styled.div`
  min-width: 20rem;
  button {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  button span {
    transition: ${(props) => props.theme.links.transition};
    color: ${(props) => props.theme.buttons.colors.primary.color};
  }
  button:hover span {
    color: ${(props) => props.theme.buttons.colors.primaryHover.color};
  }
`

const MenuItemFullAddQuantity = styled.div`
  margin: 0 0 0 ${(props) => props.theme.layout.padding};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const MenuItemFullAddQuantityButton = styled.div`
  flex: 0 0 4rem;
  width: 4rem;
  height: 4rem;
  margin-right: ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: ${(props) => props.theme.item.mobile.padding};
    flex: 0 0 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
  }
  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 2.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      border-radius: 2rem;
    }
  }
`

const MenuItemFullAddCount = styled.div`
  min-width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: 4rem;
    height: 4rem;
  }
`

const MenuItemFullAdd = ({
  addToOrder,
  disabled,
  quantity,
  increment,
  decrement,
}: {
  addToOrder: () => void
  disabled: boolean
  quantity: number
  increment: () => void
  decrement: () => void
}) => {
  return (
    <MenuItemFullAddView>
      <MenuItemFullAddButton>
        <Button onClick={addToOrder} disabled={disabled} size="big">
          <Heading size="big">Add To Order</Heading>
        </Button>
      </MenuItemFullAddButton>
      <MenuItemFullAddQuantity>
        <MenuItemFullAddQuantityButton style={{ margin: 0 }}>
          <Button onClick={decrement} size="big" color="secondary">
            <Minus size={25} />
          </Button>
        </MenuItemFullAddQuantityButton>
        <MenuItemFullAddCount>
          <Heading as="div" size="big">
            {quantity}
          </Heading>
        </MenuItemFullAddCount>
        <MenuItemFullAddQuantityButton>
          <Button onClick={increment} size="big" color="secondary">
            <Plus size={25} />
          </Button>
        </MenuItemFullAddQuantityButton>
      </MenuItemFullAddQuantity>
    </MenuItemFullAddView>
  )
}

export default MenuItemFullAdd
