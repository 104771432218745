import { useCallback, useEffect } from 'react'
import {
  resetSignUp,
  selectBrand,
  selectContent,
  selectSignUp,
  signUpCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  CheckoutConfig,
  Customer,
  CustomerCreate,
  FormFieldType,
} from '@open-tender/types'
import { useSignUpForm } from '@open-tender/utils'
import {
  ButtonSubmit,
  Checkbox,
  FormError,
  FormInputs,
  FormSubmit,
  ThanxTerms,
  ThirdPartyLoyaltyTerms,
} from 'components'
import Body from 'components/Body'
import styled from '@emotion/styled'

const SignUpFormCommunications = styled.div`
  label {
    margin: 0 0 1.5rem;
  }
`

const SignUpForm = ({
  windowRef,
  callback,
  checkConfig,
}: {
  windowRef?: React.RefObject<HTMLDivElement>
  callback?: (data: Customer) => void
  checkConfig?: CheckoutConfig
}) => {
  const dispatch = useAppDispatch()
  const {
    has_thanx = false,
    tpls,
    notification_preferences: prefs,
  } = useAppSelector(selectBrand) || {}
  const { loading, error } = useAppSelector(selectSignUp)
  const { signUp: config } = useAppSelector(selectContent) || {}
  const optionalFields = config?.displayed || []
  const hidePassword = has_thanx || tpls === 'COMO' ? true : false
  const notificationChannels = prefs
    ? Array.from(new Set(prefs?.map((i) => i.notification_channel)))
    : []
  const passwordRequirements =
    tpls === 'PUNCHH' ? ' (must be at least 8 characters)' : ''

  const signUp = useCallback(
    (data: CustomerCreate, callback?: (data: Customer) => void) =>
      dispatch(signUpCustomer({ data, callback })),
    [dispatch]
  )

  const {
    submitRef,
    data,
    comms,
    errors,
    submitting,
    fields,
    notificationFields,
    handleChange,
    handleComms,
    handleSubmit,
  } = useSignUpForm(
    loading,
    error,
    signUp,
    callback,
    checkConfig,
    hidePassword,
    optionalFields,
    notificationChannels,
    passwordRequirements
  )

  const errMsg = errors.form?.includes('parameters')
    ? 'There are one or more errors below. Please review and resubmit.'
    : errors.form

  if (errors.form && !errors.password && (data.password?.length || 0) < 8) {
    errors.password = 'Invalid password'
  }

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    dispatch(resetSignUp())
    return () => {
      dispatch(resetSignUp())
    }
  }, [dispatch])

  useEffect(() => {
    if (error) {
      if (windowRef?.current) {
        windowRef.current.scrollTop = 0
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [error, windowRef])

  return (
    <form id="signup-form" onSubmit={handleSubmit} noValidate>
      {has_thanx && <ThanxTerms />}
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <SignUpFormCommunications>
        <Body as="p" size="small" style={{ marginBottom: 15 }}>
          Choose your communication preferences:
        </Body>
        {notificationFields.map((field) => (
          <Checkbox
            key={field.name}
            type={field.type}
            label={field.label}
            name={field.name}
            on={!!comms.includes(field.name)}
            onChange={(evt) =>
              handleComms(field.name, evt.currentTarget.checked)
            }
          />
        ))}
      </SignUpFormCommunications>
      {tpls && <ThirdPartyLoyaltyTerms />}
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default SignUpForm
