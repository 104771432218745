import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import { selectBrand, selectOrder, useAppSelector } from '@open-tender/cloud'
import { Content, HeaderCheckout, Main, PageContainer } from 'components'
import CheckoutDineInForm from './CheckoutDineInForm'
import CheckoutGuestForm from './CheckoutGuestForm'

const CheckoutGuest = () => {
  const navigate = useNavigate()
  const { title: siteTitle, tpls } = useAppSelector(selectBrand) || {}
  const { serviceType } = useAppSelector(selectOrder)

  useEffect(() => {
    if (tpls === 'COMO') navigate('/checkout/signin')
  }, [tpls, navigate])

  return (
    <>
      <Helmet>
        <title>Checkout Guest | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderCheckout />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            {serviceType === 'WALKIN' ? (
              <CheckoutDineInForm />
            ) : (
              <CheckoutGuestForm />
            )}
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default CheckoutGuest
