import { useNavigate } from 'react-router-dom'
import { serviceTypeNamesMap } from '@open-tender/utils'
import {
  closeModal,
  resetOrderType,
  selectOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, ModalContent, ModalView } from 'components'

const OrderType = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { serviceType } = useAppSelector(selectOrder)
  const serviceTypeName = serviceTypeNamesMap[serviceType ?? 'WALKIN']

  const changeOrderType = () => {
    dispatch(resetOrderType())
    dispatch(closeModal())
    navigate(`/order-type`)
  }

  const cancel = () => {
    dispatch(closeModal())
  }

  return (
    <ModalView>
      <ModalContent
        title="Change your order type"
        footer={
          <div>
            <Button onClick={changeOrderType}>Change Order Type</Button>
            <Button onClick={cancel} color="secondary">
              Keep {serviceTypeName}
            </Button>
          </div>
        }
      >
        <div>
          <p>Are you sure you want to change your order type?</p>
          <p>
            This will start you over at the beginning, but the items in your
            cart will not be affected.
          </p>
        </div>
      </ModalContent>
    </ModalView>
  )
}

export default OrderType
