import PointsShopReward from 'components/PointsShopReward'
import styled from '@emotion/styled'
import AccountSection from './AccountSection'
import AccountSectionHeader from './AccountSectionHeader'
import { isMobile } from 'react-device-detect'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { RequestError } from '@open-tender/types'

const AccountPointsShopView = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: ${(props) => props.theme.layout.padding};
  gap: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const AccountPointsShopReward = styled.div`
  flex: 1 1 50%;
`

const AccountPointsShopSection = styled(AccountSection)`
  background-color: ${(props) => props.theme.bgColors.primary};
`

const AccountPointsShopSectionHeader = styled(AccountSectionHeader)``

const AccountPointsShop = ({
  rewards,
  pointsBalance,
  error,
}: {
  rewards: any
  pointsBalance: number | null
  error?: RequestError
}) => {
  const { pointsShop } = useAppSelector(selectContentSection('account')) || {}
  const rewardsSorted = [...rewards]
    .sort((a, b) => (a.points || 0) - (b.points || 0))
    .slice(0, 2)

  if (error || !rewards.length) return null

  const content = (
    <AccountPointsShopView>
      {rewardsSorted.map((reward) => (
        <AccountPointsShopReward key={reward.id}>
          <PointsShopReward reward={reward} pointsBalance={pointsBalance} />
        </AccountPointsShopReward>
      ))}
    </AccountPointsShopView>
  )

  return isMobile ? (
    <AccountPointsShopSection>
      <AccountPointsShopSectionHeader
        title={pointsShop?.title || 'Redeem with points'}
      />
      {content}
    </AccountPointsShopSection>
  ) : (
    content
  )
}

export default AccountPointsShop
