import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GroupOrderExpanded, OrderType } from '@open-tender/types'
import {
  timezoneMap,
  isoToDateStr,
  makeOrderAddress,
  makeOrderTypeName,
} from '@open-tender/utils'
import {
  reopenGroupOrder,
  removeCustomerGroupOrder,
  selectMenuSlug,
  updateOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, Card } from 'components'
import styled from '@emotion/styled'

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;
`

const OrderCardGroup = ({ order }: { order: GroupOrderExpanded }) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    cart_id,
    revenue_center: revenueCenter,
    service_type: serviceType,
    requested_at,
    address,
    guest_count,
    guest_limit,
    spending_limit,
  } = order
  const {
    revenue_center_type: orderType,
    timezone,
    is_outpost: isOutpost,
  } = revenueCenter || {}
  const orderTypeName = makeOrderTypeName(
    (orderType ?? 'OLO') as OrderType,
    serviceType
  )
  const tz = timezone && timezoneMap[timezone]
  const requestedAt =
    requested_at === 'asap'
      ? 'ASAP'
      : isoToDateStr(requested_at, tz, 'MMMM d, yyyy @ h:mma')
  const menuSlug = useAppSelector(selectMenuSlug)

  const deleteOrder = () => {
    dispatch(removeCustomerGroupOrder({ cartId: cart_id }))
  }

  const reopenOrder = () => {
    const data = { orderId: null, orderType, serviceType, isOutpost, address }
    dispatch(updateOrder(data))
    dispatch(reopenGroupOrder(order)).then(() => setOpen(true))
  }

  useEffect(() => {
    if (open && menuSlug && menuSlug !== '/') {
      setOpen(false)
      navigate(menuSlug)
    }
  }, [open, menuSlug, navigate])

  return (
    <Card
      preface={`${guest_count || 0} Guests`}
      title={`${orderTypeName} from ${revenueCenter?.name}`}
      content={
        <>
          <p>{requestedAt}</p>
          {address && <p>{makeOrderAddress(address)}</p>}
          {guest_limit && <p>Guest limit: {guest_limit}</p>}
          {spending_limit && <p>Spending limit: ${spending_limit}</p>}
        </>
      }
      footer={
        <FooterContainer>
          <Button onClick={reopenOrder} size="small">
            Reopen
          </Button>
          <Button onClick={deleteOrder} size="small" color="secondary">
            Delete
          </Button>
        </FooterContainer>
      }
    />
  )
}

export default OrderCardGroup
