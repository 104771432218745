import styled from '@emotion/styled'

const MenuItemsView = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.menuItems.container.maxWidth};
`

const MenuItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  width: calc(100% + ${(props) => props.theme.menuItems.container.gap});
  margin-top: -${(props) => props.theme.menuItems.container.gap};
  margin-left: -${(props) => props.theme.menuItems.container.gap};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% + ${(props) => props.theme.menuItems.container.gapMobile});
    margin-top: -${(props) => props.theme.menuItems.container.gapMobile};
    margin-left: -${(props) => props.theme.menuItems.container.gapMobile};
  }
`

const MenuItemsChild = styled.div`
  label: MenuItemsChild;

  flex-grow: 0;
  padding-top: ${(props) => props.theme.menuItems.container.gap};
  padding-left: ${(props) => props.theme.menuItems.container.gap};
  flex-basis: ${(props) => props.theme.menuItems.box.width};
  max-width: ${(props) => props.theme.menuItems.box.width};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-basis: ${(props) => props.theme.menuItems.box.widthMobile};
    max-width: ${(props) => props.theme.menuItems.box.widthMobile};
    padding-top: ${(props) => props.theme.menuItems.container.gapMobile};
    padding-left: ${(props) => props.theme.menuItems.container.gapMobile};
  }
`

const MenuItems = ({ children }: { children?: React.ReactNode[] }) => {
  return (
    <MenuItemsView>
      <MenuItemsContainer>
        {children?.map((child, index) => (
          <MenuItemsChild key={index}>{child}</MenuItemsChild>
        ))}
      </MenuItemsContainer>
    </MenuItemsView>
  )
}

export default MenuItems
