import { useTheme } from '@emotion/react'
import { isMobile } from 'react-device-detect'
import { MenuItemPrice } from 'components'
import {
  MenuItemButtonDescription,
  MenuItemButtonInfoView,
  MenuItemButtonName,
  MenuItemButtonTitle,
} from './MenuItemButton.styled'

const MenuItemButtonInfo = ({
  name,
  desc,
  price,
  cals,
}: {
  name: string
  desc: string | null
  price: string
  cals: string
}) => {
  const { content } = useTheme().menuItems
  const layout = isMobile ? content.layoutMobile : content.layout
  const priceBelow = layout === 'PRICE_BELOW'
  const priceLast = layout === 'PRICE_LAST'
  const isSimple = priceBelow || priceLast

  const priceCals = (
    <MenuItemPrice isSimple={isSimple} price={price} cals={cals} />
  )

  return isSimple ? (
    <MenuItemButtonInfoView>
      <MenuItemButtonTitle>
        <MenuItemButtonName>{name}</MenuItemButtonName>
      </MenuItemButtonTitle>
      {priceBelow ? priceCals : null}
      {desc && <MenuItemButtonDescription>{desc}</MenuItemButtonDescription>}
      {priceLast ? priceCals : null}
    </MenuItemButtonInfoView>
  ) : (
    <MenuItemButtonInfoView>
      <MenuItemButtonTitle>
        <MenuItemButtonName>{name}</MenuItemButtonName>
        {priceCals}
      </MenuItemButtonTitle>
      {desc && <MenuItemButtonDescription>{desc}</MenuItemButtonDescription>}
    </MenuItemButtonInfoView>
  )
}

export default MenuItemButtonInfo
