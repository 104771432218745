import {
  selectCustomer,
  selectGroupOrderToken,
  useAppSelector,
} from '@open-tender/cloud'
import { ModalView } from 'components'
import GroupOrderGuest from './GroupOrderGuest'
import GroupOrderStart from './GroupOrderStart'
import GroupOrderShare from './GroupOrderShare'

const GroupOrder = () => {
  const { auth } = useAppSelector(selectCustomer)
  const token = useAppSelector(selectGroupOrderToken)

  return (
    <ModalView style={{ width: '60rem' }}>
      {token ? (
        <GroupOrderShare />
      ) : auth ? (
        <GroupOrderStart />
      ) : (
        <GroupOrderGuest />
      )}
    </ModalView>
  )
}

export default GroupOrder
