import styled from '@emotion/styled'

export const CategorySwitchView = styled.div`
  label: CategorySwitch;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: ${(props) => props.theme.layout.navHeight};
  height: ${(props) => props.theme.layout.navHeight};
  background-color: ${(props) => props.theme.bgColors.dark};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: ${(props) => props.theme.layout.navHeightMobile};
    height: ${(props) => props.theme.layout.navHeightMobile};
  }
  display: flex;
`

export const CategorySwitchScroll = styled.div`
  flex: 1 1 100%;
  overflow-x: scroll;
  transition: all 500ms ease;
  text-align: center;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: ${(props) => props.theme.layout.navHeight};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      height: ${(props) => props.theme.layout.navHeightMobile};
      justify-content: flex-start;
    }

    li {
      display: block;
      height: 100%;
      flex-shrink: 0;
      font-size: ${(props) => props.theme.fonts.preface.fontSize};
      padding: 0 0 0 ${(props) => props.theme.layout.padding};
      @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        padding-left: ${(props) => props.theme.layout.paddingMobile};
        // padding-right: ${(props) => props.theme.layout.paddingMobile};
        padding-right: 1rem;
      }

      &:last-child {
        padding-right: ${(props) => props.theme.layout.padding};
        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
          padding-right: ${(props) => props.theme.layout.paddingMobile};
        }
      }
    }
  }
`

export const CategorySwitchButton = styled.button<{
  active?: boolean
}>`
  label: CategorySwitchButton;
  height: 100%;
  font-family: ${(props) => props.theme.fonts.preface.family};
  font-weight: ${(props) => props.theme.fonts.preface.weight};
  letter-spacing: ${(props) => props.theme.fonts.preface.letterSpacing};
  text-transform: ${(props) => props.theme.fonts.preface.textTransform};
  -webkit-font-smoothing: ${(props) => props.theme.fonts.preface.fontSmoothing};
  font-size: ${(props) => props.theme.fonts.preface.fontSize};
  color: ${(props) =>
    props.theme.links.light[props.active ? 'hover' : 'color']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.links.light.hover};
  }
`
export const CategorySwitchNavButton = styled.button<{
  disabled?: boolean
}>`
  color: ${(props) => props.theme.links.light.color};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  align-self: center;
  flex: 0 0 5rem;
  height: 100%;
`
