import { OrderRating as OrderRatingType } from '@open-tender/types'
import {
  closeModal,
  updateCustomerOrderRating,
  useAppDispatch,
} from '@open-tender/cloud'
import { OrderRatingForm, ModalContent, ModalView } from 'components'
import { useCallback } from 'react'

export interface OrderRatingProps extends Record<string, unknown> {
  orderId: number
  orderRating: OrderRatingType | null
}

const OrderRating = ({ orderId, orderRating }: OrderRatingProps) => {
  const dispatch = useAppDispatch()
  const updateRating = useCallback(
    (orderId: number | string, data: OrderRatingType) =>
      dispatch(updateCustomerOrderRating({ orderId: orderId as number, data })),
    [dispatch]
  )
  const callback = useCallback(() => dispatch(closeModal()), [dispatch])
  return (
    <ModalView>
      <ModalContent
        title="Rate your order"
        subtitle={
          <p>Please add a rating and an any additional comments below</p>
        }
      >
        <OrderRatingForm
          orderId={orderId}
          orderRating={orderRating}
          update={updateRating}
          callback={callback}
        />
      </ModalContent>
    </ModalView>
  )
}

export default OrderRating
