import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { BackgroundImage } from '.'

const VideoView = styled.video`
  position: relative;
  z-index: 1;
  flex: 1;
  object-fit: cover;
  object-position: center;
`

const PosterView = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 2;
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

const Video = ({
  src,
  poster,
  preload = 'auto',
  autoplay = true,
  style,
}: {
  src?: string
  poster?: string | null
  preload?: string
  autoplay?: boolean
  style?: React.CSSProperties
}) => {
  const videoRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  // const video = videoRef.current
  // const isReady = video ? video.readyState >= video.HAVE_FUTURE_DATA : false

  const onCanPlay = () => {
    setLoaded(true)
  }

  // useEffect(() => {
  //   if (isReady) {
  //     setLoaded(true)
  //   }
  // }, [isReady])

  return (
    <>
      {/* {!loaded && <BackgroundLoading zIndex={2} />} */}
      {!loaded && (
        <PosterView>
          <BackgroundImage imageUrl={poster} />
        </PosterView>
      )}
      <VideoView
        ref={videoRef}
        poster={poster || undefined}
        preload={preload}
        autoPlay={autoplay}
        muted={true}
        loop={true}
        playsInline={true}
        style={style}
        onCanPlay={onCanPlay}
      >
        <source src={src} type="video/mp4" />
        <a href={src}>Download the video.</a>
      </VideoView>
    </>
  )
}

export default Video
