import { formatQuantity } from '@open-tender/utils'
import {
  MenuItemOptionPriceCals,
  MenuItemOptionPricePrice,
  MenuItemOptionPriceSep,
  MenuItemOptionPriceView,
} from './MenuItemOptionPrice.styled'

const MenuItemOptionPrice = ({
  price,
  cals,
  children,
  style,
}: {
  price?: string | null
  cals?: number | null
  children?: React.ReactNode
  style?: React.CSSProperties
}) => {
  if (!price && !cals && !children) return null

  return (
    <MenuItemOptionPriceView style={style}>
      {price ? (
        <MenuItemOptionPricePrice>{price}</MenuItemOptionPricePrice>
      ) : null}
      {price && cals ? <MenuItemOptionPriceSep /> : null}
      {cals ? (
        <MenuItemOptionPriceCals>
          {formatQuantity(`${cals}`)} Cal
        </MenuItemOptionPriceCals>
      ) : null}
      {children}
    </MenuItemOptionPriceView>
  )
}

export default MenuItemOptionPrice
