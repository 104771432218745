import { ChangeEvent, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  GroupOrderPrepTime,
  GroupOrderTime,
  isGroupOrderPrepTime,
} from '@open-tender/types'
import { makeGroupOrderTime } from '@open-tender/utils'
import {
  closeModal,
  openModal,
  selectOrder,
  selectGroupOrder,
  addCustomerGroupOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Body, ButtonLink, Button, Input, ModalContent } from 'components'
import GroupOrderSteps from './GroupOrderSteps'

const formatOrderTime = (s: string) => {
  return s ? s.replace('Today', 'today').replace('Tomorrow', 'tomorrow') : ''
}

const GroupOrderSummary = styled.div`
  & > p + p {
    margin: 0 0 1.5em;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

const SpendingLimitForm = styled('form')`
  margin: 0;

  label {
    padding: 0;

    span span:first-of-type {
      width: auto;
      flex-grow: 1;
    }

    span span:last-of-type {
      flex-grow: 0;
      input {
        width: 12rem;
        text-align: right;
        @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
`

const GroupOrderStart = () => {
  const [orderTime, setOrderTime] = useState<
    GroupOrderTime | GroupOrderPrepTime | null
  >(null)
  const [spendingLimit, setSpendingLimit] = useState<string>('')
  // const [error, setError] = useState(null)
  const dispatch = useAppDispatch()
  const { requestedAt, revenueCenter, serviceType, orderType } =
    useAppSelector(selectOrder)
  const { order_times } = revenueCenter || {}
  const orderTimes =
    order_times && serviceType ? order_times[serviceType] : null
  const args = {
    focusFirst: true,
    skipClose: true,
    isGroupOrder: true,
    style: orderTimes ? { alignItems: 'flex-start' } : {},
    revenueCenter,
    serviceType,
    orderType,
    requestedAt,
  }
  const { loading } = useAppSelector(selectGroupOrder)
  const isLoading = loading === 'pending'

  useEffect(() => {
    if (!revenueCenter || !serviceType || !requestedAt) return
    const groupOrderTime = makeGroupOrderTime(
      revenueCenter,
      serviceType,
      requestedAt
    )
    setOrderTime(groupOrderTime)
  }, [revenueCenter, serviceType, requestedAt])

  const adjust = () => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(openModal({ type: 'requestedAt', args }))
    }, 300)
  }

  const start = () => {
    const limit = spendingLimit
      ? Math.abs(parseFloat(spendingLimit)).toFixed(2)
      : null
    const data = limit ? { spendingLimit: limit } : {}
    dispatch(addCustomerGroupOrder(data))
  }

  const cancel = () => {
    dispatch(closeModal())
  }

  const handleSpendingLimit = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target
    setSpendingLimit(value)
  }

  return (
    <ModalContent
      title="Start a group order"
      subtitle={
        <p>Please confirm your order date & time before you share your cart</p>
      }
      footer={
        <div>
          <Button onClick={start} color="primary" disabled={isLoading}>
            {isLoading ? 'Starting Group Order...' : 'Start a Group Order'}
          </Button>
          <Button color="secondary" onClick={cancel}>
            Nevermind
          </Button>
        </div>
      }
    >
      {orderTime && (
        <>
          {isGroupOrderPrepTime(orderTime) ? (
            <GroupOrderSummary>
              <Body as="p">
                The current wait time for group orders is {orderTime.prepTime}{' '}
                minutes from the time the order is submitted.{' '}
              </Body>
              <Body as="p">
                <ButtonLink onClick={adjust}>
                  Choose a specific order time
                </ButtonLink>
              </Body>
            </GroupOrderSummary>
          ) : (
            <GroupOrderSummary>
              <Body as="p">
                {orderTime.isAdjusted
                  ? 'The first available group order time is'
                  : 'Your currently selected group order time is'}{' '}
                {formatOrderTime(orderTime.dateStr)}, which means that all
                orders must be submitted by{' '}
                {formatOrderTime(orderTime.cutoffDateStr)}.
              </Body>
              <Body as="p">
                <ButtonLink onClick={adjust}>
                  Choose a different time
                </ButtonLink>
              </Body>
            </GroupOrderSummary>
          )}
        </>
      )}
      <SpendingLimitForm noValidate>
        <Input
          label="Set a per guest spending Limit (or leave blank for no limit)"
          name="spending_limit"
          type="number"
          min={0}
          value={spendingLimit}
          onChange={handleSpendingLimit}
          error={null}
        />
      </SpendingLimitForm>
      <GroupOrderSteps />
    </ModalContent>
  )
}

export default GroupOrderStart
