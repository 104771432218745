import { selectBrand, useAppSelector } from '@open-tender/cloud'
import React, { useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { getRecaptchaKey } from 'utils/helpers'

declare global {
  interface Window {
    recaptchaOptions: {
      enterprise: boolean
    }
  }
}

interface RecaptchaProps {
  onChange: (token: string | null) => void
  recaptchaRef?: React.Ref<ReCAPTCHA>
}

const Recaptcha = ({ onChange, recaptchaRef }: RecaptchaProps) => {
  const { use_enterprise_recaptcha = false } = useAppSelector(selectBrand) || {}
  const recaptchaKey = getRecaptchaKey(use_enterprise_recaptcha)

  useEffect(() => {
    window.recaptchaOptions = {
      enterprise: use_enterprise_recaptcha,
    }
  }, [use_enterprise_recaptcha])

  if (!recaptchaKey) return null

  return (
    <ReCAPTCHA
      {...(use_enterprise_recaptcha ? { size: 'invisible' } : {})}
      sitekey={recaptchaKey}
      onChange={onChange}
      ref={recaptchaRef}
    />
  )
}

export default Recaptcha
