import { useRef } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
// import {
//   useHeaderAnimatedStyled,
//   useHeaderScrollFactor,
// } from './useHeaderStyles'

const HeaderContainer = styled.nav``

const HeaderView = styled.div<{
  maxWidth: string
  stuck?: boolean
  borderColor?: string
  isMobile?: boolean
  height?: string
  bgColor?: string
}>`
  position: fixed;
  z-index: 14;
  top: 0;
  right: 0;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.theme.layout.navHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  padding: 0 ${(props) => props.theme.layout.padding};
  border: 0;
  border-bottom-width: ${(props) => props.theme.border.width};
  border-style: solid;
  border-color: ${(props) =>
    props.borderColor ||
    props.theme.header[props.stuck ? 'stuckBorder' : 'primaryBorder']};
  background-color: ${(props) =>
    props.theme.header[props.stuck ? 'stuck' : 'primary']};
  // box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  padding: 0 ${(props) => (props.isMobile ? '0' : '20px')};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    box-shadow: none;
    height: ${(props) => props.theme.layout.navHeightMobile};
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const HeaderTitle = styled.div<{ titleWidth?: number }>`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  > span {
    display: block;
    max-width: ${(props) => `${(props.titleWidth || 260) / 10}rem`};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${(props) => props.theme.fonts.headings.family};
    font-weight: ${(props) => props.theme.fonts.headings.weight};
    letter-spacing: ${(props) => props.theme.fonts.headings.letterSpacing};
    text-transform: ${(props) => props.theme.fonts.headings.textTransform};
    -webkit-font-smoothing: ${(props) =>
      props.theme.fonts.headings.fontSmoothing};
    font-size: ${(props) => props.theme.fonts.sizes.big};
    color: ${(props) => props.theme.buttons.colors.header.color};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.main};
    }
  }
`

const HeaderNav = styled.div<{
  isBrowser?: boolean
}>`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

interface HeaderProps {
  left?: React.ReactNode
  title?: React.ReactNode
  renderTitle?: () => React.ReactNode
  right?: React.ReactNode
  bgColor?: string
  borderColor?: string
  maxWidth?: string
  style?: React.CSSProperties
  isStatic?: boolean
  titleWidth?: number
}

const Header = ({
  left,
  renderTitle,
  title,
  right,
  bgColor = 'primary',
  borderColor = undefined,
  maxWidth = '100%',
  style = undefined,
  titleWidth = 260,
}: HeaderProps) => {
  const header = useRef<HTMLElement | null>(null)
  const theme = useTheme()
  const { navHeight, navHeightMobile } = theme.layout
  const height = isBrowser ? navHeight : navHeightMobile

  // const scrollFactor = useHeaderScrollFactor()
  // const { headerStyles: animatedStyles } = useHeaderAnimatedStyled(scrollFactor)
  // const internalStyles = isStatic
  //   ? {
  //       backgroundColor: theme.header.primary,
  //     }
  //   : animatedStyles

  return (
    <HeaderContainer
      ref={header}
      role="navigation"
      aria-label="Primary Navigation"
    >
      <HeaderView
        height={height}
        stuck
        bgColor={bgColor}
        borderColor={borderColor}
        maxWidth={maxWidth}
        isMobile={isMobile}
        style={{ ...style }}
      >
        <HeaderNav>{left}</HeaderNav>
        {title && (
          <HeaderTitle titleWidth={titleWidth}>
            <span>{title}</span>
          </HeaderTitle>
        )}
        {renderTitle?.()}
        <HeaderNav isBrowser={isBrowser}>{right}</HeaderNav>
      </HeaderView>
    </HeaderContainer>
  )
}

export default Header
