import styled from '@emotion/styled'
import { Headline } from 'components'

export const SidebarView = styled.aside`
  position: fixed;
  z-index: 17;
  top: 0;
  bottom: 0;
  right: 0;
  width: 48rem;
  max-width: 100%;
  background-color: ${(props) => props.theme.bgColors.primary};

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`

export const SidebarHeader = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: ${(props) => props.theme.bgColors.primary};

  p {
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    font-size: ${(props) => props.theme.fonts.sizes.small};

    span {
      padding: 0;
    }
  }

  p + p {
    margin: 1rem 0 0;
  }

  div {
    margin: 2rem auto 0;

    p {
      color: ${(props) => props.theme.colors.alert};
    }
  }
`

export const SidebarHeaderTitle = styled(Headline)`
  margin: 0 0 1rem -0.1rem;
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
`

export const SidebarFooter = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 7rem;
  background-color: ${(props) => props.theme.bgColors.primary};
`

export const SidebarButtons = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
    // height: 5rem;
    // padding: 0;
    max-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

export const SidebarBack = styled.div`
  width: 50%;
  padding: 0 0.5rem 0 2rem;
`

export const SidebarCheckout = styled.div`
  width: 50%;
  padding: 0 2rem 0 0.5rem;
`

export const SidebarCart = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 0 1rem;
`

export const SidebarCartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`
