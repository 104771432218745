import { GiftCardAssignForm, ModalContent, ModalView } from 'components'

const GiftCardAssign = ({
  windowRef,
}: {
  windowRef: React.RefObject<HTMLDivElement>
}) => {
  return (
    <ModalView>
      <ModalContent
        title="Add gift card to your account"
        subtitle={
          <p>
            Have a gift card number from a physical gift card or gift card
            email? Enter it below to associate the card with your online
            account.
          </p>
        }
      >
        <GiftCardAssignForm windowRef={windowRef} />
      </ModalContent>
    </ModalView>
  )
}

export default GiftCardAssign
