import styled from '@emotion/styled'

const CardModifier = styled.div`
  label: CardModifier;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.theme.cards.modifier.displayAsCard
      ? `
  padding: 15px;
  border-style: solid;
  border-width: ${props.theme.cards.modifier.borderWidth};
  border-color: ${props.theme.cards.modifier.borderColor};
  border-radius: ${props.theme.cards.modifier.borderRadius};
  background-color: ${props.theme.cards.modifier.bgColor};
  box-shadow: ${props.theme.cards.modifier.boxShadow};`
      : ''};
`

export default CardModifier
