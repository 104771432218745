import { useState, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import {
  CheckoutErrorMessages,
  ConfigSettingsCheckout,
} from '@open-tender/types'
import { formatDollars } from '@open-tender/utils'
import {
  selectCheckout,
  selectContent,
  selectSettings,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { Button, FormError, Input } from 'components'
import CheckoutSection from './CheckoutSection'
import CheckoutButton from './CheckoutButton/CheckoutButton'
import CheckoutPromoCodesGuest from './CheckoutPromoCodesGuest'

const CheckoutPromoCodesView = styled.div`
  margin: 1.5rem 0 0;
`

const CheckoutPromoCodeNew = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 0;
`

const CheckoutPromoCodeInput = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 2rem 0 0;
`

const CheckoutPromoCodeButton = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

const getPromoCodeLimit = (settings?: ConfigSettingsCheckout) => {
  const limit = settings?.orderTypes?.OLO?.promo_code_limit
  return limit || 0
}

const makePromoCodeError = (errors: CheckoutErrorMessages, index: number) => {
  const promoCodeErrors = errors.promo_codes
  if (!promoCodeErrors || typeof promoCodeErrors === 'string') return null
  return promoCodeErrors ? (promoCodeErrors[index] as string) : null
}

const CheckoutPromoCodes = () => {
  const dispatch = useAppDispatch()
  const [promoCode, setPromoCode] = useState('')
  const [error, setError] = useState('')
  const [pendingPromoCode, setPendingPromoCode] = useState<string | null>(null)
  const { check, form, loading, errors } = useAppSelector(selectCheckout)
  const { email } = form.customer
  const { checkout: config } = useAppSelector(selectContent) || {}
  const { checkout: settings } = useAppSelector(selectSettings) || {}
  const promoCodeLimit = getPromoCodeLimit(settings)
  const applied = useMemo(
    () =>
      check?.discounts
        .filter((i) => i.is_promo_code)
        .map((i) => i.name.trim().toLowerCase()),
    [check?.discounts]
  )
  const index = applied ? applied.length : 0
  const promoCodeError = makePromoCodeError(errors, index)
  const showNew = !promoCodeLimit || (applied?.length ?? 0) < promoCodeLimit

  useEffect(() => {
    if (loading !== 'pending') setPendingPromoCode(null)
    if (applied?.includes(promoCode)) setPromoCode('')
  }, [loading, applied, promoCode])

  useEffect(() => {
    if (promoCodeError) {
      setError(promoCodeError)
      dispatch(updateForm({ promoCodes: applied }))
    }
  }, [promoCodeError, dispatch, applied])

  useEffect(() => {
    if (!promoCodeError && !promoCode) {
      setError('')
    }
  }, [promoCodeError, promoCode])

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setPromoCode(evt.target.value.trim().toLowerCase())
  }

  const applyPromoCode = async () => {
    const cleanCode = promoCode.trim().toLowerCase()
    setPendingPromoCode(cleanCode)
    await dispatch(updateForm({ promoCodes: [...(applied ?? []), cleanCode] }))
    dispatch(validateOrder())
  }

  const removePromoCode = async (code: string) => {
    setPendingPromoCode(code)
    const filtered = form.promoCodes.filter(
      (i) => i.trim().toLowerCase() !== code.trim().toLowerCase()
    )
    await dispatch(updateForm({ promoCodes: filtered }))
    dispatch(validateOrder())
  }

  // const removePendingPromoCode = () => {
  //   setPromoCode('')
  //   setPendingPromoCode(null)
  //   setError('')
  //   if (form.promoCodes.length > applied.length) {
  //     dispatch(updateForm({ promoCodes: applied }))
  //   }
  // }

  if (!email) return <CheckoutPromoCodesGuest />

  return (
    <CheckoutSection title={config?.promoCodes.title}>
      <CheckoutPromoCodesView>
        {applied?.map((appliedCode) => {
          const discount = check?.discounts.find((i) => i.name === appliedCode)
          const amount = discount
            ? formatDollars(`${-parseFloat(discount.amount as string)}`)
            : null
          const subtitle = amount ? `${amount} discount applied` : undefined
          return (
            <CheckoutButton
              key={appliedCode}
              title={appliedCode}
              subtitle={subtitle}
              onPress={() => removePromoCode(appliedCode)}
              isApplied={true}
              disabled={pendingPromoCode ? true : false}
            />
          )
        })}
        {showNew && (
          <>
            <CheckoutPromoCodeNew>
              <CheckoutPromoCodeInput>
                <Input
                  type="text"
                  name="new_promo_code"
                  label={
                    applied?.length
                      ? 'Enter another promo code'
                      : 'Enter promo code'
                  }
                  value={promoCode}
                  onChange={handleChange}
                  style={{ margin: 0 }}
                />
              </CheckoutPromoCodeInput>
              <CheckoutPromoCodeButton>
                <Button
                  onClick={applyPromoCode}
                  disabled={!promoCode || pendingPromoCode ? true : false}
                  size="small"
                  color="secondary"
                >
                  {pendingPromoCode ? 'Applying...' : 'Apply'}
                </Button>
              </CheckoutPromoCodeButton>
            </CheckoutPromoCodeNew>
            <FormError errMsg={error} />
          </>
        )}
      </CheckoutPromoCodesView>
    </CheckoutSection>
  )
}

export default CheckoutPromoCodes
