import {
  selectBrand,
  selectContentSection,
  selectDisplaySettings,
  selectOrder,
  selectSelectedAllergenNames,
  selectSelectedTagNames,
  selectTimezone,
  useAppSelector,
} from '@open-tender/cloud'
import { CartItemGroups } from '@open-tender/types'
import {
  dateStrToDate,
  makeSuspendUnitlMsg,
  useMenuItemsFilter,
} from '@open-tender/utils'

export const getActiveElement = (elements: HTMLElement[], offset = 0) => {
  return elements
    .filter((i) => i.getBoundingClientRect().top <= offset)
    .reduce((max: HTMLElement | null, i: HTMLElement) => {
      return max &&
        max.getBoundingClientRect().top > i.getBoundingClientRect().top
        ? max
        : i
    }, null)
}

// https://stackoverflow.com/questions/51229742/javascript-window-scroll-behavior-smooth-not-working-in-safari
export const smoothHorizontalScrolling = (
  container: HTMLElement,
  time: number,
  amount: number,
  start: number
) => {
  let eAmt = amount / 100
  let curTime = 0
  let scrollCounter = 0
  while (curTime <= time) {
    window.setTimeout(shs, curTime, container, scrollCounter, eAmt, start)
    curTime += time / 100
    scrollCounter++
  }
}

const shs = (
  elem: HTMLElement,
  scrollCounter: number,
  eAmt: number,
  start: number
) => {
  elem.scrollLeft = eAmt * scrollCounter + start
}

export const isExpired = (
  expires_at: string | null,
  end_date: string | null
) => {
  const isExpired = expires_at ? dateStrToDate(expires_at) < new Date() : false
  const isEnded = end_date ? dateStrToDate(end_date) < new Date() : false
  return isExpired || isEnded
}

export const isSandbox =
  window.location.href.includes('sandbox') ||
  window.location.href.includes('localhost')

export const generateRandomSessionID = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000
}

export const sha256 = async (value: string) => {
  const utf8 = new TextEncoder().encode(value)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}

export type UpsellMenuPosition = 'MENU_ITEM' | 'CART' | 'CHECKOUT'

export const useMenuFilters = () => {
  const { tagsFilter, allergensFilter } =
    useAppSelector(selectDisplaySettings) || {}
  const { view_suspended_item } = useAppSelector(selectBrand) || {}
  const { requestedAt } = useAppSelector(selectOrder)
  const selectedTagsNames = useAppSelector(selectSelectedTagNames)
  const selectedAllergens = useAppSelector(selectSelectedAllergenNames)
  const { filterItems } = useMenuItemsFilter(
    requestedAt,
    selectedTagsNames,
    selectedAllergens,
    view_suspended_item ?? 'HIDE',
    tagsFilter,
    allergensFilter
  )
  return { filterItems }
}

export const getRecaptchaKey = (useEnterprise?: boolean) => {
  const key = useEnterprise
    ? process.env.REACT_APP_ENTERPRISE_RECAPTCHA_KEY
    : process.env.REACT_APP_RECAPTCHA_KEY
  return key || null
}
export const useModifiersFilters = () => {
  const { view_suspended_item } = useAppSelector(selectBrand) || {}

  const filterModifiers = (groups: CartItemGroups): CartItemGroups => {
    if (view_suspended_item !== 'HIDE') return groups
    return groups.map((g) => {
      return {
        ...g,
        options: g.options
          .filter((o) => !o.isSoldOut)
          .map((o) => {
            return {
              ...o,
              groups: filterModifiers(o.groups),
            }
          }),
      }
    })
  }

  return filterModifiers
}

export const useSuspendUntil = (suspendedUntil: number | null | undefined) => {
  const tz = useAppSelector(selectTimezone)
  const { soldOutMessage } = useAppSelector(selectContentSection('menu')) || {}
  const { view_suspended_item } = useAppSelector(selectBrand) || {}

  const soldOutMsg = suspendedUntil
    ? makeSuspendUnitlMsg(
        suspendedUntil,
        tz,
        soldOutMessage,
        view_suspended_item === 'SHOW_DATETIME'
      )
    : null

  return soldOutMsg
}
