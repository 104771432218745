import { openModal, useAppDispatch } from '@open-tender/cloud'
import { LogOut } from '../icons'
import Icon from './Icon'

const LeaveGroupIcon = ({
  style,
  fill,
}: {
  style?: React.CSSProperties
  fill?: string
}) => {
  const dispatch = useAppDispatch()

  const leave = () => {
    dispatch(openModal({ type: 'groupOrderLeave' }))
  }

  return (
    <Icon aria-label="Leave Group Order" onClick={leave} style={style}>
      <LogOut size={22} fill={fill || 'none'} />
    </Icon>
  )
}

export default LeaveGroupIcon
