import styled from '@emotion/styled'
import {
  FormFieldType,
  isCheckbox,
  isInput,
  isSelect,
  isSwitch,
  isTextarea,
} from '@open-tender/types'
import Textarea from './Textarea'
import Checkbox from './Checkbox'
import Input from './Input'
import Switch from './Switch'
import Select from './Select'

export const FormInputsView = styled.div`
  width: 100%;

  button + button {
    margin-left: 1rem;
  }
`

const FormInputs = <T extends {}>({
  fields,
  data,
  onChange,
  errors,
}: {
  fields: FormFieldType[]
  data: T
  onChange: (field: FormFieldType, value: string | number | boolean) => void
  errors: Record<string, string>
}) => {
  return (
    <FormInputsView>
      {fields.map((field) => {
        if (isTextarea(field)) {
          return (
            <Textarea
              key={field.name}
              type={field.type}
              label={field.label}
              name={field.name}
              value={data[field.name as keyof T] as string}
              onChange={(evt) => onChange(field, evt.currentTarget.value)}
              error={errors[field.name]}
              required={field.required}
            />
          )
        } else if (isCheckbox(field)) {
          return (
            <Checkbox
              key={field.name}
              type={field.type}
              label={field.label}
              name={field.name}
              on={!!data[field.name as keyof T]}
              onChange={(evt) => onChange(field, evt.currentTarget.checked)}
            />
          )
        } else if (isSwitch(field)) {
          return (
            <Switch
              key={field.name}
              type={field.type}
              label={field.label}
              name={field.name}
              on={!!data[field.name as keyof T]}
              onChange={(evt) => onChange(field, evt.currentTarget.checked)}
            />
          )
        } else if (isSelect(field)) {
          return (
            <Select
              key={field.name}
              type={field.type}
              label={field.label}
              showLabel={field.showLabel || false}
              name={field.name}
              value={data[field.name as keyof T] as string}
              onChange={(evt) => onChange(field, evt.currentTarget.value)}
              options={field.options}
            />
          )
        } else if (isInput(field)) {
          return (
            <Input
              key={field.name}
              label={field.label}
              name={field.name}
              type={field.type}
              value={data[field.name as keyof T] as string}
              onChange={(evt) => onChange(field, evt.currentTarget.value)}
              error={errors[field.name]}
              required={field.required}
              disabled={field.disabled}
            />
          )
        } else {
          return null
        }
      })}
    </FormInputsView>
  )
}

export default FormInputs
