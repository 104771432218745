import { useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { Customer } from '@open-tender/types'
import {
  linkPosToken,
  openModal,
  selectBrand,
  selectContent,
  selectCustomer,
  sendOneTimePasscode,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonLink,
  Content,
  FormWrapper,
  Main,
  PageTitle,
  HeaderDefault,
  PageContainer,
  PageContent,
  SignUpForm,
  OneTimePasscodeForm,
} from 'components'

const SignUpFormWrapper = styled(FormWrapper)`
  form + div > p {
    text-align: center;
  }
`

const comoVerifyContent = {
  title: 'Success! Just one last step.',
  subtitle:
    'We just sent a 4 digit security code to the phone number you entered. Please enter this code to verify your phone number and complete the sign up process.',
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const SignUp = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const posToken = useQuery().get('pos-token')
  const [verify, setVerify] = useState(false)
  const { signUp: signupConfig } = useAppSelector(selectContent) || {}
  const { title: siteTitle, tpls } = useAppSelector(selectBrand) || {}
  const { auth } = useAppSelector(selectCustomer)
  const token = auth ? auth.access_token : null

  const callback =
    tpls === 'COMO'
      ? (data: Customer) => {
          setVerify(true)
          const { phone } = data
          dispatch(sendOneTimePasscode({ data: { phone } }))
        }
      : undefined

  useEffect(() => {
    if (auth) {
      if (posToken) {
        dispatch(linkPosToken(posToken)).finally(() => navigate('/account'))
      } else {
        return navigate('/account')
      }
    }
  }, [auth, navigate, posToken, token, dispatch])

  const login = () => {
    dispatch(openModal({ type: 'login' }))
  }

  return (
    <>
      <Helmet>
        <title>
          {signupConfig?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            {verify ? (
              <>
                <PageTitle {...comoVerifyContent} />
                <SignUpFormWrapper>
                  <OneTimePasscodeForm />
                </SignUpFormWrapper>
              </>
            ) : (
              <>
                <PageTitle {...signupConfig}>
                  <p style={{ margin: '2rem 0' }}>
                    Already have an account?{' '}
                    <ButtonLink onClick={login}>
                      Click here to log in.
                    </ButtonLink>
                  </p>
                </PageTitle>
                <FormWrapper>
                  {!auth ? <SignUpForm callback={callback} /> : null}
                </FormWrapper>
              </>
            )}
            <PageContent>
              <p>
                <Link to="/account">{signupConfig?.back}</Link>
              </p>
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default SignUp
