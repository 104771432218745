import styled from '@emotion/styled'
import { Customer } from '@open-tender/types'
import {
  openModal,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink } from 'components'
import { AlertCircle } from 'components/icons'

const CheckoutPhoneView = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.colors.error};

  span {
    display: block;
    margin: 0 0 0 0.5rem;
    color: ${(props) => props.theme.colors.error};
  }

  button {
    display: block;
    margin: 0 0 0 1rem;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.error};
  }
`

const CheckoutPhone = ({
  callback,
}: {
  callback: (data: Customer) => void
}) => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector(selectCustomer)
  const { phone } = profile || {}

  const add = () => {
    dispatch(openModal({ type: 'profile', args: { callback } }))
  }

  return phone ? (
    <p>{phone}</p>
  ) : (
    <CheckoutPhoneView>
      <AlertCircle size={14} />
      <span>phone number required</span>
      <ButtonLink onClick={add}>add one</ButtonLink>
    </CheckoutPhoneView>
  )
}

export default CheckoutPhone
