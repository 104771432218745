import { useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import { scroller, Element } from 'react-scroll'
import { Helmet } from 'react-helmet'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  fetchAnnouncementPage,
  selectAnnouncementsPage,
  selectBrand,
  selectContent,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  BackgroundContent,
  Button,
  Content,
  Main,
  HeaderSite,
  PageHero,
  PageIntro,
} from 'components'

const AboutView = styled.div``

const About = () => {
  const dispatch = useAppDispatch()
  const { colors } = useTheme()
  const brand = useAppSelector(selectBrand)
  const { about } = useAppSelector(selectContent) || {}
  const { background, mobile, title, subtitle, content } = about || {}
  const announcements = useAppSelector(selectAnnouncementsPage('MENU'))

  const scrollToMenu = () => {
    scroller.scrollTo('aboutCards', {
      duration: 500,
      smooth: true,
      offset: -120,
    })
  }

  useEffect(() => {
    dispatch(fetchAnnouncementPage('MENU'))
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>About | {brand?.title || ''}</title>
      </Helmet>
      <Content>
        <HeaderSite />
        <Main style={{ paddingTop: '0' }}>
          <PageHero
            announcements={announcements}
            imageUrl={isBrowser ? background : mobile}
          >
            <BackgroundContent
              title={title ?? ''}
              subtitle={subtitle ?? ''}
              title_color={colors.light}
              subtitle_color={colors.light}
              vertical="BOTTOM"
              horizontal="LEFT"
            >
              <Button onClick={scrollToMenu} size="big" color="light">
                Learn More
              </Button>
            </BackgroundContent>
          </PageHero>
          <AboutView>
            <PageIntro content={content} />
            <Element name="aboutCards"></Element>
          </AboutView>
        </Main>
      </Content>
    </>
  )
}

export default About
