import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { OrderRating, OrderRatingWithOrder } from '@open-tender/types'
import { isEmpty, makeFormErrors } from '@open-tender/utils'
import {
  fetchOrderRating,
  resetOrderRating,
  selectBrand,
  selectCustomer,
  selectOrderRating,
  updateOrderRating,
  unsubscribeOrderRating,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  FormWrapper,
  Heading,
  HeaderDefault,
  Loading,
  Main,
  Message,
  OrderRatingForm,
  PageContainer,
  PageContent,
  PageTitle,
} from 'components'

const makePageTitles = (
  orderRating: OrderRatingWithOrder | null,
  isSubmitted: boolean,
  unsubscribe: string | null,
  isCancelled: boolean
) => {
  if (unsubscribe) {
    return {
      title: "You've been unsubscribed",
      subtitle: 'You will not receive future order rating emails.',
    }
  } else if (isSubmitted) {
    return {
      title: 'Thanks for rating your order!',
      subtitle:
        'You can update your rating from your Order History if you need to make any adjustments.',
    }
  } else if (orderRating && orderRating.order_id) {
    return {
      title: `Almost done!`,
      subtitle: `Please verify your rating and (optionally) add any comments, and then click Submit.`,
    }
  } else if (isCancelled) {
    return {
      title: 'This order has been cancelled',
    }
  } else {
    return {
      title: "Sorry, but we couldn't find your order",
      subtitle: 'Please try clicking on the link in the email again',
    }
  }
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Rating = () => {
  const dispatch = useAppDispatch()
  const query = useQuery()
  const queryRating = query.get('rating')
  const unsubscribe = query.get('unsubscribe')
  const [submitted, setSubmitted] = useState(false)
  const { id: ratingUuid } = useParams()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { orderRating, loading, error } = useAppSelector(selectOrderRating)
  const { auth } = useAppSelector(selectCustomer)
  const errors = makeFormErrors(error)
  const errMsg = isEmpty(errors) ? null : errors.form
  const isCancelled = errMsg ? errMsg.includes('cancelled') : false
  const title = orderRating
    ? `Rating Order #${orderRating.order_id}`
    : 'Rating Not Found'
  const isSubmitted = submitted && !error && loading !== 'pending'
  const pageTitles = makePageTitles(
    orderRating,
    isSubmitted,
    unsubscribe,
    isCancelled
  )
  const adjustedRating =
    queryRating && !submitted
      ? ({ ...orderRating, rating: parseInt(queryRating) } as OrderRating)
      : orderRating

  const updateRating = useCallback(
    (orderId: number | string, data: OrderRating) =>
      dispatch(updateOrderRating({ orderUuid: orderId as string, data })),
    [dispatch]
  )

  const callback = useCallback(() => setSubmitted(true), [setSubmitted])

  useEffect(() => {
    if (unsubscribe && ratingUuid) {
      dispatch(unsubscribeOrderRating(ratingUuid))
    } else if (ratingUuid) {
      dispatch(fetchOrderRating(ratingUuid))
    }
    return () => {
      dispatch(resetOrderRating())
    }
  }, [dispatch, ratingUuid, unsubscribe])

  return (
    <>
      <Helmet>
        <title>
          {title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...pageTitles} />
            <PageContent>
              {loading === 'pending' ? (
                <Loading text="Retrieving order rating..." />
              ) : errMsg ? (
                <Message color="error" style={{ width: '100%' }}>
                  {errMsg}
                </Message>
              ) : submitted ? (
                <p>
                  {auth ? (
                    <Link to="/account">Head back to your account page</Link>
                  ) : (
                    <Link to="/account">Head back to the home page</Link>
                  )}
                </p>
              ) : orderRating && ratingUuid ? (
                <FormWrapper>
                  <Heading
                    as="p"
                    size="h4"
                    style={{ textAlign: 'left', margin: '0 0 3rem' }}
                  >
                    Rate Order #{orderRating.order_id}
                  </Heading>
                  <OrderRatingForm
                    orderId={ratingUuid}
                    orderRating={adjustedRating}
                    update={updateRating}
                    callback={callback}
                  />
                </FormWrapper>
              ) : null}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Rating
