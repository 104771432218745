import styled from '@emotion/styled'
import { BaseItem } from '@open-tender/types'
import { X } from 'components/icons'
import { Body } from 'components'
import MenuItemSelectedCount from './MenuItemSelectedCount'

const MenuItemSelectedOptionView = styled.span`
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.2rem 0.7rem;
  margin: 0 0.5rem 0 0;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

const MenuItemSelectedOptionContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MenuItemSelectedOptionName = styled(Body)`
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
`

const MenuItemSelectedOptionRemove = styled.button`
  display: block;
  margin: 0 0 0 0.5rem;
`

const MenuItemSelectedOption = <T extends BaseItem>({
  option,
  decrement,
}: {
  option: T
  decrement?: null | ((option: T) => void)
}) => {
  return (
    <MenuItemSelectedOptionView>
      {(option.quantity ?? 0) > 1 ? (
        <MenuItemSelectedCount count={option.quantity ?? 0} />
      ) : null}
      <MenuItemSelectedOptionContainer>
        <MenuItemSelectedOptionName>{option.name}</MenuItemSelectedOptionName>
        {decrement ? (
          <MenuItemSelectedOptionRemove onClick={() => decrement(option)}>
            <X size={10} />
          </MenuItemSelectedOptionRemove>
        ) : null}
      </MenuItemSelectedOptionContainer>
    </MenuItemSelectedOptionView>
  )
}

export default MenuItemSelectedOption
