import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components'

const BackgroundCtaView = styled.div`
  margin: 2rem 0 0;

  button {
    font-size: ${(props) => props.theme.fonts.sizes.xBig};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.fonts.sizes.main};
    }
  }
`

interface BackgroundCtaProps {
  url: string | null
  urlText: string
  children?: JSX.Element | JSX.Element[]
}

const BackgroundCta = ({ url, urlText, children }: BackgroundCtaProps) => {
  const navigate = useNavigate()
  const isNavigate = url ? url.includes(window.location.origin) : false
  const linkUrl = isNavigate ? url?.replace(window.location.origin, '') : url

  if (!children && (!url || !urlText)) return null

  return (
    <BackgroundCtaView>
      {children ? (
        children
      ) : isNavigate ? (
        <Button
          onClick={() => navigate(linkUrl ?? '')}
          size="big"
          color="light"
        >
          {urlText}
        </Button>
      ) : (
        <form action={url ?? ''}>
          <Button type="submit" size="big" color="light">
            {urlText}
          </Button>
        </form>
      )}
    </BackgroundCtaView>
  )
}

export default BackgroundCta
