import styled from '@emotion/styled'

export const MenuItemPizzaView = styled.div<{ isVertical?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const MenuItemPizzaContainer = styled.div<{ isVertical?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => (props.isVertical ? 'column-reverse' : 'row')};
  gap: ${(props) => (props.isVertical ? '0.75rem' : '2rem')};
  margin: ${(props) => (props.isVertical ? '1rem 0 0' : '0 0.8rem 0 0')};
`

export const MenuItemPizzaToggle = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 8.4rem;
  opacity: ${(props) => (props.isDisabled ? '0.25' : '1')};
`

export const MenuItemPizzaAmountView = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    bottom: 0.2rem;
  }

  button {
    transform: scale(0.8);
    margin: 0 -0.4rem;
  }

  button:disabled {
    color: ${(props) => props.theme.buttons.colors.secondary.bgColor};
    background-color: ${(props) => props.theme.buttons.colors.secondary.color};
    opacity: 1;
  }
`

export const MenuItemPizzaAmountButton = styled.button`
  min-width: 6rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.bgColors.primary};
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }

  &:disabled {
    border-color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.bgColors.primary};
    cursor: pointer;
    opacity: 1;
  }
`
