import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  Body,
  ButtonLink,
  FormWrapper,
  GuestCheckoutForm,
  PageContainer,
  PageTitle,
} from 'components'

const defaultText = {
  title: 'Checkout as a guest',
  subtitle: 'Please fill out all fields to checkout as a guest.',
}

const CheckoutSignUpGuestFooter = styled.div`
  text-align: center;

  p {
    margin: 1em 0;
  }
`

const CheckoutSignUpGuest = () => {
  const navigate = useNavigate()

  return (
    <PageContainer style={{ maxWidth: '76.8rem' }}>
      <PageTitle {...defaultText}>
        <CheckoutSignUpGuestFooter>
          <Body as="p" size="small">
            Want to start earning rewards?{' '}
            <ButtonLink onClick={() => navigate(`/signup`)}>
              Sign up for an account instead.
            </ButtonLink>
          </Body>
        </CheckoutSignUpGuestFooter>
      </PageTitle>
      <FormWrapper>
        <GuestCheckoutForm />
      </FormWrapper>
    </PageContainer>
  )
}

export default CheckoutSignUpGuest
