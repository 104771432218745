import { ButtonStyledView } from './Button'

interface ButtonSubmitProps {
  submitRef?: React.LegacyRef<HTMLButtonElement>
  disabled?: boolean
  submitting?: boolean
  children: JSX.Element | JSX.Element[] | string
  size?: string
  color?: string
  style?: React.CSSProperties
}

const ButtonSubmit = ({
  submitRef,
  disabled,
  submitting,
  children,
  size = 'default',
  color = 'primary',
  style = undefined,
}: ButtonSubmitProps) => {
  return (
    <ButtonStyledView
      ref={submitRef}
      type="submit"
      disabled={disabled || submitting}
      size={size}
      color={color}
      style={style}
    >
      {children}
    </ButtonStyledView>
  )
}

export default ButtonSubmit
