import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { ISOString, Order } from '@open-tender/types'
import {
  timezoneMap,
  isoToDateStr,
  isoToDate,
  makeOrderTypeName,
} from '@open-tender/utils'
import { BgImage, Body, Box, Heading, OrderTag } from 'components'
import { HOVER_BOX_SHADOW } from 'utils/constants'
// import Circle from 'components/Circle'

const OrderCardSimpleButton = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  &:hover > div {
    box-shadow: ${HOVER_BOX_SHADOW};
  }
`

const OrderCardSimpleView = styled(Box)`
  position: relative;
  overflow: hidden;
  height: 100%;
`

const OrderCardSimpleTag = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  & > span {
    padding: 0.3rem 1rem 0.4rem;
  }
`

// const OrderCardSimpleImageCircle = styled(Circle)`
//   margin-bottom: 2rem;
// `

const OrderCardSimpleImage = styled(BgImage)`
  width: 100%;
  padding: 33.33333% 0;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 33.33333% 0;
  }
`

// const OrderCardSimpleContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `

const OrderCardSimpleContent = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`

const OrderCardSimpleTitle = styled(Heading)`
  text-align: center;
`

const OrderCardSimpleDescription = styled(Body)`
  text-align: center;
`

interface OrderCardSimpleProps {
  order: Order
}

const OrderCardSimple = ({ order }: OrderCardSimpleProps) => {
  const navigate = useNavigate()
  const {
    order_id,
    service_type,
    order_type,
    revenue_center,
    requested_at,
    timezone,
    cart,
    status,
    order_prep,
  } = order
  const { is_in_prep_queue = false } = order_prep || {}
  const orderTypeName = makeOrderTypeName(order_type, service_type)
  const tz = timezoneMap[timezone]
  const requestedAt = isoToDateStr(
    requested_at as ISOString,
    tz,
    'MMMM d, yyyy'
  )
  const isUpcoming = isoToDate(requested_at as ISOString) > new Date()
  const sorted = [...cart].sort(
    (a, b) => parseFloat(b.price_total) - parseFloat(a.price_total)
  )
  const itemImages = sorted
    .map((i) =>
      i.images
        .filter((m) => m.type === 'SMALL_IMAGE' && m.url)
        .map((image) => ({ title: i.name, imageUrl: image.url }))
    )
    .flat()

  const bgStyle =
    itemImages.length > 0
      ? {
          backgroundImage: `url(${itemImages[0].imageUrl}`,
        }
      : undefined

  return (
    <OrderCardSimpleButton
      onClick={() => navigate(`/orders/${order_id}`)}
      // label={`View details of order #${order_id}`}
    >
      <OrderCardSimpleView>
        <OrderCardSimpleTag>
          {/* {isUpcoming && <Tag text="Coming Up" color="alert" />} */}
          <OrderTag
            isUpcoming={isUpcoming}
            status={status}
            inQueue={is_in_prep_queue}
          />
        </OrderCardSimpleTag>
        {/* <OrderCardSimpleImageCircle strokeWidth={7}>
          <OrderCardSimpleImage style={bgStyle} />
        </OrderCardSimpleImageCircle> */}
        <OrderCardSimpleImage style={bgStyle} />
        <OrderCardSimpleContent>
          <OrderCardSimpleTitle as="p" className="title" size="main">
            {orderTypeName} from {revenue_center.name}
          </OrderCardSimpleTitle>
          <OrderCardSimpleDescription as="p" size="xSmall">
            Order #{order_id}
          </OrderCardSimpleDescription>
          <OrderCardSimpleDescription as="p" size="xSmall">
            {requestedAt}
          </OrderCardSimpleDescription>
        </OrderCardSimpleContent>
      </OrderCardSimpleView>
    </OrderCardSimpleButton>
  )
}

export default OrderCardSimple
